import React from 'react'

export default function Desktop() {

    // ----- Feature included in subscription -----
    const feature = <td><svg xmlns="http:// -----wFw.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-active bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // ----- Feature not included in subscription -----
    const featureInactive = <td><svg xmlns="http:// -----wFw.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-inactive bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    // ----- Point of question symbol -----
    const question = <td className="question"></td>;

    return (
        <table className="col-11 col-xl-10 table table-borderless table-hover">

            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Starter</th>
                    <th scope="col">Business</th>
                    <th scope="col">Premium</th>
                </tr>
            </thead>

            <tbody>

                {/* ----- FEATURES ----- */}
                <tr className="table-head">
                    <th scope="row" className="py-3"><h4>Fonctionnalités</h4></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>

                {/* 1 */}
                <tr>
                    <th scope="row"><p>Génération automatique de la facture en PDF</p></th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>

                {/* 2 */}
                <tr>
                    <th scope="row"><p>Base de données patients</p></th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>

                {/* 3 */}
                <tr>
                    <th scope="row">
                        <p>Stockage et classement de factures PDF dans votre espace de travail en ligne</p>
                    </th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>


                {/* 5 */}
                <tr>
                    <th scope="row"><p>Historisation mensuelle des factures pour votre comptabilité</p></th>
                    {question}
                    {featureInactive}
                    {feature}
                    {feature}
                </tr>

                {/* 6 */}
                <tr>
                    <th scope="row"><p>Ajout de pièces jointes aux mails à vos patients</p></th>
                    {question}
                    {featureInactive}
                    {feature}
                    {feature}
                </tr>

                {/* 7 */}
                <tr>
                    <th scope="row" className="d-flex">
                        <p>Connexion à votre agenda <b>Doctolib</b> et ajout des informations dans votre fiche patients</p>
                    </th>
                    {question}
                    {featureInactive}
                    {feature}
                    {feature}
                </tr>

                {/* 8 */}
                <tr>
                    <th scope="row" className="d-flex">
                        <p>Mail patient personnalisé (Logo, couleurs, textes...)</p>
                        <i className="index-one">(1)</i>
                    </th>
                    {question}
                    {featureInactive}
                    {featureInactive}
                    {feature}
                </tr>

                {/* 9 */}
                <tr>
                    <th scope="row" className="d-flex">
                        <p>Modèle de facture personnalisé</p>
                        <i className="index-one">(1)</i>
                    </th>
                    {question}
                    {featureInactive}
                    {featureInactive}
                    {feature}
                </tr>



                {/* ----- 10, SERVICES  -----*/}
                <tr className="table-head">
                    <th scope="row" className="py-3"><h4>Services</h4></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>

                {/* 1 , 11 */}
                <tr>
                    <th scope="row">
                        <p>Fréquence d'envois</p>
                    </th>
                    {question}
                    <td> <p>6j/7</p></td>
                    <td> <p>6j/7</p></td>
                    <td> <p>6j/7</p></td>
                </tr>

                {/* 2, 12 */}
                <tr>
                    <th scope="row">
                        <p>Volumes de factures patient par mois</p>
                    </th>
                    {question}
                    <td> <p>50</p></td>
                    <td> <p>100</p></td>
                    <td> <p>200</p></td>
                </tr>

                {/* 3, 13 */}
                <tr>
                    <th scope="row">
                        <p>Nombre de lieux d'exercice maximum</p>
                    </th>
                    {question}
                    <td> <p>1 lieu</p></td>
                    <td> <p>2 lieux</p></td>
                    <td> <p>3 lieux</p></td>
                </tr>

                {/* 4, 14 */}
                <tr>
                    <th scope="row">
                        <p>Facture à l'unité supplémentaire</p>
                    </th>
                    {question}
                    <td> <p>0,40cts</p></td>
                    <td> <p>0,30cts</p></td>
                    <td> <p>0,20cts</p></td>
                </tr>
                
                {/* 5, 15 */}
                <tr>
                    <th scope="row">
                        <p>Support Client</p>
                    </th>
                    {question}
                    <td>
                        <p>Standard</p>
                        <i>(5/7j - 9h-17h / Chat &amp; Mail)</i>
                    </td>
                    <td>
                        <p>Prioritaire</p>
                        <i> (6/7j - 9h-20h / Chat, Mail &amp; Tel)</i>
                    </td>
                    <td>
                        <p>Prioritaire</p>
                        <i> (6/7j - 9h-20h / Chat, Mail &amp; Tel)</i>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
