import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Component
import CalendarTable from '../Table/Calendar/Calendar'

// ---- Images ------
import freeTrialIllustration from '../../../images/letter.png'

export default function Calendar({ annual, token }) {
    const [prices, setPrices] = useState(annual ? {
        'starter': process.env.REACT_APP_CALENDAR_STARTER_ANNUAL_PRICE,
        'business': process.env.REACT_APP_CALENDAR_BUSINESS_ANNUAL_PRICE,
        'premium': process.env.REACT_APP_CALENDAR_PREMIUM_ANNUAL_PRICE
    } : {
        'starter': process.env.REACT_APP_CALENDAR_STARTER_MONTHLY_PRICE,
        'business': process.env.REACT_APP_CALENDAR_BUSINESS_MONTHLY_PRICE,
        'premium': process.env.REACT_APP_CALENDAR_PREMIUM_MONTHLY_PRICE
    })
    const [idPrice, setIdPrice] = useState(annual ? {
        'starter': process.env.REACT_APP_CALENDAR_STARTER_ANNUAL,
        'business': process.env.REACT_APP_CALENDAR_BUSINESS_ANNUAL,
        'premium': process.env.REACT_APP_CALENDAR_PREMIUM_ANNUAL
    } : {
        'starter': process.env.REACT_APP_CALENDAR_STARTER_MONTHLY,
        'business': process.env.REACT_APP_CALENDAR_BUSINESS_MONTHLY,
        'premium': process.env.REACT_APP_CALENDAR_PREMIUM_MONTHLY
    })

    let navigate = useNavigate();

    useEffect(() => {
        if (annual) {
            setPrices({
                'starter': process.env.REACT_APP_CALENDAR_STARTER_ANNUAL_PRICE,
                'business': process.env.REACT_APP_CALENDAR_BUSINESS_ANNUAL_PRICE,
                'premium': process.env.REACT_APP_CALENDAR_PREMIUM_ANNUAL_PRICE
            })
            setIdPrice({
                'starter': process.env.REACT_APP_CALENDAR_STARTER_ANNUAL,
                'business': process.env.REACT_APP_CALENDAR_BUSINESS_ANNUAL,
                'premium': process.env.REACT_APP_CALENDAR_PREMIUM_ANNUAL
            })
        } else {
            setPrices({
                'starter': process.env.REACT_APP_CALENDAR_STARTER_MONTHLY_PRICE,
                'business': process.env.REACT_APP_CALENDAR_BUSINESS_MONTHLY_PRICE,
                'premium': process.env.REACT_APP_CALENDAR_PREMIUM_MONTHLY_PRICE
            })
            setIdPrice({
                'starter': process.env.REACT_APP_CALENDAR_STARTER_MONTHLY,
                'business': process.env.REACT_APP_CALENDAR_BUSINESS_MONTHLY,
                'premium': process.env.REACT_APP_CALENDAR_PREMIUM_MONTHLY
            })
        }
    }, [annual]);


    // ----- Feature included in subscription -----
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // ----- Feature not included in subscription -----
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    return (
        <div className="price-pack">
            <div className="row justify-content-center max-md-flex-direction-column">

                {/* Price pack STARTER */}
                < div className="rate-block rate-light" >

                    <p className="pack-title little-title">Starter</p>

                    <hr></hr>

                    <div className="pack-price">
                        <p className="pack-free">GRATUIT</p>
                    </div>

                    <p className="pack-description">L’essentiel pour déléguer et gérer la gestion de vos rendez-vous professionnels et personnels.</p>

                    <p className="pack-features">Fonctionnalités principales</p>


                    <div className="features-block">

                        <div className="features-description features-active">
                            {feature}
                            <p>Intégration avec Google Agenda et Outlook</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Rappel par email du rendez-vous. Pour vos invités et vous.</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Notification par email des invitations / annulations de rendez-vous</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Lien de partage unique pour chacun de vos évènements</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Création de champs personnalisables dans le formulaire du rendez-vous</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Création de 3 types d'événements en illimité</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Possibilité de masquer les éléments mentionnant « Aliz »</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Rappel par SMS du rendez-vous pour vos invités</p>
                        </div>

                    </div>



                    <button className="btn-alizFull"
                        onClick={() => token ? navigate("/espace-client/souscription-etape1?assistant=3&price=" + idPrice.starter + "&eur=" + prices.starter) : window.location.href = '/login?assistant=3&price=' + idPrice.starter}
                    >
                        Tester Gratuitement
                    </button>
                </div >
                {/* ----- End Price pack STARTER */}






                {/* ----- Price pack BUSINESS -----*/}
                <div className="rate-block rate-light">

                    <p className="pack-title">Business</p>

                    <hr></hr>

                    <div className="pack-price">
                        <p>{prices.business} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>

                    <p className="pack-description">Planifiez plusieurs types de rendez-vous et ajoutez un formulaire à vos évènements.</p>

                    <p className="pack-features">Fonctionnalités principales</p>


                    <div className="features-block">
                        <div className="features-description features-active">
                            {feature}
                            <p>Intégration avec Google Agenda et Outlook</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Rappel par email du rendez-vous. Pour vos invités et vous.</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Notification par email des invitations / annulations de rendez-vous</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Lien de partage unique pour chacun de vos évènements</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Création de champs personnalisables dans le formulaire du rendez-vous</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Création de 3 types d'événements en illimité</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Possibilité de masquer les éléments mentionnant « Aliz »</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Rappel par SMS du rendez-vous pour vos invités</p>
                        </div>

                    </div>


                    <button className="btn-alizFull"
                        onClick={() => token ? navigate("/espace-client/souscription-etape1?assistant=3&price=" + idPrice.business + "&eur=" + prices.business) : window.location.href = '/login?assistant=3&price=' + idPrice.business}
                    >
                        Tester Gratuitement
                    </button>

                </div>
                {/* ----- End Price pack BUSINESS ----- */}





                {/* ----- Price pack PREMIUM ----- */}
                <div className="rate-block rate-light">

                    <p className="pack-title">Premium</p>

                    <hr></hr>

                    <div className="pack-price">
                        <p>{prices.premium} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>

                    <p className="pack-description">Toutes les fonctionnalités les plus avancées, personnalisez vos événements à volonté.</p>

                    <p className="pack-features">Fonctionnalités principales</p>


                    <div className="features-block">

                        <div className="features-description features-active">
                            {feature}
                            <p>Intégration avec Google Agenda et Outlook</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Rappel par email du rendez-vous. Pour vos invités et vous.</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Notification par email des invitations / annulations de rendez-vous</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Lien de partage unique pour chacun de vos évènements</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Création de champs personnalisables dans le formulaire du rendez-vous</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Création de 3 types d'événements en illimité</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Possibilité de masquer les éléments mentionnant « Aliz »</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Rappel par SMS du rendez-vous pour vos invités</p>
                        </div>

                    </div>


                    <button className="btn-alizFull"
                        onClick={() => token ? navigate("/espace-client/souscription-etape1?assistant=3&price=" + idPrice.premium + "&eur=" + prices.premium) : window.location.href = '/login?assistant=3&price=' + idPrice.premium}
                    >
                        Tester Gratuitement
                    </button>

                </div>
                {/* ----- End Price pack PREMIUM ----- */}


            </div >


            <div className="trial-free">
                <img src={freeTrialIllustration} alt="personnage allongé sur le ventre" />
                <h3>Bénéficiez de 15 jours gratuits et sans engagement !</h3>
            </div>

            {/* ----- Price Table -----*/}
            < CalendarTable />

        </div >
    )
}
