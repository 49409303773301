import React from 'react'
import { Link } from 'react-router-dom'

// Packages
import gsap, { Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Images
import Feature1 from '../../../images/CalendarManagement/Agenda - Illu Block 1.png'
import Feature1Webp from '../../../images/CalendarManagement/Agenda - Illu Block 1.webp'
import Feature2 from '../../../images/CalendarManagement/Agenda - Illu Block 2.png'
import Feature2Webp from '../../../images/CalendarManagement/Agenda - Illu Block 2.webp'
import Feature3 from '../../../images/CalendarManagement/Agenda - Illu Block 3.svg'


export default function FeaturesCalendar() {
    gsap.registerPlugin(ScrollTrigger);

    const sections = document.querySelectorAll('section');

    sections.forEach((section, index) => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: 'top-=800',
                toggleActions: 'play none none reverse',
                end: () => `+=${section.clientHeight / 2}`,
                // scrub: true,
                markers: true
            }
        });
        tl.fromTo(section, 2, {
            x: index === 1 ? -100 : 100,
            opacity: 0,
        },
            {
                x: 0,
                opacity: 1,
                ease: Power2
            }
        );
    })

    // ----- Title animation -----
    const title = document.querySelectorAll('h3');
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: title,
            start: 'top-=900',
            end: 'top-=750',
            toggleActions: 'play none none reverse',
            // scrub: true,
            markers: true
        }
    });
    tl.fromTo(title, 1, {
        opacity: 0,
    },
        {
            opacity: 1,
            ease: Power2
        }
    );

    return (
        <div className="Features-view">

            <div className="row justify-content-center mb-5">
                <h3>Découvrez notre solution pour la <br></br><span>gestion et la prise de vos rendez-vous</span></h3>
            </div>

            {/* ----- Feature 1 ----- */}
            <section className="row max-md-flex-direction-column-reverse mt-3 features-block">

                {/* ----- Explanations ----- */}
                <div className="feature-text offset-1 col-10 offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5 flex-direction-column ">
                    <h4>Connectez votre Agenda</h4>

                    <p className="describe-feature mt-3 mb-4">
                        Google Agenda, Outlook Agenda, connectez vous et définissez vos préférences.
                    </p>


                    <div className="mt-3">
                        <Link className="text-aliz d-flex text-decoration-none" to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_CALENDAR}>
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>

                </div>



                {/* ----- Illustration ----- */}
                <div className="offset-3 col-6 offset-sm-3 col-sm-6 offset-md-4 col-md-4 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5 ">
                    <picture>
                        <source srcSet={Feature1Webp} type="image/webp" />
                        <img className="feature-picture calendar-picture" src={Feature1} alt="Expert comptable" />
                    </picture>
                </div>
            </section>
            {/* ----- End feature 1 -----*/}



            {/* ----- Feature 2 -----*/}
            <section className="row max-md-flex-direction-column mt-1 features-block">

                {/* Illustration */}
                <div className="offset-3 col-6 offset-md-4 col-md-4 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5 ">
                    <picture>
                        <source srcSet={Feature2Webp} type="image/webp" />
                        <img className="feature-picture calendar-picture" src={Feature2} alt="Expert comptable" />
                    </picture>
                </div>

                {/* ----- Explanations ----- */}
                <div className="feature-text offset-1 col-10 offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4 flex-direction-column ">

                    <h4>Soyez notifié, vos invités aussi</h4>

                    <p className="describe-feature mt-3 mb-4">
                        Vos invités visualisent vos disponiblités, ils prennent rendez-vous en choisissant une durée proposée
                        et vous recevez par email la confirmation du rendez-vous.
                    </p>


                    <div className="mt-3">
                        <Link className="text-aliz d-flex text-decoration-none" to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_CALENDAR}>
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>
                </div>

            </section>
            {/* ----- End feature 2 ----- */}



            {/* ----- Feature 3 ----- */}
            <section className="row max-md-flex-direction-column-reverse features-block">

                {/* ----- Explanations ----- */}
                <div className="feature-text offset-1 col-10 offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5 flex-direction-column ">
                    <h4>Informations détaillées de vos rendez-vous</h4>

                    <p className="describe-feature mt-3 mb-4">
                        Paramétrez les champs à remplir lors de la prise de rendez-vous.
                        Ainsi, selon votre métier vous pouvez demander des informations précises et les collecter.
                    </p>

                    <div className="mt-3">
                        <Link className="text-aliz d-flex text-decoration-none " to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_CALENDAR}>
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>
                </div>

                {/* ----- Illustration -----*/}
                <div className="offset-3 col-6 offset-sm-3 col-sm-6 offset-md-4 col-md-4 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5">
                    <img className="feature-picture calendar-picture" id="" src={Feature3} alt="Expert comptable" />
                </div>
            </section>
            {/* ----- End feature 3 ----- */}


        </div >

    )
}
