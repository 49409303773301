import React from 'react'

// Images
import IconOclock from "../images/Au-quotidien/Icon - prise en main.svg";
import IconLogiciel from "../images/Au-quotidien/ICN - Logiciel.svg";
import IconData from "../images/Au-quotidien/Icon - Data.svg";
import IconWork from "../images/Au-quotidien/Icon - Work.svg";


export default function Summary() {
    return (
        <div className="summary-view">
            <div className="row">


                <div className="offset-1 col-10 offset-md-1 col-md-10 offset-xl-0 col-xl-3">

                    <h3>Aliz votre assistant au quotidien</h3>

                    <p className="summary-description">Aliz, c’est plus qu’un assistant, c’est une nouvelle façon de travailler. Un moyen simple pour vous libérer des tâches sans valeur ajoutée.</p>
                   
                    <a href={process.env.REACT_APP_LINK_CALENDAR_FOOTER_DEMO} target="_blank" rel="noopener noreferrer" className="summary-contact">Contactez-nous</a>

                </div>



                <div className="col-xl-8">

                    {/* ----- Row 1 ----- */}
                    <div className="row">
                        <div className="col-12 offset-sm-1 col-sm-10 offset-md-0 col-md-6 offset-xl-0 col-xl-4">
                            <img src={IconLogiciel} alt="Diagramme circulaire" />
                            <p className="description-title">0 Logiciel</p>
                            <p className="description-text">Nous n’installons aucune application sur votre ordinateur, vous nous partagez simplement des droits d’accès sur un dossier partagé.</p>
                        </div>


                        <div className="col-12 offset-sm-1 col-sm-10 offset-md-0 col-md-6 offset-xl-1 col-xl-4">
                            <img src={IconOclock} alt="Diagramme circulaire" />
                            <p className="description-title">Prise en main : 20 min</p>
                            <p className="description-text">La prise en main est rapide, en 20 minutes notre équipe vous montre simplement comment cela fonctionne.</p>
                        </div>
                    </div>



                    {/* ----- Row 2 ----- */}
                    <div className="row icon-row">
                        <div className="col-12 offset-sm-1 col-sm-10 offset-md-0 col-md-6 col-xl-4">
                            <img src={IconData} alt="Diagramme circulaire" />
                            <p className="description-title">0 données stockées chez ALiz</p>
                            <p className="description-text">Nous ne conservons aucune de vos données, votre assistant travaille pour vous et « chez vous » !</p>
                        </div>

                        <div className="col-12 offset-sm-1 col-sm-10 offset-md-0 col-md-6 offset-xl-1 col-xl-4">
                            <img src={IconWork} alt="Diagramme circulaire" />
                            <p className="description-title">Votre assistant travaille 24/7</p>
                            <p className="description-text">Eteignez votre ordinateur, l’assistant Aliz travaille 24h/24. Il est autonome et sait quoi faire !</p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
