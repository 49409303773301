import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ----- Packages -----
import gsap from 'gsap'

export default function RatePack() {
    let navigate = useNavigate();
    const [starter, setStarter] = useState(13)
    const [business, setBusiness] = useState(20)
    const [premium, setPremium] = useState(27)


    useEffect(() => {
        gsap.set('.annual-pack', { color: "#090E1A" })
        gsap.set('.monthly-pack', { color: "#6F7581" })
        setStarter(13)
        setBusiness(20)
        setPremium(27)
    }, []);


    // feature included in subscription
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // feature not included in subscription
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    return (
        <div className="ratePack-view" id="prices">

            <div id="presentation-prices">
                <div className="row justify-content-center">
                    <h3 className="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-6">Engagez votre assistant Aliz !</h3>
                </div>

                <div className="row justify-content-center">
                    <p className="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-4 presentation">Découvrez nos offres et profitez des à présent de 6 mois GRATUIT sur notre assistant administratif</p>
                </div>

                {/* Monthly or Annual subscription */}
                {/* <div className="row justify-content-center my-4">
                    <p className="monthly-pack">PLAN MENSUEL</p>

                    <label className="switch">
                        <input type="checkbox" id="pack" name="pack" checked={true} />
                        <span className="slider round"></span>
                    </label>

                    <p className="annual-pack">PLAN ANNUEL</p>
                </div> */}
            </div>
            <div className="row justify-content-center max-md-flex-direction-column">
                {/* Rate pack 1 "Pack gestion de la patientèle" */}
                <div className="rate-block rate-light">

                    <p className="pack-title little-title">Starter</p>
                    <hr></hr>
                    <div className="d-flex pack-price">
                        <p>{starter} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <div className="d-flex new-price">
                        <p>6,5 €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <p className="pack-description">L'indispensable pour commencer à déléguer vos tâches à votre assistant.</p>
                    <div className="pack-features">Fonctionnalités principales</div>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>Jusqu’à 50 crédits/mois</p>
                    </div>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>1 lieu d'exercice</p>
                    </div>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>Envoi par mail et stockage automatique des factures</p>
                    </div>

                    <div className="d-flex features-description doctolib-block features-inactive">
                        {featureInactive}
                        <p>Synchronisation de votre agenda Doctolib à l'assistant Aliz</p>
                    </div>

                    <div className="d-flex features-description features-inactive">
                        {featureInactive}
                        <p>Personnalisation des factures et des emails</p>
                    </div>

                    <button className="btn-alizFull" onClick={() => navigate('/espace-client/souscription-etape1?assistant=1&price=' + process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL)}>Je choisis cette option</button>
                </div>
                {/* End Rate pack 1 */}






                {/* Rate pack 2 "Offre Pro" */}
                <div className="rate-block rate-blue">

                    <p className="pack-title">Business</p>
                    <hr></hr>
                    <div className="d-flex pack-price">
                        <p>{business}  €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <div className="d-flex new-price">
                        <p>10 €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <p className="pack-description">La solution complète pour faire passer votre assistant au niveau supérieur et automatiser toujours plus.</p>
                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>Jusqu’à 100 crédits/mois</p>
                    </div>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>1 lieu d'exercice</p>
                    </div>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>Envoi par mail et stockage automatique des factures</p>
                    </div>

                    <div className="d-flex features-description doctolib-block features-active">
                        {feature}
                        <p>Synchronisation de votre agenda Doctolib à l'assistant Aliz</p>
                    </div>

                    <div className="d-flex features-description features-inactive">
                        {featureInactive}
                        <p>Personnalisation des factures et des emails</p>
                    </div>


                    <button className="btn-yellow" onClick={() => navigate('/espace-client/souscription-etape1?assistant=1&price=' + process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL)}>Je choisis cette option</button>
                </div>
                {/* End Rate pack 2 */}





                {/* Rate pack 3 "Offre Premium" */}
                <div className="rate-block rate-light">

                    <p className="pack-title">Premium</p>
                    <hr></hr>
                    <div className="d-flex pack-price">
                        <p>{premium}  €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <div className="d-flex new-price">
                        <p>13,5 €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <p className="pack-description">Profitez des fonctionnalités avancées et rendez toujours plus efficace le travail de votre assistant.</p>
                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>Jusqu’à 200 crédits/mois</p>
                    </div>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>3 lieux d'exercice</p>
                    </div>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>Envoi par mail et stockage automatique des factures</p>
                    </div>

                    <div className="d-flex features-description features-active doctolib-block">
                        {feature}
                        <p>Synchronisation de votre agenda Doctolib à l'assistant Aliz</p>
                    </div>

                    <div className="d-flex features-description features-active">
                        {feature}
                        <p>Personnalisation des factures et des emails</p>
                    </div>


                    <button className="btn-alizFull" onClick={() => navigate('/espace-client/souscription-etape1?assistant=1&price=' + process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL)}>Je choisis cette option</button>
                </div>
                {/* End Rate pack 3 */}


            </div>
        </div>
    )
}
