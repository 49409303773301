import React from 'react'

// ----- Constants -----
import { integrations } from "../../services/Constant/integrations";

// ----- Components -----
import Integration from './Integration'
import Summary from '../Summary'
import Testimonials from '../Testimonials'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function Integrations() {
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Découvrez les différentes intégrations de nos assistants virtuels - Aliz</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" content="Drive, Gmail, Doctolib, Sheets, impots.gouv … Toutes les intégrations." />
                <link rel="canonical" href="https://aliz.io/integrations" />
                <meta property="og:title" content="Découvrez les différentes intégrations de nos assistants virtuels - Aliz" />
            </Helmet>

            <div className="integration-view">

                <div className="integration-header row flex-direction-column">

                    <h1 className="col-10 col-lg-10 col-xl-6"> Aliz se connecte avec vos applications professionnelles</h1>

                    <p className="col-8 col-lg-6 col-xl-5">
                        Chaque assistant Aliz a été pensé pour travailler avec vos outils du quotidien.
                        Nous nous efforçons de ne pas rajouter de nouveaux outils afin que ce soit le plus simple et efficace pour vous.
                    </p>

                </div>


                <div className="row integration-components">

                    {integrations.map((data, key) => {
                        return (
                            <Integration key={key} data={data} />
                        );
                    })}

                </div>


                <Summary />

                <Testimonials background={'bg-light'} />

            </div>
        </>
    )
}
