import React, { useState, useEffect, useRef } from 'react'
import { getSmallprofile, setSmallprofile } from '../../../services/User/clientArea.js'

export default function FormPersonal({setIsUpdateProfileVisible}) {
    const input = useRef();

    const [profile, setProfile] = useState([])
    // Personal informations form 
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorsprofile, setErrorsprofile] = useState([])
    const [successprofile, setSuccessprofile] = useState("")

    const personnalData = {
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "password": password !== "" ? password : ""
    }

    useEffect(() => {
        if (successprofile === "success") {
            setIsUpdateProfileVisible(true)

        } else if (successprofile === "email") {
            setIsUpdateProfileVisible(true)
            localStorage.removeItem('TokenAliz')
            window.location.reload();
        } 
        // eslint-disable-next-line
    }, [successprofile])

    // Get profile
    useEffect(() => {
        getSmallprofile(setProfile)
    }, [])

    // put profile in input
    useEffect(() => {
        if (profile) {
            setFirstname(profile.firstname)
            setLastname(profile.lastname)
            setEmail(profile.email)
        }
    }, [profile])

    // Show or hide password
    const showPassword = () => {
        if (input.current.type === "password") {
            input.current.type = "text";
        } else {
            input.current.type = "password";
        }
    }

    const handleProfile = () => {
        setSmallprofile(personnalData, setErrorsprofile, setSuccessprofile)
    }

    return (
        <div>
            <h1>Mes informations personnelles</h1>
            <h2 className="catch-phrase">Modifiez à tout moment vos informations personnelles dans cet espace.</h2>

            <div className="col-xl-8 pl-0">
                <div className="d-flex">
                    <div className="col-5">
                        <label htmlFor="firstname">Prénom</label>
                        <input type="text"
                            name="firstname"
                            id="firstname"
                            value={firstname || ""}
                            minLength="2"
                            maxLength="70"
                            required
                            onChange={(e) => setFirstname(e.target.value)}
                        />
                        {errorsprofile.firstname && <span className="text-danger">{errorsprofile.firstname}<br></br></span>}
                    </div>
                    <div className="offset-2 col-5">
                        <label htmlFor="lastname">Nom</label>
                        <input type="text"
                            name="lastname"
                            id="lastname"
                            value={lastname || ""}
                            minLength="2"
                            maxLength="70"
                            required
                            onChange={(e) => setLastname(e.target.value)}
                        />
                        {errorsprofile.lastname && <span className="text-danger">{errorsprofile.lastname}</span>}
                    </div>
                </div>



                <div className="d-flex flex-direction-column">
                    <div className="d-flex" title="Pour des raisons de sécurité, tout changement d'email entraînera une déconnexion">
                        <label htmlFor="email">Email </label>
                        <svg className="exclamationPoint" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2.75024C17.1089 2.75024 21.2499 6.89224 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89224 6.89188 2.75024 11.9999 2.75024Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.9" d="M11.995 8.20435V12.6233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.9" d="M11.995 15.7961H12.005" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <input type="email"
                        name="email"
                        id="email"
                        value={email || ""}
                        minLength="5"
                        maxLength="100"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errorsprofile.email && <span className="text-danger">{errorsprofile.email}</span>}
                </div>

                <div className="d-flex flex-direction-column">
                    <label htmlFor="password">Mot de passe</label>
                    <div className="d-flex">
                        <input type="password"
                            name="password"
                            id="password"
                            value={password || ""}
                            minLength="5"
                            maxLength="70"
                            ref={input}
                            onChange={(e) => setPassword(e.target.value)} />

                        <svg onClick={showPassword} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="showPassword bi bi-eye" viewBox="0 0 16 16">
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                    </div>
                    {errorsprofile.password && <span className="text-danger">{errorsprofile.password}</span>}
                </div>

                <button className="btn-alizOut mb-5" onClick={handleProfile}>Enregistrer</button>
            </div>

        </div>
    )
}
