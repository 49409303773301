import React from 'react'
import { Link } from 'react-router-dom'

// Packages
import gsap, { Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Images
import Fonctionnnality1 from '../../../images/AdministrativeManagement/Patientele - illu block 1.svg'
import Fonctionnnality2 from '../../../images/AdministrativeManagement/Patientele - illu block 2.svg'
import Fonctionnnality3 from '../../../images/AdministrativeManagement/Patientele - illu block 3.png'
import Fonctionnnality3Webp from '../../../images/AdministrativeManagement/Patientele - illu block 3.webp'


export default function FeaturesAdministrative() {
    gsap.registerPlugin(ScrollTrigger);

    const sections = document.querySelectorAll('section');

    sections.forEach((section, index) => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: 'top-=800',
                toggleActions: 'play none none reverse',
                end: () => `+=${section.clientHeight / 2}`,
                markers: true
            }
        });
        tl.fromTo(section, 2, {
            x: index === 1 ? -100 : 100,
            opacity: 0,
        },
            {
                x: 0,
                opacity: 1,
                ease: Power2
            }
        );
    })

    // ----- Title animation -----
    const title = document.querySelectorAll('h3');
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: title,
            start: 'top-=900',
            end: 'top-=750',
            toggleActions: 'play none none reverse',
            // scrub: true,
            markers: true
        }
    });
    tl.fromTo(title, 1, {
        opacity: 0,
    },
        {
            opacity: 1,
            ease: Power2
        }
    );


    return (
        <div className="Features-view">

            <div className="row justify-content-center">
                <h3>Découvrez notre solution pour la <br></br><span>gestion administrative de votre patientèle</span></h3>
            </div>

            {/* ----- Feature 1 ----- */}
            <section className="row max-md-flex-direction-column-reverse mt-3 features-block">

                {/* ----- Explanations ----- */}
                <div className="feature-text offset-1 col-10 offset-sm-2 col-sm-8 offset-md-2 col-md-8 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4 flex-direction-column ">
                    <h4>Gestion de vos rendez-vous patients</h4>

                    <p className="describe-feature mt-3 mb-4">
                        Synchronisez en temps réel vos agendas avec celui de Doctolib et réunissez l’ensemble des informations de rendez-vous dans un unique document.
                    </p>


                    <div className="principal-fonctionnnality">

                        <b>Fonctionnalités principales</b>

                        <div className="d-flex mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <p>Connexion Agendas Google, Outlook &amp; <b>Doctolib</b> </p>
                        </div>

                        <div className="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <p>Lecture en temps réel de vos disponibilités</p>
                        </div>

                        <div className="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <p>Informations détaillées sur vos rendez-vous</p>
                        </div>

                    </div>

                    <div className="mt-3">
                        <Link className="text-aliz d-flex text-decoration-none " to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_PATIENTELE}>
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>
                </div>

                {/* ----- Illustration -----*/}
                <div className="offset-2 col-8 offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-0 col-lg-6 offset-xl-1 col-xl-5 ">
                    <img className="feature-picture administrative-picture" src={Fonctionnnality1} alt="Expert comptable" />
                </div>
            </section >
            {/* ----- End feature 1 -----*/}



            {/* ----- Feature 2 -----*/}
            <section className="row max-md-flex-direction-column mt-1 features-block">
                {/* ----- Illustration ----- */}
                <div className="offset-3 col-6 offset-sm-3 col-sm-6 offset-md-4 col-md-4 offset-lg-0 col-lg-6 offset-xl-1 col-xl-5 ">
                    <img className="feature-picture administrative-picture" src={Fonctionnnality2} alt="Expert comptable" />
                </div>

                {/* ----- Explanations ----- */}
                <div className="feature-text offset-1 col-10 offset-sm-2 col-sm-8 offset-md-2 col-md-8 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4 flex-direction-column">

                    <h4>Vos factures générées automatiquement !</h4>

                    <p className="describe-feature mt-3 mb-4">
                        Après vos rendez-vous, vos factures sont générées et stockées sous format pdf.
                    </p>

                    <div className="principal-fonctionnnality">

                        <b>Fonctionnalités principales</b>

                        <div className="d-flex mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <p>Format PDF</p>
                        </div>

                        <div className="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <p>Champs éditables (mutuelle)</p>
                        </div>

                        <div className="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <p>Personnalisation du modèle de facture</p>
                        </div>

                        <div className="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <p>Stockage dans votre cloud (Google, Microsoft)</p>
                        </div>

                    </div>

                    <div className="mt-3">
                        <Link className="text-aliz d-flex text-decoration-none" to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_PATIENTELE}>
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>
                </div>

            </section>
            {/* ----- End feature 2 ----- */}



            {/* ----- Feature 3 ----- */}
            <section className="row max-md-flex-direction-column-reverse features-block">

                {/* ----- Explanations -----*/}
                <div className="feature-text offset-1 col-10 offset-sm-2 col-sm-8 offset-md-2 col-md-8 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4 flex-direction-column ">
                    <h4>Envoi d'email automatique à vos patients</h4>

                    <p className="describe-feature mt-3 mb-4">
                        Une fois la consultation terminée, l’assistant virtuel Aliz envoi la facture à votre patient directement depuis votre adresse email professionnelle.
                    </p>

                    <div className="principal-fonctionnnality">

                        <b>Fonctionnalités principales</b>

                        <div className="d-flex mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <p>Un envoi automatique chaque heure</p>
                        </div>

                        <div className="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <p>Ajout de pièces jointes au mail</p>
                        </div>

                    </div>

                    <div className="mt-3">
                        <Link className="text-aliz d-flex text-decoration-none" to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_PATIENTELE}>
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>
                </div>

                {/* ----- Illustration -----*/}
                <div className="offset-2 col-8 offset-sm-2 col-sm-8 offset-md-4 col-md-4 offset-lg-0 col-lg-6 offset-xl-1 col-xl-5">
                    <picture>
                        <source srcSet={Fonctionnnality3Webp} type="image/webp" />
                        <img className="feature-picture administrative-picture" src={Fonctionnnality3} id="feature3" alt="Gestion Administrative" />
                    </picture>
                </div>
            </section>
            {/* ----- End feature 3 -----*/}


        </div >

    )
}
