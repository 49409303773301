import React from 'react'

// Images
import PoteDansLaCom from '../../images/Partner/logo-jai-un-pote-com.jpeg'
import PoteDansLaComWebp from '../../images/Partner/logo-jai-un-pote-com.webp'
import Koopilot from '../../images/Partner/koopilot.png'
import KoopilotWebp from '../../images/Partner/koopilot.webp'


export default function Partners() {
    return (
        <div id="presentation" classname="row">

            <h3>Nos Partenaires</h3>

            <h4>Rendez-vous sur les sites web de nos partenaires afin de découvrir leurs contenus et bénéficiez d'offres spéciales sur tous nos assistants &#128578;</h4>


            <div id="partners" className="row">

                <div className="partner-block col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3">

                    <div className="img-block">
                        <picture>
                            <source srcSet={PoteDansLaComWebp} type="image/webp" />
                            <img src={PoteDansLaCom} alt="J'ai une pote dans la com" />
                        </picture>
                    </div>

                    <p className="title">J'ai un pote dans la com</p>

                    <p className="description">
                        Le premier média innovant à destination des professionnels de la communication.
                        Retrouvez toute l'actualité de la communication, du marketing et de la créativité en France et à l'international !
                    </p>

                    <a className="btn-alizFull" href='https://lnkd.in/dBGXyDfd'>Voir le partenaire</a>

                </div>


                <div className="partner-block col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3">

                    <div className="img-block">
                        <picture>
                            <source srcSet={KoopilotWebp} type="image/webp" />
                            <img id="koopilot" src={Koopilot} alt="J'ai une pote dans la com" />
                        </picture>
                    </div>

                    <p className="title">Koopilot</p>

                    <p className="description">
                        Conçu par et pour des entrepreneurs, Koopilot a été pensé et conçu pour ceux et celles qui ont besoin d’outils de qualité au quotidien,
                        rapidement accessibles, avec des prix négociés et des partenaires sélectionnés.
                    </p>

                    <a className="btn-alizFull" href='https://www.koopilot.com/catalogue/liberez-vous-des-taches-chronophages/'>Voir le partenaire</a>

                </div>

            </div>
        </div>
    )
}
