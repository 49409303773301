import React from 'react'

// ----- Components ----- 
import HowItWork from './HowItWork'
import Assistants from './Assistants'
import Testimonials from '../Testimonials'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function Home() {

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Faites-en plus avec nos assistants virtuels - Aliz</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" content="Engagez un assistant virtuel et déléguez lui vos tâches en ligne. Gagnez du temps pour vous concentrer sur ce qui comptent vraiment pour vous !" />
                <link rel="canonical" href="https://aliz.io" />
                <meta property="og:title" content="Faites-en plus avec nos assistants virtuels - Aliz" />
            </Helmet>

            <div className="home-view">

                {/* ----- Background circle in position absolute ----- */}
                <div id="circle-bg1" className="d-none d-xl-block"></div>
                <div id="circle-bg2" className="d-none d-xl-block"></div>


                <div className="row">

                    {/* ----- Catch phrase & Cta -----*/}
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6 flex-direction-column catch-phrase">

                        <h1>Votre temps n’est pas extensible.<b> Celui d’Aliz, oui.</b></h1>

                        <h2>
                            Libérez-vous des tâches chronophages en embauchant un assistant virtuel.
                            Déléguez maintenant vos charges administratives et finissez vos journées plus tôt.
                        </h2>

                        <div className="mt-5 btn-block d-flex max-md-flex-direction-column">

                            <div>
                                <a className="btn-yellow" href="/tarifs" target="blank">Essai Gratuit</a>
                            </div>

                            <div>
                                <a className="btn-demo d-flex" href="#video">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
                                        <path d="M10.804 8L5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                                    </svg>
                                    <p>Qui sommes-nous?</p>
                                </a>
                            </div>

                        </div>
                    </div>
                    {/* ----- End catch phrase -----*/}


                    <img src={process.env.PUBLIC_URL + '/images/Illustration - Hero.svg'} alt="work wave" className="illustration-home" />
                </div>

            </div>

            <HowItWork />
            <Assistants />
            <Testimonials />
        </>
    )
}
