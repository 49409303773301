import React, { useState } from 'react'
import { CardElement } from '@stripe/react-stripe-js';

export default function CardForm({ onSubmit, disabled, processing, errorProcess }) {
  const [name, setName] = useState("")
  const [isDefaultPayment, setIsDefaultPayment] = useState(false)
  const [error, setError] = useState("")
  const [cardComplete, setCardComplete] = useState("")


  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#000',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#87BBFD',
        },
      },
      invalid: {
        iconColor: '#f10000',
        color: '#f10000',
      },
    },
  };


  const SubmitButton = ({ processing, error, children, disabled }) => (
    <button
      className={`btn-alizFull SubmitButton ${error ? 'SubmitButton--error' : ''}`}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? 'Processing...' : children}
    </button>
  );
  const ErrorMessage = ({ children }) => (
    <div className="ErrorMessage alert-danger mb-5 p-3" role="alert">
      <svg width="16" height="16" viewBox="0 0 17 17" className="mr-3">
        <path
          fill="#FFF"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        />
        <path
          fill="#6772e5"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        />
      </svg>
      {children}
    </div>
  );

  return (
    <div className="userArea-view">
      <div className="row">

        <h1 className="offset-1 col-10 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6">Ajouter une carte à vos moyens de paiement</h1>

        <form onSubmit={onSubmit} className="offset-1 col-10 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6">
          <fieldset className="FormGroup">

            <div className="FormRow">
              <label htmlFor="name" className="FormRowLabel">
                Name
              </label>
              <input
                className="FormRowInput"
                id={"name"}
                type={"text"}
                placeholder={"Jane Doe"}
                required={true}
                autoComplete={"name"}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>

          </fieldset>
          <fieldset className="FormGroup my-5">
            <div className="FormRow">
              <CardElement options={CARD_OPTIONS} onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }} />
            </div>
          </fieldset>

          <div className="form-row row">
            <div className="col-8 col-md-10 d-flex isDefaultPayment">
              <input type="checkbox"
                id="isDefaultPayment"
                value={isDefaultPayment}
                name="isDefaultPayment"
                onChange={(e) => setIsDefaultPayment(e.target.checked)} />

              <label htmlFor="isDefaultPayment">Moyen de paiement par défaut</label>
            </div>
          </div>


          {error && <ErrorMessage>{error.message}</ErrorMessage>}
          {errorProcess && <ErrorMessage>{errorProcess}</ErrorMessage>}

          <SubmitButton processing={processing} error={error} disabled={disabled}>
            Ajouter Carte
          </SubmitButton>

          {/* Display mandate acceptance text. */}
          <div className="mandate-acceptance col-xl-10">
            En fournissant vos informations de paiement et en confirmant ce paiement,
            vous autorisez (A) Aliz et Stripe, notre service de paiement
            fournisseur, d'envoyer des instructions à votre banque pour débiter votre compte et
            (B) votre banque à débiter votre compte conformément à ces
            instructions. Dans le cadre de vos droits, vous avez droit à un remboursement
            de votre banque selon les termes et conditions de votre accord avec
            votre banque. Un remboursement doit être réclamé dans les 8 semaines à compter du
            date à laquelle votre compte a été débité. Vos droits sont expliqués dans
            un relevé que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des
            notifications pour les débits futurs jusqu'à 2 jours avant qu'ils ne surviennent.
          </div>

        </form>
      </div>
    </div>
  )
}
