import React from 'react'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Components -----
import Navbar from './NavTerms'

export default function LegalNotice() {
    return (
        <>
            <Helmet title="Mentions Légales" />

            <div className="conditionsTerms container-fluid">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">

                        <h1>Mentions Légales</h1>
                        <h2 className="catch-phrase">
                            1. Les sites internet ALIZ (aliz.io et calendar.aliz.io) sont édités par :
                        </h2>

                        <p className="conditionsTerms-text">
                            La société TED, SAS au capital social de 1.000€
                            <br></br>
                            Siège social : 59 RUE DE PONTHIEU – bureau 562, 75008 PARIS
                            <br></br>
                            RCS PARIS n° 820 308 013
                            <br></br>
                            Numéro de TVA : FR04 820308013
                            <br></br>
                            <br></br>
                            Directeur de la publication : Mathias Chaumon
                            <br></br>
                            Contact : contact@aliz.io
                        </p>


                        <h2 className="catch-phrase">
                            2. Les sites internet Aliz sont hébergés par :
                        </h2>

                        <p className="conditionsTerms-text">
                            Dénomination sociale : OVH Cloud
                            <br></br>
                            Adresse : 2 rue Kellermann - 59100 Roubaix - France Téléphone n° : 1007
                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}

