import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// -----Images ----- 
import Logo from '../../images/logo/Logo Blanc_Sans Texte.png'
import LogoWebp from '../../images/logo/Logo Blanc_Sans Texte.webp'
import Illustration from '../../images/Login/Illustration - Hero.svg'

// ----- Services ----- 
import { ResetPasswordLink } from '../../services/User/register.js'

// ----- Component ------
import Popup from '../Popup'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function ResetPassword({ token }) {
    // ----- Get params -----
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const [errors, setErrors] = useState([])
    const [response, setResponse] = useState([])

    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [tokenUrl, setTokenUrl] = useState(query.get("token") ? query.get("token") : "")

    // -----Popup -----
    const [isResetPasswordVisible, setIsResetPasswordVisible] = useState(false)

    let navigate = useNavigate()

    const input = useRef();
    const inputConfirm = useRef();

    const data = {
        'password': password,
        'passwordConfirm': passwordConfirm,
        'token': tokenUrl
    };

    const handleReset = (e) => {
        e.preventDefault();
        setErrors([])
        setResponse([])
        ResetPasswordLink(data, setResponse, setErrors)
    };

    useEffect(() => {
        setTokenUrl(query.get("token"))
    }, [tokenUrl, query])

    useEffect(() => {
        if (typeof response !== "undefined" && response.length > 1) {
            setIsResetPasswordVisible(true)
        }
    }, [response])

    useEffect(() => {
        if (token) {
            navigate(-1)
        }
    }, [token, navigate]);

    // Show or hide password
    const showPassword = () => {
        if (input.current.type === "password") {
            input.current.type = "text";
        } else {
            input.current.type = "password";
        }
    }

    // Show or hide password
    const showPasswordConfirm = () => {
        if (inputConfirm.current.type === "password") {
            inputConfirm.current.type = "text";
        } else {
            inputConfirm.current.type = "password";
        }
    }


    return (
        <>
            <Helmet title="Reinitialiser mon mot de passe" />

            <div className="login-view">
                <div className="row justify-content-center">

                    <div className="col-sm-12 col-lg-6 illustration-block">

                        {/* ----- Logo ----- */}
                        <a className="logo d-flex" href="/">
                            <picture>
                                <source srcSet={LogoWebp} type="image/webp" />
                                <img src={Logo} alt="Aliz Logo" />
                            </picture>
                            <span>Aliz</span>
                        </a>

                        {/* ----- Illustration ----- */}
                        <img className="illustration-login" src={Illustration} alt="Men under wave" />

                    </div>



                    <div className="col-sm-12 col-lg-6 login-block">

                        <h1 className="offset-2 col-8 offset-xl-3 col-xl-6">Réinitialiser votre mot de passe:</h1>

                        <h2 className="offset-2 col-8 offset-xl-3 col-xl-6">Choisissez votre nouveau mot de passe. Vous recevrez un mail de confirmation.</h2>



                        <div className="d-flex flex-direction-column offset-2 col-8 offset-xl-3 col-xl-6 mt-4">

                            {errors.token && <span className="text-danger mt-2">{errors.token}</span>}
                            {errors.account && <span className="text-danger mt-2">{errors.account}</span>}

                            <label htmlFor="password">Mot de passe</label>

                            <div className="d-flex">
                                <input type="password"
                                    name="password"
                                    id="password"
                                    className="login-input"
                                    value={password}
                                    ref={input}
                                    minLength="5"
                                    required
                                    onChange={(e) => setPassword(e.target.value)} />

                                <svg onClick={showPassword} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="showPassword bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                            </div>

                            {errors.password && <span className="text-danger mt-2">{errors.password}</span>}

                        </div>



                        <div className="d-flex flex-direction-column offset-2 col-8 offset-xl-3 col-xl-6 mt-4">

                            <label htmlFor="password">Confirmer votre mot de passe</label>

                            <div className="d-flex">
                                <input type="password"
                                    name="passwordConfirm"
                                    id="passwordConfirm"
                                    className="login-input"
                                    value={passwordConfirm}
                                    ref={inputConfirm}
                                    minLength="5"
                                    required
                                    onChange={(e) => setPasswordConfirm(e.target.value)} />

                                <svg onClick={showPasswordConfirm} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="showPassword bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                            </div>

                            {errors.passwordConfirm && <span className="text-danger mt-2">{errors.passwordConfirm}</span>}

                        </div>




                        <div className="offset-2 col-8 offset-xl-3 col-xl-6">
                            <button className="login-btn" onClick={handleReset}>Envoyer</button>
                        </div>


                        <a className="redirect" href="/login">Se connecter</a>

                    </div>
                </div>


                <Popup
                    text="Votre mot de passe à bien été modifié! Un email viens de confirmation va vous être envoyé"
                    isVisible={isResetPasswordVisible}
                />

            </div>
        </>
    )
}
