import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}

// _______________ Notifications _______________

export async function notificationByUser(setNotifications, setLoading) {
    await axios({
        url: url + 'auth/notifications_by_user',
        headers: headersAuth,
    })
        .then((response) => {
            // Send response for check token 
            if (response.data && response.data.response) {
                setNotifications(response.data.response)
                setLoading(false)
            }else{
                setLoading(false)
            }
        }, (err) => {
            setNotifications(false)
        });
}

export async function deleteNotification(setNotification, id) {
    await axios({
        method: "post",
        url: url + 'auth/delete_notification',
        headers: headersAuth,
        data: { "id": id }
    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setNotification(response.data.response)
            }
        }, (err) => {
            setNotification(false)
        });
}

export async function readedNotification(setNotification, id) {
    await axios({
        method: "post",
        url: url + 'auth/notification_readed',
        headers: headersAuth,
        data: { "id": id }
    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setNotification(response.data.response)
            }
        }, (err) => {
            setNotification(false)
        });
}

export async function numberNotReadedNotification(setNumberNotReadedNotification) {
    await axios({
        url: url + 'auth/number_not_readed_notification',
        headers: headersAuth,
    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setNumberNotReadedNotification(response.data.numberNotReadedNotification)
            }
        }, (err) => {
            setNumberNotReadedNotification(false)
        });
}