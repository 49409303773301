import React, { useRef, useState, useEffect } from 'react'
import { deleteNotification, readedNotification } from "../../../services/User/notification"

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import gsap from 'gsap';

export default function Notification({ notification, setIsDeleted }) {
    const [isDisplay, setIsDisplay] = useState(false)
    const [isReaded, setIsReaded] = useState(notification.isReaded)

    const refToggle = useRef()

    const deleteNotif = () => {
        confirmAlert({
            title: "Supprimer notification",
            message: 'Êtes-vous sûr de vouloir supprimer cette notification?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => {
                        deleteNotification(setIsDeleted, notification.id)
                    }
                },
                {
                    label: 'Non',
                    onClick: () => alert("La notification n'a pas été supprimé")
                }
            ]
        });
    }

    const toggleInformation = () => {
        setIsDisplay(isDisplay ? false : true)
    }

    useEffect(() => {
        if (isDisplay) {
            gsap.to(refToggle.current, { css: { transform: "scaleY(100%)", height: "auto" }, duration: 0.5 })
            readedNotification(setIsReaded, notification.id)
        } else {
            gsap.to(refToggle.current, { css: { transform: "scaleY(0)", height: "0" }, duration: 0.5 })
        }
    }, [isDisplay, notification.id])


    return (
        <div className={`notification-component row ${isReaded ? "componentReaded" : null}`} onClick={toggleInformation}>

            <div className="col-1">
                {isReaded === false && <div className="isNotReaded"></div>}
            </div>

            <div className="col-9 col-sm-10 flex-direction-column">
                <p className="notification-title">{notification.title}</p>
            </div>

            <div className="col-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16" onClick={deleteNotif}>
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                </svg>
            </div>

            <div className="toggleInformation col-12" ref={refToggle}>
                <hr></hr>
                <p>{notification.text}</p>

                {notification.link && notification.linkName &&
                    <a href={notification.link} target="_blank" rel="noopener noreferrer">{notification.linkName}</a>
                }
                <i>Reçu le {notification.createdAt}</i>

            </div>

        </div>

    )
}
