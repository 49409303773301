import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { createSetupIntent, setDefaultPaymentUser } from '../../../../services/User/paymentMethod'

import CardForm from './CardForm';

export default function PaymentSetupForm() {
    const stripe = useStripe();
    const elements = useElements();
    let navigate = useNavigate();

    const [clientSecret, setClientSecret] = useState([])
    const [ResponseDebitSetup, setResponseDebitSetup] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState("")


    const type = {
        'type': 'card'
    }
    useEffect(() => {
        createSetupIntent(setClientSecret, type)
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();

        setProcessing(true)

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        const card = elements.getElement(CardElement);

        const name = event.target['name'];
        const isDefaultPayment = event.target.isDefaultPayment;

        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: card,
                billing_details: {
                    name: name.value,
                },
            }
        });

        if (result.error) {
            // Show error to your customer.
            setProcessing(false)
            setError(result.error.message)
        } else {
            setProcessing(false)

            if (isDefaultPayment.value === true || isDefaultPayment.value === "true") {
                setDefaultPaymentUser(result.setupIntent.payment_method, setResponseDebitSetup)
                navigate('/espace-client/mes_moyens_de_paiement?paymentMethod=addCard')
            } else {
                navigate('/espace-client/mes_moyens_de_paiement?paymentMethod=addCard')
            }
        }
    };


    return (
        <CardForm onSubmit={handleSubmit} disabled={!stripe} processing={processing} error={error} />
    );
}
