import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'

export default function NavTerms() {
    const [location, setLocation] = useState('')

    const locationPathname = useLocation()
    
    // Listener url pathname and Check token 
    useEffect(() => {
        setLocation(locationPathname.pathname)
    }, [locationPathname]);

    return (
        <ul>
            <li className={location.match(/conditions-generales\/cgu/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.7367 2.76196H8.08369C6.02469 2.76196 4.24969 4.43096 4.24969 6.49096V17.204C4.24969 19.38 5.90869 21.115 8.08369 21.115H16.0727C18.1327 21.115 19.8017 19.265 19.8017 17.204V8.03796L14.7367 2.76196Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.474 2.75024V5.65924C14.474 7.07924 15.623 8.23124 17.042 8.23424C18.359 8.23724 19.706 8.23824 19.797 8.23224" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M14.284 15.5579H8.88699" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M12.2425 10.6056H8.88651" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/conditions-generales/cgu">Conditions générales d'utilisation</Link>
            </li>

            <li className={location.match(/conditions-generales\/politique-de-confidentialite/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M16.4234 9.4478V7.3008C16.4234 4.7878 14.3854 2.7498 11.8724 2.7498C9.35937 2.7388 7.31337 4.7668 7.30237 7.2808V7.3008V9.4478" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.6832 21.2496H8.04218C5.94818 21.2496 4.25018 19.5526 4.25018 17.4576V13.1686C4.25018 11.0736 5.94818 9.37659 8.04218 9.37659H15.6832C17.7772 9.37659 19.4752 11.0736 19.4752 13.1686V17.4576C19.4752 19.5526 17.7772 21.2496 15.6832 21.2496Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.8629 14.2028V16.4238" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <Link to="/conditions-generales/politique-de-confidentialite">Politique de confidentialité</Link>
            </li>

            <li className={location.match(/conditions-generales\/mentions-legales/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.865 5.12377C19.302 5.27677 19.594 5.68877 19.594 6.15177V12.9248C19.594 14.8178 18.906 16.6248 17.691 18.0248C17.08 18.7298 16.307 19.2788 15.486 19.7228L11.928 21.6448L8.364 19.7218C7.542 19.2778 6.768 18.7298 6.156 18.0238C4.94 16.6238 4.25 14.8158 4.25 12.9208V6.15177C4.25 5.68877 4.542 5.27677 4.979 5.12377L11.561 2.81077C11.795 2.72877 12.05 2.72877 12.283 2.81077L18.865 5.12377Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M9.32251 11.9177L11.2145 13.8107L15.1125 9.91272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/conditions-generales/mentions-legales">Mentions légales</Link>
            </li>

            <li className={location.match(/conditions-generales\/politique-utilisation-des-cookies/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M2.80408 15.4769C2.80408 15.4769 2.94608 17.2149 2.97908 17.7629C3.02308 18.4979 3.30708 19.3189 3.78108 19.8889C4.45008 20.6969 5.23808 20.9819 6.29008 20.9839C7.52708 20.9859 16.5221 20.9859 17.7591 20.9839C18.8111 20.9819 19.5991 20.6969 20.2681 19.8889C20.7421 19.3189 21.0261 18.4979 21.0711 17.7629C21.1031 17.2149 21.2451 15.4769 21.2451 15.4769" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.49597 5.32949V4.95849C8.49597 3.73849 9.48397 2.75049 10.704 2.75049H13.286C14.505 2.75049 15.494 3.73849 15.494 4.95849L15.495 5.32949" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.995 16.6782V15.3842" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.74988 8.38905V11.8561C4.66788 13.1211 6.96588 14.0071 9.48788 14.3581C9.78988 13.2571 10.7829 12.4501 11.9899 12.4501C13.1779 12.4501 14.1909 13.2571 14.4729 14.3681C17.0049 14.0171 19.3119 13.1311 21.2399 11.8561V8.38905C21.2399 6.69505 19.8769 5.33105 18.1829 5.33105H5.81688C4.12288 5.33105 2.74988 6.69505 2.74988 8.38905Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/conditions-generales/politique-utilisation-des-cookies">Politique d'utilisation des cookies</Link>
            </li>

            <li className={location.match(/conditions-generales\/conditions-specifiques-liees-aux-produits/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M7.42226 19.8203C7.84426 19.8203 8.18726 20.1633 8.18726 20.5853C8.18726 21.0073 7.84426 21.3493 7.42226 21.3493C7.00026 21.3493 6.65826 21.0073 6.65826 20.5853C6.65826 20.1633 7.00026 19.8203 7.42226 19.8203Z" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M18.6747 19.8203C19.0967 19.8203 19.4397 20.1633 19.4397 20.5853C19.4397 21.0073 19.0967 21.3493 18.6747 21.3493C18.2527 21.3493 17.9097 21.0073 17.9097 20.5853C17.9097 20.1633 18.2527 19.8203 18.6747 19.8203Z" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2.74988 3.25L4.82988 3.61L5.79288 15.083C5.87088 16.018 6.65188 16.736 7.58988 16.736H18.5019C19.3979 16.736 20.1579 16.078 20.2869 15.19L21.2359 8.632C21.3529 7.823 20.7259 7.099 19.9089 7.099H5.16388" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M14.1254 10.795H16.8984" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/conditions-generales/conditions-specifiques-liees-aux-produits">Conditions liées aux produits</Link>
            </li>

            <li className={location.match(/conditions-generales\/conditions-utilisation-des-services/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.6648 18.6316L5.73341 21.8811C5.25984 22.1272 4.67641 21.953 4.41533 21.4875V21.4875C4.3398 21.3433 4.29905 21.1834 4.29634 21.0206V6.62247C4.29634 3.87647 6.17277 2.77808 8.873 2.77808H15.2162C17.8341 2.77808 19.7929 3.80325 19.7929 6.4394V21.0206C19.7929 21.2804 19.6897 21.5295 19.506 21.7132C19.3224 21.8969 19.0733 22 18.8135 22C18.6478 21.9974 18.485 21.9567 18.3375 21.8811L12.3696 18.6316C12.1497 18.5128 11.8847 18.5128 11.6648 18.6316Z" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M8.36957 9.32266H15.6648" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/conditions-generales/conditions-utilisation-des-services">Conditions d’Utilisation des Services</Link>
            </li>

            <li className={location.match(/conditions-generales\/cgv/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M13.6996 19.8981H20.0766" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.8549 4.95573C13.5919 4.01673 14.7829 4.06573 15.7229 4.80273L17.1129 5.89273C18.0529 6.62973 18.3859 7.77273 17.6489 8.71373L9.3599 19.2887C9.0829 19.6427 8.6599 19.8517 8.2099 19.8567L5.0129 19.8977L4.2889 16.7827C4.1869 16.3457 4.2889 15.8857 4.5659 15.5307L12.8549 4.95573Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M11.3026 6.93616L16.0966 10.6942" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/conditions-generales/cgv">Conditions générales de vente</Link>
            </li>

            <li>
                <Link to="/" className="btn-yellow">Accueil</Link>
            </li>
        </ul>
    )
}
