import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// ----- Component -----
import Administrative from './Assistants/Administrative'
import Tva from './Assistants/Tva'
import Calendar from './Assistants/Calendar'
import VerifLegal from './Assistants/VerifLegal'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function HomeStep1({ responseToken }) {

    let navigate = useNavigate()

    // Get params 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const subscription = {
        'robot': query.get("assistant"),
        'priceId': query.get("price"),
    }

    // when assistant or priceId change, we redirect to prices page
    useEffect(() => {
        if (!subscription.robot || !subscription.priceId) {
            navigate("/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_PATIENTELE + "&pack-annuel=true")
        }
    }, [subscription, navigate]);

    // console.log(subscription)
    return (
        <>
            <Helmet title="Souscription - Étape 1" />

            <div className="subscription-view container-fluid">

                <div className="row">

                    <div className="offset-1 col-10 col-md-10 offset-lg-2 col-lg-8 offset-xl-3 col-xl-8">
                        {(() => {
                            switch (subscription.robot) {
                                case process.env.REACT_APP_BOT_ID_PATIENTELE:
                                    return <Administrative subscription={subscription} responseToken={responseToken} />;
                                case process.env.REACT_APP_BOT_ID_TVA:
                                    return <Tva subscription={subscription} responseToken={responseToken} />;
                                case process.env.REACT_APP_BOT_ID_CALENDAR:
                                    return <Calendar subscription={subscription} responseToken={responseToken} />;
                                case process.env.REACT_APP_BOT_ID_VERIFLEGAL:
                                    return <VerifLegal subscription={subscription} responseToken={responseToken} />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                </div>

            </div>
        </>
    )
}
