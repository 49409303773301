import React from 'react'

export default function Step() {
    return (
        <div className="Osteo_step-view">

            <div className="header-step">
                <h3>Comment ça marche?</h3>
                <hr></hr>
            </div>

            <div className="all-step">
                <div className="offset-1 col-10 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-1 col-xl-2">
                    <p className="step-number">1.</p>
                    <p>Prenez votre patient en consultation. </p>
                </div>

                <div className="offset-1 col-10 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-0 col-xl-2">
                    <p className="step-number">2.</p>
                    <p> Aliz, votre assistant virtuel récupère automatiquement les informations de votre rendez-vous.</p>
                </div>

                <div className="offset-1 col-10 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-0 col-xl-2">
                    <p className="step-number">3.</p>
                    <p>Aliz génère la facture et l'envoi par mail à votre patient.</p>
                </div>

                <div className="offset-1 col-10 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-0 col-xl-2">
                    <p className="step-number">4.</p>
                    <p>Les factures, votre base patient et l'historique est généré, tout est préparé pour faciliter le traitement dans votre comptabilité.</p>
                    <p><i>*aucune de vos données personnelles ou professionnelles ne sont stockées chez ALIZ</i></p>
                </div>
                <div className="offset-1 col-10 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-0 col-xl-2">
                    <p className="step-number">5.</p>
                    <p>Partez l’esprit libre en week-end, Aliz s'occupe de tout.</p>
                </div>
            </div>
        </div>
    )
}
