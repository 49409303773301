import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Component
import VerifLegalTable from '../Table/VerifLegal/VerifLegal'

// ---- Images ------
import freeTrialIllustration from '../../../images/letter.png'

export default function VerifLegal({ annual, token }) {
    const [prices, setPrices] = useState(annual ? {
        'starter': process.env.REACT_APP_VERIFEGAL_STARTER_ANNUAL_PRICE,
        'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_ANNUAL_PRICE,
        'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_ANNUAL_PRICE
    } : {
        'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_MONTHLY_PRICE,
        'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_MONTHLY_PRICE,
        'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_MONTHLY_PRICE
    })
    const [idPrice, setIdPrice] = useState(annual ? {
        'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_ANNUAL,
        'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_ANNUAL,
        'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_ANNUAL
    } : {
        'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_MONTHLY,
        'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_MONTHLY,
        'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_MONTHLY
    })

    let navigate = useNavigate();

    useEffect(() => {
        if (annual) {
            setPrices({
                'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_ANNUAL_PRICE,
                'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_ANNUAL_PRICE,
                'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_ANNUAL_PRICE
            })
            setIdPrice({
                'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_ANNUAL,
                'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_ANNUAL,
                'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_ANNUAL
            })
        } else {
            setPrices({
                'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_MONTHLY_PRICE,
                'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_MONTHLY_PRICE,
                'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_MONTHLY_PRICE
            })
            setIdPrice({
                'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_MONTHLY,
                'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_MONTHLY,
                'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_MONTHLY
            })
        }
    }, [annual]);

    // ----- Feature included in subscription -----
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;
    // ----- Feature not included in subscription -----
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;


    return (
        <div className="price-pack">
            <div className="row justify-content-center max-md-flex-direction-column" >

                {/* ----- Price pack STARTER ----- */}
                < div className="rate-block rate-light" >

                    <p className="pack-title little-title">Starter</p>

                    <hr></hr>

                    <div className="pack-price">
                        <p className="pack-free">GRATUIT</p>
                    </div>

                    <p className="pack-description">
                        Pour accéder à toutes les données d’une recherche de société, faire des recherches de société en masse et fiabiliser vos bases de données.
                    </p>

                    <p className="pack-features">Fonctionnalités principales</p>


                    <div className="features-block">

                        <div className="features-description">
                            {feature}
                            <p>Données sur l’entreprise et sur le dirigeant</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Analyse de la qualité des données avec le <b>ALIZ TRUST SCORING</b></p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Accès aux recherches multiples</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>25 crédits/mois pour tester la vérification et le nettoyage de base de données</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Alerte et mise à jour de votre base de données</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Accès à l’API AlizVerif pour intégration dans vos systèmes d’informations, ERP, CRM...</p>
                        </div>

                    </div>


                    <button className="btn-alizFull"
                        onClick={() =>
                            token ?
                                navigate("/espace-client/souscription-etape1?assistant=4&price=" + idPrice.starter + "&eur=" + prices.starter) :
                                window.location.href = '/login?assistant=4&price=' + idPrice.starter}
                    >
                        Tester Gratuitement
                    </button>
                </div >
                {/* ----- End Price STARTER ----- */}






                {/* ----- Price pack BUSINESS ----- */}
                <div className="rate-block rate-light">

                    <p className="pack-title">Business</p>

                    <hr></hr>

                    <div className="pack-price">
                        <p>{prices.business} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>

                    <p className="pack-description">
                        Tous les avantages du Starter + toutes les fonctionnalités du nettoyage de bases de données pour améliorer la qualité de vos bases fournisseurs et clients.
                    </p>

                    <p className="pack-features">Fonctionnalités principales</p>


                    <div className="features-block">

                        <div className="features-description">
                            {feature}
                            <p>Données sur l’entreprise et sur le dirigeant</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Analyse de la qualité des données avec le <b>ALIZ TRUST SCORING</b></p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Accès aux recherches multiples</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>500 crédits/mois pour vérifier et nettoyer vos bases de données</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Alerte et mise à jour de votre base de données</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Accès à l’API AlizVerif pour intégration dans vos systèmes d’informations, ERP, CRM...</p>
                        </div>

                    </div>


                    <button className="btn-alizFull"
                        onClick={() =>
                            token ?
                                navigate("/espace-client/souscription-etape1?assistant=4&price=" + idPrice.business + "&eur=" + prices.business) :
                                window.location.href = '/login?assistant=4&price=' + idPrice.business}
                    >
                        Tester Gratuitement
                    </button>

                </div>
                {/* ----- End Price pack BUSINESS ----- */}





                {/* ----- Price pack PREMIUM ----- */}
                <div className="rate-block rate-light">

                    <p className="pack-title">Enterprise</p>

                    <hr></hr>

                    <div className="pack-price">
                        <p>Sur devis</p>
                    </div>

                    <p className="pack-description">
                        Tous les avantages du Business + intégrer AlizVerif à votre outil, recevoir des alertes en cas de mise à jour des données et nettoyer en masse vos base de données.
                    </p>

                    <p className="pack-features">Fonctionnalités principales</p>


                    <div className="features-block">

                        <div className="features-description">
                            {feature}
                            <p>Données sur l’entreprise et sur le dirigeant</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Analyse de la qualité des données avec le <b>ALIZ TRUST SCORING</b></p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Accès aux recherches multiples</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Crédits illimités pour nettoyer et améliorer la qualité de vos base de données</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Alerte et mise à jour de votre base de données</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Accès à l’API AlizVerif pour intégration dans vos systèmes d’informations, ERP, CRM...</p>
                        </div>

                    </div>


                    <button className="btn-alizFull"
                        onClick={() => window.open(process.env.REACT_APP_LINK_CALENDAR_VERIF_ENTERPRISE_PACK, '_blank')}
                    >
                        Tester Gratuitement
                    </button>

                </div>
                {/* ----- End Price pack PREMIUM ----- */}


            </div >


            <div className="trial-free">
                <img src={freeTrialIllustration} alt="personnage allongé sur le ventre" />
                <h3>Bénéficiez de 15 jours gratuits et sans engagement !</h3>
            </div>


            {/* ----- Price Table ----- */}
            <VerifLegalTable />

        </div >
    )
}
