import React, { useState, useEffect } from 'react'
import { getBillingprofile, setBillingprofile } from '../../../services/User/clientArea.js'
import { getSubscriptions } from '../../../services/User/subscription'

// Components
import LoaderSuspense from '../../LoaderSuspense'

export default function FormFacturation({ setisUpdateBillingProfileVisible }) {
    const [profile, setProfile] = useState([])
    const [errorsBilling, setErrorsBilling] = useState([])
    const [successBilling, setSuccessBilling] = useState('')
    const [subscriptions, setSubscriptions] = useState([])
    const [isLoadedSubscription, setIsLoadedSubscription] = useState(false)

    // Facturation informations form 
    const [address, setAddress] = useState('')
    const [gmail, setGmail] = useState('')

    // Get information billing
    useEffect(() => {
        getBillingprofile(setProfile)
        getSubscriptions(setSubscriptions, setIsLoadedSubscription)
    }, [])

    // put profile billing in input
    useEffect(() => {
        if (profile) {
            setAddress(profile.billingAddress)
            setGmail(profile.gmail)
        }
    }, [profile])

    const billingData = {
        "billingAddress": address,
        "gmail": gmail,
    }
    const handleBilling = () => {
        setBillingprofile(billingData, setErrorsBilling, setSuccessBilling)
    }

    useEffect(() => {
        if (successBilling === "success") {
            window.scrollTo(0, 0)
            setisUpdateBillingProfileVisible(true)
        }
        // eslint-disable-next-line
    }, [successBilling])

    return (
        <div>
            <h2>Adresse de facturation</h2>
            <h3 className="catch-phrase">Ceci est l’adresse qui apparait sur vos factures.</h3>

            <div className="col-xl-8 pl-0">
                <div className="d-flex flex-direction-column">
                    <label htmlFor="firstname">Adresse de facturation</label>
                    <input type="address"
                        name="address"
                        id="address"
                        minLength="5"
                        maxLength="100"
                        value={address || ""}
                        required
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    {errorsBilling.address && <span className="text-danger">{errorsBilling.address}<br></br></span>}
                </div>

                {!isLoadedSubscription && <LoaderSuspense />}

                {subscriptions.length > 0 &&
                    <div className="d-flex flex-direction-column">
                        <div className="d-flex" title="Attention, cette adresse est indispensable au bon fonctionnement de vos assistants Aliz,
                         pour toute informations, veuillez contacter le support.">
                            <label htmlFor="gmail">Adresse email Gmail</label>
                            <svg className="exclamationPoint" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2.75024C17.1089 2.75024 21.2499 6.89224 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89224 6.89188 2.75024 11.9999 2.75024Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.9" d="M11.995 8.20435V12.6233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.9" d="M11.995 15.7961H12.005" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <input type="email"
                            name="gmail"
                            id="gmail"
                            value={gmail || ""}
                            minLength="5"
                            maxLength="100"
                            required
                            disabled="disabled"
                            onChange={(e) => setGmail(e.target.value)}
                        />
                        {errorsBilling.gmail && <span className="text-danger">{errorsBilling.gmail}</span>}
                    </div>
                }

                <button className="btn-alizOut mb-5" onClick={handleBilling}>Enregistrer</button>

            </div>

        </div >
    )
}
