import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getPrice } from '../../../../services/User/stripe.js'
import { authGoogleDrive, setGmailUser } from '../../../../services/auth.js'
import { setSubscriptionStatus } from '../../../../services/User/subscription'
import GdriveLogo from '../../../../images/Header/Gdrive ico.png'
import GdriveLogoWebp from '../../../../images/Header/Gdrive ico.webp'

export default function GoogleDrive({ subscription }) {
    // Get params 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [priceResponse, setPriceResponse] = useState([])

    // errors 
    const [gmailErrors, setGmailErrors] = useState([])
    const [driveError, setDriveError] = useState(false)

    const [formSuccess, setFormSuccess] = useState(query.get("subscription") ? query.get("subscription") : false)

    // Drive 
    const [googleDriveResponse, setGoogleDriveResponse] = useState([])
    const [driveSuccess, setDriveSuccess] = useState(query.get("googledrive") ? query.get("googledrive") : false)
    // Gmail 
    const [gmailAddress, setGmailAddress] = useState(query.get("email") ? query.get("email") : false)
    const [gmailResponse, setGmailResponse] = useState([])

    const [subscriptionResponse, setSubscriptionResponse] = useState([])
    let navigate = useNavigate();


    useEffect(() => {
        setFormSuccess(query.get("subscription") ? query.get("subscription") : false)
        setGmailAddress(query.get("email") ? query.get("email") : false)
    }, [query])


    // ______Dashboard subscription_______
    // Get information of subscription by price Id
    useEffect(() => {
        getPrice(setPriceResponse, subscription.priceId)
    }, [subscription.priceId])


    // Check if form step 1/2 is Done
    useEffect(() => {
        if (!formSuccess) {
            return navigate('/espace-client/souscription-etape1?assistant=' + subscription.robot + '&price=' + subscription.priceId)
        }
        // eslint-disable-next-line
    }, [formSuccess])

    // _________Drive____________
    const authDrive = () => {
        authGoogleDrive(setGoogleDriveResponse, window.location.href, subscription.robot, subscription.user)
    }

    useEffect(() => {
        // Redirect url for callback -> /api/googledrive/callback
        if (googleDriveResponse.data) {
            return window.location.href = googleDriveResponse.data.url
        }
    }, [googleDriveResponse])

    // if google drive callback success, we set successDrive
    useEffect(() => {
        if (driveSuccess === "success") {
            setDriveSuccess(true)
        }
    }, [driveSuccess])


    const statusData = {
        "robot": subscription.robot,
        "priceId": subscription.priceId
    }
    // On submit 
    const isSubscrib = () => {
        if (driveSuccess) {
            setDriveError(false)
            setGmailUser(setGmailResponse, setGmailErrors, gmailAddress)
            setSubscriptionStatus(statusData, setSubscriptionResponse)

        } else {
            setDriveError(true)
        }
    }

    useEffect(() => {
        // If succes and bot not patientele ? subscription done : push to step 3 ( doctolib)
        if (gmailResponse.gmail === "success" && subscription.robot !== "1") {
            return navigate('/espace-client/accueil?subscription=success')
        } else if (gmailResponse.gmail === "success" && subscription.robot === "1") {
            return navigate('/espace-client/souscription-etape3?googledrive=success&price=' + subscription.priceId)
        }
        // if (gmailResponse.gmail === "success") {
        //     return navigate('/espace-client/accueil?subscription=success')
        // }
        // eslint-disable-next-line
    }, [gmailResponse])

    return (
        <div className="subscribForm-view shadow-frame">
            <div className="d-flex flex-direction-column">
                <h1>Assistant de {priceResponse.metadata && priceResponse.metadata.product}</h1>

                <p className="ask-information gmail-explains col-12 col-xl-9 pl-0">
                    Afin de profiter pleinement de votre nouvel assistant, vous devez lui fournir un accès à votre Google Drive
                    afin d'y stocker les fichiers générés par votre assistant.
                    <br></br><br></br>Toute les informations récoltées seront utilisées uniquement pour faire le lien entre votre activité professionnelle et votre assistant.
                    <br></br>Pour plus d'informations, vous pouvez contacter le
                    <a href={process.env.REACT_APP_LINK_CALENDAR_CONTACT_SUPPORT} className="text-aliz" target="_blank" rel="noopener noreferrer"><b> support</b></a>
                </p>

                {/* Gmail Form */}
                <div className="col-12 col-xl-8 px-0">
                    <div className="container_btn-googleDrive">
                        <button className="btn-googleDrive col-10 col-sm-8 col-md-6 col-xl-6 mb-5 d-flex" onClick={authDrive} title="Cliquez ici pour partager votre Drive avec votre assistant">
                            <picture>
                                <source srcSet={GdriveLogoWebp} type="image/webp" />
                                <img src={GdriveLogo} alt="Google Drive logo" />
                            </picture>
                            <p>Lier Google Drive</p>
                        </button>
                    </div>
                    {driveSuccess && <span className="text-success">Votre Google Drive à été lié avec succès
                        <svg className='success-icon' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M16.3344 2.00018H7.66543C4.26785 2.00018 2.00043 4.43278 2.00043 7.91618V16.0842C2.00043 19.5709 4.2618 22.0002 7.66543 22.0002H16.3334C19.738 22.0002 22.0004 19.5709 22.0004 16.0842V7.91618C22.0004 4.42969 19.7382 2.00018 16.3344 2.00018ZM7.66543 3.50018H16.3344C18.8849 3.50018 20.5004 5.23515 20.5004 7.91618V16.0842C20.5004 18.7653 18.8848 20.5002 16.3334 20.5002H7.66543C5.11519 20.5002 3.50043 18.7655 3.50043 16.0842V7.91618C3.50043 5.23856 5.12077 3.50018 7.66543 3.50018ZM16.0903 9.09685C15.7974 8.80396 15.3225 8.80396 15.0296 9.09685L10.813 13.312L8.97015 11.4697L8.88601 11.3971C8.59235 11.1793 8.1757 11.2036 7.90949 11.47C7.61665 11.7629 7.61675 12.2378 7.90971 12.5306L10.2837 14.9036L10.3678 14.9762C10.6614 15.194 11.078 15.1697 11.3443 14.9035L16.0903 10.1575L16.1629 10.0734C16.3807 9.77978 16.3565 9.36312 16.0903 9.09685Z" fill={`${driveSuccess ? "#21e01a" : "#6c757d"}`} />
                        </svg>
                    </span>}
                    {driveError && <span className="text-danger">Vous avez besoin de partager votre Google Drive à votre assistant</span>}
                    {gmailErrors.gmail && <span className="text-danger">{gmailErrors.gmail}</span>}
                    {/* {subscriptionResponse.errors && <span className="text-danger">{gmailErrors.gmail}</span>} */}

                </div>


                <div className="col-12 offset-xl-0 col-xl-8 px-0">
                    <button className="btn-alizFull" onClick={isSubscrib}>Suivant</button>
                </div>
            </div>

        </div>
    )
}
