import React from 'react'

export default function Tablet() {
    // feature included in subscription
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-active bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // feature not included in subscription
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-inactive bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    return (
        <div className="tabletTable-view">
            <h2>Fonctionalités</h2>

            {/* 1 */}
            <div className="row justify-content-center flex-direction-column">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>Intégration Google Meet et Outlook (lien de visioconférence)</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <hr></hr>

            {/* 2 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>Lien de partage unique pour vos évènements</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>


            <hr></hr>

            {/* 3 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>Suivi de vos rendez-vous avec notifications Google et Outlook</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <hr></hr>

            {/* 4 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>E-mail de rappel pour vous et vos invités</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>



            <hr></hr>

            {/* 5 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>Création de 3 types d'événements en illimité</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {featureInactive}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <hr></hr>

            {/* 6 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>Ajout d’un formulaire personnalisable dans vos évènements pour vos invités</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {featureInactive}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <hr></hr>

            {/* 7 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>Rappel par SMS du rendez-vous pour vos invités</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {featureInactive}
                                {featureInactive}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* 9 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>Possibilité de masquer les éléments mentionnant « Aliz »</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {featureInactive}
                                {featureInactive}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <hr></hr>

            <h2 className="mt-5">Services</h2>

            {/* 10 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>Nombre maximal d’agendas connectés à AlizCalendar</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>1</p></td>
                                <td><p>5</p></td>
                                <td><p>10</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <hr></hr>

            {/* 11 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-8">
                        <h3>Nombre de rendez-vous par mois</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>illimité</p></td>
                                <td><p>illimité</p></td>
                                <td><p>illimité</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <hr></hr>


            {/* 12 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Support Client*</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>Standard</p></td>
                                <td><p>Standard</p></td>
                                <td><p>Prioritaire</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="row justify-content-center">
                    <div className="col-5 support">
                        <p>*Support Standard</p>
                        <p>(Chat - Mail 5/7j - 9h-17h)</p>
                    </div>

                    <div className="col-5 support">
                        <p>*Support Prioritaire</p>
                        <p>(Chat - Mail - Tel : 5/7j de 9h-18h)</p>
                    </div>
                </div>
            </div>

            <hr></hr>


            <h2 className="mt-5">Intégration web</h2>

            {/* 13 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Intégration sur votre site web de votre agenda AlizCalendar</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <hr></hr>

            {/* 14 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Intégration d’un widget AlizCalendar sur votre site web</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <hr></hr>
            
            {/* 15 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Intégration d’un lien/bouton de redirection vers votre agenda AlizCalendar</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div >
    )
}
