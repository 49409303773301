import React from 'react'

export default function CountDown() {
    return (
        <div id="countDown">
            <h2>Il vous reste: </h2>
            {/* <FlipCountdown
                size={isDesktopOrLaptop ? 'medium' : 'small'}
                endAt={'2021-11-29 23:59:59'} // Date/Time
                dayTitle='Days'
                hourTitle='Hours'
                minuteTitle='Minutes'
                secondTitle='Seconds'
                endAtZero
                hideYear
                hideMonth
            /> */}
        </div>
    )
}
