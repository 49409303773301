import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}


export async function isCalendarSubscribed(setResponse) {
    await axios({
        url: url + 'auth/isCalendarSubscribed',
        headers: headersAuth,

    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setResponse(response.data.isSubscribed)
            }
        }, (err) => {
            setResponse(false)
        });
}


