import React, { useEffect, useState, Suspense, lazy } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';
import gsap from 'gsap'

// ----- Component ------
import LoaderSuspense from "../LoaderSuspense"
import Summary from "../Summary"

const AdministrativeOffer = lazy(() => import('./Offers/Administrative'));
const TvaOffer = lazy(() => import('./Offers/Tva'));
const VerifLegalOffer = lazy(() => import('./Offers/VerifLegal'));
const CalendarOffer = lazy(() => import('./Offers/Calendar'));


export default function HomePrice({ token }) {
    // ----- Get params  -----
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [sub, setSub] = useState(Boolean(query.get("pack-annuel") ? query.get("pack-annuel") : true))
    const [selected, setSelected] = useState(query.get("assistant") ? query.get("assistant") : process.env.REACT_APP_BOT_NAME_PATIENTELE)

    let navigate = useNavigate();

    // ----- When selected, sub or url change, we set url -----
    useEffect(() => {
        navigate("/tarifs?assistant=" + selected + "&pack-annuel=" + sub)
    }, [selected, navigate, sub]);


    // ----- Scroll window on top -----
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    useEffect(() => {
        if (sub) {
            gsap.set('.annual-pack', { color: "#090E1A" })
            gsap.set('.monthly-pack', { color: "#6F7581" })
        } else {
            gsap.set('.annual-pack', { color: "#6F7581" })
            gsap.set('.monthly-pack', { color: "#090E1A" })
        }
    }, [sub]);


    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Les tarifs de nos assistants virtuels - Aliz</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" content="Découvrez les tarifs de nos assistants virtuels - Comparez les différents plans et commencez à déléguer ! " />
                <link rel="canonical" href="https://aliz.io" />
                <meta property="og:title" content="Les tarifs de nos assistants virtuels - Aliz" />
            </Helmet>

            <div className="price-view">

                <div className="price-header">

                    <div className="row justify-content-center">
                        <h1 className="col-12">Nos tarifs</h1>
                        <h2 className="col-8 col-lg-6 col-xl-4">Découvrez nos offres et profitez des à présent d'un <b>essai GRATUIT</b> et <b>sans CB</b> de <b>15 jours</b> sur tous nos assistants</h2>
                    </div>

                    <div className="row justify-content-center">
                        <h3 className="col-8 col-lg-6 col-xl-4">Aucune carte de crédit requise pour démarrer !</h3>
                    </div>

                </div>

                {/* ----- Button for active & display Price Pack ----- */}
                <div className="block-btn row justify-content-center">
                    <button type="button" className={`btn-pack ${process.env.REACT_APP_BOT_NAME_PATIENTELE === selected ? "active" : ""}`} onClick={() => setSelected(process.env.REACT_APP_BOT_NAME_PATIENTELE)}>{process.env.REACT_APP_BOT_NAME_PATIENTELE}</button>
                    <button type="button" className={`btn-pack ${process.env.REACT_APP_BOT_NAME_TVA === selected ? "active" : ""}`} onClick={() => setSelected(process.env.REACT_APP_BOT_NAME_TVA)}>{process.env.REACT_APP_BOT_NAME_TVA}</button>
                    <button type="button" className={`btn-pack ${process.env.REACT_APP_BOT_NAME_CALENDAR === selected ? "active" : ""}`} onClick={() => setSelected(process.env.REACT_APP_BOT_NAME_CALENDAR)}>{process.env.REACT_APP_BOT_NAME_CALENDAR}</button>
                    <button type="button" className={`btn-pack ${process.env.REACT_APP_BOT_NAME_VERIFLEGAL === selected ? "active" : ""}`} onClick={() => setSelected(process.env.REACT_APP_BOT_NAME_VERIFLEGAL)}>{process.env.REACT_APP_BOT_NAME_VERIFLEGAL}</button>
                </div>


                {/* ----- Monthly or Annual subscription ----- */}
                <div className="row justify-content-center mt-5">
                    <div className="col-auto d-flex">
                        <p className="monthly-pack">PLAN MENSUEL</p>

                        <label className="switch">
                            <input type="checkbox" id="pack" name="pack" checked={sub} onChange={(e) => setSub(e.target.checked)} />
                            <span className="slider round"></span>
                        </label>

                        <p className="annual-pack">PLAN ANNUEL</p>
                        <p className="discount">-30%</p>
                    </div>
                </div>

                <div className="row justify-content-center">
                    {(() => {
                        switch (selected) {
                            case process.env.REACT_APP_BOT_NAME_PATIENTELE:
                                return <Suspense fallback={<LoaderSuspense />}>
                                    <AdministrativeOffer annual={sub} token={token} />;
                                </Suspense>
                            case process.env.REACT_APP_BOT_NAME_TVA:
                                return <Suspense fallback={<LoaderSuspense />}>
                                    <TvaOffer annual={sub} token={token} />
                                </Suspense>;
                            case process.env.REACT_APP_BOT_NAME_CALENDAR:
                                return <Suspense fallback={<LoaderSuspense />}>
                                    <CalendarOffer annual={sub} token={token} />
                                </Suspense>;
                            case process.env.REACT_APP_BOT_NAME_VERIFLEGAL:
                                return <Suspense fallback={<LoaderSuspense />}>
                                    <VerifLegalOffer annual={sub} token={token} />
                                </Suspense>;
                            default:
                                return <Suspense fallback={<LoaderSuspense />}>
                                    <AdministrativeOffer annual={sub} token={token} />
                                </Suspense>;
                        }
                    })()}
                </div>

            </div>

            <Summary />
        </>
    )
}
