import React from 'react'
import { Link } from 'react-router-dom'

// Packages
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Integration({data}) {

    return (
        <div className="integration-block">

            <div className="img-block">
                <LazyLoadImage
                    className="assistant-picture"
                    src={`./images/Integration/${data.logo}`}
                    alt="logo Google, Outlook et Impôts"
                    effect="blur"
                />
            </div>

            <p className="title">{data.title}</p>

            <p className="description">{data.description}</p>

            <Link className="btn-alizFull" to={data.href}>Voir les assistants</Link>

        </div>
    )
}
