import React from 'react'

export default function DesktopTva() {
    // feature included in subscription
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-active bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // feature not included in subscription
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-inactive bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg></td>;

    // point of question symbol
    const question = <td className="question"></td>;
    return (
        <table className="col-11 col-xl-10 table table-borderless table-hover">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Starter</th>
                    <th scope="col">Business</th>
                    <th scope="col">Premium</th>
                </tr>
            </thead>

            <tbody>
                {/* 0 , FEATURES */}
                <tr className="table-head">
                    <th scope="row" className="py-3"><h4>Fonctionnalités</h4></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {/* 1 */}
                <tr>
                    <th scope="row"><p>Déclaration automatique de TVA sur impots.gouv</p></th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 2 */}
                <tr>
                    <th scope="row"><p>Stockage PDF de la déclaration de TVA, accusé réception, validation de paiement dans votre espace de travail (Drive)</p></th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 3 */}
                <tr>
                    <th scope="row">
                        <p>Rapport détaillé par e-mail de l'activité de votre assistant Aliz</p>
                    </th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 4 */}
                <tr>
                    <th scope="row"><p>Historisation mensuelle des déclarations TVA réalisées</p></th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 5 */}
                <tr>
                    <th scope="row" className="d-flex">
                        <p>Contrôle de cohérence automatisé de la TVA déclarée</p>
                        <i className="index-one">(1)</i>
                    </th>
                    {question}
                    {featureInactive}
                    {feature}
                    {feature}
                </tr>
                {/* 6 */}
                <tr>
                    <th scope="row"><p>Envoi d'email automatique aux clients pour demander les pièces comptables</p></th>
                    {question}
                    {featureInactive}
                    {featureInactive}
                    {feature}
                </tr>


                {/* 9, SERVICES */}
                <tr className="table-head">
                    <th scope="row" className="py-3"><h4>Services</h4></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {/* 1 , 10 */}
                <tr>
                    <th scope="row">
                        <p>Fréquence d'envois des déclarations TVA</p>
                    </th>
                    {question}
                    <td> <p>10 fois par mois</p></td>
                    <td> <p>illimité</p></td>
                    <td> <p>illimité</p></td>
                </tr>
                {/* 2, 11 */}
                {/* <tr>
                    <th scope="row">
                        <p>Nombre de Licence / Utilisateur</p>
                    </th>
                    {question}
                    <td> <p>1 licence</p></td>
                    <td> <p>1 licence</p></td>
                    <td> <p>1 licence</p></td>
                </tr> */}
                {/* 2, 11 */}
                {/* <tr>
                    <th scope="row">
                        <p>Prix par utilisateur additionnel</p>
                    </th>
                    {question}
                    <td> <p>25€/ mois</p></td>
                    <td> <p>20€/ mois</p></td>
                    <td> <p>15€/ mois</p></td>
                </tr> */}
                {/* 3, 12 */}
                <tr>
                    <th scope="row">
                        <p>Volume de déclaration TVA par mois</p>
                    </th>
                    {question}
                    <td> <p>25</p></td>
                    <td> <p>50</p></td>
                    <td> <p>150</p></td>
                </tr>
                {/* 4, 13 */}
                <tr>
                    <th scope="row">
                        <p>Déclaration à l'unité supplémentaire</p>
                    </th>
                    {question}
                    <td> <p>0,98cts</p></td>
                    <td> <p>0,79cts</p></td>
                    <td> <p>0,73cts</p></td>
                </tr>

                {/* 5, 14 */}
                <tr>
                    <th scope="row">
                        <p>Support Client</p>
                    </th>
                    {question}
                    <td>
                        <p>Standard</p>
                        <i>(5/7j - 9h-17h / Chat &amp; Mail)</i>
                    </td>
                    <td>
                        <p>Prioritaire</p>
                        <i> (6/7j - 9h-20h / Chat, Mail &amp; Tel)</i>
                    </td>
                    <td>
                        <p>Prioritaire</p>
                        <i> (6/7j - 9h-20h / Chat, Mail &amp; Tel)</i>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
