import React from 'react'
import { Link } from 'react-router-dom'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Components -----
import Navbar from './NavTerms'

export default function CookiePolicy() {
    return (
        <>
            <Helmet title="Politique d'Utilisation Des Cookies" />

            <div className="conditionsTerms container-fluid">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">

                        <h1>Politique d’utilisation des cookies</h1>
                        <h2 className="catch-phrase">Mise à jour : le 09/07/2021</h2>


                        <p className="conditionsTerms-text">
                            La société TED (« <b>TED</b> » ou « <b>nous</b> ») dépose des cookies dans l’équipement terminal
                            (ordinateur, smartphone, tablette) de l’utilisateur (ci-après « <b>l’Utilisateur</b> »)
                            lors de sa connexion sur les sites Aliz : aliz.io et calendar.aliz.io (ci-après les « <b>Sites</b> »).
                        </p>



                        <h2 className="catch-phrase">1. Qu'est-ce qu'un cookie ?</h2>

                        <p className="conditionsTerms-text">
                            Les cookies sont des suites d’informations qui peuvent être conservées dans l’équipement terminal
                            de l’Utilisateur lors de sa connexion sur les Sites.
                            Le terminal conserve les cookies pendant une certaine durée et les renvoie au serveur web chaque fois
                            que l’Utilisateur se connecte à nouveau aux Sites,
                            par exemple afin que les Sites se souviennent de la langue de préférence de l’Utilisateur.
                            Il existe différents types de cookies :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>
                                des <b>cookies de session</b> qui sont supprimés dès que l’Utilisateur quitte le site internet qu’il visite ;
                            </li>
                            <li>
                                des <b>cookies permanents</b> ou persistants qui demeurent sur le terminal de l’Utilisateur jusqu’à
                                expiration de leur durée de vie ou jusqu’à ce que l’Utilisateur les supprime ;
                            </li>
                            <li>
                                des <b>cookies propriétaires</b> qui sont installés par le site internet que l’Utilisateur est en train de consulter ;
                            </li>
                            <li>
                                des <b>cookies tiers</b> installés par un domaine autre que celui du site internet que l’Utilisateur est en train de consulter.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            Certains cookies sont des cookies « techniques », exclusivement installés pour faciliter la communication
                            électronique ou absolument nécessaires à la fourniture du service sur internet.
                            Ils sont déposés automatiquement sur le terminal de l’Utilisateur lorsqu’il navigue sur les Sites.
                            L’Utilisateur ne peut pas les désactiver. Les cookies de mesures d’audience non strictement nécessaires,
                            des cookies de réseaux sociaux sont déposés sur le terminal de l’Utilisateur s’il y a consenti en cliquant sur « accepter ».
                            Le traitement des données collectées à partir de ces cookies est donc fondé sur le consentement de l’Utilisateur,
                            qu’il peut retirer à tout moment.
                        </p>





                        <h2 className="catch-phrase">2. Pourquoi utilisons-nous des cookies ? </h2>

                        <p className="conditionsTerms-text">
                            Le cas échéant avec le consentement de l’Utilisateur, TED dépose des cookies sur son terminal aux fins :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>
                                d’assurer la navigation des Utilisateurs sur les Sites ;
                            </li>
                            <li>
                                d’assurer et d’améliorer le fonctionnement et la sécurité des Sites ;
                            </li>
                            <li>
                                de réaliser des calculs statistiques ;
                            </li>
                            <li>
                                d’analyser la fréquentation et l’utilisation des Sites ;
                            </li>
                            <li>
                                de permettre à l’Utilisateur de partager des contenus vers les réseaux sociaux.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            Si l’Utilisateur y consent, des tiers sont également susceptibles de déposer des cookies sur son terminal.
                            TED n’a pas d’information et de maîtrise sur l’utilisation et le traitement des données personnelles par ces tiers.
                            Leur liste est indiquée dans l’outil de gestion du consentement. Des cookies de partage sur les réseaux sociaux peuvent également être déposés.
                            Si l’Utilisateur utilise les boutons de partage, il peut consulter les politiques de confidentialité relatives à chaque réseau social.
                        </p>






                        <h2 className="catch-phrase">3. Quels cookies utilisons-nous ? </h2>


                        <table className="table mt-5">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Nature</th>
                                    <th scope="col">Fonction</th>
                                    <th scope="col">Durée de vie</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cookies de localisation</td>
                                    <td>
                                        Attribuent à chaque utilisateur enregistré sur notre site un identifiant unique correspondant
                                        à sa localisation et au langage utilisé.
                                    </td>
                                    <td>30 jours</td>
                                </tr>
                                <tr>
                                    <td>Cookies techniques</td>
                                    <td>
                                        Utilisés à travers la page web pour optimiser la navigation de l’utilisateur.
                                        Ils ne collectent aucune information personnelle
                                    </td>
                                    <td>90 jours</td>
                                </tr>
                                <tr>
                                    <td>Cookies de session</td>
                                    <td>
                                        Permettent de suivre les actions de l’utilisateur au cours de navigation.
                                    </td>
                                    <td>23 jours</td>
                                </tr>
                                <tr>
                                    <td>Cookies de mesure d’audience</td>
                                    <td>
                                        Permettent de réaliser le suivi de la navigation et de mesurer l’audience des sites internet.
                                    </td>
                                    <td>13 mois</td>
                                </tr>
                                <tr>
                                    <td>Cookies de consentement</td>
                                    <td>
                                        Les cookies de consentement permettent de se souvenir si l’utilisateur a accepté ou non le dépôt de cookies sur son terminal
                                    </td>
                                    <td>6 mois</td>
                                </tr>
                            </tbody>
                        </table>


                        <p className="conditionsTerms-text">
                            Les données collectées à partir des cookies sont traitées conformément à la réglementation en la matière
                            et à la <Link to="/conditions-generales/politique-de-confidentialite">Politique de confidentialité</Link> d’Aliz.
                        </p>







                        <h2 className="catch-phrase">4. Comment l’Utilisateur peut-il paramétrer le dépôt de cookies ? </h2>

                        <p className="conditionsTerms-text">
                            Lors de sa première connexion sur les Sites, l’Utilisateur est informé au moyen d’un outil de gestion du consentement
                            du dépôt de cookies sur son terminal.
                            L’Utilisateur a la possibilité d’accepter ou de refuser l’intégralité des cookies soumis au recueil de son consentement.
                            <br></br>
                            Tant que l’Utilisateur n’a pas accepté le dépôt de cookies, aucun cookie autre que strictement technique
                            n’est déposé sur son terminal.
                            Un outil de gestion du consentement, accessible sur la page d’accueil des Sites permet aux Utilisateurs de paramétrer
                            ses choix en matière de cookies.
                        </p>


                    </div>
                </div>

            </div>
        </>
    )
}
