import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

const headers = {
    'Content-Type': 'application/json'
};
const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}


// _______________________________ User _________________________________________

export async function getSmallprofile(setProfile) {
    await axios({
        url: url + 'auth/smallprofile',
        headers: headersAuth,

    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setProfile(response.data.profile)
            }
        }, (err) => {
            setProfile(false)
        });
}

export async function getBillingprofile(setProfile) {
    await axios({
        url: url + 'auth/billingprofile',
        headers: headersAuth,

    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setProfile(response.data.billingProfile)
            }
        }, (err) => {
            setProfile(false)
        });
}

export async function setBillingprofile(data, setErrors, setSuccess) {
    await axios({
        method: "post",
        url: url + 'auth/setbillingprofile',
        headers: headersAuth,
        data: data
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setSuccess("success");
            }
        }, (err) => {
            alert(err)
        });
}

export async function setSmallprofile(data, setErrors, setSuccess) {
    await axios({
        method: "post",
        url: url + 'auth/setsmallprofile',
        headers: headersAuth,
        data: data
    })
        .then(function (response) {
            // setResponse(JSON.stringify(response.data))
            if (response.data.errors) {
                setErrors(response.data.errors)

            } else if (typeof response.data.email !== 'undefined' && response.data.email === 'change') {
                setSuccess("email");
            } else {
                setSuccess("success");

            }
        }, (err) => {
            alert(err)
        });
}

export async function newEmailFooter(data, setResponse, setErrors) {
    await axios({
        method: 'POST',
        url: url + 'footer/email',
        headers: headers,
        data: data
    })
        .then((response) => {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setResponse("success");
            }
        }, (err) => {
            setResponse(err)
        });
}




export async function getStatistics(setStatistic, setIsLoaded, byMonthOrWeek) {
    await axios({
        method: 'POST',
        url: url + 'auth/counterbyuser',
        headers: headersAuth,
        data: {
            'byMonth': byMonthOrWeek
        }

    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setStatistic(response.data)
                setIsLoaded(true)
            }
        }, (err) => {
            setStatistic(false)
        });
}