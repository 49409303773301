import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

// const headers = {
//     'Content-Type': 'application/json'
// };
const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}


export async function getBotPatientieleprofile(setProfile) {
    await axios({
        url: url + 'auth/botpatientele',
        headers: headersAuth,

    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setProfile(response.data.tbotpatientele)
            }
        }, (err) => {
            setProfile(false)
        });
}
export async function setBotPatientieleprofile(data, setErrors, setSuccess) {
    await axios({
        method: "post",
        url: url + 'auth/setbotpatient',
        headers: headersAuth,
        data: data
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setSuccess("success");
            }
        }, (err) => {
            alert(err)
        });
}

