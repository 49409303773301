import React, { useEffect, useState } from 'react'
import { isCalendarSubscribed } from './../../services/User/calendar.js'
import { isBotVerifLegalSubscribed } from './../../services/User/verifLegal'
import { Link } from 'react-router-dom'
import { numberNotReadedNotification } from './../../services/User/notification'

export default function Nav() {
    const [location, setLocation] = useState('')
    const [isCalendar, setIsCalendar] = useState(false)
    const [isVerifLegal, setIsVerifLegal] = useState([])

    const [responseNumberNotReadedNotification, setResponseNumberNotReadedNotification] = useState(0)

    // Listener url pathname and Check token 
    useEffect(() => {
        setLocation(window.location.pathname)
    }, [location]);

    useEffect(() => {
        isCalendarSubscribed(setIsCalendar)
        isBotVerifLegalSubscribed(setIsVerifLegal)
        numberNotReadedNotification(setResponseNumberNotReadedNotification)
    }, [])


    return (
        <ul>
            <li className={location.match(/espace-client\/accueil/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/espace-client/accueil">Mes souscriptions</Link>
            </li>


            <li className={location.match(/espace-client\/profil/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.4" cx="11.5789" cy="6.77803" r="4.77803" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.00002 18.2014C3.99873 17.8655 4.07385 17.5338 4.2197 17.2312C4.67736 16.3158 5.96798 15.8307 7.03892 15.611C7.81128 15.4462 8.59431 15.3361 9.38217 15.2815C10.8408 15.1534 12.3079 15.1534 13.7666 15.2815C14.5544 15.3367 15.3374 15.4468 16.1099 15.611C17.1808 15.8307 18.4714 16.2701 18.9291 17.2312C19.2224 17.848 19.2224 18.564 18.9291 19.1808C18.4714 20.1419 17.1808 20.5813 16.1099 20.7918C15.3384 20.9635 14.5551 21.0767 13.7666 21.1305C12.5794 21.2311 11.3866 21.2495 10.1968 21.1854C9.92221 21.1854 9.65677 21.1854 9.38217 21.1305C8.59663 21.0773 7.81632 20.9641 7.04807 20.7918C5.96798 20.5813 4.68652 20.1419 4.2197 19.1808C4.0746 18.8747 3.99955 18.5402 4.00002 18.2014Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/espace-client/profil">Mes informations personnelles</Link>
            </li>

            <li className={location.match(/espace-client\/mon-profil-professionnel/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M2.80408 15.4769C2.80408 15.4769 2.94608 17.2149 2.97908 17.7629C3.02308 18.4979 3.30708 19.3189 3.78108 19.8889C4.45008 20.6969 5.23808 20.9819 6.29008 20.9839C7.52708 20.9859 16.5221 20.9859 17.7591 20.9839C18.8111 20.9819 19.5991 20.6969 20.2681 19.8889C20.7421 19.3189 21.0261 18.4979 21.0711 17.7629C21.1031 17.2149 21.2451 15.4769 21.2451 15.4769" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.49597 5.32949V4.95849C8.49597 3.73849 9.48397 2.75049 10.704 2.75049H13.286C14.505 2.75049 15.494 3.73849 15.494 4.95849L15.495 5.32949" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.995 16.6782V15.3842" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.74988 8.38905V11.8561C4.66788 13.1211 6.96588 14.0071 9.48788 14.3581C9.78988 13.2571 10.7829 12.4501 11.9899 12.4501C13.1779 12.4501 14.1909 13.2571 14.4729 14.3681C17.0049 14.0171 19.3119 13.1311 21.2399 11.8561V8.38905C21.2399 6.69505 19.8769 5.33105 18.1829 5.33105H5.81688C4.12288 5.33105 2.74988 6.69505 2.74988 8.38905Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/espace-client/mon-profil-professionnel">Mes informations professionnelles</Link>
            </li>

            <li className={location.match(/espace-client\/mes_factures/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.7367 2.76196H8.08369C6.02469 2.76196 4.24969 4.43096 4.24969 6.49096V17.204C4.24969 19.38 5.90869 21.115 8.08369 21.115H16.0727C18.1327 21.115 19.8017 19.265 19.8017 17.204V8.03796L14.7367 2.76196Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.474 2.75024V5.65924C14.474 7.07924 15.623 8.23124 17.042 8.23424C18.359 8.23724 19.706 8.23824 19.797 8.23224" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M14.284 15.5579H8.88699" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M12.2425 10.6056H8.88651" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/espace-client/mes_factures">Mes factures</Link>
            </li>


            <li className={location.match(/espace-client\/mes_moyens_de_paiement/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M7.42226 19.8203C7.84426 19.8203 8.18726 20.1633 8.18726 20.5853C8.18726 21.0073 7.84426 21.3493 7.42226 21.3493C7.00026 21.3493 6.65826 21.0073 6.65826 20.5853C6.65826 20.1633 7.00026 19.8203 7.42226 19.8203Z" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M18.6747 19.8203C19.0967 19.8203 19.4397 20.1633 19.4397 20.5853C19.4397 21.0073 19.0967 21.3493 18.6747 21.3493C18.2527 21.3493 17.9097 21.0073 17.9097 20.5853C17.9097 20.1633 18.2527 19.8203 18.6747 19.8203Z" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2.74988 3.25L4.82988 3.61L5.79288 15.083C5.87088 16.018 6.65188 16.736 7.58988 16.736H18.5019C19.3979 16.736 20.1579 16.078 20.2869 15.19L21.2359 8.632C21.3529 7.823 20.7259 7.099 19.9089 7.099H5.16388" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M14.1254 10.795H16.8984" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/espace-client/mes_moyens_de_paiement">Mes moyens de paiement</Link>
            </li>

            <li className={location.match(/espace-client\/mes_notifications/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.50083 13.7871V13.5681C3.53295 12.9202 3.7406 12.2925 4.10236 11.7496C4.7045 11.0975 5.1167 10.2983 5.29571 9.43598C5.29571 8.7695 5.29571 8.0935 5.35393 7.42703C5.65469 4.21842 8.82728 2 11.9611 2H12.0387C15.1725 2 18.345 4.21842 18.6555 7.42703C18.7137 8.0935 18.6555 8.7695 18.704 9.43598C18.8854 10.3003 19.2972 11.1019 19.8974 11.7591C20.2618 12.2972 20.4698 12.9227 20.4989 13.5681V13.7776C20.5206 14.648 20.2208 15.4968 19.6548 16.1674C18.907 16.9515 17.8921 17.4393 16.8024 17.5384C13.607 17.8812 10.383 17.8812 7.18762 17.5384C6.09914 17.435 5.08576 16.9479 4.33521 16.1674C3.778 15.4963 3.48224 14.6526 3.50083 13.7871Z" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M9.55493 20.8518C10.0542 21.4785 10.7874 21.884 11.5922 21.9788C12.3971 22.0735 13.2072 21.8495 13.8433 21.3564C14.0389 21.2106 14.2149 21.041 14.3672 20.8518" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Link to="/espace-client/mes_notifications">Mes notifications</Link>
                {responseNumberNotReadedNotification > 0 &&
                    <div className="responseNumberNotReadedNotification"><p>{responseNumberNotReadedNotification}</p></div>}
            </li>

            <li className={location.match(/espace-client\/demander_assistance/) ? "active" : ''}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.0568 2.0002C8.54687 1.98602 5.28557 3.80716 3.4605 6.80047C1.63543 9.79378 1.51292 13.5225 3.13757 16.6288L3.33789 17.0192C3.50209 17.3265 3.53644 17.6865 3.43329 18.0192C3.14742 18.7785 2.90849 19.5546 2.71784 20.343C2.71784 20.743 2.83231 20.9716 3.26158 20.9621C4.0219 20.7942 4.77068 20.5779 5.50332 20.3145C5.81886 20.2276 6.15437 20.2477 6.45725 20.3716C6.73389 20.5049 7.2967 20.8478 7.31578 20.8478C10.9915 22.7806 15.4808 22.2473 18.5998 19.5075C21.7187 16.7677 22.8199 12.3901 21.3676 8.50416C19.9153 4.61821 16.2111 2.03065 12.0568 2.0002V2.0002Z" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <ellipse opacity="0.4" cx="7.28718" cy="12.0002" rx="0.476965" ry="0.47619" fill="#200E32" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <ellipse opacity="0.4" cx="12.0568" cy="12.0002" rx="0.476965" ry="0.47619" fill="#200E32" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <ellipse opacity="0.4" cx="16.8265" cy="12.0002" rx="0.476965" ry="0.47619" fill="#200E32" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <Link to="/espace-client/demander_assistance">Demande une assistance</Link>
            </li>

            <li className={location.match(/espace-client\/parrainage/) ? "active" : ''}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M17.5948 10.9319C19.1972 10.9319 20.497 9.63298 20.497 8.03064C20.497 6.42831 19.1972 5.12939 17.5948 5.12939" stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.9291 14.0847C19.4076 14.1177 19.8833 14.1856 20.3517 14.291C21.0026 14.4184 21.7854 14.6851 22.0641 15.2691C22.2419 15.6431 22.2419 16.0785 22.0641 16.4534C21.7863 17.0373 21.0026 17.3031 20.3517 17.437" stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path opacity="0.4" d="M6.28998 10.9319C4.68765 10.9319 3.38782 9.63298 3.38782 8.03064C3.38782 6.42831 4.68765 5.12939 6.28998 5.12939" stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4.95577 14.0847C4.47727 14.1177 4.00152 14.1856 3.5331 14.291C2.88227 14.4184 2.09943 14.6851 1.82168 15.2691C1.64293 15.6431 1.64293 16.0785 1.82168 16.4534C2.09852 17.0373 2.88227 17.3031 3.5331 17.437" stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9377 14.7096C15.1846 14.7096 17.9584 15.2009 17.9584 17.1672C17.9584 19.1325 15.2029 19.6422 11.9377 19.6422C8.68997 19.6422 5.91705 19.1508 5.91705 17.1846C5.91705 15.2183 8.67255 14.7096 11.9377 14.7096Z" stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M11.9377 11.9049C9.79638 11.9049 8.07947 10.188 8.07947 8.04575C8.07947 5.90442 9.79638 4.1875 11.9377 4.1875C14.0791 4.1875 15.796 5.90442 15.796 8.04575C15.796 10.188 14.0791 11.9049 11.9377 11.9049Z" stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <Link to="/espace-client/parrainage">Parrainez un ami</Link>
            </li>

            {isCalendar &&
                <button className="hasSubscriptionBtn calendarBtn mb-4" onClick={() => window.open(
                    process.env.REACT_APP_LINK_CALENDAR + "user/aliz/connect?token=" + localStorage.getItem('tokenAliz'),
                    '_blank' // <- This is what makes it open in a new window.
                )}> Accéder à AlizCalendar </button>
            }
            {/* eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2Njg0MzMxNjAsImV4cCI6MTY3MTA2MTE2MCwicm9sZXMiOlsiUk9MRV9VU0VSIiwiUk9MRV9BRE1JTiJdLCJ1c2VybmFtZSI6ImpvcmRhbi5waGlsaXBwb3RAdGVkLWNvbXBhbnkuY29tIn0.m5ycFxvynlqY8IvjmD0cYr0wVMeBt5UEetKBD8WYaGcWTTxRSMWjT4WI1dKLfRuTlrdKmSAiXXE2LCytzZaawmnMTupP4LnpvMwNYGbP9V1Mr35tfUFO6kCe5S61Ai9FopMsqiD4sCciX0AFF-p1sJ50WUfQomiQGBblCF51F5zBWLPM41DWDmnMB7yeiOUkVbe0Z1Gj2BlAzWYKLYLsrmJde_fiNiRPEveFf2kARyArO-7_t33BIZcNcvyh2dzEa-5DyJoNPtg_mfdVx9k3-qvpBcEC2V7gLt-W5yBMLQQBwAH5a6-2zgr4rj24fe4NZ7dj7pH1pB6kgJxABx7WivxZeXyJqQRrKnFE9XSXiEyTE7rp_yYXtzGLKEyW2C7Yoxquq2jbFef90hi_Jhyu8oIVQr8kctLHipuqkHWgMNDLx4VGnGoMxwruj6T7nzLC6UReonvbAy6kbHjAWffJdsZCLGn0jYYXDKIG00f-X9fG-77uScNvbC5AyzLmw5kZQNKD9CNkHvvYgdX4Ctsjobk-9OZUxBOrkElatKv3MfI8n-ORnUUwNdsHx5Dh4TdKL6rrGkXpST72vhOIEOn1ofDPh1BQ0bL4UCXOlgrCVcSLsA3wCJCUKhoitqma3Zi6ZkBQ6clqoP5TxoZmjpKIj1cQTdrGSnXz9mieU86ywyU */}
            {isVerifLegal?.isSubscribed &&
                <button className="hasSubscriptionBtn verifLegalBtn mb-4" onClick={() => window.open(
                    process.env.REACT_APP_LINK_VERIFLEGAL + "?token=" + localStorage.getItem('tokenAliz'),
                    '_blank' // <- This is what makes it open in a new window.
                )}>Accéder à AlizVerif</button>
            }




            {/* <li>
                <a href={process.env.REACT_APP_LINK_ASSISTANCE} className="btn-referAFriend btn-yellow" target="_blank" rel="noopener noreferrer">Demander assistance</a>
            </li> */}
        </ul >
    )
}
