import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { newSubscriptionTva } from '../../../../../services/User/subscription.js'
import { issetBot } from '../../../../../services/robots.js'

import gsap from 'gsap'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ConditionsTva from './../TermsAndConditions/Tva'

export default function Tva({ responseToken, subscription }) {

    let navigate = useNavigate();

    const [subscrib, setSubscrib] = useState([])

    const [errors, setErrors] = useState([])
    // _______Data________ 
    const [socialReason, setSocialReason] = useState('')
    const [siretNumber, setSiretNumber] = useState('')
    const [identifiersImpots, setIdentifiersImpots] = useState('')
    const [passwordImpots, setPasswordImpots] = useState('')
    const [licenseNumber, setLicenseNumber] = useState('0')
    const [phone, setPhone] = useState('')
    const [agree, setAgree] = useState(false)
    // ________End data__________

    const [subscriptionExist, setSubscriptionExist] = useState(false)

    const [prices, setPrices] = useState([])

    // Agree Button section 
    // const agreeBtnDisabled = useRef();
    const agreeBtnActived = useRef();

    useEffect(() => {
        if (responseToken === false) {
            window.location.href = '/login?assistant=' + subscription.robot + '&price=' + subscription.priceId
        }
    }, [responseToken, subscription.priceId, subscription.robot, navigate])




    useEffect(() => {
        // !IMPORTANT : props in array 
        setPrices(subscription.priceId)
        issetBot(setSubscriptionExist, parseInt(subscription.robot))
        setLicenseNumber(0)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (subscriptionExist.waiting) {
            navigate('/espace-client/souscription-etape2?assistant=' + subscription.robot + '&price=' + subscription.priceId + '&eur=' + subscription.price + '&subscription=success')
        }
        // eslint-disable-next-line
    }, [subscriptionExist, navigate])


    // Data for create new subscription && create new customer in stripe
    const data = {
        'robot': parseInt(subscription.robot),
        'priceId': prices,
        'socialReason': socialReason,
        'siretNumber': siretNumber,
        'identifiersImpots': identifiersImpots,
        'passwordImpots': passwordImpots,
        'licenseNumber': licenseNumber,
        'phone': phone,
        'agree': agree
    }

    const subscribed = () => {
        newSubscriptionTva(data, setErrors, setSubscrib)
    }

    useEffect(() => {
        if (subscrib === "success") {
            return navigate('/espace-client/souscription-etape2?assistant=' + subscription.robot + '&price=' + subscription.priceId + '&eur=' + subscription.price + '&subscription=success')
        }
        // eslint-disable-next-line
    }, [subscrib])


    const agreeCgu = () => {
        confirmAlert({

            customUI: ({ onClose }) => {
                return (
                    <div className="row justify-content-center popup-custom">
                        <div className="custom-ui" >
                            <ConditionsTva />
                        </div>

                        <div className="d-flex justify-content-center">
                            <button className="btn-red"
                                onClick={() => {
                                    setAgree(false)
                                    onClose();
                                }}>
                                Refuser
                            </button>

                            <button className="btn-alizFull"
                                ref={agreeBtnActived}
                                onClick={() => {
                                    setAgree(true)
                                    onClose();
                                }}
                            >Accepter</button>

                        </div>
                    </div >
                );
            }
        });
    }

    useEffect(() => {
        if (agree === true) {
            gsap.set('.ballAgree', { backgroundColor: '#21e01a' })
        }
    }, [agree])

    return (
        <div className="subscribForm-view ">
            <div className="d-flex flex-direction-column">
                <h1>Assistant de Déclaration de TVA - {process.env.REACT_APP_BOT_NAME_TVA}</h1>

                <p className="ask-information col-12 col-xl-9">Afin de profiter au maximum de votre assistant, nous devons lui fournir quelques informations supplémentaires</p>

                {/* {errors.alreadyFree && <div className="">
                    <span className="text-danger-xl col-12 col-xl-9">{errors.alreadyFree}<br></br></span>
                    <button className="btn btn-danger col-8" onClick={() => navigate(-1)}>Retour</button>
                </div>} */}

                {/* Form Subscription */}
                {(() => {
                    if (!subscriptionExist.exist) {

                        return <div className="col-12 offset-xl-0 col-xl-8 px-0">

                            <div className="d-flex">
                                <div className="col-5">
                                    <label htmlFor="socialReason">Raison Sociale *</label>
                                    <input type="text"
                                        name="socialReason"
                                        id="socialReason"
                                        value={socialReason || ''}
                                        minLength="3"
                                        maxLength="70"
                                        required
                                        onChange={(e) => setSocialReason(e.target.value)}
                                    />
                                    {errors.socialReason && <span className="text-danger">{errors.socialReason}<br></br></span>}
                                </div>
                                <div className="offset-2 col-5">
                                    <label htmlFor="siretNumber">N° SIRET *</label>
                                    <input type="text"
                                        name="siretNumber"
                                        id="siretNumber"
                                        value={siretNumber || ''}
                                        minLength="13"
                                        maxLength="14"
                                        required
                                        onChange={(e) => setSiretNumber(e.target.value)}
                                    />
                                    {errors.siretNumber && <span className="text-danger">{errors.siretNumber}</span>}
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="col-5">
                                    <label htmlFor="identifiersImpots">Identifiants Impôts.gouv *</label>
                                    <input type="text"
                                        name="identifiersImpots"
                                        id="identifiersImpots"
                                        value={identifiersImpots || ''}
                                        minLength="12"
                                        maxLength="13"
                                        required
                                        onChange={(e) => setIdentifiersImpots(e.target.value)}
                                    />
                                    {errors.identifiersImpots && <span className="text-danger">{errors.identifiersImpots}<br></br></span>}
                                </div>
                                <div className="offset-2 col-5">
                                    <label htmlFor="passwordImpots">Mot de passe Impôts.gouv *</label>
                                    <input type="password"
                                        name="passwordImpots"
                                        id="passwordImpots"
                                        value={passwordImpots || ''}
                                        minLength="3"
                                        maxLength="50"
                                        required
                                        onChange={(e) => setPasswordImpots(e.target.value)}
                                    />
                                    {errors.passwordImpots && <span className="text-danger">{errors.passwordImpots}</span>}
                                </div>
                            </div>

                            <div className="d-flex flex-direction-column">
                                <label htmlFor="phone">Téléphone *</label>
                                <input type="phone"
                                    name="phone"
                                    id="phone"
                                    value={phone || ''}
                                    minLength="9"
                                    maxLength="10"
                                    required
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                {errors.phone && <span className="text-danger">{errors.phone}</span>}
                            </div>



                            <div className="d-flex col-12 offset-xl-0 col-xl-12 mt-3 pl-0">
                                <div className="agreeText" onClick={agreeCgu}>
                                    {agree ?
                                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.3344 2.00018H7.66543C4.26785 2.00018 2.00043 4.43278 2.00043 7.91618V16.0842C2.00043 19.5709 4.2618 22.0002 7.66543 22.0002H16.3334C19.738 22.0002 22.0004 19.5709 22.0004 16.0842V7.91618C22.0004 4.42969 19.7382 2.00018 16.3344 2.00018ZM7.66543 3.50018H16.3344C18.8849 3.50018 20.5004 5.23515 20.5004 7.91618V16.0842C20.5004 18.7653 18.8848 20.5002 16.3334 20.5002H7.66543C5.11519 20.5002 3.50043 18.7655 3.50043 16.0842V7.91618C3.50043 5.23856 5.12077 3.50018 7.66543 3.50018ZM16.0903 9.09685C15.7974 8.80396 15.3225 8.80396 15.0296 9.09685L10.813 13.312L8.97015 11.4697L8.88601 11.3971C8.59235 11.1793 8.1757 11.2036 7.90949 11.47C7.61665 11.7629 7.61675 12.2378 7.90971 12.5306L10.2837 14.9036L10.3678 14.9762C10.6614 15.194 11.078 15.1697 11.3443 14.9035L16.0903 10.1575L16.1629 10.0734C16.3807 9.77978 16.3565 9.36312 16.0903 9.09685Z" fill={"#5A8AFF"} />
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#6c757d" viewBox="0 0 16 16">
                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    }
                                    <p className={`${agree ? "accepted" : ""}`}> J'accepte les conditions spécifiques liées au produit.</p>
                                </div>
                            </div>
                            {errors.agree && <span className="text-danger">{errors.agree}</span>}

                            <p className="text-aliz"> * Champs obligatoire</p>


                            <button className="btn-alizFull mb-5" onClick={subscribed}>Suivant</button>
                        </div>
                    } else {
                        return <div className="mt-5">
                            <span className="text-danger-xl col-12 col-xl-9">Vous avez déjà bénéficié d'un essai gratuit sur cette assistant. <br></br> Activer ou modifier votre souscription en <a href="/espace-client/accueil#souscriptions"> cliquant ici .</a></span>
                        </div>
                    }
                })()}
            </div >

        </div >
    )
}
