import React, { useEffect, useRef } from 'react'
import gsap from "gsap";

export default function Review({ review }) {

    const ratingRef = useRef()

    useEffect(() => {
        const starTotal = 5;
        const starPercentage = (5 / starTotal) * 100;

        const starPercentageRounded = `${(Math.round(starPercentage / 10) * 10)}%`;
        gsap.set(ratingRef.current, { css: { width: starPercentageRounded }, duration: 0 });
    }, [])


    return (
        <div className="review-component">
            <div className="review-header">
                {review.profile_photo_url &&
                    <img src={review.profile_photo_url} alt="Profile of review author" />
                }
                {review.author_name &&
                    <p>{review.author_name}</p>
                }
            </div>

            <div className="review-rating">
                <div className="stars-outer">
                    <div className="stars-inner" ref={ratingRef}></div>
                </div>
             
                {review.relative_time_description &&
                    <p className="review-time"> {review.relative_time_description}</p>
                }
            </div>

            {review.text &&
                <p className="review-text">{review.text}</p>
            }

        </div>
    )
}
