import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Services
import { initPayment } from '../../../../services/User/stripe.js'
import { UpdateActiveSubscription, UpdateTrialSubscription } from '../../../../services/User/subscription.js'

// Constant 
// import { SubscriptionStatus } from '../../../../services/Constant/subscriptionStatus'

// Components
import AdministrativeTable from '../../../Prices/Table/Administrative/Administrative'

// Packages
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


export default function Administrative(props) {

    const [updateResponse, setUpdateResponse] = useState([])
    const [updateErrors, setupdateErrors] = useState([])

    let navigate = useNavigate();

    const [updateTrialResponse, setUpdateTrialResponse] = useState([])
    const [updateTrialErrors, setupdateTrialErrors] = useState([])
    // Checkout Stripe 
    const [sessionId, setSessionId] = useState([])
    const stripe = window.Stripe(process.env.REACT_APP_SK_STRIPE);


    const [prices, setPrices] = useState(props.annual ? {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL_PRICE,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL_PRICE,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL_PRICE,
    } : {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY_PRICE,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY_PRICE,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY_PRICE,
    })
    const [idPrice, setIdPrice] = useState(props.annual ? {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL
    } : {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY
    })


    useEffect(() => {
        if (props.annual) {
            setPrices({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL_PRICE,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL_PRICE,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL_PRICE,
            })
            setIdPrice({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL
            })
        } else {
            setPrices({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY_PRICE,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY_PRICE,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY_PRICE,
            })
            setIdPrice({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY
            })
        }
    }, [props.annual]);



    // feature included in subscription
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // feature not included in subscription
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;


    const submit = (priceId, price) => {
        let priceTva = 0;
        if (props.annual) {
            priceTva = (parseInt(price) + (20 * parseInt(price) / 100)) * 12
        } else {
            priceTva = parseInt(price) + (20 * parseInt(price) / 100)
        }
        priceTva = Math.round(priceTva * 100) / 100
        if (props.pack === "Trial") {
            confirmAlert({
                title: "Activation d'abonnement",
                message: 'Êtes-vous sûr de vouloir activer votre abonnement? (' + priceTva + '€ TTC)',
                buttons: [
                    {
                        label: 'Oui',
                        onClick: () => {
                            UpdateTrialSubscription(setUpdateTrialResponse, setupdateTrialErrors, priceId, props.robot)
                        }
                    },
                    {
                        label: 'Non',
                        onClick: () => alert("L'activation n'a pas été pris en compte")
                    }
                ]
            });
        } else {
            // sinon confirm alert :
            confirmAlert({
                title: "Modification d'abonnement",
                message: 'Êtes-vous sûr de vouloir modifier votre abonnement? (' + priceTva + '€ TTC)',
                buttons: [
                    {
                        label: 'Oui',
                        onClick: () => UpdateActiveSubscription(setUpdateResponse, setupdateErrors, priceId, props.robot)
                    },
                    {
                        label: 'Non',
                        onClick: () => alert("La modification n'a pas été pris en compte")
                    }
                ]
            });
        }
    };



    useEffect(() => {
        if (updateResponse === "success") {
            navigate('/espace-client/accueil?updateSubscription=success')
        }
        // eslint-disable-next-line
    }, [updateResponse])


    useEffect(() => {
        if (updateTrialResponse.update === "success") {
            // Activer ma souscription
            initPayment(updateTrialResponse.priceId, setSessionId, props.robot, props.prices.recurring.usage_type)
        }
        // eslint-disable-next-line
    }, [updateTrialResponse])

    // If initPayment success, we redirect to Stripe Checkout
    useEffect(() => {
        if (typeof sessionId === 'string') {
            stripe.redirectToCheckout({
                sessionId: sessionId
            })
        }
    }, [sessionId, stripe]);
    // console.log(props.pack)
    return (
        <div className="price-pack">
            <div className="row justify-content-center max-md-flex-direction-column" >

                {updateErrors.length > 0 && <div className="alert alert-danger">{updateErrors} </div>}
                {updateTrialErrors.length > 0 && <div className="alert alert-danger">{updateTrialErrors} </div>}

                {/* Rate pack 1 "Pack starter" */}
                < div className="rate-block rate-light" >

                    {props.prices.id === idPrice.starter && <div className="currentSubscription">Mon abonnement</div>}

                    <p className="pack-title little-title">Starter</p>
                    <hr></hr>
                    <div className="pack-price">
                        <p>{prices.starter} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <p className="pack-description">L'indispensable pour commencer à déléguer vos tâches à votre assistant.</p>
                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">
                        <div className="features-description features-active">
                            {feature}
                            <p>Jusqu’à 50 crédits/mois</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>1 lieu d'exercice</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Envoi par mail et stockage automatique des factures</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Synchronisation de votre agenda Doctolib à l'assistant Aliz</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Personnalisation des factures</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Personnalisation des emails</p>
                        </div>
                    </div>

                    <button className="btn-alizFull"
                        onClick={() => submit(idPrice.starter, prices.starter)}
                        disabled={props.prices.id === idPrice.starter && !props.pack.match(/Trial/) ? true : false}>
                        Activer abonnement
                    </button>
                </div >
                {/* End Rate pack 1 */}




                {/* Rate pack 2 "Offre Business" */}
                <div className="rate-block rate-light">

                    {props.prices.id === idPrice.business && <div className="currentSubscription">Mon abonnement</div>}

                    <p className="pack-title">Business</p>
                    <hr></hr>
                    <div className="pack-price">
                        <p>{prices.business} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <p className="pack-description">La solution complète pour faire passer votre assistant au niveau supérieur et automatiser toujours plus.</p>
                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">
                        <div className="features-description features-active">
                            {feature}
                            <p>Jusqu’à 100 crédits/mois</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>2 lieux d'exercice</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Envoi par mail et stockage automatique des factures</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Synchronisation de votre agenda Doctolib à l'assistant Aliz</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Personnalisation des factures</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Personnalisation des emails</p>
                        </div>
                    </div>

                    <button className="btn-alizFull"
                        onClick={() => submit(idPrice.business, prices.business)}
                        disabled={props.prices.id === idPrice.business && !props.pack.match(/Trial/) ? true : false}>
                        Activer abonnement
                    </button>
                </div>
                {/* End Rate pack 2 */}





                {/* Rate pack 3 "Offre Premium" */}
                <div className="rate-block rate-light">

                    {props.prices.id === idPrice.premium && <div className="currentSubscription">Mon abonnement</div>}

                    <p className="pack-title">Premium</p>
                    <hr></hr>
                    <div className="pack-price">
                        <p>{prices.premium} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <p className="pack-description">Profitez des fonctionnalités avancées et rendez toujours plus efficace le travail de votre assistant</p>
                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">

                        <div className="features-description features-active">
                            {feature}
                            <p>Jusqu’à 200 crédits/mois</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>3 lieux d'exercice</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Envoi par mail et stockage automatique des factures</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Synchronisation de votre agenda Doctolib à l'assistant Aliz</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Personnalisation des factures</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Personnalisation des emails</p>
                        </div>
                    </div>

                    <button className="btn-alizFull"
                        onClick={() => submit(idPrice.premium, prices.premium)}
                        disabled={props.prices.id === idPrice.premium && !props.pack.match(/Trial/) ? true : false}>
                        Activer abonnement
                    </button>
                </div>
                {/* End Rate pack 3 */}


            </div >

            <br></br>
            {/* Price Table */}
            < AdministrativeTable />

            <div className="row mb-5">
                <div className="offset-1 col-10 offset-sm-1 col-sm-8 offset-lg-0 col-lg-6 offset-xl-1 col-xl-10 support">
                    <i>(1) Cette fonctionnalité n'est pas disponible lors du test gratuit.<br></br> Vous pouvez contacter le
                        <a href={process.env.REACT_APP_LINK_CALENDAR_CONTACT_SUPPORT} className="text-aliz" target="_blank" rel="noopener noreferrer"><b> support </b></a>
                        pour plus d'informations
                    </i>
                </div>
            </div>
        </div >
    )
}