import React from 'react'
import { Link } from 'react-router-dom'

// ----- Component -----
import FeaturesAdministrative from "./FeaturesAdministrative"
import AdministrativePack from "../RatePack"
import LittleFaq from "../../Assistants/FAQ"
import Summary from '../../Summary'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function AdministrativeManagement({ token, location }) {
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>AlizPatientele, l’assistant de Gestion administrative de la patientèle - Aliz</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" content="Confiez à notre assistant virtuel vos tâches administratives et concentrez-vous sur les soins aux patients." />
                <link rel="canonical" href="https://aliz.io/nos-solutions/AlizPatientele" />
                <meta property="og:title" content="L’assistant de Gestion administrative de la patientèle - Aliz" />
            </Helmet>

            <div className="homeAssistant-view">

                {/* ----- Assistant presentation -----*/}
                <div className="row justify-content-center home-block">
                    {/* ----- Catch phrase & Cta ----- */}
                    <div className="col-11 col-sm-10 col-md-11 col-lg-6 flex-direction-column catch-phrase">
                        <h1>Assistant gestion administrative de votre patientèle - {process.env.REACT_APP_BOT_NAME_PATIENTELE}.</h1>
                        <h2>
                            Chaque mois, la gestion administrative occupe quasiment 20% du temps.
                            La prise de rendez-vous, les emails, les factures, la comptabilité…
                            il est temps de vous concentrer sur l’essentiel : votre métier !
                        </h2>

                        <div className="row btn-block">
                            <a className="col-auto btn-alizFull" href={process.env.REACT_APP_LINK_CALENDAR_FOOTER_DEMO}>Demander une démo</a>
                            <Link className="offset-1 col-auto btn-alizOut" to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_PATIENTELE}>Voir les prix</Link>
                        </div>
                    </div>
                    {/* ----- End catch phrase -----*/}

                    <div className="col-11 col-sm-10 offset-lg-1 col-lg-5 offset-xl-0 col-xl-6">
                        <iframe src="https://www.youtube.com/embed/CjQGOyJ4i2o" title="Aliz - Votre assistant de gestion patientèle" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>


                <FeaturesAdministrative />

                <AdministrativePack token={token} location={location} />

                <LittleFaq />

                <Summary />

            </div>
        </>
    )
}
