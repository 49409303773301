import React from 'react'
import { Link } from "react-router-dom";
import Logo from '../../../../images/logo/Logo Couleur_Sans texte.png';
import Price from './Price'
import GoogleReview from './GoogleReview'
// import InformationsGoogle from './MyInformations'
import Summary from '../../../Summary'
// import Footer from '../../../Section/Footer'

import Parcours from "../../../../images/landing/parcours-utilisateur-patientele.png"

export default function Home() {

    const redirectForm = () => {
        window.open(process.env.REACT_APP_LINK_CALENDAR_FOOTER_DEMO, '_blank');
        window.location.href = window.location.href + "#form";
    }


    return (
        <div id="landing_patientele3">

            <div className="navbar" id="logo-row">
                <Link to="/"><img src={Logo} alt="Aliz" className="navbar-brand" id="header-logo" /></Link>
            </div>

            <div className="row" id="home">
                <div id="catch-phrase">
                    <h1>Et si vous déléguiez votre facturation à notre assistant de gestion de patientèle ?</h1>
                </div>

                <div className="row">

                    <div id="left-side">
                        <div>
                            <Price />
                        </div>
                    </div>

                    <div id="right-side">
                        <GoogleReview />
                        <button className="btn-yellow" onClick={redirectForm}>Essayer gratuitement</button>
                    </div>
                </div>
            </div>

            {/* Iframe video  */}
            <div id="frame-block">
                <iframe src="https://www.youtube.com/embed/yybnis2V1mk"
                    title="YouTube video player"
                    frameborder="0"
                    allowfullscreen
                >
                </iframe>
                <button className="btn-yellow" onClick={redirectForm}>Essayer gratuitement</button>
            </div>


            <div id="howitworks">
                <img src={Parcours} alt="Schéma du fonctionnement de l'assistant virtuel" />
            </div>

            {/* <InformationsGoogle /> */}
            <div id="my-informations" className="row justify-content-center">
                <iframe id="form" src="https://forms.monday.com/forms/embed/cbef5047d2e892419b8adce872c0991b?r=use1" title="monday formulaire"></iframe>
            </div>
            <Summary />
        </div>
    )
}
