import React from 'react'
import { useMediaQuery } from 'react-responsive'

// Component
import TableDesktop from './Desktop'
import TableTablet from './Tablet'

export default function Administrative() {

    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 768 })
        return isDesktop ? children : null
    }

    const Tablet = ({ children }) => {
        const isTablet = useMediaQuery({ maxWidth: 767.98 })
        return isTablet ? children : null
    }

    return (
        <div className="grid-view row justify-content-center">

            <Desktop>
                <TableDesktop />
            </Desktop>

            <Tablet>
                <TableTablet />
            </Tablet>
            
        </div>
    )
}
