import React, { useState, useEffect } from 'react'
import { insertClient } from '../../../services/landing.js'
import { getGeoInfo } from '../../../services/User/register.js'
import emailjs, { init } from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
init("user_miA3ipyhZVGHs8J5Myyb1");

export default function Client() {
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [city, setCity] = useState('')
    const [errors, setErrors] = useState([])
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false)
    // POPUP OPEN || CLOSE ??
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    // GEOLOCALISATION USER
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            getGeoInfo(setCity, position.coords.latitude, position.coords.longitude)
        });
    }, [city])

    const data = {
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "city": city,
        "page": "Landing Santé v2"
    }

    const paramsForProspect = {
        "firstname": firstname,
        'email': email
    }
    const paramsForClement = {
        "firstname": firstname,
        'lastname': lastname,
        'email': email,
        'city': city,
        'clement': 'clement.rebecchi@ted-company.com',
        'mathias': 'mathias.chaumon@ted-company.com',
        'page': 'Landing Santé v2'
    }

    const contactSubmit = (e) => {
        e.preventDefault();
        setErrors([])
        insertClient({ data }, setErrors, setOpen)

        emailjs.send('landing-two', 'template_yxy8ytg', paramsForProspect)
            .then(function (response) {
                // alert('SUCCESS!', response.status, response.text);
            }, function (error) {
                // alert('FAILED...', error);
            });
        emailjs.send('clement-landing-sante-2', 'template_tdxi0cr', paramsForClement)
            .then(function (response) {
                // alert('SUCCESS!', response.status, response.text);
            }, function (error) {
                // alert('FAILED...', error);
            });

    }

    useEffect(() => {
        if (open) {
            setShow(true);
        }
    }, [open])
    return (
        <div className="Sante2_contact-view">
            <form className="" id="contact" >

                <h2>VOS COORDONNÉES</h2>
                <p>Renseignez vos informations afin que nous puissions vous aider à déléguer vos tâches chronophages</p>

                <div className="all-input">
                    <div className="containerInput">
                        <input type="text"
                            name="firstname"
                            placeholder="Prénom"
                            id="firstname"
                            className="border-1"
                            required
                            minLength="3"
                            maxLength="50"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                        />
                        <span className="focusBorder-1"></span>
                    </div>
                    {errors.firstname && <span className="text-danger">{errors.firstname}</span>}

                    <div className="containerInput">
                        <input type="text"
                            name="lastname"
                            placeholder="Nom"
                            id="lastname"
                            className="border-2"
                            required
                            minLength="3"
                            maxLength="50"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                        />
                        <span className="focusBorder-2"></span>
                    </div>
                    {errors.lastname && <span className="text-danger">{errors.lastname}</span>}

                    <div className="containerInput">
                        <input type="email"
                            name="email"
                            placeholder="E-mail"
                            id="lastname"
                            className="border-3"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <span className="focusBorder-3"></span>
                    </div>
                    {errors.email && <span className="text-danger">{errors.email}</span>}

                    <button variant="primary" onClick={contactSubmit}> ENVOYER</button>

                    {(() => {
                        if (!errors.firstname && !errors.lastname && !errors.email) {

                            return <Modal className="Sante2" show={show} onHide={handleClose}
                                centered size="lg">

                                <Modal.Header>
                                    <div className="column-header">
                                        <div className="modal-bg">
                                            <svg viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </div>
                                        <Modal.Title>Bienvenue à bord !</Modal.Title>
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        Merci pour votre confiance, notre équipe va prendre contact avec vous prochainement<br></br>
                                         À très bientôt chez Aliz!
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn-aliz" onClick={handleClose}>FERMER</Button>
                                </Modal.Footer>
                            </Modal>
                        }
                    })()}
                </div>
            </form>
        </div>
    )
}