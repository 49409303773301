import React, { useRef } from 'react'

// ----- Packages -----
import gsap from 'gsap'

export default function FAQ() {

    const questionOneRef = useRef()
    const questionTwoRef = useRef()
    const questionThreeRef = useRef()
    const questionFourRef = useRef()
    const questionFiveRef = useRef()

    const toggleResponse = (questionRef, arrow) => {
        // console.log(questionRef)
        if (questionRef.style.display === 'none') {
            gsap.set(questionRef, { display: 'block' })
            gsap.set(arrow, { rotate: '180deg' })

        } else {
            gsap.set(questionRef, { display: 'none'})
            gsap.set(arrow, { rotate: '0deg' })
        }

    }


    return (
        <div className="littleFaq-view">

            <div className="row justify-content-center">
                <h3 className="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8">On répond à toutes vos questions !</h3>
            </div>

            <div className="row justify-content-center">
                <div className="col-10 col-sm-9 col-md-8 col-lg-6 col-xl-5">
                    {/* ----- Questions FAQ ----- */}


                    <div id="accordion">

                        {/* ----- Question 1 ----- */}
                        <div className="card" id="cardOne" onClick={() => toggleResponse(questionOneRef.current, "#cardOne svg")}>

                            <div className="card-header">
                                <h5>
                                    <button className="btn btn-link">
                                        <span>Quelle est la différence entre un logiciel et les assistants Aliz ?</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </button>
                                </h5>
                            </div>

                            <div className="card-body" ref={questionOneRef}>
                                Les logiciels doivent être installés et ils ne font que vous aider sur certaines tâches.
                                Aliz en revanche, vous permet de déléguer entièrement vos tâches administratives et fonctionnelles à des robots afin qu'ils les effectuent.
                            </div>

                        </div>
                        {/* ----- End Question 1 ----- */}


                        {/* ----- Question 2 ----- */}
                        <div className="card" id="cardTwo" onClick={() => toggleResponse(questionTwoRef.current, "#cardTwo svg")} >

                            <div className="card-header">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed">
                                        <span>Comment mes données sont-elles sécurisées ?</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </button>
                                </h5>
                            </div>

                            <div className="card-body" ref={questionTwoRef}>
                                Nous ne stockons aucunes de vos données professionnelles ou sensibles. Vos données de travail restent sur votre Drive (google, microsoft).
                            </div>


                        </div>
                        {/* ----- End Question 2 ----- */}



                        {/* ----- Question 3 ----- */}
                        <div className="card" id="cardThree" onClick={() => toggleResponse(questionThreeRef.current, "#cardThree svg")} >

                            <div className="card-header">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed">
                                        <span>Comment souscrire à une offre Aliz?</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </button>
                                </h5>
                            </div>

                            <div className="card-body" ref={questionThreeRef}>
                                Il suffit de vous inscrire sur notre site et de sélectionner l’offre qui vous intéresse. Aucune carte bancaire n’est nécessaire au démarrage!
                            </div>

                        </div>
                        {/* ----- Question 3 ----- */}



                        {/* ----- Question 4 ----- */}
                        <div className="card" id="cardFour" onClick={() => toggleResponse(questionFourRef.current, "#cardFour svg")} >

                            <div className="card-header">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed">
                                        <span>Comment faire évoluer mon offre ?</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </button>
                                </h5>
                            </div>

                            <div className="card-body" ref={questionFourRef}>
                                Si vous souhaitez changer de pack ou plan, notre équipe est à votre disposition. Contacter nous ici.
                            </div>

                        </div>
                        {/* ----- Question 4 ----- */}



                        {/* ----- Question 5 ----- */}
                        <div className="card" id="cardFive" onClick={() => toggleResponse(questionFiveRef.current, "#cardFive svg")}>

                            <div className="card-header" id="headingFive">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed">
                                        <span>Est-ce que vous proposez une solution de prise de rendez-vous chez Aliz ?</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </button>
                                </h5>
                            </div>

                            <div className="card-body" ref={questionFiveRef}>
                                Tout à fait ! Nous avons un assistant qui gère vos rendez-vous. Et en plus… il est gratuit en pack Starter!
                            </div>

                        </div>
                        {/* ----- Question 5 ----- */}
                    </div>
                    {/* ----- End Questions FAQ ----- */}

                </div>
            </div>

        </div>
    )
}
