import React from 'react'

export default function TabletTva() {
    // feature included in subscription
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-active bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // feature not included in subscription
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-inactive bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    return (
        <div className="tabletTable-view">
            <h2>Fonctionalités</h2>

            {/* 1 */}
            <div className="row justify-content-center flex-direction-column">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Déclaration automatique de TVA sur impots.gouv </h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <hr></hr>

            {/* 2 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Stockage PDF de la déclaration de TVA, accusé réception, validation de paiement dans votre espace de travail (Drive)</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>


            <hr></hr>

            {/* 3 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Rapport détaillé par e-mail de l'activité de votre assistant Aliz </h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <hr></hr>

            {/* 4 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Historisation mensuelle des déclarations TVA réalisées </h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {feature}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>



            <hr></hr>

            {/* 5 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Contrôle de cohérence automatisé de la TVA déclarée</h3>
                        <i className="index-one">(1)</i>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {featureInactive}
                                {feature}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <hr></hr>

            {/* 6 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Envoi d'email automatique aux clients pour demander les pièces comptables</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {featureInactive}
                                {featureInactive}
                                {feature}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <hr></hr>


            <h2 className="mt-5">Services</h2>

            {/* 7 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Fréquence d'envois déclaration TVA</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>10 fois par mois</p></td>
                                <td><p>illimité</p></td>
                                <td><p>illimité</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <hr></hr>

            {/* 8 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Licence / Utilisateur</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>1 licence</p></td>
                                <td><p>1 licence</p></td>
                                <td><p>1 licence</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <hr></hr>

            {/* 9 */}
            {/* <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Prix par utilisateur additionnel</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>25€/mois</p></td>
                                <td><p>20€/mois</p></td>
                                <td><p>15€/mois</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}

            <hr></hr>

            {/* 10 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Volume de déclaration TVA par mois par licence</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>20</p></td>
                                <td><p>50</p></td>
                                <td><p>150</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <hr></hr>

            {/* 10 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Support Client*</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>Standard</p></td>
                                <td><p>Standard</p></td>
                                <td><p>Prioritaire</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="row justify-content-center">
                    <div className="col-5 support">
                        <p>*Support Standard</p>
                        <p>(Chat - Mail 5/7j - 9h-17h)</p>
                    </div>

                    <div className="col-5 support">
                        <p>*Support Prioritaire</p>
                        <p>(Chat - Mail - Tel : 5/7j de 9h-18h)</p>
                    </div>
                </div>
            </div>


            <hr></hr>

            {/* 10 */}
            <div className="row justify-content-center flex-direction-column mt-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h3>Déclaration à l'unité supplémentaire</h3>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <table className="offset-1 col-8 col-sm-6 table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Starter</th>
                                <th scope="col">Business</th>
                                <th scope="col">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>0,98cts</p></td>
                                <td><p>0,79cts</p></td>
                                <td><p>0,73cts</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <hr></hr>
        </div >
    )
}
