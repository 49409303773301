import React from 'react'
import Chart from "react-apexcharts";
import LoaderSuspense from '../../LoaderSuspense'

export default function ChartsDetails({ statistics, isLoaded }) {

    // console.log(statistics)
    // Chart options
    const options = {
        chart: {
            type: 'line'
        },
        stroke: {
            curve: 'smooth',
        },
        series: [
            // {
            //     name: 'Temps gagné en min',
            //     data: statistics.timeArr
            // },
            {
                name: 'Factures envoyées',
                data: statistics.sendArr
            },
            {
                name: 'Factures archivées',
                data: statistics.archivedArr
            },
            {
                name: 'Factures corrigées',
                data: statistics.correctedArr
            },
            {
                name: 'Factures renvoyées',
                data: statistics.returnedArr
            },
        ],
        xaxis: {
            categories: statistics.monthArr
        },
        colors: ['#5a8aff', '#71DBEA', '#21e01a', '#b872d1', '#FFCF59']
    }
    // console.log(stats)
    return (
        <div className="graphic-block">
            {(() => {
                if (!statistics.nmbTask && isLoaded === true) {
                    return <h4 className="statsSoon">Vos statistiques bientôt ici !</h4>
                } else if (statistics.nmbTask > 0 && isLoaded === true) {
                    return <Chart
                        options={options}
                        series={options.series}
                        type="area"
                        width="95%"
                        colors={options.colors}
                        className="chart"
                    />
                } else {
                    return <LoaderSuspense />;
                }
            })()}

        </div>
    )
}
