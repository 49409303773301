import React, { useEffect, useRef } from 'react'

// Packages
import gsap from 'gsap'

export default function Popup({isVisible, className, text}) {

    const ref = useRef();

    useEffect(() => {
        if (isVisible) {
            gsap.fromTo(ref.current, { top: '-500', display: 'none', opacity: 0 }, { top: '30vh', display: 'block', opacity: 1, duration: 2 })
            gsap.to(ref.current, { top: '-500', display: 'none', delay: 4, opacity: 0, duration: 2 });
        }
    }, [isVisible])

    return (
        <div className={`success-popup ${typeof className != "undefined" ? "failed-popup" : ""}`} ref={ref}>
            <div className="row justify-content-center">

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.3344 2.00018H7.66543C4.26785 2.00018 2.00043 4.43278 2.00043 7.91618V16.0842C2.00043 19.5709 4.2618 22.0002 7.66543 22.0002H16.3334C19.738 22.0002 22.0004 19.5709 22.0004 16.0842V7.91618C22.0004 4.42969 19.7382 2.00018 16.3344 2.00018ZM7.66543 3.50018H16.3344C18.8849 3.50018 20.5004 5.23515 20.5004 7.91618V16.0842C20.5004 18.7653 18.8848 20.5002 16.3334 20.5002H7.66543C5.11519 20.5002 3.50043 18.7655 3.50043 16.0842V7.91618C3.50043 5.23856 5.12077 3.50018 7.66543 3.50018ZM16.0903 9.09685C15.7974 8.80396 15.3225 8.80396 15.0296 9.09685L10.813 13.312L8.97015 11.4697L8.88601 11.3971C8.59235 11.1793 8.1757 11.2036 7.90949 11.47C7.61665 11.7629 7.61675 12.2378 7.90971 12.5306L10.2837 14.9036L10.3678 14.9762C10.6614 15.194 11.078 15.1697 11.3443 14.9035L16.0903 10.1575L16.1629 10.0734C16.3807 9.77978 16.3565 9.36312 16.0903 9.09685Z" fill="#200E32" />
                </svg>

                <p>{text && text}</p>

            </div>
        </div>
    )
}
