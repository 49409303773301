import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../../images/landing/Typo.png'
export default function Header() {
    return (
        <div className="Osteo_header-view">
            <nav className="container-fluid fixed-top">
                <Link to="/Landing/assistant-virtuel-professions-de-sante-1">
                    <img className="logo-header" src={logo} alt="Logo Aliz" />
                </Link>
            </nav>
        </div>
    )
}
