import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Axios 
import { checkToken } from './services/auth.js'

// All stylesheets
import './style/App.scss';

// Helmet - Navigator Title page
import { HelmetProvider } from 'react-helmet-async';

// Images
import logo from './images/logo/Logo Blanc.png';

// Animation
import gsap, { CSSPlugin } from 'gsap';


// __________________Components___________________
// Section
import Header from "./components/Section/Header"
import Footer from "./components/Section/Footer"

// Home page
import Home from "./components/Home/Home"


// Registration
import Login from "./components/Registration/Login"
import Register from "./components/Registration/Register"

// Prices 
import HomePrice from "./components/Prices/HomePrice"

// Our assistants
// _______Administrative______ 
import HomeAdministrativeManagement from "./components/Assistants/PatienteleManagement/HomeAssistant"

// __________Calendar_________
import HomeCalendarManagement from "./components/Assistants/CalendarManagement/HomeAssistant"

// __________TVA_________
import HomeTvaManagement from "./components/Assistants/TvaDeclaration/HomeAssistant"

// __________Prospect_________
import HomeVerifLegal from "./components/Assistants/VerifLegal/HomeAssistant"
// End assistants_________________________

// ______________Client Area______________
import HeadClient from "./components/User/Head"
import ClientDashboard from "./components/User/Home/Home"
import Billing from "./components/User/Billing/Billing"
import PaimentMethod from "./components/User/PaymentMethod/PaymentMethod"
import SEPAProvider from "./components/User/PaymentMethod/AddSEPA/SEPAProvider"
import CardProvider from "./components/User/PaymentMethod/AddCard/CardProvider"
import GoogleReview from "./components/User/GoogleReviews/GoogleReviews"
import Notification from "./components/User/Notification/Home"
import Assistance from "./components/User/Assistance"
import SponsorShip from "./components/User/SponsorShip"

// import CardProviderUpdate from "./components/User/PaymentMethod/UpdateCard/CardProviderUpdate"

import Profile from "./components/User/Profile/Profile"
import ProfessionalProfile from "./components/User/ProfessionalProfile/ProfessionalProfile"
// import MyTvaCollabs from "./components/User/TvaCollab/Home"

// ______________Tutorial______________
// import TutorialPatientele from "./components/User/Tutorial/Patientele"
// import TutorialTva from "./components/User/Tutorial/Tva"

// ______________Subscriptions______________
// Step of subscriptions
import SubscriptionHome1 from "./components/User/Subscription/Step1/HomeStep1"
import SubscriptionHome2 from "./components/User/Subscription/Step2/HomeStep2"
//Doctolib Step 3
import SubscriptionHome3 from "./components/User/Subscription/Step3/Step1/HomeStep3"
import DoctolibYes from "./components/User/Subscription/Step3/Step2/Yes"
import DoctolibNo from "./components/User/Subscription/Step3/Step2/No"

// Update Subscription
import UpdateSubscription from "./components/User/UpdateSubscription/UpdateSubscription"

// ______________Terms & Conditions______________
import Cgu from './components/TermsAndConditions/Cgu'
import Cgv from './components/TermsAndConditions/Cgv'
import CookiePolicy from './components/TermsAndConditions/CookiePolicy'
import LegalNotice from './components/TermsAndConditions/LegalNotice'
import PrivacyPolicy from './components/TermsAndConditions/PrivacyPolicy'
import ProductConditions from './components/TermsAndConditions/ProductConditions'
import ServiceTerms from './components/TermsAndConditions/ServiceTerms'


// ______________Forgot Password______________
import ForgotPassword from './components/Registration/ForgotPassword'
import ResetPassword from './components/Registration/ResetPassword'


// Page in bulk 
import NotFound from './components/NotFound'
import Integrations from './components/Integration/Integrations'
import CustomerOpinion from './components/CustomerOpinion/CustomersOpinion'
import Partners from './components/Partner/Home'
import ScrollTop from './components/ScrollTop'
// import Faq from "./components/FAQ/FAQ"

// ______________Landing Page______________
import Patientele from "./components/Landing/Patientele/Patientele"
import Patientele3 from "./components/Landing/Patientele/Patientele3/Home"

import HomeSante1 from "./components/Landing/Sante1/HomeSante1"
import HomeSante2 from "./components/Landing/Sante2/HomeSante2"
import HomeOsteo from "./components/Landing/Osteo/HomeOsteo"
import BlackFriday from "./components/Landing/Patientele/BlackFriday/Home"

// Get location pathname
import LocationUrl from './components/Location'


export default function App() {

  const [load, setLoad] = useState(false)
  const [location, setLocation] = useState('')

  const [token, setToken] = useState("")
  const [responseToken, setResponseToken] = useState([])

  gsap.registerPlugin(CSSPlugin);

  // Animation loader aliz
  useEffect(() => {
    if (!load) {
      setLoad(true)
      var tl = gsap.timeline({ repeat: 0, yoyo: false });
      tl.to('.shine div', { css: { webkitFilter: 'blur(0px)', filter: 'blur(0px)', transitionDuration: '0.5' } });
      tl.set('.loader', { css: { display: 'none' } }, .5);
      tl.set('.App', { css: { display: 'block' } }, .5);
    }
  }, [load])



  // check if user is already logged
  useEffect(() => {
    checkToken(setResponseToken)
  }, []);

  useEffect(() => {
    if (responseToken.initials) {
      setToken(true);
    } else if (responseToken === false) {
      setToken(false);
    }
  }, [responseToken])

  useEffect(() => {
    if (location.match(/espace-client/) && !location.match(/espace-client\/souscription-etape1/)) {
      if (responseToken === false) {
        return window.location.href = "/login"
      }
    }
  }, [location, responseToken])

  return (
    <HelmetProvider>

      <Router>
        {/* Get Url pathname location  */}
        <LocationUrl setLocation={setLocation} />

        {/* ANIMATION CONTAINER */}
        <div className="shine container-fluid">
          <div className="loader row justify-content-center">
            <img src={logo} alt="logo Aliz" />
          </div>
        </div>

        <div className="App">


          {/* We show header if url pathname not Registration page */}
          {!location.match(/login/) && !location.match(/register/) && !location.match(/landing/) && !location.match(/mot-de-passe/) &&
            <Header token={token} />
          }

          <ScrollTop responseToken={responseToken} />

          {token &&
            <GoogleReview />
          }

          {token && !location.match(/landing/) &&
            <HeadClient token={responseToken} />
          }

          <Routes>

            {/* Homepage */}
            <Route exact path="/" element={<Home />} />

            {/* ____________Registration__________ */}
            {/* Login */}
            <Route exact path="/login" element={<Login token={token} />} />

            {/* Register */}
            <Route exact path="/register" element={<Register token={token} />} />

            {/* Forgot Password */}
            <Route exact path="/mot-de-passe-oublie" element={<ForgotPassword token={token} />} />

            {/* Forgot Password */}
            <Route exact path="/reinitialiser-mot-de-passe" element={<ResetPassword token={token} />} />


            {/* ___________Assistants__________ */}
            {/* Administrative Management */}
            <Route exact path="/nos-solutions/AlizPatientele" element={<HomeAdministrativeManagement token={token} location={location} />} />

            {/* Calendar Management */}
            <Route exact path="/nos-solutions/AlizCalendar" element={<HomeCalendarManagement token={token} location={location} />} />

            {/* TVA Declaration */}
            <Route exact path="/nos-solutions/AlizTva" element={<HomeTvaManagement token={token} location={location} />} />

            {/* Qualification Prospect */}
            <Route exact path="/nos-solutions/AlizVerif" element={<HomeVerifLegal token={token} location={location} />} />
            {/* _____________End assistants___________ */}


            {/* Prices */}
            <Route exact path="/tarifs" element={<HomePrice token={token} />} />



            {/* _____________________Client area_________________________ */}
            {/* Home */}
            <Route exact path="/espace-client/accueil" element={<ClientDashboard responseToken={responseToken} />} />


            {/* ______ Subscriptions Step _______  */}
            {/* 1st Step*/}
            <Route exact path="/espace-client/souscription-etape1" element={<SubscriptionHome1 responseToken={responseToken} />} />

            {/* 2nd Step  */}
            <Route exact path="/espace-client/souscription-etape2" element={<SubscriptionHome2 responseToken={responseToken.id ? responseToken.id : null} />} />

            {/* 3rd Step (AlizPatientele only) */}
            <Route exact path="/espace-client/souscription-etape3" element={<SubscriptionHome3 responseToken={responseToken} />} />

            {/* Subscriptions Patientele : I have doctolib */}
            <Route exact path="/espace-client/souscription-etape3/guide_utilisation" element={<DoctolibNo />} />

            {/* Subscriptions Patientele : I don't have doctolib */}
            <Route exact path="/espace-client/souscription-etape3/connecter_doctolib" element={<DoctolibYes />} />
            {/* ______ End Subscriptions Step _______  */}


            {/* Update my subscription */}
            <Route exact path="/espace-client/modification-souscription" element={<UpdateSubscription />} />

            {/* Billing */}
            <Route exact path="/espace-client/mes_factures" element={<Billing />} />

            {/* Payment method */}
            <Route exact path="/espace-client/mes_moyens_de_paiement" element={<PaimentMethod />} />


            {/* Add new SEPA to Payment method */}
            <Route exact path="/espace-client/ajouter_sepa" element={<SEPAProvider />} />


            {/* Add new Card to Payment method */}
            <Route exact path="/espace-client/ajouter_cb" element={<CardProvider />} />


            {/* Notification */}
            <Route exact path="/espace-client/mes_notifications" element={<Notification />} />


            {/* Demander une Assistance */}
            <Route exact path="/espace-client/demander_assistance" element={<Assistance />} />

            {/* Demander une Assistance */}
            <Route exact path="/espace-client/parrainage" element={<SponsorShip />} />

            {/* Update Card to Payment method */}
            {/* <Route exact path="/espace-client/modifier_cb">
              <Helmet title="Espace client - Modifier Moyens de paiements" />
              <CardProviderUpdate />
            </Route> */}

            {/* My TVA Collaborators */}
            {/* <Route exact path="/espace-client/mes-collaborateurs-tva">
              <Helmet title="Espace client - Tva Collaborateurs" />
              <MyTvaCollabs />
            </Route> */}

            {/* Profile */}
            <Route exact path="/espace-client/profil" element={<Profile />} />

            {/* Professional Profile */}
            <Route exact path="/espace-client/mon-profil-professionnel" element={<ProfessionalProfile />} />
            {/* _____________________End Client area_________________________ */}



            {/* _____________________Terms & Conditions_________________________ */}

            {/* CGU */}
            <Route exact path="/conditions-generales/cgu" element={<Cgu />} />

            {/* CGV */}
            <Route exact path="/conditions-generales/cgv" element={<Cgv />} />


            {/* Legal Notice  */}
            <Route exact path="/conditions-generales/mentions-legales" element={<LegalNotice />} />

            {/* Cookie Policy  */}
            <Route exact path="/conditions-generales/politique-utilisation-des-cookies" element={<CookiePolicy />} />

            {/* Privacy Polici */}
            <Route exact path="/conditions-generales/politique-de-confidentialite" element={<PrivacyPolicy />} />


            {/* Product Specific Conditions */}
            <Route exact path="/conditions-generales/conditions-specifiques-liees-aux-produits" element={<ProductConditions />} />


            {/* Terms of Service */}
            <Route exact path="/conditions-generales/conditions-utilisation-des-services" element={<ServiceTerms />} />
            {/* _____________________End Terms & conditions_________________________ */}


            {/* _____________________Tutorial Google Drive_________________________ */}
            {/* Acccéder à mon Drive */}
            {/* <Route exact path="/tutoriel/google-drive-tva">
              <Helmet title="ALIZ - Assistant virtuel | Tutoriel - Accéder à mon Drive TVA" />
              <TutorialTva />
            </Route> */}
            {/* TVA Tutorial */}
            {/* <Route exact path="/tutoriel/google-drive-tva">
              <Helmet title="ALIZ - Assistant virtuel | Tutoriel - Accéder à mon Drive TVA" />
              <TutorialTva />
            </Route> */}

            {/* Patientele Tutorial */}
            {/* <Route exact path="/tutoriel/google-drive-patientele">
              <Helmet title="ALIZ - Assistant virtuel | Tutoriel - Accéder à mon Drive Patientele" />
              <TutorialPatientele />
            </Route> */}
            {/* _____________________End Tutorial Google Drive_________________________ */}


            {/* ____________________Other page in bulk______________________ */}
            <Route exact path="/integrations" element={<Integrations />} />

            <Route exact path="/avis-clients" element={<CustomerOpinion />} />

            <Route exact path="/nos-partenaires" element={<Partners />} />



            {/* ___________Landing pages__________ */}
            {/* Patientele 1 & 2 (A/B testing) */}
            <Route exact path="/landing/assistant-gestion-administrative/1" element={<Patientele />} />

            {/* Patientele 3 */}
            <Route exact path="/landing/assistant-gestion-administrative/3" element={<Patientele3 />} />

            {/* Sante 1 */}
            <Route exact path="/landing/assistant-virtuel-professions-de-sante-1" element={<HomeSante1 />} />

            {/* Sante 2 */}
            <Route exact path="/landing/assistant-virtuel-professions-de-sante-2" element={<HomeSante2 />} />

            {/* Osteopathe */}
            <Route exact path="/landing/assistant-virtuel-osteopathe" element={<HomeOsteo />} />



            {/* Black Friday */}
            <Route exact path="/landing/black-friday" element={<BlackFriday />} />


            {/* 404 Not Found */}
            <Route path="*" element={<NotFound />} />

          </Routes>

          {/* We show header if url pathname not Registration page */}
          {!location.match(/login/) && !location.match(/register/) && !location.match(/landing/) && !location.match(/espace-client/) && !location.match(/mot-de-passe/) &&
            <Footer token={token} />
          }

        </div>

      </Router>

    </HelmetProvider >
  );
}
