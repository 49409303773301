import React from 'react'

// ----- Images -----
import JumpMan from '../../images/Partner/jumpman.png'
import JumpManWebp from '../../images/Partner/jumpman.webp'

// ----- Components -----
import Partners from './Partners'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';


export default function Home() {
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Aliz - Assistant virtuel | Nos partenaires</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" content="partenariat, code promotionnel." />
                <link rel="canonical" href="https://aliz.io/nos-partenaires" />
                <meta property="og:title" content="Découvrez nos partenaires et profitez de leurs bons plans! - Aliz" />
            </Helmet>
            
            <div id="partner-component">

                <div id="home-block" className="row">

                    {/* ----- Catch phrase & Cta ----- */}
                    <div id="catch-phrase" className="col-10 col-sm-8 col-lg-7 col-xl-7">

                        <h1>Découvrez nos partenaires</h1>

                        <h2>Articles, bon plans ou encore code promos, c’est ici que ça se passe!</h2>

                        <a className="col-md-8 col-lg-10 col-xl-7 d-block btn-alizFull" href="#presentation">Voir nos partenaires</a>

                    </div>


                    {/* ----- End catch phrase -----*/}
                    <div id="illustration" className="col-12 col-lg-5 col-xl-5">
                        <picture>
                            <source srcSet={JumpManWebp} type="image/webp" />
                            <img id="jumpman" src={JumpMan} alt="Jump man happy with bubble yelllow in background" />
                        </picture>
                    </div>

                </div>


                <Partners />

            </div>
        </>
    )
}
