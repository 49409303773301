import React from 'react'
import { Link } from 'react-router-dom'

// Images
import AssistantAdministrative from './../../images/AdministrativeManagement/Patientele - illu block Hero@2x.png'
import AssistantCalendar from './../../images/CalendarManagement/Agenda - Illu Block Hero.svg'
import AssistantTva from './../../images/TvaDeclaration/TVA - Illu Block Hero@2x.png'
import AssistantProspect from './../../images/QualificationProspect/Qualification - Illu Block Hero.svg'

// Packages
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function Assistants() {
    return (
        <div className="assistants-view">

            <div className="row justify-content-center">
                <h3>Nos assistants</h3>
            </div>

            {/* ----- Assistant 1 ----- */}
            <div className="assistant-block row max-md-flex-direction-column-reverse mt-5">

                <div className="assistant-text offset-1 col-10 offset-sm-3 col-sm-6 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-2 col-xl-4 flex-direction-column">

                    <h4>{process.env.REACT_APP_BOT_NAME_PATIENTELE}</h4>

                    <p className="describe-assistant mt-4">
                        Avant et après un rendez-vous, de nombreuses tâches administratives sont à réaliser.
                        Gestion Doctolib ou des rendez-vous, factures, emails, classement, comptabilité.
                        Découvrez comment, en moins de 20 minutes, vous pouvez déléguer le tout à votre assistant virtuel.
                    </p>

                    <div className="mt-4">
                        <Link className="text-aliz d-flex text-decoration-none" to="/nos-solutions/AlizPatientele">
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>

                </div>

                <div className="offset-1 col-10 offset-sm-3 col-sm-6 offset-md-3 col-md-6 offset-lg-1 col-lg-4 offset-xl-1 col-xl-4 ">
                    <LazyLoadImage
                        className="assistant-picture"
                        src={AssistantAdministrative}
                        alt="Gestion patientèle"
                        effect="blur"
                    />
                </div>

            </div>
            {/* ----- End assistant 1 ----- */}


            {/* ----- Assistant 2 -----*/}
            <div className="assistant-block row max-md-flex-direction-column">

                <div className="offset-1 col-10 offset-sm-3 col-sm-6 offset-md-3 col-md-6 offset-lg-1 col-lg-4 offset-xl-2 col-xl-4">
                    <LazyLoadImage
                        className="assistant-picture"
                        src={AssistantCalendar}
                        alt="Gestion des rendez-vous"
                        effect="blur"
                    />
                </div>

                <div className="assistant-text offset-1 col-10 offset-sm-3 col-sm-6 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4 flex-direction-column">

                    <h4>{process.env.REACT_APP_BOT_NAME_CALENDAR}</h4>

                    <p className="describe-assistant mt-4">
                        Proposer des créneaux à vos interlocuteurs n’est pas forcément chose facile.
                        Concilier votre agenda avec ses disponibilités peut prendre du temps.
                        Equipez-vous et délaissez cette tâche à votre assistant.
                    </p>

                    <div className="mt-4">
                        <Link className="text-aliz d-flex text-decoration-none " to="/nos-solutions/AlizCalendar">
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>

                </div>
            </div>
            {/* ----- End assistant 2 ----- */}


            {/* ----- Assistant 3 -----*/}
            <div className="assistant-block row max-md-flex-direction-column-reverse">

                <div className="assistant-text offset-1 col-10 offset-sm-3 col-sm-6 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-2 col-xl-4 flex-direction-column ">

                    <h4>{process.env.REACT_APP_BOT_NAME_VERIFLEGAL}</h4>

                    <p className="describe-assistant mt-4">
                        Contrôler des bases fournisseurs, chercher des informations juridiques sur des prospects,
                        améliorer la qualité d’un CRM. Sujets importants mais au combien chronophages.
                        Laissez-faire votre assistant pendant que vous vous concentrez sur ce qui compte.
                    </p>

                    <div className="mt-4">
                        <Link className="text-aliz d-flex text-decoration-none" to="/nos-solutions/AlizVerif">
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>

                </div>

                <div className="offset-1 col-10 offset-sm-3 col-sm-6 offset-md-3 col-md-6 offset-lg-1 col-lg-4 offset-xl-1 col-xl-4 ">
                    <LazyLoadImage
                        className="assistant-picture"
                        src={AssistantProspect}
                        alt="Verification des données légales d'entreprise"
                        effect="blur"
                    />
                </div>
            </div>
            {/* ----- End assistant 3 ----- */}


            {/* Assistant 4 */}
            <div className="assistant-block row max-md-flex-direction-column">

                <div className="offset-1 col-10 offset-sm-3 col-sm-6 offset-md-3 col-md-6 offset-lg-1 col-lg-4 offset-xl-2 col-xl-4">
                    <LazyLoadImage
                        className="assistant-picture"
                        src={AssistantTva}
                        alt="Déclaration de TVA - Expert comptable"
                        effect="blur"
                    />
                </div>

                <div className="assistant-text offset-1 col-10 offset-sm-3 col-sm-6 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4 flex-direction-column">

                    <h4>{process.env.REACT_APP_BOT_NAME_TVA}</h4>

                    <p className="describe-assistant mt-4">
                        Chaque mois, la déclaration de TVA est un casse-tête.
                        De nombreuses tâches manuelles, du stress, des emails et impots.gouv… Découvrez comment,
                        en 20 minutes, vous pouvez vous libérer totalement de cette charge.
                    </p>

                    <div className="mt-4">
                        <Link className="text-aliz d-flex text-decoration-none" to="/nos-solutions/AlizTva">
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </Link>
                    </div>

                </div>
            </div>
            {/*-----  End assistant 4 ----- */}

        </div>
    )
}
