import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

//_________Landing page___________

// Santé
export async function insertClient( data, setErrors, setSuccess) {
    await axios({
        method: 'post',
        url: url + 'landing/clients',
        data: data
    })
        .then(function (response) {
            // open modal or 
            if (!response.data.errors) {
                setSuccess(response.data)
            } else {
                setErrors(response.data.errors)
            }


        })
        .catch(function (error) {
            alert(error);
        })
}

export async function insertSimulation({ data }) {
    await axios({
        method: 'POST',
        url: url + 'landing/simulations',
        data: data
    })
        .then(function (response) {
            return response.data
        })
}

// Insert Client Google Auth
export async function insertClientGoogleAuth(tokenId, setSuccess, setErrors) {
    await axios({
        method: "post",
        url: url + 'googleInsertClient',
        data: {
            'tokenId': tokenId
        }
    })
        .then(function (response) {
            if (typeof response.data.errors !== "undefined" && response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setSuccess(response);
            }
        }, (err) => {
            setErrors(err)
        });
}