import React from 'react'
import logo from '../../../images/landing/Typo.png'
export default function Header() {
    return (
        <div className="Sante1_header-view">
            <nav className="container-fluid fixed-top">
                <a className="" href="/Landing/assistant-virtuel-professions-de-sante-1">
                    <img className="logo-header" src={logo} alt="Logo Aliz" />
                </a>
            </nav>
        </div>
    )
}
