import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Component
import AdministrativeTable from '../Table/Administrative/Administrative'

// ---- Images ------
import freeTrialIllustration from '../../../images/letter.png'

export default function Administrative({ annual, token }) {

    const [prices, setPrices] = useState(annual ? {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL_PRICE,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL_PRICE,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL_PRICE,
    } : {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY_PRICE,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY_PRICE,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY_PRICE,
    })
    const [idPrice, setIdPrice] = useState(annual ? {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL
    } : {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY
    })

    let navigate = useNavigate();

    useEffect(() => {
        if (annual) {
            setPrices({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL_PRICE,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL_PRICE,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL_PRICE,
            })
            setIdPrice({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL
            })
        } else {
            setPrices({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY_PRICE,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY_PRICE,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY_PRICE,
            })
            setIdPrice({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY
            })
        }
    }, [annual]);


    // ----- Feature included in subscription -----
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // ----- Feature not included in subscription -----
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    return (
        <div className="price-pack">
            <div className="row justify-content-center max-md-flex-direction-column" >

                {/* ----- Price pack STARTER ----- */}
                < div className="rate-block rate-light" >

                    <p className="pack-title little-title">Starter</p>

                    <hr></hr>

                    <div className="pack-price">
                        <p>{prices.starter} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>

                    <p className="pack-description">L'indispensable pour commencer à déléguer vos tâches à votre assistant.</p>

                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">

                        <div className="features-description features-active">
                            {feature}
                            <p>Jusqu’à 50 crédits/mois</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>1 lieu d'exercice</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Envoi par mail et stockage automatique des factures</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Synchronisation de votre agenda Doctolib à l'assistant Aliz</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Personnalisation des factures</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Personnalisation des emails</p>
                        </div>
                    </div>

                    <button className="btn-alizFull"
                        onClick={() => token ? navigate("/espace-client/souscription-etape1?assistant=1&price=" + idPrice.starter + "&eur=" + prices.starter) : window.location.href = '/login?assistant=1&price=' + idPrice.starter}
                    >
                        Tester Gratuitement
                    </button>
                </div >
                {/* ----- End price pack STARTER -----*/}





                {/* ----- Price pack BUSINESS ----- */}
                <div className="rate-block rate-light">

                    <p className="pack-title">Business</p>

                    <hr></hr>

                    <div className="pack-price">
                        <p>{prices.business} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>

                    <p className="pack-description">La solution complète pour faire passer votre assistant au niveau supérieur et automatiser toujours plus.</p>

                    <p className="pack-features">Fonctionnalités principales</p>


                    <div className="features-block">

                        <div className="features-description features-active">
                            {feature}
                            <p>Jusqu’à 100 crédits/mois</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>2 lieux d'exercice</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Envoi par mail et stockage automatique des factures</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Synchronisation de votre agenda Doctolib à l'assistant Aliz</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Personnalisation des factures</p>
                        </div>

                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Personnalisation des emails</p>
                        </div>

                    </div>


                    <button className="btn-alizFull"
                        onClick={() => token ? navigate("/espace-client/souscription-etape1?assistant=1&price=" + idPrice.business + "&eur=" + prices.business) : window.location.href = '/login?assistant=1&price=' + idPrice.business}
                    >
                        Tester Gratuitement
                    </button>
                </div>
                {/* ----- End price pack BUSINESS -----*/}





                {/* ----- Price pack PREMIUM */}
                <div className="rate-block rate-light">

                    <p className="pack-title">Premium</p>

                    <hr></hr>

                    <div className="pack-price">
                        <p>{prices.premium} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>

                    <p className="pack-description">Profitez des fonctionnalités avancées et rendez toujours plus efficace le travail de votre assistant</p>

                    <p className="pack-features">Fonctionnalités principales</p>


                    <div className="features-block">

                        <div className="features-description features-active">
                            {feature}
                            <p>Jusqu’à 200 crédits/mois</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>3 lieux d'exercice</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Envoi par mail et stockage automatique des factures</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Synchronisation de votre agenda Doctolib à l'assistant Aliz</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Personnalisation des factures</p>
                        </div>

                        <div className="features-description features-active">
                            {feature}
                            <p>Personnalisation des emails</p>
                        </div>

                    </div>

                    <button className="btn-alizFull" 
                    onClick={() => token ? navigate("/espace-client/souscription-etape1?assistant=1&price=" + idPrice.premium + "&eur=" + prices.premium) : window.location.href = '/login?assistant=1&price=' + idPrice.premium}
                    >
                        Tester Gratuitement
                        </button>

                </div>
                {/* ----- End price pack PREMIUM */}


            </div >

            <div className="trial-free">
                <img src={freeTrialIllustration} alt="personnage allongé sur le ventre" />
                <h3>Bénéficiez de 15 jours gratuits et sans engagement !</h3>
            </div>


            {/* ----- Price Table -----*/}
            < AdministrativeTable />

            <div className="row mb-5">
                <div className="offset-1 col-10 offset-sm-1 col-sm-8 offset-lg-0 col-lg-6 offset-xl-1 col-xl-10 support">
                    <i>(1) Cette fonctionnalité n'est pas disponible lors du test gratuit.<br></br> Vous pouvez contacter le
                        <a href={process.env.REACT_APP_LINK_CALENDAR_CONTACT_SUPPORT} className="text-aliz" target="_blank" rel="noopener noreferrer"><b> support </b></a>
                        pour plus d'informations
                    </i>
                </div>
            </div>
            
        </div >
    )
}
