import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { isCalendarSubscribed } from './../../services/User/calendar.js'
import { isBotVerifLegalSubscribed } from './../../services/User/verifLegal'
import { numberNotReadedNotification } from './../../services/User/notification'

import Arrow from "../../images/Header/Arrow - Down.svg"
import gsap from 'gsap'

export default function Head({ token }) {
    const [toggle, setToggle] = useState(false)
    const [isCalendar, setIsCalendar] = useState(false)
    const [isVerifLegal, setisVerifLegal] = useState([])
    const [responseNumberNotReadedNotification, setResponseNumberNotReadedNotification] = useState(0)
    // const [isTva, setIsTva] = useState(false)
    const dropdown = useRef();
    const ul = useRef();

    useEffect(() => {
        const tl = gsap.timeline();
        if (toggle) {
            tl.to(dropdown.current, 0, {
                display: "block",
                height: "auto",
                ease: "power3.inOut"
            });
        } else {
            tl.to(dropdown.current, 0, {
                display: "none",
                height: 0,
                ease: "power3.inOut",
            });
        }
    }, [toggle]);

    useEffect(() => {
        isCalendarSubscribed(setIsCalendar)
        isBotVerifLegalSubscribed(setisVerifLegal)
        numberNotReadedNotification(setResponseNumberNotReadedNotification)
    }, [])


    return (
        <div className="head-view">
            <div className="cicle-user d-flex" onClick={() => setToggle(toggle ? false : true)}>
                {responseNumberNotReadedNotification > 0 &&
                    <div id="circle-notif" className="responseNumberNotReadedNotification"><p>{responseNumberNotReadedNotification}</p></div>
                }
                <div className="circle">{token && token.initials}</div>
                <img src={Arrow} alt="arrow down" />
            </div>
            <div className="dropdown" ref={dropdown}>
                <ul ref={ul}>
                    <li><Link to="/espace-client/accueil">Mes souscriptions</Link></li>
                    <li><Link to="/espace-client/profil">Informations personnelles</Link></li>
                    <li><Link to="/espace-client/mon-profil-professionnel">Informations professionnelles</Link></li>
                    <li><Link to="/espace-client/mes_factures">Mes factures</Link></li>
                    <li><Link to="/espace-client/mes_moyens_de_paiement">Mes moyens de paiments</Link></li>
                    <li className="d-flex"><Link to="/espace-client/mes_notifications">Mes notifications</Link>
                        {responseNumberNotReadedNotification > 0 &&
                            <div className="responseNumberNotReadedNotification"><p>{responseNumberNotReadedNotification}</p></div>
                        }
                    </li>
                    <li><Link to="/espace-client/demander_assistance">Demande une assistance</Link></li>
                    <li><Link to="/espace-client/parrainage">Parrainez un ami</Link></li>

                    {/* {isTva &&
                       <li><a href="/espace-client/mes-collaborateurs-tva">Mes Collaborateurs</a></li>
                    } */}
                    {isCalendar &&
                        <button className="hasSubscriptionBtn calendarBtn my-4" onClick={() => window.open(
                            process.env.REACT_APP_LINK_CALENDAR + "user/aliz/connect?token=" + localStorage.getItem('tokenAliz'),
                            '_blank' // <- This is what makes it open in a new window.
                        )}>Accéder à AlizCalendar</button>
                    }
                    {isVerifLegal?.isSubscribed &&
                        <button className="hasSubscriptionBtn verifLegalBtn mb-4" onClick={() => window.open(
                            process.env.REACT_APP_LINK_VERIFLEGAL + "?token=" + localStorage.getItem('tokenAliz'),
                            '_blank' // <- This is what makes it open in a new window.
                        )}>Accéder à AlizVerif</button>
                    }
                </ul>
            </div>
        </div>
    )
}
