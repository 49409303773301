import React, { useState, useEffect } from 'react'

// Constant 
import { SubscriptionStatus } from '../../../services/Constant/subscriptionStatus'

export default function Pack({ subscription }) {

    const [recurring, setRecurring] = useState("")
    const [allId, setAllId] = useState([])
    const [allEuros, setAllEuros] = useState(0)

    useEffect(() => {
        const allPricesId = [];
        const allPriceEuros = [];
        if (subscription.prices && subscription.prices.length > 0) {
            subscription.prices.forEach(price => {
                allPricesId.push(price.price.id)
                allPriceEuros.push(price.price.metadata.price)

                setAllId(allPricesId)

                // We want french terms
                if (price.price.recurring) {
                    if (price.price.recurring.interval === "year") {
                        setRecurring("annuel")
                    } else {
                        setRecurring("mensuel")
                    }
                }
            })
            setAllEuros(allPriceEuros.reduce(function (a, b) {
                return (parseInt(a) + parseInt(b));
            }, 0))
        }
        // eslint-disable-next-line
    }, [subscription])
    // console.log(subscription)

    return (
        <div className={`subscription-block ${subscription.status === SubscriptionStatus.CANCELED ? "canceledSub" : ""} ${subscription.status === SubscriptionStatus.DEMO || subscription.status === SubscriptionStatus.EXPIRED || subscription.status === SubscriptionStatus.WAITING || subscription.status === SubscriptionStatus.INCOMPLETE ? "inactiveSub" : ""} ${subscription.trialPeriodEnded ? "trialEnded" : ""}`}>

            <div className="d-flex typeAndRecurring">

                {subscription.status && recurring &&
                    <p className="pack-recurring">Engagement {recurring}</p>
                }

                {(() => {
                    switch (subscription.status) {
                        case SubscriptionStatus.DEMO:
                            return <p className="pack-type">PÉRIODE D'ESSAI EN COURS</p>
                        case SubscriptionStatus.EXPIRED:
                            return <p className="pack-type">PÉRIODE D'ESSAI TERMINÉE</p>
                        case SubscriptionStatus.WAITING:
                            return <p className="pack-type">EN ATTENTE</p>
                        case SubscriptionStatus.ACTIVE:
                            return <p className="pack-type">EN COURS</p>
                        case SubscriptionStatus.CANCELED:
                            return <p className="pack-type">SUPPRIMÉ</p>
                        case SubscriptionStatus.INCOMPLETE:
                            return <p className="pack-type">PAIEMENT INCOMPLET</p>
                        default:
                            break;
                    }
                })()}
            </div>

            <div className="d-flex offerAndPrice">
                {subscription.prices &&
                    <p className="pack-offer">Offre {subscription.prices[0].price.nickname}</p>
                }
                <p className="pack-price">{allEuros}€ <i> {recurring === "annuel" ? "/an" : "/mois"}  <b>HT</b></i>
                </p>
            </div>

            <p className="pack-name">{subscription?.robot?.name}</p>

            <hr></hr>


            {subscription.robot.id && subscription.robot.id === process.env.REACT_APP_BOT_ID_CALENDAR && subscription.status !== SubscriptionStatus.EXPIRED &&
                <button className="hasSubscriptionBtn calendarBtn btn-alizOut my-4" onClick={() => window.open(
                    process.env.REACT_APP_LINK_CALENDAR + "user/aliz/connect?token=" + localStorage.getItem('tokenAliz'),
                    '_blank' // <- This is what makes it open in a new window.
                )}>Accéder à AlizCalendar</button>
            }

            {subscription.robot.id && subscription.robot.id === process.env.REACT_APP_BOT_ID_VERIFLEGAL && subscription.status !== SubscriptionStatus.EXPIRED &&
                <button className="hasSubscriptionBtn calendarBtn btn-alizOut my-4" onClick={() => window.open(
                    process.env.REACT_APP_LINK_VERIFLEGAL + "?token=" + localStorage.getItem('tokenAliz'),
                    '_blank' // <- This is what makes it open in a new window.
                )}>Accéder à AlizVerif</button>
            }

            <div className="offset-md-1 col-md-10 offset-xl-2 col-xl-8">
                <div className="pack-date">
                    <p className="pack-createdAt"> Date de souscription: {subscription.createdAt}</p>

                    {
                        // If subscription is trial/demo, we display "Fin du test gratuit", else "Prochain paiement dû"
                        (() => {
                            if (subscription.status === SubscriptionStatus.DEMO || subscription.status === SubscriptionStatus.EXPIRED) {
                                return <p className="pack-end">Fin du test gratuit: {subscription.trialDateEnded && subscription.trialDateEnded}</p>
                            } else if (subscription.status === SubscriptionStatus.ACTIVE) {
                                return <p className="pack-end">Prochain paiement dû: {subscription.endCurrentPeriod}</p>
                            } else if (subscription.status === SubscriptionStatus.INCOMPLETE) {
                                return <p className="pack-end">Veuillez contacter le <b title="contact@aliz.io">support</b>,<br></br> un problème est survenu avec votre moyen de paiement </p>
                            }
                        })()
                    }

                    {
                        (() => {
                            // Revoir condition pour calendar
                            if (subscription.robot.id !== parseInt(process.env.REACT_APP_BOT_ID_CALENDAR) && subscription.status === SubscriptionStatus.ACTIVE) {
                                return <p>Crédits consommés durant la période : {subscription.totalUsage ? subscription.totalUsage : 0} /  {subscription.creditsByPeriod ? subscription.creditsByPeriod : 0}</p>
                            }
                        })()
                    }

                </div>
            </div>


            {(() => {
                switch (subscription.status) {
                    case SubscriptionStatus.ACTIVE:
                        return <div className="d-flex max-lg-flex-direction-column btn-block">
                            <button className="btn-alizFull" onClick={() => { window.location.href = "/espace-client/modification-souscription?assistant=" + subscription.robot.id }}>Modifier souscription</button>
                            <button className="btn-yellow" onClick={() => { window.location.href = "/espace-client/mon-profil-professionnel?assistant=" }}>Mes informations</button>
                            <button className="btn-red" onClick={() => { window.location.href = process.env.REACT_APP_LINK_CALENDAR_DELETE_SUBSCRIPTION }}>Annuler souscription</button>
                        </div>
                    case SubscriptionStatus.WAITING:
                        return <div className="d-flex max-lg-flex-direction-column btn-block">
                            <button className="btn-alizFull mr-3" onClick={() => {
                                Array.isArray(allId) ?
                                    window.location.href = "/espace-client/souscription-etape2?assistant=" + subscription.robot.id + "&price=" + allId[0] + "&subscription=success"
                                    :
                                    window.location.href = "/espace-client/souscription-etape2?assistant=" + subscription.robot.id + "&price=" + allId + "&subscription=success"
                            }}>Finalisez votre souscription</button>
                        </div>
                    case SubscriptionStatus.DEMO:
                        return <div className="d-flex max-lg-flex-direction-column btn-block">
                            <button className="btn-alizFull" onClick={() => { window.location.href = "/espace-client/modification-souscription?assistant=" + subscription.robot.id }}>Activer souscription</button>
                            <button className="btn-red" onClick={() => { window.location.href = process.env.REACT_APP_LINK_CALENDAR_DELETE_SUBSCRIPTION }}> Annuler souscription</button >
                        </div>
                    case SubscriptionStatus.EXPIRED:
                        return <div className="d-flex max-lg-flex-direction-column btn-block">
                            <button className="col-12 col-sm-10 col-xl-8 btn-yellow" onClick={() => { window.location.href = "/espace-client/modification-souscription?assistant=" + subscription.robot.id }}>Activer souscription</button>
                        </div>
                    case SubscriptionStatus.CANCELED:
                        return <div className="d-flex max-lg-flex-direction-column btn-block">
                            <button className="btn-alizFull mr-3" onClick={() => { window.location.href = "/espace-client/modification-souscription?assistant=" + subscription.robot.id }}>Activer souscription</button>
                        </div>
                    default:
                        break;
                }
            })()}


        </div >
    )
}
