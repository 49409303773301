import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Illustration from '../../../images/QualificationProspect/character-search-data.png'

// ----- Component -----
import FeaturesVerif from "./FeaturesVerif"
import VerifLegalPack from "../RatePack"
import LittleFaq from "../FAQ"
import Summary from '../../Summary'

// ----- Services -----
import { isBotVerifLegalSubscribed } from '../../../services/User/verifLegal.js'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function VerifLegal({ token, location }) {


    const [isSubscribedVerifLegal, setIsSubscribedVerifLegal] = useState(false)

    useEffect(() => {
        isBotVerifLegalSubscribed(setIsSubscribedVerifLegal)
    }, [])

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>AlizVerif, l’assistant de vérification des données légales d’entreprise - Aliz</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" content="Grâce à une interface simple, Aliz vous évite de passer des heures à vérifier les informations légales d'une entreprise." />
                <link rel="canonical" href="https://aliz.io/nos-solutions/AlizVerif" />
                <meta property="og:title" content="L’assistant de vérification des données légales d’entreprise - Aliz" />
            </Helmet>

            <div className="homeAssistant-view">

                {/* ----- Assistant presentation -----*/}
                <div className="row justify-content-center home-block">
                    {/* ----- Catch phrase & Cta -----*/}
                    <div className="col-11 col-sm-10 col-md-11 col-lg-6 flex-direction-column catch-phrase">

                        <h1>{process.env.REACT_APP_BOT_NAME_VERIFLEGAL}, l'assistant intelligent qui vérifie les données d'entreprises</h1>

                        <h2>Vous pouvez ici réaliser des recherches sur des entreprises, leurs données et leurs dirigeants et bien plus encore</h2>


                        <div className={`${isSubscribedVerifLegal ? 'flex-direction-column' : ""} row btn-block`}>

                            {isSubscribedVerifLegal ?
                                <button className="hasSubscriptionBtn verifLegalBtn col-auto" onClick={() => window.open(
                                    process.env.REACT_APP_LINK_VERIFLEGAL + "?token=" + localStorage.getItem('tokenAliz'),
                                    '_blank' // <- This is what makes it open in a new window.
                                )}>Accéder à AlizVerif</button> :
                                <a className="col-auto btn-alizFull" href={process.env.REACT_APP_LINK_CALENDAR_SUBSCRIPTION}>Essayer gratuitement</a>
                            }

                            <Link className={`${!isSubscribedVerifLegal ? 'offset-1' : "mt-3"} col-auto btn-alizOut`} to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_VERIFLEGAL}>Voir les prix</Link>
                        </div>


                    </div>

                    {/* ----- End catch phrase ----- */}
                    <div className="col-10 col-sm-8 col-md-9 offset-lg-1 col-lg-5 ">
                        <img src={Illustration} alt="personne analysant des données sur un smartphone" className="illustration" />
                    </div>
                </div>

                <FeaturesVerif />

                <VerifLegalPack token={token} location={location} />

                <LittleFaq />

                <Summary />
            </div>
        </>
    )
}
