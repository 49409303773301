import React from 'react'
import { useNavigate } from 'react-router-dom'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function No() {

    let navigate = useNavigate()

    return (
        <>
            <Helmet title="Souscription réussi" />

            <div className="subscription-view container-fluid doctolib-connection">

                <div className="row">

                    <div className="offset-1 col-10 col-md-10 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6 shadow-frame">
                        <div className="subscribForm-view">
                            <div className="d-flex flex-direction-column">
                                <h1>Assistant de Gestion Patientèle</h1>

                                <p className="ask-information gmail-explains">
                                    <b>Félicitations, votre assistant de gestion patientèle est prêt à être utilisé</b>
                                    <br></br>
                                    Visionnez la vidéo de présentation ci-dessous pour le découvrir:
                                </p>

                                <div className="row justify-content-center my-4">
                                    <iframe width="560" height="315"
                                        src="https://www.youtube.com/embed/DJbsiJmjklg"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>

                                <div id="btn-block-2" className="d-flex flex-direction-column-reverse col-12 offset-xl-1 col-xl-10">
                                    <a className="btn-greyFull" id="btn-grey" href={process.env.PUBLIC_URL + "/download/Notice_utilisation_assistant_gestion_patientèle.pdf"} download> Telecharger le guide d'utilisation</a>
                                    <button className="btn-alizFull" id="btn-blue" onClick={() => navigate('/espace-client/accueil?subscription=success')}>Continuer</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
