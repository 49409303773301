import React, { useState, useEffect } from 'react'

// ----- Services -----
import { notificationByUser } from '../../../services/User/notification'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';
import ReactPaginate from "react-paginate";

// ----- Components -----
import Navbar from '../Nav'
import Loader from './../../LoaderSuspense'
import Notification from './Notification'

export default function Home() {
    const [notifications, setNotifications] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [isDeleted, setIsDeleted] = useState(false)
    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(0)

    useEffect(() => {
        notificationByUser(setNotifications, setLoading)
    }, [])

    useEffect(() => {
        if (isDeleted) {
            setNotifications([])
            notificationByUser(setNotifications, setLoading)
        }
    }, [isDeleted])

    // Pagination
    const PER_PAGE = 10;

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    useEffect(() => {
        setOffset(currentPage * PER_PAGE);
        setPageCount(Math.ceil(notifications.length / PER_PAGE));
    }, [notifications, offset, currentPage])



    return (
        <>
            <Helmet title="Espace client - Mes notifications" />

            <div className="userArea-view">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">
                        <h1> Mes notifications</h1>
                        <h2 className="catch-phrase">Voici toute vos notifications concernant les nouveautés Aliz, vos factures et vos informations de souscriptions .</h2>

                        {(() => {
                            if (notifications.length === 0 && loading === false) {
                                return <p className="no-data-fetching">Vous n'avez aucune notification </p>
                            } else if (notifications.length > 0 && loading === false) {
                                return notifications
                                    .slice(offset, offset + PER_PAGE)
                                    .map((notification, key) => {
                                        return <Notification notification={notification} key={key} setIsDeleted={setIsDeleted} />
                                    })
                            } else {
                                return <Loader />;
                            }
                        })()}


                        <div className="row justify-content-center">
                            {(() => {
                                if (notifications.length > 10 && loading === false) {
                                    return <ReactPaginate
                                        previousLabel={"← Previous"}
                                        nextLabel={"Next →"}
                                        pageCount={pageCount}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                    />
                                }
                            })()}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
