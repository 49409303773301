import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// ----- Services -----
import { getOneSubscription } from '../../../services/User/subscription'

// ----- Components -----
import AdministrativeOffer from './Offers/Administrative'
import TvaOffer from './Offers/Tva'
import CalendarOffer from './Offers/Calendar'
import VerifLegalOffer from './Offers/VerifLegal'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function UpdateSubscription() {
    // Get params 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [sub, setSub] = useState(true)
    const [robot, setRobot] = useState(query.get("assistant") ? query.get("assistant") : "")

    const [mySubscription, setMySubscription] = useState([])
    // const [paymentMethodType, setPaymentMethodType] = useState("card")
    // const [methodIsCard, setMethodIsCard] = useState(true)

    useEffect(() => {
        setRobot(query.get("assistant") ? query.get("assistant") : "")
        if (robot.length > 0) {
            getOneSubscription(setMySubscription, robot)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (typeof mySubscription.prices !== "undefined") {
            setSub(mySubscription.recurring === "Annuel" ? true : false)
        }
    }, [mySubscription])

    const component = () => {
        if (robot.length > 0) {
            switch (robot) {
                case process.env.REACT_APP_BOT_ID_PATIENTELE:
                    return <AdministrativeOffer annual={sub} robot={robot} pack={mySubscription.type ? mySubscription.type : ""} prices={mySubscription.prices && mySubscription.prices[0] ? mySubscription.prices[0] : ""} />;
                case process.env.REACT_APP_BOT_ID_TVA:
                    return <TvaOffer annual={sub} robot={robot} pack={mySubscription.type ? mySubscription.type : ""} prices={mySubscription.prices && mySubscription.prices[0] ? mySubscription.prices[0] : ""} />;
                case process.env.REACT_APP_BOT_ID_CALENDAR:
                    return <CalendarOffer annual={sub} robot={robot} pack={mySubscription.type ? mySubscription.type : ""} prices={mySubscription.prices && mySubscription.prices[0] ? mySubscription.prices[0] : ""} />;
                case process.env.REACT_APP_BOT_ID_VERIFLEGAL:
                    return <VerifLegalOffer annual={sub} robot={robot} pack={mySubscription.type ? mySubscription.type : ""} prices={mySubscription.prices && mySubscription.prices[0] ? mySubscription.prices[0] : ""} />;
                default:
                    return <h3>Une erreur s'est produite, aucun assistant ne correspond</h3>;
            }
        }
    }

    // useEffect(() => {
    //     if (methodIsCard) {
    //         setPaymentMethodType("card")
    //     } else {
    //         setPaymentMethodType("sepa_debit")
    //     }
    // }, [methodIsCard])

    return (
        <>
            <Helmet title="Espace client - Modifier ma souscription" />

            <div className="userArea-view updateSubscription-view">

                <div className="row">

                    <div className="offset-1 col-10  offset-xl-2 col-xl-8">
                        <h1> Activer une souscription</h1>
                        <h2 className="catch-phrase mb-5">Voici votre souscription actuelle, vous pouvez l'activer/modifier en choissisant votre abonnement ci-dessous.
                            <br></br>Pour toute demande, vous pouvez contacter le <b onClick={() => window.open(process.env.REACT_APP_LINK_CALENDAR_UPDATE_SUBSCRIPTION, "_blank")
                            }>support</b>
                        </h2>

                        <div className="packInfo-block d-flex">
                            <p className="pack-info">Pack : <b>{mySubscription.type && mySubscription.type}</b></p>
                            <p className="pack-info">Prix :  <b>{mySubscription.totalPrice && mySubscription.totalPrice} €</b></p>
                            <p className="pack-info">Paiment :  <b>{mySubscription.recurring && mySubscription.recurring}</b></p>
                        </div>

                        <div>
                            <h3>Assistant  {
                                (() => {
                                    if (mySubscription.robot) {
                                        switch (mySubscription.robot.id) {
                                            case parseInt(process.env.REACT_APP_BOT_ID_PATIENTELE):
                                                return process.env.REACT_APP_BOT_NAME_PATIENTELE
                                            case parseInt(process.env.REACT_APP_BOT_ID_TVA):
                                                return process.env.REACT_APP_BOT_NAME_TVA
                                            case parseInt(process.env.REACT_APP_BOT_ID_CALENDAR):
                                                return process.env.REACT_APP_BOT_NAME_CALENDAR
                                            case parseInt(process.env.REACT_APP_BOT_ID_VERIFLEGAL):
                                                return process.env.REACT_APP_BOT_ID_VERIFLEGAL
                                            default:

                                                break;
                                        }
                                    }
                                })()
                            } {mySubscription.robot && mySubscription.robot.slug}</h3>


                            {/* ----- Monthly or Annual subscription ----- */}
                            <div className="row justify-content-center mt-5">
                                <div className="col-auto d-flex">
                                    <p className="monthly-pack">PLAN MENSUEL</p>

                                    <label className="switch">
                                        <input type="checkbox" id="pack" name="pack" checked={sub} onChange={(e) => mySubscription.recurring === "Mensuel" || mySubscription.status === "demo" || mySubscription.status === "expired" ? setSub(e.target.checked) : null} />
                                        <span className="slider round"></span>
                                    </label>

                                    <p className="annual-pack">PLAN ANNUEL</p>
                                    <p className="discount">-30%</p>
                                </div>
                            </div>


                            {/* <h4 className="mt-5">Votre moyen de paiement:</h4>
                        <div className="row ml-4">
                            <p className="monthly-pack">SEPA</p>
                            <label className="switch">
                                <input type="checkbox" id="paymentType" name="paymentType" checked={methodIsCard} onChange={(e) => setMethodIsCard(e.target.checked)} />
                                <span className="slider round"></span>
                            </label>
                            <p className="annual-pack">CB</p>
                        </div> */}

                            {mySubscription.recurring && mySubscription.recurring === "Annuel" && mySubscription.status !== "demo" &&
                                <div className="row justify-content-center mt-5">
                                    <p className="contact-support">Si vous souhaitez passer à un abonnement Mensuel, veuillez contacter le
                                        <button className="btn-alizOut ml-4 my-auto" onClick={() => window.open(process.env.REACT_APP_LINK_CALENDAR_UPDATE_SUBSCRIPTION, "_blank")}>support</button>
                                    </p>
                                </div>
                            }

                            <div className="row justify-content-center">
                                {component()}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}