import React from 'react'

export default function LoaderSuspense() {
    return (
        <div className="loaderSuspense">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
