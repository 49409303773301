import React, { useState, useEffect } from 'react'
import { removePaymentMethod } from '../../../services/User/paymentMethod.js'
import LoaderSuspense from '../../LoaderSuspense'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function Card({ countCards, cardDefaultPayementId, paymentMethod, setCardDefaultPayementId, setDefaultCardChanged }) {
    const [response, setResponse] = useState([])
    const [isLoadedRemove, setIsLoadedRemove] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        if (paymentMethod.defaultPayment) {
            setIsChecked(true)
        }
    }, [paymentMethod, cardDefaultPayementId])


    useEffect(() => {
        if (typeof response.remove !== 'undefined' && response.remove) {
            window.location.href = '/espace-client/mes_moyens_de_paiement?paymentMethod=removeCard'
        } else if (typeof response.remove !== 'undefined' && !response.remove) {
            alert("Une erreur s'est produite, votre carte n'a pas pu être supprimé. Veuillez contacter le support")
        }
    }, [response])

    // Alert : remove Card?
    const removeCard = () => {
        setIsLoadedRemove(true)
        confirmAlert({
            title: "Supprimer cette carte",
            message: 'Êtes-vous sûr de vouloir supprimer ce moyen de paiement?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => removePaymentMethod(paymentMethod.id, setResponse, setIsLoadedRemove)
                },
                {
                    label: 'Non',
                    onClick: () => {
                        setIsLoadedRemove(false)
                        alert("La modification n'a pas été pris en compte")
                    }
                }
            ]
        });
    }


    return (
        <div className="card-block ">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 flex-direction-column">
                    <p className="card-expire"> {paymentMethod.type.charAt(0).toUpperCase() + paymentMethod.type.slice(1)} {paymentMethod.brand.charAt(0).toUpperCase() + paymentMethod.brand.slice(1)} expirant le {paymentMethod.exp_month}/{paymentMethod.exp_year.toString().substr(2)}</p>
                    <p className="card-number">&#x2022; &#x2022; &#x2022; &#x2022; &#160; &#x2022; &#x2022; &#x2022; &#x2022; &#160; &#x2022; &#x2022; &#x2022; &#x2022;  &#160;{paymentMethod.last4}</p>

                </div>

                <div className={`card-isDefault ${paymentMethod.defaultPayment ? "isDefault" : ""} col-auto`}>
                    <input id={paymentMethod.id}
                        name="defaultCard"
                        value={paymentMethod.defaultPayment}
                        checked={Boolean(isChecked)}
                        onClick={() => {
                            setCardDefaultPayementId(paymentMethod.id)
                            setDefaultCardChanged(true)
                        }}
                        onChange={(e) => (setIsChecked(e.target.value))}
                        type="radio"
                        />
                    <label className="" htmlFor={paymentMethod.id}>Par défaut</label>
                </div>

            </div>

            <div className="row">
                {/* <button className="btn-yellow col-5 col-sm-4 col-xl-2" onClick={() => navigate('/espace-client/modifier_cb')}>
                    Modifier
                </button> */}

                {countCards > 1 &&
                    <button className="btn-red col-5 col-sm-4 col-xl-2" onClick={removeCard}>
                        Supprimer
                    </button>
                }
            </div>

            {(() => {
                if (isLoadedRemove === true) {
                    return <LoaderSuspense />;
                }
            })()}
        </div>
    )
}
