import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

const headers = {
    'Content-Type': 'application/json'
};

// __________________ LOGIN ___________________
export async function loginUser(setResponse, email, password, setErrors, setIsLogged) {
    await axios({
        method: 'POST',
        url: url + 'login_check',
        headers: headers,
        data: {
            "email": email, // à modifier en username pour nouvelle version symfony
            "password": password
        },
    })
        .then((response) => {
            // Send response for check token 
            setResponse(response);
            // If response return data, login is ok
            if (typeof response.data !== 'undefined' && !response.data.errors) {
                setIsLogged("success")
            }
        }, (err) => {
            if (typeof err.response.status !== "undefined") {
                setErrors(err.response.data);
            }
        });
}

// __________________ REGISTER ___________________

export async function registerUser({ data }, setErrors, setSuccess) {
    await axios({
        method: "post",
        url: url + 'register',
        headers: headers,
        data: data
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)

            } else {
                setSuccess("success");
            }
        }, (err) => {
            alert(err)
        });
}


//______________________GEOLOCALISATION______________________ 
export async function getGeoInfo(setCity, latitude, longitude) {
    await axios({
        method: 'GET',
        url: 'https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latitude + '&lon=' + longitude + '&zoom=18&addressdetails=1'
    })
        .then((response) => {
            setCity(response.data.address.city);
        })
};



export async function ForgotPasswordLink(email, setSuccess, setErrors) {
    await axios({
        method: "post",
        url: url + 'forgotPassword',
        headers: headers,
        data: {
            'email': email
        }
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else if (response) {
                setSuccess(response.data);
            }
        }, (err) => {
            setErrors(err)
        });
}

export async function ResetPasswordLink(data, setSuccess, setErrors) {
    await axios({
        method: "post",
        url: url + 'resetPassword',
        headers: headers,
        data: data
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else if (response.data) {
                setSuccess(response.data.reset);
            }
        }, (err) => {
            setErrors(err)
        });
}

