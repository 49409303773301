import React from 'react'
import { useNavigate } from "react-router-dom";
import Logo from '../../../../images/logo/Logo Blanc.png'
import CountDown from './CountDown'
import Prices from './Prices'
import Testimonials from './Testimonials'


export default function Home() {

    let navigate = useNavigate();


    return (
        <div id="landing_blackFriday">
            <div id="home">
                <div className="navbar" id="logo-row" onClick={() => navigate('/')}>
                    <img src={Logo} alt="Aliz" className="navbar-brand" id="header-logo" />
                </div>
                <img src={process.env.PUBLIC_URL + '/images/landing/ALIZ_vague_homme.png'} alt="work wave" className="illustration-home" />


                <div className="blockHome">
                    <div>
                        <p>Avec le code promo</p>
                        <h1>Blackaliz</h1>
                        <p>bénéficiez de 6 mois offerts à la souscription</p>

                        <CountDown />

                        <button className="btn-yellow" onClick={() => navigate('/espace-client/souscription-etape1?assistant=1&price=' + process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL)}>J'en profite</button>
                    </div>

                </div>
            </div>


            <div id="iframe">
                <iframe src={process.env.REACT_APP_ALIZ_PATIENTELE_VIDEO + "?autoplay=1&mute=1"} frameBorder="0" allow="autoplay ; fullscreen" allowfullscreen title="Présentation d'Aliz et de ses fonctionnalitées" ></iframe>
            </div>

            <Prices />

            <Testimonials />
        </div>
    )
}
