import React, { useEffect, useRef } from 'react'
// import { useNavigate, useLocation } from "react-router-dom";

// import Patientele1 from './Patientele1/Home'
// import Patientele2 from './Patientele2/Home'
// import Popup from './../../Popup'

// import { Experiment, Variant, emitter } from '@marvelapp/react-ab-test';



export default function Patientele() {

    // const navigate = useNavigate()
    // const location = useLocation()
    // let searchParams = new URLSearchParams(location.search);

    // Get params 
    // function useQuery() {
    //     return new URLSearchParams(useLocation().search);
    // }
    // let query = useQuery();

    // const [isContactUsVisible, setIsContactUsVisible] = useState(false)


    // emitter.defineVariants('Landing', ['A', 'B', 'C'], [50, 50, 0]);

    // emitter.addPlayListener((experimentName, variantName) => {
    //     searchParams.set("ab_testing", experimentName + '_' + variantName);
    //     navigate({
    //         pathname: location.pathname,
    //         search: searchParams.toString()
    //     });
    // });

    const experimentRef = useRef()

    useEffect(() => {
        experimentRef.current.win();
    }, [])

    // useEffect(() => {
        // if (query.get("contactus") === "success") {
        //     setIsContactUsVisible(true)
            // window.open(process.env.REACT_APP_LINK_CALENDAR_FOOTER_DEMO, '_blank');
            // Delete query
            // searchParams.delete("contactus");
            // navigate({
            //     pathname: location.pathname,
            //     search: searchParams.toString()
            // });
            // Aliz Calendar
        // } 
        // eslint-disable-next-line
    // }, [query])



    return (
        <div>
            {/* <Experiment name="Landing" ref={experimentRef}>
                <Variant name="A">
                    <Patientele1 ab_testing={"Patientele_A"} />
                </Variant>
                <Variant name="B">
                    <Patientele2 ab_testing={"Patientele_B"} />
                </Variant>
                <Variant name="C">
                    <div ab_testing={"Patientele_C"}>Subsection C</div>
                </Variant>
            </Experiment> */}

            {/* Popup success making contact in footer */}
            {/* <Popup
                text="Votre email à bien été prise en compte, vous allez être recontacté prochainement."
                isVisible={isContactUsVisible}
            /> */}
        </div>
    )
}
