import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import HeaderDesktop from "./HeaderDesktop"
import HeaderTablet from "./HeaderTablet"
import Popup from '../Popup'

export default function Header({ token }) {

    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 1200 })
        return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
        const isTablet = useMediaQuery({ maxWidth: 1199.98 })
        return isTablet ? children : null
    }


    // Get params 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [isContactUsVisible, setIsContactUsVisible] = useState(false)
    const [isLoginVisible, setIsLoginVisible] = useState(false)
    const [isRegisterVisible, setIsRegisterVisible] = useState(false)
    const [isAddCardVisible, setIsAddCardVisible] = useState(false)
    const [isRemoveCardVisible, setIsRemoveCardVisible] = useState(false)
    const [isUpdateCardVisible, setIsUpdateCardVisible] = useState(false)


    useEffect(() => {
        if (query.get("contactus") === "success") {
            setIsContactUsVisible(true)
        }
        if (query.get("login") === "success") {
            setIsLoginVisible(true)
        }
        if (query.get("register") === "success") {
            setIsRegisterVisible(true)
        }
        if (query.get("paymentMethod") === "addCard") {
            setIsAddCardVisible(true)
        }
        if (query.get("paymentMethod") === "removeCard") {
            setIsRemoveCardVisible(true)
        }
        if (query.get("paymentMethod") === "updateDefaultCard") {
            setIsUpdateCardVisible(true)
        }
        // eslint-disable-next-line
    }, [query]);


    useEffect(() => {
        if (isContactUsVisible) {
            window.open(process.env.REACT_APP_LINK_CALENDAR_FOOTER_DEMO, "_blank")
        }
    }, [isContactUsVisible])


    return (
        <div className="all-headers">

            <Desktop>
                <HeaderDesktop token={token} />
            </Desktop>

            <Tablet>
                <HeaderTablet token={token} />
            </Tablet>
            

            {/* ----- Popup success making contact in footer ----- */}
            <Popup
                text="Votre email à bien été prise en compte, vous allez être recontacté prochainement."
                isVisible={isContactUsVisible}
            />

            {/* ----- Popup success login ----- */}
            <Popup
                text="Vous êtes maintenant connecté à Aliz."
                isVisible={isLoginVisible}
            />

            {/* ----- Popup success registration ----- */}
            <Popup
                text="Bienvenue chez Aliz ! votre inscription a bien été prise en compte."
                isVisible={isRegisterVisible}
            />

            {/* ----- Popup Card payment method ----- */}
            <Popup
                text="L'ajout de votre nouvelle méthode de paiement à bien été pris en compte."
                isVisible={isAddCardVisible}
            />
            <Popup
                text="La suppression de votre moyen de paiement à bien été pris en compte."
                isVisible={isRemoveCardVisible}
            />
            <Popup
                text="Votre moyen de paiement par défaut à bien été modifié."
                isVisible={isUpdateCardVisible}
            />
            {/* ----- End Popup ----- */}


        </div>
    )
}
