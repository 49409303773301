import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// Google analytics
import ReactGA from 'react-ga';

export default function Location({ setLocation }) {
    const location = useLocation()


    // Google analytics initialize with react-router-dom
    useEffect(() => {
        ReactGA.initialize('UA-182952766-1');
        // To report page view with dynamic path
    }, [])

    useEffect(() => {
        setLocation(location.pathname)
        ReactGA.pageview(location.pathname + location.search);
        // console.log(location.pathname + location.search)
        // eslint-disable-next-line
    }, [location])

    return (
        <div>

        </div>
    )
}
