import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Services -----
import { getSubscriptions } from '../../../services/User/subscription'

// ----- Components -----
import Navbar from '../Nav'
import ProfesionalAdministrative from './Form/FormAdministrative'
import ProfesionalTva from './Form/FormTva'
import ProfesionalCalendar from './Form/FormCalendar'
import ProfesionalVerifLegal from './Form/FormVerifLegal'

import ProfesionalDefault from './Default'
import JoinUs from './JoinUs'
import Popup from '../../Popup'
import LoaderSuspense from '../../LoaderSuspense'


export default function ProfessionalProfile() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    let navigate = useNavigate();
    const [selected, setSelected] = useState(query.get("assistant") ? query.get("assistant") : "")
    const [subscriptions, setSubscriptions] = useState([])
    // check if user has subscription robot
    const [administrative, setAdministrative] = useState(false)
    const [tva, setTva] = useState(false)
    const [calendar, setCalendar] = useState(false)
    const [verifLegal, setVerifLegal] = useState(false)
    // If user has 0 subscription, hide all form
    const [hasSubscription, setHasSubscription] = useState(false)
    const [isLoadedSubscription, setIsLoadedSubscription] = useState(false)

    const [isProfessionalProfilUpdateVisible, setIsProfessionalProfilUpdateVisible] = useState(false)

    // when selected, or url change, we set url 
    useEffect(() => {
        navigate("/espace-client/mon-profil-professionnel?assistant=" + selected)
    }, [selected, navigate]);

    useEffect(() => {
        getSubscriptions(setSubscriptions, setIsLoadedSubscription)
    }, [])

    // console.log(process.env.REACT_APP_BOT_ID_PATIENTELE)

    useEffect(() => {
        if (subscriptions.length > 0) {
            subscriptions.forEach(subscription => {
                // console.log(subscription.robot.id)
                switch (subscription.robot.id) {
                    case parseInt(process.env.REACT_APP_BOT_ID_PATIENTELE):
                        setAdministrative(subscription.prices[0].price.nickname)
                        setHasSubscription(true)
                        break;
                    case parseInt(process.env.REACT_APP_BOT_ID_TVA):
                        setTva(subscription.prices[0].price.nickname)
                        setHasSubscription(true)
                        break;
                    case parseInt(process.env.REACT_APP_BOT_ID_CALENDAR):
                        subscription.prices.forEach(price => {
                            setCalendar(price.price.nickname)
                        });
                        setHasSubscription(true)
                        break;
                    case parseInt(process.env.REACT_APP_BOT_ID_VERIFLEGAL):
                        setVerifLegal(subscription.prices[0].price.nickname)
                        setHasSubscription(true)
                        break;
                    default:
                        break;
                }
            });
        }
    }, [subscriptions])

    console.log(administrative)
    return (
        <>
            <Helmet title="Espace client - Profil professionnel" />

            <div className="userArea-view professionalProfile">
                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">
                        <h1>Mes informations professionnelles</h1>
                        <h2 className="catch-phrase">Ces informations permettent à votre assistant de remplir pleinement vos tâches chronophrages.</h2>

                        {(() => {
                            if (selected === "") {
                                return <ProfesionalDefault />
                            }
                        })()}

                        {/* Button for active & display Price Pack */}
                        <div className="block-btn">
                            {administrative && <button type="button" className={`btn-pack ${process.env.REACT_APP_BOT_NAME_PATIENTELE === selected ? "active" : ""}`} onClick={() => setSelected(process.env.REACT_APP_BOT_NAME_PATIENTELE)}>{process.env.REACT_APP_BOT_NAME_PATIENTELE}</button>}
                            {tva && <button type="button" className={`btn-pack ${process.env.REACT_APP_BOT_NAME_TVA === selected ? "active" : ""}`} onClick={() => setSelected(process.env.REACT_APP_BOT_NAME_TVA)}>{process.env.REACT_APP_BOT_NAME_TVA}</button>}
                            {calendar && <button type="button" className={`btn-pack ${process.env.REACT_APP_BOT_NAME_CALENDAR === selected ? "active" : ""}`} onClick={() => setSelected(process.env.REACT_APP_BOT_NAME_CALENDAR)}>{process.env.REACT_APP_BOT_NAME_CALENDAR}</button>}
                            {verifLegal && <button type="button" className={`btn-pack ${process.env.REACT_APP_BOT_NAME_VERIFLEGAL === selected ? "active" : ""}`} onClick={() => setSelected(process.env.REACT_APP_BOT_NAME_VERIFLEGAL)}>{process.env.REACT_APP_BOT_NAME_VERIFLEGAL}</button>}
                        </div>
                        {(() => {
                            switch (selected) {
                                case process.env.REACT_APP_BOT_NAME_PATIENTELE:
                                    return <ProfesionalAdministrative pack={administrative} setIsProfessionalProfilUpdateVisible={setIsProfessionalProfilUpdateVisible} />
                                case process.env.REACT_APP_BOT_NAME_TVA:
                                    return <ProfesionalTva pack={tva} setIsProfessionalProfilUpdateVisible={setIsProfessionalProfilUpdateVisible} />
                                case process.env.REACT_APP_BOT_NAME_CALENDAR:
                                    return <ProfesionalCalendar />
                                case process.env.REACT_APP_BOT_NAME_VERIFLEGAL:
                                    return <ProfesionalVerifLegal setIsProfessionalProfilUpdateVisible={setIsProfessionalProfilUpdateVisible} />
                                default:
                                    return null;
                            }
                        })()}

                        {/* Joins us if user had no subscription, else we display loader  */}
                        {(() => {
                            if (!hasSubscription && isLoadedSubscription) {
                                return <JoinUs />
                            } else if (!isLoadedSubscription) {
                                return <LoaderSuspense />
                            }
                        })()}

                    </div>
                </div>
                {/* Popup success profile */}
                <Popup
                    text="Votre profil professionnel a bien été modifié"
                    isVisible={isProfessionalProfilUpdateVisible}
                />

            </div>
        </>
    )
}
