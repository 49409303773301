import React from 'react'

export default function Calendar() {
    return (
        <div className="confirmCgu row justify-content-center">
            <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-1 col-lg-10 col-xl-10">

                <h1>Conditions spécifiques liées aux produits - Assistant virtuel de Gestion des rendez-vous</h1>

                <br></br>
                <h3>Définitions</h3>

                <p className="conditionsTerms-text">
                    Les « Informations de contact » sont le nom, l'adresse e-mail, le numéro de téléphone, le(s) nom(s) d'utilisateur(s)
                    et les informations similaires envoyées par les visiteurs sur les pages de destination du Client, dans le Service d'abonnement,
                    ou chargées par le Client dans le Service d'abonnement.
                    <br></br>
                    Le produit « GESTION DE RENDEZ-VOUS » est basé sur un modèle de plans de facturation et engagement annuels ou mensuels et des packs avec
                    différentes options :

                </p>
                <ul className="conditionsTerms-text">
                    <li> Le Pack Starter ;</li>
                    <li> Le Pack Business ;</li>
                    <li> Le Pack Premium ;</li>
                </ul>

                <h3>Frais d'abonnement assistant virtuel Gestion de rendez-vous</h3>

                <p className="conditionsTerms-text">
                    Les Frais d'abonnement aux produit GESTION DE RENDEZ-VOUS demeurent fixes pendant la Période d'abonnement du Client,
                    à moins que ce dernier :
                </p>

                <ul className="conditionsTerms-text">
                    <li> (I) ne mette à niveau les produits ou les forfaits de base, ou</li>
                    <li> (II) ne souscrive à des produits ou fonctionnalités supplémentaires, ou </li>
                    <li> (IV) sauf mention contraire dans le Bon de commande.</li>
                </ul>
                <p className="conditionsTerms-text">

                    Des informations complémentaires sur les autres ajustements possibles de ces frais sont disponibles dans la suite
                    de la section « Ajustements des frais » ci-dessous.
                    <br></br>
                    Les Frais sont perçus au 1er jour du démarrage de l’abonnement quelque soit le type de pack et le type d’engagement.
                </p>


                <h3>Engagement assistant virtuel Gestion de rendez-vous</h3>

                <p className="conditionsTerms-text">
                    L’engagement pour le produit GESTION DE RENDEZ-VOUS se fait au moment de la souscription, au choix:
                </p>

                <ul className="conditionsTerms-text">
                    <li>Engagement Annuel</li>
                    <li>Engagement Mensuel</li>
                </ul>
                <p className="conditionsTerms-text">
                    Si le client s’engage annuellement, cela veut dire que pour 365 jours il bénéficie du service sans augmentation tarifaire.
                    <br></br>
                    Il n’est pas possible de changer d’engagement au cours de la période.
                    <br></br>
                    Toute demande de changement d’engagement (passer d’un Annuel à Mensuel) peut être discutée et validée pendant
                    la durée de souscription.
                    <br></br>
                    Les changements seront appliqués à la date de renouvellement.
                    <br></br>
                    <br></br>
                    Si le client s’engage mensuellement, il bénéficie donc du service sur un mois glissant.
                    <br></br>
                    S’il décide de s’engager annuellement, c’est alors possible, le nouvel engagement sera alors effectif
                    à la date conclue entre le prestataire et le client.
                    <br></br>
                    Si le changement intervient pendant un mois en cours, les frais d’abonnement de l’engagement seront rétrocédés
                    au prorata selon la date de démarrage de l’abonnement annuel.
                </p>


                <h3>Modifications</h3>

                <p className="conditionsTerms-text">
                    Aliz se réserve le droit d'apporter des modifications au produit GESTION DE RENDEZ-VOUS ou aux
                    Outils gratuits susceptibles de réduire considérablement les fonctionnalités fournies au Client durant
                    la Période d'abonnement.
                </p>
            </div>
        </div>
    )
}
