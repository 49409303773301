import React from 'react'
import Step from './Step'
import Simulation from './Simulation'
import Client from './Client'
import Header from './Header'
import Footer from './Footer'
import HeaderLanding from './Header'



export default function Home() {

    return (
        <>
            <HeaderLanding />

            <div className="Sante2_home-view">

                <Header />
                <div className="background-home"></div>
                <div className="row p-0 m-0">

                    <div className="offset-sm-2 col-sm-8 offset-xl-1 col-xl-5">
                        <h1>VOUS MANQUEZ DE TEMPS POUR VOS PATIENTS?</h1>
                        <p className="presentation">
                            Vous rêvez d'en finir avec les charges administratives et de vous concentrer sur l'essence même de votre métier: vos patients?<br></br>
                        </p>
                        <p className="price">
                            Libérez-votre emploi du temps pour seulement <strong> 20€/mois </strong> grâce à notre assistant virtuel.
                        </p>
                    </div>

                    <div id="contact" className="offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-2 col-xl-3">
                        <Client />
                    </div>
                </div>

                <div className="body-view row">
                    <Simulation />
                    <Step />
                    <Footer />
                </div>

            </div>
        </>
    )
}
