import React, { useEffect, useState } from 'react';

// Packages
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import gsap from "gsap";

// Component
import Testimonial from "./Testimonial";

// Constant
import { testimonials } from "../services/Constant/testimonials";

export default function Testimonials({background}) {
    const [count, setCount] = useState(0);

    // The carousel is not displayed without the autoPlay and autoPlaySpeed.
    // Finally, it is only displayed the 1st time after the end of autoPlaySpeed value 
    // (if 1s : autoPlay is too fast to slide, if 10s : too long to display when loading),
    // which I activate once and desactivate it with a counter.
    useEffect(() => {
        if (count === 0) {
            setTimeout(() => {
                gsap.to('.react-multi-carousel-list', { css: { width: "101%" }, duration: 0 });
                setCount(1)
            }, 1000);
        }
    }, [count])


    // Responsive Carousel
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1300 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1300, min: 992 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 992, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div id={`${background ? "bg-light" : ""}`} className="testimonials-view">


            <div className="row justify-content-center">

                <h3 className="col-xl-8">Nos clients témoignent</h3>

                <p className="catch-phrase col-xl-6">
                    Voici les professionnels de santé, les indépendants, les auto-entrepreneurs,
                    les professionnels libéraux, les TPE et PME qui nous font confiance au quotidien
                    pour déléguer leurs charges et tâches administratives.
                </p>

            </div>


            <Carousel
                swipeable={false}
                draggable={true}
                showDots={true}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={count === 0 ? true : false}
                autoPlaySpeed={100}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={[]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                partialVisible={true}
                responsive={responsive}
            >

                {testimonials.map((data, key) => {
                    return (
                        <Testimonial key={key} data={data} />
                    );
                })}
            </Carousel>

        </div>
    )
}