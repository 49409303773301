import React, { useEffect, useState } from 'react'
import { getBotTvaprofile, setBotTvaprofile } from '../../../../services/User/tva.js'

// import gsap from 'gsap'

export default function FormTva({setIsProfessionalProfilUpdateVisible}) {
    const [profile, setProfile] = useState([])
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState('')

    const [socialReason, setSocialReason] = useState('')
    const [siretNumber, setSiretNumber] = useState('')
    const [identifiersImpots, setIdentifiersImpots] = useState('')
    const [passwordImpots, setPasswordImpots] = useState('')
    const [licenseNumber, setLicenseNumber] = useState(0)


    // Collab 
    // const [lastnameCollab, setLastnameCollab] = useState('')
    // const [firstnameCollab, setFirstnameCollab] = useState('')
    // const [emailCollab, setEmailCollab] = useState('')

    useEffect(() => {
        getBotTvaprofile(setProfile)
    }, [])

    useEffect(() => {
        if (profile) {
            setSocialReason(profile.socialReason)
            setSiretNumber(profile.siretNumber)
            setIdentifiersImpots(profile.identifiersImpots)
            setLicenseNumber(profile.licenseNumber)
        }
    }, [profile])

    const data = {
        'socialReason': socialReason,
        'siretNumber': siretNumber,
        'identifiersImpots': identifiersImpots,
        'passwordImpots': passwordImpots,
        'licenseNumber': licenseNumber,
        // 'firstnameCollab': firstnameCollab,
        // 'lastnameCollab': lastnameCollab,
        // 'emailCollab': emailCollab,
    }

    const handleProfile = () => {
        setBotTvaprofile(data, setErrors, setSuccess)
    }


    useEffect(() => {
        if (success === "success") {
            setIsProfessionalProfilUpdateVisible(true)
        }
        // eslint-disable-next-line
    }, [success])

    // const showCollab = () => {
    //     gsap.set('.addCollab svg, .addCollab label', { display: 'none' })
    //     gsap.fromTo('.blockCollab input, .blockCollab label', { autoAlpha: 0, display: 'none' }, { autoAlpha: 1, display: 'block', duration: 0.5 })
    // }

    return (
        <div>
            <div className="col-xl-8 pl-0 mt-5">

                {/* <h4><a href="/tutoriel/google-drive-tva">Comment accéder à mon Google Drive?</a></h4>
                <hr></hr> */}

                <h3>Modifier mes informations professionnelles</h3>

                <div className="d-flex">
                    <div className="col-5">
                        <label htmlFor="socialReason">Raison Sociale</label>
                        <input type="text"
                            name="socialReason"
                            id="socialReason"
                            value={socialReason || ''}
                            minLength="3"
                            maxLength="70"
                            required
                            onChange={(e) => setSocialReason(e.target.value)}
                        />
                        {errors.socialReason && <span className="text-danger">{errors.socialReason}<br></br></span>}
                    </div>
                    <div className="offset-2 col-5">
                        <label htmlFor="siretNumber">N° SIRET</label>
                        <input type="text"
                            name="siretNumber"
                            id="siretNumber"
                            value={siretNumber || ''}
                            minLength="13"
                            maxLength="14"
                            required
                            onChange={(e) => setSiretNumber(e.target.value)}
                        />
                        {errors.siretNumber && <span className="text-danger">{errors.siretNumber}</span>}
                    </div>
                </div>

                <div className="d-flex">
                    <div className="col-5">
                        <label htmlFor="identifiersImpots">Identifiants Impôts.gouv</label>
                        <input type="text"
                            name="identifiersImpots"
                            id="identifiersImpots"
                            value={identifiersImpots || ''}
                            minLength="12"
                            maxLength="13"
                            required
                            onChange={(e) => setIdentifiersImpots(e.target.value)}
                        />
                        {errors.identifiersImpots && <span className="text-danger">{errors.identifiersImpots}<br></br></span>}
                    </div>
                    <div className="offset-2 col-5">
                        <label htmlFor="passwordImpots">Mot de passe Impôts.gouv</label>
                        <input type="text"
                            name="passwordImpots"
                            id="passwordImpots"
                            value={passwordImpots || ''}
                            minLength="3"
                            maxLength="50"
                            required
                            onChange={(e) => setPasswordImpots(e.target.value)}
                        />
                        {errors.passwordImpots && <span className="text-danger">{errors.passwordImpots}</span>}
                    </div>
                </div>

                {/* Add a collab */}
                {/* {pack && pack.match(/remium/) && profile.countCollab < 1 &&
                    <div className="addCollab" onClick={showCollab}>
                        <label htmlFor="" className="text-aliz mt-4">Votre abonnement premium inclus un collaborateur avec 150 crédits/mois </label>
                        <div className="d-flex">
                            <label htmlFor="">Ajouter un collaborateur </label>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                        </div>
                    </div>
                } */}


                {/* <div className="d-flex flex-direction-column blockCollab">
                    <label htmlFor="lastameCollab">Nom de votre collaborateur</label>
                    <input type="text"
                        name="lastnameCollab"
                        id="lastnameCollab"
                        value={lastnameCollab || ''}
                        minLength="2"
                        maxLength="70"
                        required
                        onChange={(e) => setLastnameCollab(e.target.value)}
                    />
                    {errors.lastnameCollab && <span className="text-danger">{errors.lastnameCollab}</span>}
                </div>

                <div className="d-flex flex-direction-column blockCollab">
                    <label htmlFor="firstnameCollab">Prénom de votre collaborateur</label>
                    <input type="text"
                        name="firstnameCollab"
                        id="firstnameCollab"
                        value={firstnameCollab || ''}
                        minLength="2"
                        maxLength="70"
                        required
                        onChange={(e) => setFirstnameCollab(e.target.value)}
                    />
                    {errors.firstnameCollab && <span className="text-danger">{errors.firstnameCollab}</span>}
                </div>

                <div className="d-flex flex-direction-column blockCollab">
                    <label htmlFor="emailCollab">Email de votre collaborateur</label>
                    <input type="text"
                        name="emailCollab"
                        id="emailCollab"
                        value={emailCollab || ''}
                        minLength="5"
                        maxLength="100"
                        required
                        onChange={(e) => setEmailCollab(e.target.value)}
                    />
                    {errors.emailCollab && <span className="text-danger">{errors.emailCollab}</span>}
                </div>
                {errors.countCollab && <span className="text-danger">{errors.countCollab}<br></br></span>}
                {errors.incompleteCollab && <span className="text-danger">{errors.incompleteCollab}<br></br></span>} */}

                <button className="btn-alizOut mb-5" onClick={handleProfile}>Enregistrer</button>
            </div>
        </div>
    )
}
