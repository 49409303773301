import React, { useEffect, useState } from 'react';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import { createSetupIntent, setDefaultPaymentUser } from '../../../../services/User/paymentMethod'

import IbanForm from './IbanForm';

export default function PaymentSetupForm() {
    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState([])
    // eslint-disable-next-line
    const [isLoaded, setIsLoaded] = useState(false)
    const [ResponseDebitSetup, setResponseDebitSetup] = useState([])
    const [paymentMethodId, setPaymentMethodId] = useState("")

    const data = {
        'paymentMethod': paymentMethodId
    }

    const type = {
        'type' : 'sepa'
    }

    useEffect(() => {
        createSetupIntent(setClientSecret, setIsLoaded, type)
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        const iban = elements.getElement(IbanElement);

        const accountholderName = event.target['accountholder-name'];
        const email = event.target.email;

        const result = await stripe.confirmSepaDebitSetup(clientSecret, {
            payment_method: {
                sepa_debit: iban,
                billing_details: {
                    name: accountholderName.value,
                    email: email.value,
                },
            }
        });

        if (result.error) {
            // Show error to your customer.
            console.log(result.error.message);
        } else {
            // console.log(result)
            setPaymentMethodId(result.setupIntent.payment_method)
            
            setDefaultPaymentUser(data, setResponseDebitSetup)
            // Show a confirmation message to your customer.
            // The SetupIntent is in the 'succeeded' state.
        }
    };

    useEffect(() => {
        // console.log(ResponseDebitSetup)
    }, [ResponseDebitSetup])
    return (
        <IbanForm onSubmit={handleSubmit} disabled={!stripe} />
    );
}