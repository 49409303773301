import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// Images
import Illustration from '../../images/Illustration - Bannière.svg'
import Logo from '../../images/logo/Logo Couleur_Sans texte.png'
import LogoWebp from '../../images/logo/Logo Couleur_Sans texte.webp'

// Services
import { newEmailFooter } from '../../services/User/clientArea'



export default function Footer({ token }) {
    const [email, setEmail] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [phone, setPhone] = useState("")

    const [response, setResponse] = useState("")
    const [errors, setErrors] = useState([])

    const data = {
        "email": email,
        "firstname": firstname,
        "lastname": lastname,
        "phone": phone
    }

    const subscrib = () => {
        newEmailFooter(data, setResponse, setErrors)
    }


    useEffect(() => {
        if (response === "success") {
            setResponse('');
            window.location.href = "/?contactus=success"
        }
        // eslint-disable-next-line
    }, [response]);

    const faqWordpress = process.env.REACT_APP_LINK_WORDPRESS_FAQ


    return (
        <div className="footer-view">

            {/* ----- Card free demo ----- */}
            <div className="footer-card row justify-content-center">
                <div className="col-12 demo-card">
                    <div className="row max-lg-flex-direction-column-reverse">

                        <div className="col-xl-7 demo-body flex-direction-column">

                            <p className="demo-title">Vous souhaitez découvrir gratuitement la solution Aliz ?</p>

                            <p className="demo-text">Programmez un rendez-vous de 30 minutes avec notre équipe.</p>

                            <div className="demo-form">
                                <div className="input-container">
                                    <input placeholder="Nom" type="text" id="lastname" onChange={(e) => setLastname(e.target.value)} />
                                    <input placeholder="Prénom" type="text" id="firstname" onChange={(e) => setFirstname(e.target.value)} />
                                </div>

                                <div className="input-container">
                                    <input placeholder="Téléphone (facultatif)" type="tel" id="phone" onChange={(e) => setPhone(e.target.value)} />
                                    <input placeholder="Email" type="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                                </div>

                                <div>
                                    <button onClick={subscrib}>Demander une démo</button>
                                </div>
                            </div>

                            {errors.email && <span className="text-danger mt-2 mb-0 d-block">{errors.email}</span>}
                            {errors.firstname && <span className="text-danger mt-2 mb-0 d-block">{errors.firstname}</span>}
                            {errors.lastname && <span className="text-danger mt-2 mb-0 d-block">{errors.lastname}</span>}

                        </div>

                        <img
                            alt={"Personnage souriant tenant une tablette, entouré d'objets"}
                            src={Illustration} // use normal <img> attributes as props
                            className="illustration col-xl-4"
                        />

                    </div>
                </div>
            </div>
            {/* ----- End Card free demo ----- */}




            {/* ----- All links of website ----- */}
            <div className="footer-body row py-5">

                <div className="col-6 col-lg-4 col-xl-2">


                    <div className="logo-footer d-flex">
                        <picture>
                            <source srcSet={LogoWebp} type="image/webp" />
                            <img className="logo-footer" src={Logo} alt="Logo Aliz" />
                        </picture>
                        <p>Aliz</p>
                    </div>


                    <div className="d-flex">

                        <div>
                            <a href={process.env.REACT_APP_LINKEDIN_CHANNEL} target="_blank" rel="noopener noreferrer">
                                <svg className="bi bi-linkedin" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                </svg>
                            </a>
                        </div>

                        <div className="logo-socialmedia">
                            <a href={process.env.REACT_APP_YOUTUBE_CHANNEL} target="_blank" rel="noopener noreferrer">
                                <svg className=" bi bi-youtube" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.122C.002 7.343.01 6.6.064 5.78l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                                </svg>
                            </a>
                        </div>

                        <div className="logo-socialmedia">
                            <a href={process.env.REACT_APP_INSTAGRAM_CHANNEL} target="_blank" rel="noopener noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                </svg>
                            </a>
                        </div>

                        <div className="logo-socialmedia">
                            <a href={process.env.REACT_APP_FACEBOOK_CHANNEL} target="_blank" rel="noopener noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>

                <ul className="col-6 col-lg-4 col-xl-2">
                    <li><Link to="/">Accueil</Link></li>
                    <li><Link to="/#video">Comment ça marche?</Link></li>
                    <li><a href={faqWordpress} target="_blank" rel="noopener noreferrer">FAQ</a></li>
                    <li><Link to="/avis-clients">Nos clients témoignent</Link></li>
                    <li><Link to="/tarifs">Tarifs</Link></li>
                    {(() => {
                        if (!token) {
                            return <li><Link to="/login">Se connecter</Link></li>
                        }
                    })()}
                </ul>



                <ul className="col-6 col-sm-6 col-lg-4 col-xl-2">
                    <li><p>Nos assistants</p></li>
                    <li><Link to="/nos-solutions/AlizPatientele">{process.env.REACT_APP_BOT_NAME_PATIENTELE}</Link></li>
                    <li><Link to="/nos-solutions/AlizCalendar">{process.env.REACT_APP_BOT_NAME_CALENDAR}</Link></li>
                    <li><Link to="/nos-solutions/AlizVerif">{process.env.REACT_APP_BOT_NAME_VERIFLEGAL}</Link></li>
                    <li><Link to="/nos-solutions/AlizTva">{process.env.REACT_APP_BOT_NAME_TVA}</Link></li>
                </ul>



                <ul className="col-6 col-sm-6 col-lg-4 col-xl-2">
                    <li><p>Ressources</p></li>
                    <li><Link to="/integrations">Intégrations</Link></li>
                    <li><Link to="/nos-partenaires">Partenaires</Link></li>
                    <li><a href="https://blog.aliz.io/" target="_blank" rel="noopener noreferrer">Blog</a></li>
                </ul>

                <ul className="col-6 col-sm-6 col-lg-4 col-xl-2">
                    {/* ----- <li><Link to="/">Contact</Link></li> ----- */}
                </ul>
            </div>
            {/* ----- End links ----- */}


            <hr></hr>


            {/* ----- Terms agree & legal notices ----- */}
            <ul className="row my-4">
                <li className="col-6 col-lg-4 col-xl-2 d-flex">Aliz</li>
                <li className="col-6 col-lg-4 col-xl-2"><Link to="/conditions-generales/cgu">Conditions d'utilisation</Link></li>
                <li className="col-6 col-lg-4 col-xl-2"><Link to="/conditions-generales/politique-de-confidentialite">Politique de confidentialité</Link></li>
                <li className="col-6 col-lg-4 col-xl-2"><Link to="/conditions-generales/conditions-specifiques-liees-aux-produits">Conditions spécifiques aux produits</Link></li>
                <li className="col-6 col-lg-4 col-xl-2"><Link to="/conditions-generales/mentions-legales">Mentions légales</Link></li>
                <li className="col-6 col-lg-4 col-xl-2"><Link to="/conditions-generales/politique-utilisation-des-cookies">Utilisation des cookies</Link></li>
                <li className="col-6 col-lg-4 offset-xl-2 col-xl-2"><Link to="/conditions-generales/conditions-utilisation-des-services">Conditions d’Utilisation des Services</Link></li>
                <li className="col-6 col-lg-4 col-xl-2"><Link to="/conditions-generales/cgv">Conditions de ventes</Link></li>
            </ul>
            {/* ----- End Terms agree & legal notices ----- */}

        </div>
    )
}
