import React, { useState } from 'react';
import { IbanElement } from '@stripe/react-stripe-js';

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: 'FR',
  style: IBAN_STYLE,
};

export default function IbanForm({ onSubmit, disabled }) {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [IBAN, setIBAN] = useState("")
  const [isDefaultPayment, setIsDefaultPayment] = useState(false)

  return (
    <div className="userArea-view">
      <div className="row">

        <form onSubmit={onSubmit} className="offset-1 col-10  offset-xl-2 col-xl-8">
          <div className="form-row inline">
            <div className="col">
              <label>
                Nom
                <input
                  name="accountholder-name"
                  placeholder="Jenny Rosen"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
            </div>

            <div className="col">
              <label>
                Adresse Email
                <input
                  name="email"
                  type="email"
                  placeholder="jenny.rosen@example.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
            </div>
          </div>

          <div className="form-row">
            <label className="col">
              IBAN
              <IbanElement options={IBAN_ELEMENT_OPTIONS}
                value={IBAN}
                onChange={(e) => setIBAN(e)} />
            </label>
          </div>

          <div className="form-row">
            <label className="switch col">
              <input type="checkbox" id="isDefaultPayment" value={isDefaultPayment} name="isDefaultPayment" onChange={(e) => setIsDefaultPayment(e.target.checked)} />
              <span className="slider round"></span>
            </label>

            <div className="col-8 col-md-10 agreeText">
              <label htmlFor="isDefaultPayment">Moyen de paiement par défaut</label>
            </div>
          </div>

          <button className="btn-alizFull" type="submit" disabled={disabled}>
            Set up SEPA Direct Debit
          </button>


          {/* Display mandate acceptance text. */}
          <div className="mandate-acceptance col-xl-10">
            En fournissant vos informations de paiement et en confirmant ce paiement,
            vous autorisez (A) Aliz et Stripe, notre service de paiement
            fournisseur, d'envoyer des instructions à votre banque pour débiter votre compte et
            (B) votre banque à débiter votre compte conformément à ces
            instructions. Dans le cadre de vos droits, vous avez droit à un remboursement
            de votre banque selon les termes et conditions de votre accord avec
            votre banque. Un remboursement doit être réclamé dans les 8 semaines à compter du
            date à laquelle votre compte a été débité. Vos droits sont expliqués dans
            un relevé que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des
            notifications pour les débits futurs jusqu'à 2 jours avant qu'ils ne surviennent.
          </div>
        </form>
      </div>
    </div>
  );
};