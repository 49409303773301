import React from 'react'

export default function JoinUs() {
    return (
        <div className="row">
            <div className="col-xl-12">
                <h3>Oops, c'est bien vide ici!<br></br>il semblerait que vous n'ayez souscrit à aucune offre</h3>
                <div className="ourOffers">
                    <h4>Venez découvrir nos assistants et commencez à déléguer dès à présent vos tâches chronophrages:</h4>
                    <div className="block-btn">
                        <button type="button" className="btn-pack active" onClick={() => window.location.href = "/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_PATIENTELE + "&pack-annuel=true"}>{process.env.REACT_APP_BOT_NAME_PATIENTELE}</button>
                        <button type="button" className="btn-pack active" onClick={() => window.location.href = "/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_TVA + "&pack-annuel=true"}>{process.env.REACT_APP_BOT_NAME_TVA}</button>
                        <button type="button" className="btn-pack active" onClick={() => window.location.href = "/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_CALENDAR + "&pack-annuel=true"}>{process.env.REACT_APP_BOT_NAME_CALENDAR}</button>
                        <button type="button" className="btn-pack active" onClick={() => window.location.href = "/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_VERIFLEGAL + "&pack-annuel=true"}>{process.env.REACT_APP_BOT_NAME_VERIFLEGAL}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
