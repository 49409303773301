import React from 'react';


// ----- Packages -----
import { Helmet } from 'react-helmet-async';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// ----- Components -----
import PaymentSetupForm from './PaymentSetupForm';

const stripePromise = loadStripe(
  'pk_test_51IHQShKFnW3wuUUc2wszXQ5SzrmlW8qHvHEwPoPXaPm8e8IHoAy4YOjNJkSNb19Chxn6FOnev3EtCVtN6WV0KFvU004MshAjXQ'
);

export default function SEPAProvider() {
  return (
    <>
      <Helmet title="Espace client - Ajouter Moyens de paiements" />

      <Elements stripe={stripePromise} >
        <PaymentSetupForm />
      </Elements>
    </>
  );
};