import React, { useEffect, useState, useRef } from 'react'
import { askReview } from '../../../services/User/subscription'
import Review from './Review'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function GoogleReview() {
    const [count, setCount] = useState(0);
    const modalRef = useRef();
    const [responseGoogleReview, setResponseGoogleReview] = useState([])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        askReview(setResponseGoogleReview)
    }, [])

    useEffect(() => {
        if (responseGoogleReview.response) {
            handleShow()
        }
    }, [responseGoogleReview])

    // The carousel is not displayed without the autoPlay and autoPlaySpeed.
    // Finally, it is only displayed the 1st time after the end of autoPlaySpeed value 
    // (if 1s : autoPlay is too fast to slide, if 10s : too long to display when loading),
    // which I activate once and desactivate it with a counter.
    useEffect(() => {
        if (count === 0) {
            setTimeout(() => {
                // gsap.to('.react-multi-carousel-list', { css: { width: "101%" }, duration: 0 });
                setCount(1)
            }, 1000);
        }
    }, [count])

    // Responsive Carousel
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1300 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1300, min: 992 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 992, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    // console.log(responseGoogleReview)
    return (
        <div>
            <Modal id="reviewModal" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered size="xl" ref={modalRef}>
                <Modal.Header>
                    <Modal.Title>Votre avis est précieux!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p id="leave-review">Tout comme Ambre, Sophie ou Bernard, n'hésitez pas à laisser votre avis Google.<br></br> Vos commentaires servent à faire évoluer Aliz, et vos étoiles à nous faire briller un peu plus!</p>
                    <Carousel
                        swipeable={false}
                        draggable={true}
                        showDots={true}
                        ssr={false} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={count === 0 ? true : false}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        partialVisible={true}
                        responsive={responsive}
                    >

                        {responseGoogleReview.reviews && responseGoogleReview.reviews.map((review, key) =>
                            <Review review={review} key={key} />
                        )}
                    </Carousel>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => window.open(
                        "https://g.page/aliz-assistant/review?gm",
                        '_blank' // <- This is what makes it open in a new window.
                    )}>Laisser un avis Google</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
