import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import gsap from "gsap"

import LogoWhite from "../../images/logo/Logo Blanc_Sans Texte.png"
import LogoBlue from "../../images/logo/Logo Couleur_Sans texte.png"
import Arrow from "../../images/Header/Arrow - Down.svg"

import GoogleDriveLogo from "../../images/Header/Gdrive ico@2x.png"
import GoogleDriveLogoWebp from "../../images/Header/Gdrive ico@2x.webp"
import OneDrive from "../../images/Header/oneDrive.png"
import OneDriveWebp from "../../images/Header/oneDrive.webp"
import Outlook from "../../images/Header/outlook.png"
import OutlookWebp from "../../images/Header/outlook.webp"
import Gmail from "../../images/Header/gmail.png"
import GmailWebp from "../../images/Header/gmail.webp"
import GoogleAgenda from "../../images/Header/googleAgenda.png"
import GoogleAgendaWebp from "../../images/Header/googleAgenda.webp"


import StatsLogo from "../../images/Header/stats.svg"
import PlannerLogo from "../../images/Header/planner.svg"
import SearchClientLogo from "../../images/Header/search-client.svg"
import BookLogo from "../../images/Header/book.svg"

export default function HeaderTablet({ token }) {
    // If false > true > open, if true > false > close
    const [toggled, setToggled] = useState(false);
    const [nav, setNav] = useState(false);
    const [logo, setLogo] = useState({})

    const [location, setLocation] = useState('')
    const [match, setMatch] = useState(false)

    const [disconnectedUser, setDisconnectedUser] = useState(false)

    // Header text & logo color changes depending on the url
    useEffect(() => {
        setLocation(window.location.pathname)
        if (location.match(/FAQ/i) || location === "/") {
            gsap.to('.btn-toggle, .navbar-brand span', { css: { color: "white" }, duration: 0 });
            setLogo(LogoWhite);
            setMatch(true)
        } else {
            gsap.to('.btn-toggle', { css: { color: "black" }, duration: 0 });
            setLogo(LogoBlue);
            setMatch(false)
        }
    }, [location]);


    // Show or hide dropdown menu ressources
    const toggledMenu = (e) => {
        const idToggled = e.currentTarget.id.substring(0, 10);
        const linkToggled = e.currentTarget.id;

        setToggled(toggled === false ? true : false)
        if (toggled === false) {
            gsap.fromTo('#' + idToggled + 'Dropdown', { display: 'none', opacity: 0 }, { display: 'block', opacity: 1, duration: 1 });
            gsap.to('#' + linkToggled + ' img', { transform: 'rotate(180deg)', duration: 0 });

        } else {
            gsap.to('#assistantsDropdown, #ressourcesDropdown, #integratioDropdown', { display: 'none', opacity: 0, duration: 0.5 });
            gsap.to('#' + linkToggled + ' img', { transform: 'rotate(0deg)', duration: 0 });

        }
    };

    const toggledNavbar = (e) => {
        e.preventDefault();
        setNav(!nav ? true : false)
        if (!nav) {
            gsap.to('.headerTablet-view nav', { backgroundColor: 'white', boxShadow: '0 0 10px 10px #5887FF1F', duration: 0.8 });
            if (match) {
                setLogo(LogoBlue);
                gsap.to('.btn-toggle', { color: 'black', duration: 0.5 });
                gsap.to('.navbar-brand span', { color: '#5A8AFF', duration: 0.5 });
            }
        } else {
            gsap.to('.headerTablet-view nav', { backgroundColor: 'transparent', boxShadow: 'unset', duration: 0.8 });
            if (match) {
                gsap.to('.btn-toggle, .navbar-brand span', { color: 'white', duration: 0.5 });
                setLogo(LogoWhite);
            }
        }
    }

    useEffect(() => {
        if (!nav) {
            gsap.to('#navbarNavDropdown', { display: 'none', opacity: 0, duration: 0.8 });
        } else {
            gsap.to('#navbarNavDropdown', { display: 'block', opacity: 1, duration: 0.8 });
        }
    }, [nav])


    // Log off user 
    const disconnected = () => {
        if (localStorage.getItem('tokenAliz')) {
            setDisconnectedUser(true)
        }
    }
    useEffect(() => {
        if (disconnectedUser) {
            localStorage.removeItem('tokenAliz');
            window.location.href = "/";
        }
    }, [disconnectedUser]);

    const faqWordpress = process.env.REACT_APP_LINK_WORDPRESS_FAQ

    return (
        <header className="headerTablet-view">

            <nav className="navbar navbar-expand-xl">

                {/* Logo */}
                <Link className="navbar-brand" to="/"><img src={logo} alt="Aliz Logo" /><span>Aliz</span></Link>

                {/* Button toggled responsive */}
                <button onClick={toggledNavbar} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <svg className="btn-toggle bi bi-filter-right" xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z" />
                    </svg>
                </button>

                {/* This block is responsive  */}
                <div className="collapse navbar-collapse" id="navbarNavDropdown">

                    {/* Nav left */}
                    <ul className="navbar-nav mr-auto">

                        <div className="dropdown-divider"></div>

                        {/* Onclick show/hide dropdown menu assistants*/}
                        <li className="nav-item d-flex" id="assistantsToggled" onClick={toggledMenu}>
                            <p>Nos assistants</p>
                            <img src={Arrow} alt="arrow down" />
                        </li>

                        {/* Menu dropdown assistants */}
                        <li id="assistantsDropdown">
                            <Link to="/nos-solutions/AlizPatientele" className="col-10 col-sm-8 col-md-6 d-flex">
                                <img className="logo-md" src={StatsLogo} alt="Chart statistic" />
                                <div className="body-text">
                                    <p className="dropdown-text">{process.env.REACT_APP_BOT_NAME_PATIENTELE}</p>
                                    <p className="light-text">De la prise de rendez-vous à la facturation, en passant par l’envoi de mails et la compta, tout y est !</p>
                                </div>
                            </Link>

                            <Link to="/nos-solutions/AlizCalendar" className="col-10 col-sm-8 col-md-6 d-flex ">
                                <img className="logo-md" src={PlannerLogo} alt="Calendar blue" />
                                <div className="body-text">
                                    <p className="dropdown-text">{process.env.REACT_APP_BOT_NAME_CALENDAR}</p>
                                    <p className="light-text">On gère vos agendas, et en plus on notifie vos invités.</p>
                                </div>
                            </Link>

                            <Link to="/nos-solutions/AlizVerif" className="col-10 col-sm-8 col-md-6 d-flex ">
                                <img className="logo-md" src={SearchClientLogo} alt="character with magnifying glass " />
                                <div className="body-text">
                                    <p className="dropdown-text">{process.env.REACT_APP_BOT_NAME_VERIFLEGAL}</p>
                                    <p className="light-text">On collecte et on vérifie pour vous toutes les données juridiques de vos prospects, clients, fournisseurs...</p>
                                </div>
                            </Link>

                            <Link to="/nos-solutions/AlizTva" className="col-10 col-sm-8 col-md-6 d-flex ">
                                <img className="logo-md" src={BookLogo} alt="Green account book" />
                                <div className="body-text">
                                    <p className="dropdown-text">{process.env.REACT_APP_BOT_NAME_TVA}</p>
                                    <p className="light-text"> Finissez-en avec la TVA, déléguez et contrôlez chaque mois la déclaration et les paiements sur impots.gouv</p>
                                </div>
                            </Link>
                        </li>
                        {/* End dropdown assistants */}

                        <div className="dropdown-divider" id="assistant-hr"></div>



                        <li className="nav-item d-flex" id="integratioToggled" onClick={toggledMenu}>
                            <p> Intégrations </p>
                            <img src={Arrow} alt="arrow down" />
                        </li>


                        {/* Menu dropdown ressources */}
                        <div id="integratioDropdown">

                            <Link to="/integrations" className="integration-link">
                                <picture>
                                    <source srcSet={GoogleAgendaWebp} type="image/webp" />
                                    <img className="logo-md" src={GoogleAgenda} alt="Logo Google" />
                                </picture>
                                <p className="dropdown-text">Google Drive</p>
                            </Link>

                            <Link to="/integrations" className="integration-link">
                                <picture>
                                    <source srcSet={OneDriveWebp} type="image/webp" />
                                    <img className="logo-md" src={OneDrive} alt="Logo Google" />
                                </picture>
                                <p className="dropdown-text" >One Drive</p>
                            </Link>

                            <Link to="/integrations" className="integration-link">
                                <picture>
                                    <source srcSet={OutlookWebp} type="image/webp" />
                                    <img className="logo-md" src={Outlook} alt="Logo Google" />
                                </picture>
                                <p className="dropdown-text">Outlook</p>
                            </Link>

                            <Link to="/integrations" className="integration-link">
                                <picture>
                                    <source srcSet={GmailWebp} type="image/webp" />
                                    <img className="logo-md" src={Gmail} alt="Logo Google" />
                                </picture>
                                <p className="dropdown-text">Gmail</p>
                            </Link>

                            <Link to="/integrations" className="integration-link">
                                <picture>
                                    <source srcSet={GoogleDriveLogoWebp} type="image/webp" />
                                    <img className="logo-md" src={GoogleDriveLogo} alt="Logo Google" />
                                </picture>
                                <p className="dropdown-text">Google agenda</p>
                            </Link>

                            <div className="my-5 ml-2 border-lightgrey col-6 col-sm-4 col-md-3">
                                <Link to="/integrations">Tous les connecteurs</Link>
                            </div>

                        </div>

                        <div className="dropdown-divider"></div>


                        <li className="nav-item">
                            <Link to="/avis-clients">Avis clients</Link>
                        </li>

                        <div className="dropdown-divider"></div>

                        <li className="nav-item">
                            <Link to="/tarifs">Tarifs</Link>
                        </li>

                        <div className="dropdown-divider"></div>



                        {/* Onclick show/hide dropdown menu ressources */}
                        <li className="nav-item d-flex" id="ressourcesToggled" onClick={toggledMenu}>
                            <p> Ressources </p>
                            <img src={Arrow} alt="arrow down" />
                        </li>

                        {/* Menu dropdown ressources */}
                        <div id="ressourcesDropdown">
                            {/* FAQ section */}
                            <a className="faq-link" href={faqWordpress} target="_blank" rel="noopener noreferrer"> FAQ</a>

                            {/* Blog section */}
                            <a className="faq-link mb-4" href={process.env.REACT_APP_LINK_BLOG} target="_blank" rel="noopener noreferrer"> Le blog</a>
                        </div>
                        {/* End dropdown ressources */}
                    </ul>
                    {/* End nav left */}



                    {/* Nav right */}
                    {(() => {
                        if (!token) {
                            return <ul className="navbar-nav nav2">

                                <div className="dropdown-divider"></div>

                                <li className="nav-item">
                                    <Link to="/login">Connexion</Link>
                                </li>

                                <div className="dropdown-divider"></div>

                                <li className="nav-item row">
                                    <Link className="col-10 col-sm-4 col-lg-3 btn-yellow" to="/register">S'inscrire</Link>
                                </li>
                            </ul>
                        } else {
                            return <ul className="navbar-nav nav2">
                                <div className="dropdown-divider"></div>
                                <li className="nav-item" onClick={disconnected}> <button className="btn-yellow">Déconnexion</button></li>
                            </ul>
                        }
                    })()}
                    {/* End nav right */}

                </div>

            </nav>
        </header>
    )
}