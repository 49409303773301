import React from 'react'
import { Link } from 'react-router-dom'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Components -----
import Navbar from './NavTerms'

export default function Cgv() {
    return (
        <>
            <Helmet title="CGV" />

            <div className="conditionsTerms container-fluid">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">

                        <h1>CONDITIONS GÉNÉRALES DE VENTE DES SERVICES ALIZ</h1>

                        <h2 className="catch-phrase">Mise à jour : le 09/07/2021</h2>


                        <h2 className="catch-phrase">1. PRÉAMBULE</h2>

                        <p className="conditionsTerms-text">
                            La Société TED (ci-après « <b>TED</b> » ou « <b>le Prestataire</b> ») édite et gère les services
                            d’assistants virtuels ALIZ, désignant l’ensemble des moyens logiciels et matériels
                            (outil en ligne de fourniture de service d’assistance pour des tâches administratives comme
                            l’envoi d’e-mail, la facturation, la création de dossiers, la prise de rendez-vous)
                            (ci-après « <b>Services</b> » ou « <b>Services ALIZ</b> »)
                            (voir <Link to="/conditions-generales/mentions-legales">Mentions légales</Link>).
                            <br></br>
                            Les Services ALIZ ont été choisis par le professionnel identifié lors de la création de son compte sur
                            les sites internet ALIZ (ci-après « <b>les Sites</b> »).
                            Le professionnel a pu évaluer la pertinence des Services et son adéquation à ses besoins et reconnaît avoir été
                            pleinement informé des conditions et caractéristiques des Services.
                        </p>




                        <h2 className="catch-phrase">2. OBJET</h2>

                        <p className="conditionsTerms-text">
                            Les conditions générales de vente décrites (ci-après « <b>les CGV</b> »)
                            s’appliquent à tout professionnel titulaire d’un compte ADELI et âgé de plus de 18 ans,
                            souhaitant utiliser les Services ALIZ dans le cadre de son activité professionnelle (ci-après « <b>le Client</b>»),
                            conformément aux articles L441-1 et L441-2 du code du commerce.
                            <br></br>
                            Ces CGV détaillent les droits et obligations du Prestataire et du Client (« <b>les Parties</b> »)
                            quant à la conclusion du Contrat de Prestation de Service (« <b>le Contrat</b> ») comprenant :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>Les présentes CGV ;</li>
                            <li>Les Conditions d’Utilisation des Services d’ALIZ (ci-après « <b>CUSA</b> »). </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            En recourant aux Services ALIZ, le Client accepte les présentes CGV,
                            applicables à compter de la validation de la Commande par ALIZ.
                            <br></br>
                            Le Contrat annule et remplace tous les accords écrits et verbaux, remis ou échangés entre les Parties antérieurement à sa signature.
                            <br></br>
                            Le présent Contrat est conclu à compter de la Commande. Pendant la phase de test,
                            TED effectue une vérification des données du Client (compte ADELI, identité…)
                            et pourra mettre fin au Contrat dans le cas où les informations fournies sont incorrectes.
                            Dans ce cas, le Client ne sera pas facturé pour les Services.
                            <br></br>
                            En cas de modification, les nouvelles CGV seront portées à la connaissance du Client.
                            Les CGV applicables au Client sont les CGV applicables au moment de la conclusion du Contrat,
                            sauf si le Client accepte de nouvelles CGV.
                        </p>


                        <h3>Définitions</h3>

                        <p className="conditionsTerms-text">
                            <b>Abonnement :</b> désigne la souscription du Client aux Services ALIZ.
                            <br></br>
                            <b>Période d’abonnement :</b> désigne la période de souscription aux Services ALIZ.
                            <br></br>
                            <b>Commande :</b> Désigne la Commande créée et validée par le Client pour l'achat
                            d'un des Services ALIZ, matérialisée par un formulaire sur les Sites et réglée
                            via le processus de paiement en ligne sur les Sites ALIZ. Elle contient les détails
                            afférents à l'achat, comme les conditions de l'abonnement, le ou les
                            Services achetés et les frais applicables.
                            Le Client peut consulter son ou ses factures relatives aux Commandes dans la section
                            «Mes factures» de son espace personnel sur le Site ALIZ.
                            <br></br>
                            <b>Crédit :</b> pour chaque Service, en fonction de son Abonnement,
                            le Client dispose d’un certain nombre de crédits de consommation. Ces crédits peuvent être
                            différents selon le Service souscrit et son usage (un crédit peut valoir facture, un rendez-vous,
                            une déclaration TVA, etc.). Si le Client dépasse le nombre de Crédits maximum au regard de son Abonnement,
                            une Facture dédiée aux Crédits est automatiquement envoyée au Client à l’issue de la Période de facturation.
                            <br></br>
                            <b>Informations Confidentielles :</b> informations techniques concernant le Service,
                            l’existence du Contrat et le contenu de ses stipulations, les informations financières et commerciales,
                            ainsi que les documents contractuels échangés par les Parties.
                            <br></br>
                            <b>Force Majeure :</b> conformément à l’article 1218 du Code civil. La Force Majeure inclut
                            les événements répondant aux critères habituellement reconnus par la jurisprudence des tribunaux français.
                            <br></br>
                            <b>Sites :</b> désigne les sites web https://www.aliz.io et calendar.aliz.io.
                            <b>Période de facturation :</b> période pour laquelle le Client accepte de prépayer les frais détaillés
                            dans la Commande. Par exemple, si le Client souscrit au Service d'abonnement pour une Période d'abonnement
                            d'un (1) an avec un paiement de douze (12) mois d'avance, la Période de facturation sera de douze (12) mois.
                            <br></br>
                            <b>Outils gratuits.</b> Services fournis à titre gratuit par le Prestataire aux Clients.
                        </p>







                        <h2 className="catch-phrase">3. MODALITÉS DE SOUSCRIPTION AUX SERVICES ALIZ</h2>

                        <p className="conditionsTerms-text">
                            Le Client peut souscrire aux Services ALIZ directement sur le Site, après avoir préalablement
                            créé un compte en ligne selon la procédure indiquée sur le Site et renseigné ses coordonnées,
                            choisi l’offre applicable, la durée du Service ALIZ ainsi qu’après acceptation des CGV et des CUSA.
                            <br></br>
                            En souscrivant aux Services ALIZ, le Client aura accès aux Services décrits dans les Conditions d’Utilisation
                            des Services ALIZ comme prévu dans la Commande.
                            <br></br>
                            TED peut également donner accès aux Clients à ses Outils gratuits à tout moment en les activant
                            dans le compte ALIZ du Client.
                        </p>







                        <h2 className="catch-phrase">4. LE PRIX</h2>

                        <p className="conditionsTerms-text">
                            <b>Abonnement :</b> Chaque Client est tenu de payer le prix dû pour l’utilisation des Services ALIZ,
                            tel que précisé dans les Conditions d’Utilisation des Services ALIZ.
                            Le prix à payer par le Client est inscrit dans la Commande.
                            <br></br>
                            <b>Essai gratuit :</b> Il est possible pour un Client d’essayer à titre gratuit les Services ALIZ.
                            Le nombre d’essais gratuit est limité à un (1) pour chaque Client au regard de son identité et
                            de ses coordonnées. En cas de fraude identifiée, TED se réserve le droit de facturer
                            l’essai illégitime au Client.
                            <br></br>
                            L’essai n’implique pas une obligation pour le Client de prendre un abonnement.
                            <br></br>
                            Si le Client s'inscrit pour une période d'essai gratuite telle qu’indiquée sur les Sites,
                            TED met gracieusement à sa disposition le Service ALIZ applicable jusqu'à la première des dates suivantes :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>la fin de ladite période d'essai (sauf si elle est résiliée avant) ; ou</li>
                            <li>la date de début de son abonnement payant. </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            À moins de souscrire un Abonnement avant la fin de l'essai gratuit, toutes les données du Client enregistrées
                            pourront être définitivement supprimées à la fin de la période d’essai, sans qu'il soit possible de les récupérer.
                            Si TED inclut des conditions supplémentaires sur la page internet de l'inscription à l'essai, celles-ci s'appliqueront
                            également.


                            <b>Frais d’abonnement :</b> Les frais d’abonnement applicables sont ceux figurant dans la Commande,
                            tels qu’affichés sur le Site au moment de la commande par le Client.
                            Toutefois, des frais supplémentaires peuvent s’appliquer lorsque :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>
                                I. Le Client dépasse le nombre de Crédits maximum au regard de son Abonnement,
                                la limite d'envois d'e-mails, les limites en termes d’utilisateurs ou toute autre limite applicable.
                                Le Client se verra facturer le surplus selon les conditions applicables à chaque Service ;
                            </li>
                            <li>
                                II. Le Client met à niveau les Services ou les packages de base, dans ce cas, les nouveaux prix
                                pourront être applicables ;
                            </li>
                            <li>III. Le Client souscrit à des Services supplémentaires. </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            TED se laisse la possibilité de réduire les Frais d’abonnement d'un Client après lui
                            avoir envoyé un avis écrit.
                            <br></br>
                            <b>Augmentation du prix.</b> Au renouvellement du Contrat, TED peut choisir d’augmenter les frais
                            appliqués au Client.
                            <br></br>
                            Le cas échéant, le Client en sera informé par écrit minimum trente (30) jours avant la date de
                            renouvellement du Contrat. Les frais majorés seront appliqués à compter de la date de
                            renouvellement du Contrat.
                            <br></br>
                            En cas de désaccord, le Client peut procéder à la résiliation du Contrat dans les formes prévues à
                            l’article 10 « Résiliation ».
                            <br></br>
                            <b>Modalités de paiement du prix.</b>
                            <br></br>
                            Le paiement des Services par le Client s’effectue via la plateforme sécurisée du Prestataire
                            de Services de Paiement (Stripe Connect), permettant l’encaissement du prix des Services.
                            À l’étape du paiement de la Commande, le Client sera redirigé automatiquement vers la marketplace
                            de transactions du Prestataire de Services de Paiement.
                            La saisie des informations bancaires se fait via un serveur sécurisé permettant de garantir la
                            sécurité et la confidentialité des informations fournies par le Client lors de la transaction bancaire.
                            <br></br>
                            Le Client peut procéder au paiement des Services par carte bancaire, le Client doit être titulaire
                            de la carte bancaire.
                            <br></br>
                            En payant par carte bancaire, le Client autorise TED à :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>
                                I. À débiter sa carte ou son compte bancaire pour les Frais d’abonnement et les éventuels frais
                                supplémentaires mentionnés ci-dessus.
                            </li>
                            <li>
                                II. Autorise également TED à recourir aux services d'un tiers pour le traitement des paiements
                                et à transmettre à ce dernier ses informations de paiement.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            Le Client s’engage à maintenir à jour ses coordonnées bancaires et les informations
                            de facturation dans la rubrique « Mes moyens de paiement » afin de prévenir tout échec du paiement
                            lors de la reconduction tacite de l’abonnement.
                            Tous les prix et frais doivent être réglés en amont de l’utilisation des Services par le Client,
                            sauf lorsque les Crédits sont dépassés, lesquels seront facturés à la fin de la Période de facturation.
                            <br></br>
                            Les prix et frais supplémentaires prélevés ne feront l’objet d’aucun remboursement.
                            <br></br>
                            Dans tous les cas, lorsque le paiement est valide, une facture TTC est directement envoyée
                            au Client et mise à sa disposition à partir de son compte ALIZ.
                            <br></br>
                            <b>Taxes.</b> L’ensemble des frais sont affichés toutes taxes comprises (« TTC »).
                            TED facture au Client le montant hors-taxe (« HT ») et la part de TVA.
                            <br></br>
                            <b>Défaut de paiement.</b> En cas d’irrégularité de paiement, TED informera le Client d’un avis
                            de défaut de paiement pour toute somme due. Le Client s’engage à régulariser la situation dans un
                            délai de 10 (dix) jours.
                            <br></br>
                            Si le Client ne procède pas à la régularisation dans le délai imparti, TED suspendra l'accès du Client
                            à l'un ou à l'ensemble des Services ALIZ. Les sommes resteront dues par le Client à TED conformément
                            au Contrat.
                            <br></br>
                            De plus, en cas d’impayé à l’échéance, TED est en droit à sa discrétion (i) de facturer un intérêt
                            de retard portant sur toutes les sommes dues jusqu’à leur complet paiement,
                            le taux s’élevant à dix fois le taux légal en vigueur à l’échéance, (ii) de facturer sur justificatifs
                            tous frais de recouvrement notamment frais bancaires liés au rejet d’un paiement en sus des
                            frais de recouvrement légaux de 40 €, (iii) le tout sans préjudice d’éventuels dommages et
                            intérêts et de l’éventuelle résiliation ci-après stipulée.
                        </p>





                        <h2 className="catch-phrase">5. UTILISATION DES SERVICES ALIZ</h2>

                        <p className="conditionsTerms-text">
                            <b>Fonctionnalités.</b> En échange du paiement du prix, TED s’engage à mettre à disposition
                            du Client les Services ALIZ auxquels il a souscrit. Le Prestataire s’engage à traiter les Requêtes
                            effectuées au cours d’un mois calendaire donné dans un délai maximum de 48h à compter de la saisie
                            d’une information partagée par le Client via un Service ALIZ pour l’envoi d’une facture, et d’un
                            courriel au patient.
                            <br></br>
                            <b>Fonctionnalités supplémentaires.</b> Le Client peut souscrire à des fonctionnalités supplémentaires
                            en passant une autre commande ou en activant des fonctionnalités supplémentaires à
                            partir de son compte ALIZ, si TED a mis cette option à sa disposition.
                            Le présent Contrat s'applique à toutes les commandes et fonctionnalités supplémentaires que
                            le Client active à partir de son compte ALIZ.
                            <br></br>
                            Les conditions liées à l’utilisation des Services ALIZ sont décrites dans les
                            <Link to="/conditions-generales/conditions-utilisation-des-services">CUSA</Link>.
                            <br></br>

                            <b>3.4 Incidents</b>
                            <br></br>
                            Dans le cas où une utilisation normale de Services ALIZ, non imputable à une mauvaise utilisation
                            des Services ALIZ par le Client, et/ou non liée aux aléas du réseau internet, serait empêchée pendant
                            une durée supérieure à 2 jours consécutifs ou par intermittence pendant une durée totale supérieure à
                            4 jours consécutifs au cours d’une même période de 30 jours, TED s’engage à appliquer des pénalités
                            libératoires en faveur du Client sous forme d’avoir à hauteur de 5% sur sa prochaine commande de Services,
                            en en faisant la demande écrite et motivée dans les 15 jours suivants l'incident.
                            Ces pénalités sont libératoires de telle sorte que le Client ne pourra réclamer aucune autre indemnité,
                            retenue ou annulation de commande en cours d’aucune sorte au titre de la qualité des Services ALIZ.
                        </p>






                        <h2 className="catch-phrase">6. DURÉE DU CONTRAT</h2>

                        <p className="conditionsTerms-text">
                            <b>Durée et renouvellement.</b> La durée du Contrat est définie au sein de la Commande.
                            Sauf mention contraire dans la Commande, le renouvellement tacite de l'abonnement est automatique pour
                            une durée égale à la Période d'abonnement choisie : annuelle ou mensuelle.
                            <br></br>
                            Dans l’hypothèse où une des Parties ne souhaiterait pas renouveler son Contrat,
                            celui-ci devra en informer l’autre Partie, sous réserve du respect d’un préavis de trente (30) jours
                            avant la date de renouvellement du Contrat.
                            <br></br>
                            Dans l’hypothèse où le Client souhaite changer de durée d’abonnement (annuel ou mensuel)
                            ou d’option des services (conformément au CUSA), ce dernier devra en informer le Prestataire
                            sous réserve d’un préavis de trente (30) jours avant la date de renouvellement du Contrat.
                        </p>





                        <h2 className="catch-phrase">7. TRAITEMENT DES DONNÉES À CARACTÈRE PERSONNEL</h2>

                        <p className="conditionsTerms-text">
                            Dans le cadre de l’exécution du Contrat, chacune des Parties est susceptible d’être responsable de traitement,
                            ou bien sous-traitant, voire tiers au sens du Règlement UE n°2016/679 (ci-après « <b>RGPD</b> »)
                            et de la loi n°78-17 du 6 janvier 1978 dite Informatique et Libertés (ci-après ensemble
                            « <b>Règlementation protégeant les données personnelles</b> »).
                            <b>7.1 Les engagements de TED en qualité de sous-traitant</b>
                            <br></br>
                            Dans le cadre de l’exécution du Contrat, et/ou sur instruction documentée du Client,
                            TED est susceptible de recevoir, d’accéder ou de traiter des données personnelles pour
                            le compte du Client. À ce titre, TED a la qualité de sous-traitant au sens de la Règlementation
                            protégeant les données personnelles.
                            <br></br>
                            Dans cette situation, TED s’engage :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>
                                I. à veiller à ce que les membres de son personnel autorisés à traiter les données personnelles
                                s'engagent à respecter la confidentialité et ne traitent les données personnelles que dans
                                le cadre du Contrat et/ou sur instruction du Client ;
                            </li>
                            <li>
                                II. à ne traiter les données personnelles que sur instruction documentée du Client, notamment
                                conformément au Contrat et dans le respect de la Règlementation protégeant les données personnelles.
                                En cas de demande d’instructions du Client hors Contrat, ce dernier s’engage à présenter cette
                                demande à TED qui y répondra dans ses meilleurs délais, acceptant par écrit cette demande sous
                                réserve de faisabilité et fixant les modalités notamment financières ;
                            </li>
                            <li>
                                III. au regard de l’économie du Contrat, de l’état de l’art, et des informations mises à la
                                disposition de TED par le Client, TED met en œuvre les moyens appropriés pour préserver la
                                confidentialité et la sécurité des données personnelles traitées pour le compte du Client.
                                L’objectif est d’éviter que ces données ne soient déformées, endommagées ou communiquées à des
                                personnes non autorisées. Il appartient au Client d'apprécier le caractère plus ou moins
                                sensible/risqué des traitements effectués et/ou données personnelles traitées par TED pour son compte,
                                et de solliciter TED, le cas échéant et sans délai, pour mettre en place d'un niveau de sécurisation
                                adapté, sous réserve de l'accord des Parties sur les modalités, notamment financières de cette mise
                                en place ;
                            </li>

                            <li>
                                IV. Les données personnelles traitées par TED pour le compte du Client dans le cadre des
                                Services ne sont pas conservées, les Services ne comprenant pas de fonctionnalité d’hébergement.
                            </li>
                            <li>
                                V. TED s’engage à mettre à la disposition du Client toutes les informations nécessaires pour
                                démontrer le respect des obligations prévues au présent article et pour permettre la réalisation
                                d'audits documentaires. Le Client peut réaliser un audit sur place si l’audit documentaire
                                n’est pas satisfaisant dans les limites suivantes : une (1) fois par an, et dans la limite d'une
                                charge de deux (2) jours/personne mise à disposition par TED, aux frais du Client. Dans ce cas,
                                les Parties conviennent que ces audits seront effectués par un auditeur indépendant qui devra être
                                validé par TED, et qui signera un engagement de confidentialité. L'audit est aux frais du Client.
                                Préalablement aux opérations d'audit, le Client indiquera l'objet spécifique de l'audit et les types
                                d'information auxquelles l'auditeur devra accéder, qui devront seulement concerner les
                                obligations prévues au présent article, à l'exclusion de tout autre domaine et à l’exclusion de
                                toute violation d’un secret des affaires ou d’une clause de confidentialité d’un autre Client.
                                TED s'engage à collaborer de bonne foi avec l'auditeur indépendant pour la réalisation des
                                opérations d'audit. Les opérations d'audit ne sauraient perturber l'activité de TED, le bon
                                fonctionnement de ses infrastructures, ou l'exécution du présent Contrat. Un exemplaire intégral
                                du rapport d'audit rédigé par l'auditeur sera remis à TED en même temps qu'au Client, à défaut
                                un tel rapport ne lui sera pas opposable.
                            </li>

                            <li>
                                VI. à notifier au Client toute violation de données personnelles au sens de la Règlementation
                                protégeant les données personnelles, dans les meilleurs délais après en avoir pris connaissance.
                            </li>
                            <li>
                                VII. à n'effectuer aucun transfert des données personnelles vers un pays tiers à l'Espace économique
                                européen, ou le cas échéant à en informer le Client et à prendre toutes mesures prescrites par les
                                lois et règlements en vigueur, notamment par la Règlementation protégeant les données personnelles,
                                afin de s'assurer que ce transfert présente les garanties appropriées.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            De manière générale, le Client autorise le Prestataire à faire appel à des sous-traitants ultérieurs,
                            pour les seuls besoins de la bonne exécution du Contrat et/ou des instructions du Client, à condition
                            qu'ils s'engagent à respecter les termes du présent Contrat.
                            La liste des sous-traitants ultérieurs est communiquée et tenue à jour.
                            En cas de refus par le Client du nouveau sous-traitant de TED, le Client assumera toutes les conséquences
                            et dommages résultant de tout retard ou impossibilité d’utilisation d’un Service ALIZ. Nonobstant ce qui
                            précède, le Prestataire restera seul responsable de l’exécution du Contrat, y compris de l’exécution
                            des obligations qu’il aura sous-traitées.
                            <br></br>
                            Dans le cas où le Client solliciterait TED pour des demandes en lien avec la Règlementation
                            protégeant les données personnelles, dans le cadre des traitements pour lesquels le Client
                            est responsable de traitement, ces prestations seront fournies compte tenu de la nature du
                            traitement et des informations à la disposition de TED, sous réserve de faisabilité et
                            d’acceptation par TED, aux tarifs en vigueur de cette dernière.
                            <br></br>
                            <b>7.2 Les engagements du Client</b>
                            <br></br>
                            Conformément à la Règlementation protégeant les données personnelles, le Client est seul
                            responsable de traitement, au titre des données personnelles traitées par TED pour le compte
                            du Client dans le cadre de l’exécution du Contrat et/ou selon ses instructions. Plus généralement,
                            il est seul responsable de l’usage qu’il fait desdites données, qu’elles soient à caractère
                            personnel ou non ; de l’usage qu’il fait des Services ALIZ ; et des données qu’il intègre ou
                            traite par le biais des Services ALIZ. En tant que responsable de traitement,
                            le Client doit notamment :
                        </p>


                        <ul className="conditionsTerms-text">
                            <li>
                                I. respecter la Loi Informatique et Libertés, le RGPD et toute règlementation applicable à la protection
                                des données personnelles, et effectuer toute formalité requise à ce titre ;
                            </li>
                            <li>
                                II. informer sous 48heures à partir du moment où il en a connaissance TED en cas d’incident,
                                de violation de données personnelles, ou en cas de contrôle de la CNIL ou de toute autorité
                                administrative ou judiciaire, concernant un/des traitement(s) objets du présent article.
                            </li>
                            <li>
                                III. Conformément à l’article 28 du RGPD, le Client s’engage à détailler l’objet, la nature,
                                la finalité du/des traitement(s) sous-traités à TED.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            Le Client est informé que chaque patient dispose d'un droit d'accès, de rectification, de modification,
                            de portabilité ou d’effacement des données personnelles les concernant, sous réserve néanmoins du respect
                            d’obligations légales et/ou de l’exercice ou la défense de droits en justice. Il peut, définir des
                            directives sur le sort de ses données personnelles « post mortem » en application de l’article 85
                            de la Loi Informatique et Libertés, et dans certains cas, demander la limitation du traitement,
                            s’y opposer, ou le cas échéant retirer son consentement. Pour cela, ainsi que pour toute information
                            complémentaire, le Client centralise les demandes, en sa qualité de responsable de traitement,
                            et les adresse à TED : dpo@aliz.io. En effet, seul le Client peut juger de la validité des demandes des
                            patients. TED, en qualité de sous-traitant accompagne le Client, sur instructions de ce dernier,
                            dans la réponse à ces demandes.
                            <br></br>
                            <b>7.3 Modalités du traitement par TED des données de patient</b>
                            <b>Objet.</b> L’exécution du Contrat implique le traitement par TED de données à caractère personnel
                            pour le compte du Client, et notamment à des fins de génération, de structuration et d’envoi de
                            factures aux patients du Client.
                            <br></br>
                            <b>Durée.</b> Les données à caractère personnel des patients ne sont pas conservées par TED,
                            les Services ALIZ ne disposant pas de fonctionnalité d’hébergement. Les données sont supprimées
                            immédiatement après leur traitement.
                            <br></br>
                            <b>Catégories de personnes concernées :</b> Les patients des Clients utilisant les Services ALIZ.
                            <br></br>
                            <b>Nature du traitement :</b> l'enregistrement, la consultation, la communication par transmission et
                            l'effacement des données de patients.
                            <br></br>
                            <b>Catégorie de données personnelles traitées.</b> Les données traitées par TED sont :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>L’identifiant de la facture ;</li>
                            <li>La date de la consultation ;</li>
                            <li>Les nom, prénom et date de naissance du patient ;</li>
                            <li>Les motifs de la consultation ;</li>
                            <li>Le tarif</li>
                            <li>Le mode de règlement ;</li>
                            <li>L’adresse électronique du patient ;</li>
                            <li>Le destinataire de la facture ;</li>
                            <li>Si un courriel a été envoyé ou non;</li>
                            <li>L’état et le statut de la facture ;</li>
                            <li>Le statut de la consultation effectuée ou non ;</li>
                            <li>La date de traitement.</li>
                        </ul>



                        <p className="conditionsTerms-text">Sous-traitants : </p>

                        <table className="table mt-5">
                            <tbody>
                                <tr>
                                    <th scope="row">Nom</th>
                                    <td>Microsoft AZURE</td>
                                    <td>UIPATH</td>
                                </tr>
                                <tr>
                                    <th scope="row">Lieu du traitement</th>
                                    <td>France</td>
                                    <td>Roumanie</td>
                                </tr>
                                <tr>
                                    <th scope="row">Données concernées</th>
                                    <td>Toutes les données</td>
                                    <td>Toutes les données</td>
                                </tr>
                                <tr>
                                    <th scope="row">Finalité</th>
                                    <td>Hébergement</td>
                                    <td>Machine virtuelle</td>
                                </tr>
                                <tr>
                                    <th scope="row">Contact</th>
                                    <td>0157-324-218 (équipe commerciale), de 9h à 17h, du lundi au vendredi</td>
                                    <td>privacy@uipath.com</td>
                                </tr>
                                <tr>
                                    <th scope="row">Adresse</th>
                                    <td>37 Quai du Président Roosevelt, 92130 Issy-les-Moulineaux</td>
                                    <td>
                                        4 rue Vasile Alecsandri, 11 rue Daniel Constantin,
                                        5ème étage, bâtiment A, district 1, Bucarest, Roumanie
                                    </td>
                                </tr>
                            </tbody>
                        </table>




                        <p className="conditionsTerms-text">
                            <b>7.4 Les engagements de TED en qualité de responsable de traitement </b>
                            <br></br>
                            Le Client est informé que les données personnelles concernant les contacts de TED chez le Client font
                            l’objet d’un traitement par TED au titre de la gestion de la relation Client pendant la durée du Contrat,
                            et éventuellement à des fins de prospection directe par TED pour des logiciels et/ou services identiques
                            ou analogues à ceux fournis en exécution du Contrat. L’objet et la finalité de ce traitement dans le
                            cadre de l’utilisation des Services ALIZ sont décrits dans la
                            <Link to="/conditions-generales/politique-de-confidentialite">Politique de Confidentialité</Link>
                            d’ALIZ
                        </p>







                        <h2 className="catch-phrase">8. PROPRIÉTÉ DES SERVICES ALIZ</h2>


                        <p className="conditionsTerms-text">
                            Propriété des Services ALIZ. Le Client reconnaît les droits de propriété intellectuelle de TED sur les
                            Services ALIZ, ses composantes et les contenus y afférent et renonce à contester ces droits sous quelle
                            que forme que ce soit. Les marques, logos, slogans, graphismes, photographies, animations, vidéos,
                            solutions logicielles et textes contenus dans les Services ALIZ sont la propriété intellectuelle exclusive
                            de TED ou de ses partenaires et ne peuvent être reproduits, utilisés ou représentés sans autorisation
                            expresse préalable sous peine de poursuites judiciaires.
                            <br></br>
                            Toute représentation ou reproduction, totale ou partielle, des Services et de leur contenu, par quel que
                            procédé que ce soit, sans l’autorisation expresse et préalable de TED est interdite et constituera une
                            contrefaçon sanctionnée par les articles L. 335-2 et suivants et les articles L.713-1 et suivants du
                            code de propriété intellectuelle.
                            <br></br>
                            L’utilisation des Services ALIZ par le Client ne vaut pas transfert de propriété intellectuelle.
                            TED accorde au Client, dans le cadre et pour la durée du Contrat, une licence d’utilisation sur les
                            Services ALIZ, non cessible, non exclusive et pour les seuls besoins du Client, pour le monde entier.
                            <br></br>
                            Chaque Partie conserve la propriété exclusive des moyens, outils, assistants, inventions, méthodes ou
                            savoir-faire préexistants, nés ou mis au point par elle-même indépendamment de l’exécution du Contrat et
                            faisant l’objet d’une protection spécifique (droit d’auteur, brevet, marque, etc.).
                        </p>







                        <h2 className="catch-phrase">9. RESPONSABILITÉ</h2>

                        <p className="conditionsTerms-text">
                            Principe de responsabilité. Le Client engage sa responsabilité contractuelle en cas de violation des
                            stipulations du Contrat dans les conditions de droit commun.
                            <br></br>
                            Limitation de responsabilité. Le Prestataire ne pourra en aucun cas être tenu pour responsable de tout
                            dommage en cas de préjudice causé par une interruption ou une baisse de service de l’opérateur de
                            télécommunications, du fournisseur d’électricité ou en cas de Force Majeure.
                            Les Parties reconnaissent que les garanties et limitations de responsabilité stipulées au présent article
                            opèrent une répartition des risques et des responsabilités raisonnables eu égard au contexte et aux
                            conditions financières encadrant leur accord.
                            <br></br>
                            TED ne sera pas responsable au titre du Contrat (que cette responsabilité soit contractuelle, délictuelle
                            (y compris la négligence) ou autre), y compris en cas de réclamation par un tiers envers le Client visant
                            à mettre en cause les Éléments de Propriété Intellectuelle propres aux Services, de toutes pertes suivantes
                            subies ou encourues par le Client (que ces pertes soient ou non envisageables par les Parties à la date
                            d’entrée en vigueur du présent Contrat) :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>I. perte de profits réels ou prévus (y compris la perte de profits sur des contrats) ; </li>
                            <li>II. perte d’économies prévues ;</li>
                            <li>III.  perte d’opportunités d’affaires ;</li>
                            <li>IV. perte de réputation ou atteinte à l’image ; ou </li>
                            <li>V. pertes particulières, indirectes ou consécutives.</li>
                        </ul>

                        <p className="conditionsTerms-text">
                            La responsabilité du Prestataire ne pourra pas non plus être engagée en cas de :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>I. défaut de fourniture par le Client d’une adresse de messagerie valable ;</li>
                            <li>II. défaut de fourniture par le Client des accès suffisants à son ou ses calendriers ;</li>
                            <li>III. défaut de respect par le Client de la configuration minimale requise pour l’utilisation des Services ;</li>
                            <li>IV. utilisation non conforme ou illicite du Service par le Client ; </li>
                            <li>
                                V. indisponibilité et/ou déconnexion des calendriers et/ou défaut de synchronisation des données du
                                Client avec ses calendriers entrainant notamment une possibilité de chevauchement de rendez-vous.
                            </li>
                        </ul>


                        <p className="conditionsTerms-text">
                            TED ne pourra en aucun cas être tenu pour responsable de tout dommage en cas de préjudice causé par une
                            interruption ou une baisse de service de l’opérateur de télécommunications, du fournisseur d’électricité
                            ou en cas de Force Majeure.
                            <br></br>
                            <b>Réparation du préjudice.</b> Lorsque la responsabilité de TED procède d’une violation du Contrat,
                            la réparation du préjudice sera limitée à 50% du montant des redevances payées et/ou dues par le
                            Client au titre de la Période d’abonnement concernée.
                            <br></br>
                            La responsabilité civile de TED ne peut pas être engagée pour les Outils gratuits. En tout état de cause,
                            le montant du préjudice sera limité à cent (100) euros dans le cas où la responsabilité civile de TED serait
                            reconnue à l’égard du Client ou de tout tiers découlant de l’utilisation des Outils gratuits.
                            <br></br>
                            Cette limitation n’est pas applicable aux dommages résultant d’une faute lourde, dolosive ou intentionnelle
                            d’une Partie, entendue au sens du droit commun et jurisprudentiel, aux dommages corporels
                            (incluant le décès), et ce, quel que soit le fondement juridique de la réclamation et la procédure
                            employée pour la faire aboutir.
                            <br></br>
                            Produits tiers. TED décline toute responsabilité en ce qui concerne l’usage de produits ou services
                            tiers utilisés par le Client, sous sa seule responsabilité. TED ne pourra être tenu de tout problème
                            technique ou maintenance liés à ces produits tiers.
                            <br></br>
                            Force Majeure. Les Parties ne seront pas responsables en cas de défaillance ou de retard d’exécution
                            causés par le fait d’un tiers ou par un cas de Force Majeure. La Force Majeure ne peut en aucun cas
                            être invoquée s’agissant des obligations de paiement mises à la charge du Client.
                            <br></br>
                            La Partie qui invoque un cas de Force Majeure doit immédiatement notifier l’autre Partie :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>
                                I. la survenance d’un tel événement et de son estimation sur la nature et la durée des circonstances
                                du cas de Force Majeure ; ainsi que
                            </li>
                            <li>
                                II. des mesures qui ont été ou qui vont être prises pour limiter les effets du cas de Force Majeure.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            Les obligations prévues dans ce Contrat seront suspendues pendant toute la durée du cas de Force Majeure.
                            La suspension des obligations ou le retard ne pourra en aucun cas être une cause de responsabilité
                            pour non-exécution de l’obligation en cause ni induire le versement de dommages et intérêts ou de
                            pénalités d’aucune sorte.
                        </p>







                        <h2 className="catch-phrase">10. RÉSILIATION</h2>

                        <p className="conditionsTerms-text">
                            En cas d’augmentation du prix telle que définie à l’article 4, le Client pourra décider de ne pas renouveler
                            le Contrat, sous réserve d’en informer TED avant la date de renouvellement du Contrat.
                            <br></br>
                            Résiliation pour motifs légitimes. Toutefois, il est possible à chaque Partie de résilier le présent
                            Contrat ou de mettre fin aux Services dans les cas suivants :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>
                                I. En cas de violation d’une obligation substantielle du Contrat, étant entendu que l’obligation du
                                Client est le paiement des frais d’abonnement et que l’obligation de TED est la fourniture des Services,
                                à défaut de réponse à une mise en demeure de réaliser l’obligation restée sans réponse suivant un
                                préavis de trente (30) jours ; ou
                            </li>
                            <li>
                                II. Si TED considère que le Client agit ou a agi d'une façon qui entraîne des retombées négatives
                                ou nuisibles pour TED, ses prospects ou ses clients.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            En cas de résiliation par le Client ou d’expiration du compte du Client, TED pourra choisir de continuer
                            à fournir ses Outils gratuits.
                            <br></br>
                            Si le Client résilie le présent Contrat, le Prestataire remboursera sans délai tous les Frais d’abonnement
                            prépayés, mais non utilisés dans le cadre du ou des Services souscrits.
                            <br></br>
                            Si le Prestataire résilie le présent Contrat pour motifs légitimes, le Client devra verser sans délai
                            tous les frais impayés exigibles jusqu’à la fin de la Période d’abonnement.
                            Le cas échéant, les frais avancés par le Client ne seront pas remboursés.
                            <br></br>
                            <b>Forme.</b> La résiliation doit être notifiée à l’autre Partie par lettre recommandée avec accusé de réception.
                        </p>






                        <h2 className="catch-phrase">11. CONFIDENTIALITÉ</h2>

                        <p className="conditionsTerms-text">
                            <b>Obligation.</b> Chacune des Parties qui reçoit des Informations Confidentielles dans le cadre du
                            Contrat s’engage, pendant toute la durée du Contrat, à :
                        </p>
                        <ul className="conditionsTerms-text">
                            <li>
                                I.ne pas divulguer ou utiliser les Informations Confidentielles à d’autres fins que pour l’exécution
                                du Contrat ou d’une manière non conforme à celui-ci ;
                            </li>
                            <li>
                                II. limiter l’accès aux Informations Confidentielles à ceux de ses employés ou de ses prestataires
                                qui ont besoin d’en avoir connaissance ; et
                            </li>
                            <li>
                                III. ne pas vendre, transférer, publier, divulguer ou rendre disponible de quelque façon que ce soit
                                tout ou partie des Informations Confidentielles à un tiers ou à toute personne non autorisée au
                                titre du Contrat, sans le consentement écrit préalable de la Partie divulgatrice.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            Ne sont pas considérées comme des Informations Confidentielles les informations :
                        </p>
                        <ul className="conditionsTerms-text">
                            <li>
                                I.qui sont accessibles au public dès leur divulgation ou qui deviennent accessibles par la suite
                                sans que l’une des Parties ait manqué à son obligation de confidentialité ; ou
                            </li>
                            <li>
                                II. qui ont été légitimement obtenues auprès d’un tiers sans que celui-ci ait porté atteinte à
                                une obligation de confidentialité.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            <b>Modalités.</b> Le Client demeurera soumis à cette obligation de confidentialité pour une durée de 5 ans,
                            même en cas de nullité ou de résiliation du Contrat.
                        </p>






                        <h2 className="catch-phrase">12. DIVERS</h2>

                        <p className="conditionsTerms-text">
                            <b>Interprétation des CGV.</b> En cas de contradiction entre les présentes CGV et les CCUSA, les stipulations des
                            CUSA prévaudront.
                            <br></br>
                            <b>Convention de Preuve.</b> Sauf preuve contraire, les données enregistrées dans le système informatique de TED
                            constituent la preuve de l'ensemble des transactions conclues avec le Client.
                            <br></br>
                            <b>Nullité.</b> Si une ou plusieurs stipulations des présentes CGV sont tenues pour non valides ou déclarées
                            comme telles en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une
                            juridiction compétente, les autres stipulations garderont toute leur force et leur portée.
                            <br></br>
                            <b>Cession.</b> Ni ce Contrat, ni les droits ou obligations consentis au titre de celui-ci ne peuvent être
                            transférés par le Client sans l’accord préalable et écrit du Prestataire.
                            <br></br>
                            <b>Nonobstant ce qui précède,</b> cet accord n’a pas à être demandé dans le cadre d’une cession à toute entité qui
                            contrôle, est sous contrôle commun ou est contrôlée par l’une des Parties, sauf si cette entité est concurrente de l’une ou l’autre des Parties.
                            Pour les besoins de cet article, le mot « <b>contrôle</b> » doit être compris comme la détention directe ou
                            indirecte de 50% ou plus des droits de vote ou du capital social d’une telle entité, le pouvoir de nommer
                            ou d’élire la majorité du conseil d’administration ou le pouvoir de diriger la gestion d’une telle entité.
                            En cas de cession, le cessionnaire est tenu de reprendre l’ensemble des obligations précédemment mises à
                            la charge du cédant.
                            <br></br>
                            <b>Non-renonciation.</b> Le fait pour l'une des Parties de ne pas se prévaloir auprès de l'autre Partie
                            d'un manquement à l'une quelconque des obligations visées au sein des présentes CGV ne saurait être
                            interprété pour l'avenir comme une renonciation à l'obligation en cause.
                            <br></br>
                            <b>Référence.</b> Le Client autorise le Prestataire à utiliser et reproduire sa dénomination, ses marques
                            et autres signes distinctifs à titre de référence commerciale dans ses communiqués de presse, brochures
                            commerciales, sur son site ou encore à l’occasion de présentations commerciales, conférences et salons
                            professionnels.
                            <br></br>
                            <b>Langue.</b> En cas de rédaction du Contrat en plusieurs langues ou de traduction, seule la version
                            française fera foi.
                            <br></br>
                            <b>Droit applicable.</b> Le Contrat est soumis au droit français, à l'exclusion de toute autre législation.
                            <br></br>
                            <b>Différends – Tribunaux compétents.</b> En cas de différend survenant entre les Parties au sujet de la
                            validité, de l'interprétation, de l'exécution du Contrat, celles-ci s’efforceront de trouver une solution
                            amiable avant toute saisine du tribunal compétent.
                            <br></br>
                            En cas de litige relatif à l’exécution du Contrat, les Parties s’engagent à adresser une mise en demeure
                            préalable, par lettre recommandée avec avis de réception.
                            <br></br>
                            Les Parties doivent se consulter ou se rencontrer dans un délai de dix (10) jours suivant la notification du
                            différend, afin d’essayer de trouver un accord. Si un tel accord est trouvé, un avenant doit être établi et
                            signé par les représentants habilités des deux parties.
                            <br></br>
                            En l’absence ou en cas d’échec d’une solution amiable au différend dans un délai raisonnable,
                            LES PARTIES POURRONT SOUMETTRE LE LITIGE AUX TRIBUNAUX DU RESSORT DE LA COUR D’APPEL DE PARIS,
                            NONOBSTANT PLURALITÉ DE DÉFENDEURS OU APPEL EN GARANTIE, MÊME POUR LES PROCÉDURES D’URGENCE OU POUR LES
                            PROCÉDURES CONSERVATOIRES, EN RÉFÉRÉ OU PAR REQUÊTE.
                            <br></br>
                            LES PARTIES CONVIENNENT QU’ELLES NE SONT PAS OBLIGATOIREMENT TENUES D’APPLIQUER LA PROCÉDURE DE CONCILIATION
                            AMIABLE AVANT LA MISE EN ŒUVRE D’UNE PROCÉDURE D’URGENCE OU CONSERVATOIRE, EN RÉFÉRÉ OU SUR REQUÊTE.
                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}
