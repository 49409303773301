import React from 'react'

// ----- Components -----
import Step from './Step'
import Simulation from './Simulation'
import Client from './Client'
import HeaderLanding from './Header'
import Footer from './Footer'

export default function HomeSante1() {
    return (
        <>
            <HeaderLanding />

            <div className="Sante1_home-view">

                {/* <Header /> */}

                <div className="bg-responsive"></div>
                <div className="row">

                    <div className="offset-1 col-10 offset-sm-1 col-sm-10 offset-lg-1 col-lg-10 offset-xl-0 col-xl-6 ">
                        <h1 className="offset-xl-2 col-xl-8">Combien de temps comptez-vous perdre ?</h1>
                        <p className="presentation offset-sm-1 col-sm-10 offset-xl-2 col-xl-8 ">

                            Chaque mois, la gestion administrative occupe 20% de votre temps. Et si vous accordiez ce temps à vos patients? <br></br>

                        </p>
                        <p className="price offset-sm-1 col-sm-10 offset-xl-2 col-xl-8 ">
                            Avec Aliz, déléguez votre administratif pour seulement <strong>20€/mois</strong>.
                        </p>

                        <a href="#contact" className="offset-sm-2 col-sm-8 offset-xl-2 col-xl-4 btn btn-alizz btn-1">
                            TESTER GRATUITEMENT
                        </a>
                        <a href="https://calendly.com/tedconsulting/decouverte-aliz-mon-assistant-virtuel" className="offset-sm-2 col-sm-8 offset-xl-0 col-xl-4 btn btn-alizz btn-2">
                            PRENDRE RENDEZ-VOUS
                        </a>
                    </div>

                    <div className="d-none d-xl-block col-xl-6 bg">
                    </div>
                </div>


                <div className="body-view row">
                    <Simulation />
                    <Step />
                    <Client />
                    <Footer />
                </div>

            </div>
        </>
    )
}
