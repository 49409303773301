import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { newSubscriptionPatientele } from '../../../../../services/User/subscription.js'
import { issetBot } from '../../../../../services/robots.js'
import { getPrice } from '../../../../../services/User/stripe.js'

import GooglePlaces from '../GooglePlaces';

import Template1 from '../../../../../images/User/Template-4_Template-Client.png'
import Template1Webp from '../../../../../images/User/Template-4_Template-Client.webp'
import Template2 from '../../../../../images/User/Template-3_Template.png'
import Template2Webp from '../../../../../images/User/Template-3_Template.webp'

import gsap from 'gsap'
import { Modal } from 'react-bootstrap';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ConditionsPatientele from './../TermsAndConditions/Patientele'


export default function Administrative({ responseToken, subscription }) {

    let navigate = useNavigate();

    const [subscrib, setSubscrib] = useState([])
    const [priceResponse, setPriceResponse] = useState([])
    // const [recurring, setRecurring] = useState('')
    const [errors, setErrors] = useState([])
    // _______Data________ 
    const [phone, setPhone] = useState('')
    const [profession, setProfession] = useState('')
    const [siretNumber, setSiretNumber] = useState('')
    const [adeliNumber, setAdeliNumber] = useState('')
    const [office, setOffice] = useState('')
    const [officeAddress, setOfficeAddress] = useState('')
    const [officePostalCode, setOfficePostalCode] = useState('')
    const [template, setTemplate] = useState('')
    const [inkPad, setInkPad] = useState(null)
    const [inkPad2, setInkPad2] = useState(null)
    const [inkPad3, setInkPad3] = useState(null)
    const [agree, setAgree] = useState(false)
    const [ccMailPatient, setCcMailPatient] = useState(false)
    const [mailCusto, setMailCusto] = useState(false)
    const [mentionEi, setMentionEi] = useState(false)

    const [office2, setOffice2] = useState('')
    const [office3, setOffice3] = useState('')
    const [officeAddress2, setOfficeAddress2] = useState('')
    const [officeAddress3, setOfficeAddress3] = useState('')
    const [officePostalCode2, setOfficePostalCode2] = useState('')
    const [officePostalCode3, setOfficePostalCode3] = useState('')

    // ________End data__________

    const [subscriptionExist, setSubscriptionExist] = useState(false)
    const [prices, setPrices] = useState([])

    // Agree Button section 
    const agreeBtnActived = useRef();

    useEffect(() => {
        if (responseToken === false) {
            window.location.href = '/login?assistant=' + subscription.robot + '&price=' + subscription.priceId
        }
    }, [responseToken, subscription.priceId, subscription.robot, navigate])

    // Get information of subscription by price Id
    useEffect(() => {
        getPrice(setPriceResponse, subscription.priceId)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // !IMPORTANT : props in array 
        setPrices([subscription.priceId])
        issetBot(setSubscriptionExist, parseInt(subscription.robot))
        // eslint-disable-next-line
    }, [])

    // If the subscription already has a "waiting" status when loading, go to step 2
    useEffect(() => {
        if (subscriptionExist.waiting) {
            navigate('/espace-client/souscription-etape2?assistant=' + subscription.robot + '&price=' + subscription.priceId + '&subscription=success')
        }
    }, [subscriptionExist, navigate, subscription.robot, subscription.priceId])

    // Data for create new subscription && create new customer in stripe
    const data = {
        'robot': parseInt(subscription.robot),
        'priceId': prices,
        'phone': phone,
        'profession': profession,
        'office': office,
        'office2': office2 === "" ? "" : office2,
        'office3': office3 === "" ? "" : office3,
        'officeAddress': officeAddress,
        'officeAddress2': officeAddress2 === "" ? "" : officeAddress2,
        'officeAddress3': officeAddress3 === "" ? "" : officeAddress3,
        'officePostalCode': officePostalCode,
        'officePostalCode2': officePostalCode2 === "" ? "" : officePostalCode2,
        'officePostalCode3': officePostalCode3 === "" ? "" : officePostalCode3,

        'siretNumber': siretNumber,
        'adeliNumber': adeliNumber,
        'runningDay': ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        'template': template,
        'inkPad': inkPad,
        'inkPad2': inkPad2,
        'inkPad3': inkPad3,
        'agree': agree,
        'ccMailPatient': ccMailPatient,
        'mailCusto': mailCusto,
        'mentionEi': mentionEi,
    }

    const subscribed = () => {
        newSubscriptionPatientele(data, setErrors, setSubscrib)
    }

    useEffect(() => {
        if (subscrib === "success") {
            return navigate('/espace-client/souscription-etape2?assistant=' + subscription.robot + '&price=' + subscription.priceId + '&subscription=success');
        }
        // eslint-disable-next-line
    }, [subscrib])

    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const showOffice2 = () => {
        gsap.set('.addOffice', { display: 'none' })
        gsap.set('.blockOffice2', { display: 'block' })
        gsap.set('.addOffice2', { display: 'block' })
    }

    const showOffice3 = () => {
        gsap.set('.addOffice2', { display: 'none' })
        gsap.set('.blockOffice3', { display: 'block' })
    }


    const changeFile = (e) => {
        if (typeof e.target.files !== "undefined") {
            setInkPad(e.target.files[0])
        }
    }

    const changeFile2 = (e) => {
        if (typeof e.target.files !== "undefined") {
            setInkPad2(e.target.files[0])
        }
    }

    const changeFile3 = (e) => {
        if (typeof e.target.files !== "undefined") {
            setInkPad3(e.target.files[0])
        }
    }

    // Alert : accept or reject the terms of use
    const agreeCgu = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="row justify-content-center popup-custom">

                        <div className="custom-ui" >
                            <ConditionsPatientele />
                        </div>

                        <div className="d-flex justify-content-center">
                            <button className="btn-red"
                                onClick={() => {
                                    setAgree(false)
                                    onClose();
                                }}>
                                Refuser
                            </button>

                            <button className="btn-alizFull"
                                ref={agreeBtnActived}
                                onClick={() => {
                                    setAgree(true)
                                    onClose();
                                }}
                            >Accepter</button>
                        </div>
                    </div >
                );
            }
        });
    }

    // Change the background of the checked box - conditions of use
    useEffect(() => {
        if (agree === true) {
            gsap.set('.ballAgree', { backgroundColor: '#21e01a' })
        }
    }, [agree])



    return (
        <div className="subscribForm-view">
            <div className="d-flex flex-direction-column">
                <h1>Assistant de Gestion Patientèle - {process.env.REACT_APP_BOT_NAME_PATIENTELE}</h1>

                <p className="ask-information col-12 col-xl-9">Nous devons fournir à votre assistant quelques informations supplémentaires <br></br>Certaines fonctionnalités ne sont pas disponible dans le test gratuit</p>


                {/* Form Subscription */}
                {(() => {
                    if (!subscriptionExist.exist) {

                        return <div className="col-12 offset-xl-0 col-xl-8 px-0">

                            <div className="d-flex">
                                <div className="col-5">
                                    <label htmlFor="phone">Téléphone *</label>
                                    <input type="text"
                                        name="phone"
                                        id="phone"
                                        value={phone || ''}
                                        minLength="9"
                                        maxLength="10"
                                        required
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    {errors.phone && <span className="text-danger">{errors.phone}<br></br></span>}
                                </div>
                                <div className="offset-2 col-5">
                                    <label htmlFor="profession">Profession *</label>
                                    <input type="text"
                                        name="profession"
                                        id="profession"
                                        value={profession || ''}
                                        minLength="2"
                                        maxLength="50"
                                        required
                                        onChange={(e) => setProfession(e.target.value)}
                                    />
                                    {errors.profession && <span className="text-danger">{errors.profession}</span>}
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="col-5">
                                    <label htmlFor="siretNumber">N° SIRET *</label>
                                    <input type="text"
                                        name="siretNumber"
                                        id="siretNumber"
                                        value={siretNumber || ''}
                                        minLength="13"
                                        maxLength="14"
                                        required
                                        onChange={(e) => setSiretNumber(e.target.value)}
                                    />
                                    {errors.siretNumber && <span className="text-danger">{errors.siretNumber}<br></br></span>}
                                </div>
                                <div className="offset-2 col-5">
                                    <label htmlFor="adeliNumber">N° ADELI *</label>
                                    <input type="text"
                                        name="adeliNumber"
                                        id="adeliNumber"
                                        value={adeliNumber || ''}
                                        minLength="8"
                                        maxLength="9"
                                        required
                                        onChange={(e) => setAdeliNumber(e.target.value)}
                                    />
                                    {errors.adeliNumber && <span className="text-danger">{errors.adeliNumber}</span>}
                                </div>
                            </div>

                            {/* Office 1 */}
                            <div className="d-flex flex-direction-column">
                                <div className="d-flex" title="Veuillez saisir le même nom de cabinet que vous avez fournis sur Doctolib">
                                    <label htmlFor="office">Nom de votre Cabinet *</label>
                                    <svg className="exclamationPoint" width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2.75024C17.1089 2.75024 21.2499 6.89224 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89224 6.89188 2.75024 11.9999 2.75024Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.9" d="M11.995 8.20435V12.6233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.9" d="M11.995 15.7961H12.005" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <input type="text"
                                    name="office"
                                    id="office"
                                    value={office || ''}
                                    minLength="2"
                                    maxLength="70"
                                    required
                                    onChange={(e) => setOffice(e.target.value)}
                                />
                                {errors.office && <span className="text-danger">{errors.office}</span>}
                            </div>

                            <div className="d-flex flex-direction-column">
                                <label htmlFor="officeAddress">Adresse de votre cabinet *</label>
                                <GooglePlaces officeAddress={officeAddress} setOfficeAddress={setOfficeAddress} />

                                {errors.officeAddress && <span className="text-danger">{errors.officeAddress}</span>}
                            </div>

                            <div className="d-flex flex-direction-column">
                                <label htmlFor="officePostalCode">Code postal de votre cabinet *</label>
                                <input type="text"
                                    name="officePostalCode"
                                    id="officePostalCode"
                                    value={officePostalCode || ''}
                                    minLength="2"
                                    maxLength="8"
                                    required
                                    onChange={(e) => setOfficePostalCode(e.target.value)}
                                />
                                {errors.officePostalCode && <span className="text-danger">{errors.officePostalCode}</span>}
                            </div>

                            <div className="d-flex flex-direction-column">
                                <label htmlFor="inkPad">Tampon du cabinet</label>
                                <input type="file"
                                    name="inkPad"
                                    id="inkPad"
                                    required
                                    onChange={changeFile}
                                />
                                {errors.inkPad && <span className="text-danger">{errors.inkPad}</span>}
                            </div>

                            {/* Office 2 */}
                            {priceResponse.nickname && !priceResponse.nickname.match(/starter/i) &&
                                <div className="addOffice" onClick={showOffice2}>
                                    <label htmlFor="">Ajouter un deuxième cabinet </label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                </div>
                            }

                            <div className="blockOffice2">
                                <div className="d-flex flex-direction-column">
                                    <label htmlFor="office2">Nom de votre deuxième Cabinet</label>
                                    <input type="text"
                                        name="office2"
                                        id="office2"
                                        placeholder="Optionnel"
                                        value={office2 || ''}
                                        minLength="2"
                                        maxLength="70"
                                        required
                                        onChange={(e) => setOffice2(e.target.value)}
                                    />
                                    {errors.office2 && <span className="text-danger">{errors.office2}</span>}
                                </div>

                                <div className="d-flex flex-direction-column">
                                    <label htmlFor="officeAddress2">Adresse de votre deuxième cabinet</label>
                                    <GooglePlaces officeAddress={officeAddress2} setOfficeAddress={setOfficeAddress2} />

                                    {errors.officeAddress2 && <span className="text-danger">{errors.officeAddress2}</span>}
                                </div>

                                <div className="d-flex flex-direction-column">
                                    <label htmlFor="officePostalCode2">Code postal de votre deuxième cabinet *</label>
                                    <input type="text"
                                        name="officePostalCode2"
                                        id="officePostalCode2"
                                        value={officePostalCode2 || ''}
                                        minLength="2"
                                        maxLength="8"
                                        required
                                        onChange={(e) => setOfficePostalCode2(e.target.value)}
                                    />
                                    {errors.officePostalCode2 && <span className="text-danger">{errors.officePostalCode2}</span>}
                                </div>


                                <div className="d-flex flex-direction-column">
                                    <label htmlFor="inkPad2">Tampon du votre deuxième cabinet</label>
                                    <input type="file"
                                        name="inkPad2"
                                        id="inkPad2"
                                        required
                                        onChange={changeFile2}
                                    />
                                    {/* {errors.inkPad2 && <span className="text-danger">{errors.inkPad2}</span>} */}
                                </div>
                            </div>

                            {/* Office 3  */}
                            {priceResponse.nickname && priceResponse.nickname.match(/premium/i) &&
                                <div className="addOffice2" onClick={showOffice3}>
                                    <label htmlFor="">Ajouter un troisième cabinet </label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                </div>
                            }

                            <div className="blockOffice3">
                                <div className="d-flex flex-direction-column">
                                    <label htmlFor="office3">Nom de votre troisième Cabinet</label>
                                    <input type="text"
                                        name="office3"
                                        id="office3"
                                        placeholder="Optionnel"
                                        value={office3 || ''}
                                        minLength="2"
                                        maxLength="70"
                                        required
                                        onChange={(e) => setOffice3(e.target.value)}
                                    />
                                    {errors.office3 && <span className="text-danger">{errors.office3}</span>}
                                </div>

                                <div className="d-flex flex-direction-column">
                                    <label htmlFor="officeAddress3">Adresse de votre troisième cabinet</label>
                                    <GooglePlaces officeAddress={officeAddress3} setOfficeAddress={setOfficeAddress3} />

                                    {errors.officeAddress3 && <span className="text-danger">{errors.officeAddress3}</span>}
                                </div>

                                <div className="d-flex flex-direction-column">
                                    <label htmlFor="officePostalCode3">Code postal de votre troisième cabinet *</label>
                                    <input type="text"
                                        name="officePostalCode3"
                                        id="officePostalCode3"
                                        value={officePostalCode3 || ''}
                                        minLength="2"
                                        maxLength="8"
                                        required
                                        onChange={(e) => setOfficePostalCode3(e.target.value)}
                                    />
                                    {errors.officePostalCode3 && <span className="text-danger">{errors.officePostalCode3}</span>}
                                </div>

                                <div className="d-flex flex-direction-column">
                                    <label htmlFor="inkPad3">Tampon du votre troisième cabinet</label>
                                    <input type="file"
                                        name="inkPad3"
                                        id="inkPad3"
                                        required
                                        onChange={changeFile3}
                                    />
                                    {/* {errors.inkPad3 && <span className="text-danger">{errors.inkPad3}</span>} */}
                                </div>
                            </div>


                            <div className="d-flex flex-direction-column">
                                <label htmlFor="">Template de facturation *</label>
                                <div className="d-flex template">
                                    <label htmlFor="1" onClick={() => setShow1(true)}>
                                        <picture>
                                            <source srcSet={Template1Webp} type="image/webp" />
                                            <img src={Template1} alt="template de facturation" />
                                        </picture>                                    </label>
                                    <input type="radio" id="1" name="template" value="1" onClick={(e) => setTemplate(e.target.value)} />

                                    <label htmlFor="2" onClick={() => setShow2(true)}>
                                        <picture>
                                            <source srcSet={Template2Webp} type="image/webp" />
                                            <img src={Template2} alt="template de facturation" />
                                        </picture>                                    </label>
                                    <input type="radio" id="2" name="template" value="2" onClick={(e) => setTemplate(e.target.value)} />
                                </div>
                                {errors.template && <span className="text-danger">{errors.template}</span>}
                            </div>


                            {priceResponse.nickname && priceResponse.nickname.match(/premium/i) &&
                                <div className="d-flex col-12 offset-xl-0 col-xl-12 mt-3 pl-0">
                                    <label className="switch">
                                        <input type="checkbox" id="mailCusto" name="mailCusto" onChange={(e) => setMailCusto(e.target.checked)} />
                                        <span className="slider round"></span>
                                    </label>

                                    <div className="col-8 col-md-10 agreeText">
                                        <label htmlFor="mailCusto">J'ai un mail customisé pour mes patients </label>
                                    </div>
                                </div>
                            }
                            {/* {priceResponse.nickname && priceResponse.nickname.match(/premium/i) &&
                                <div className="d-flex col-12 offset-xl-0 col-xl-12 mt-3 pl-0">
                                    <label className="switch">
                                        <input type="checkbox" id="mailCusto" name="mailCusto" onChange={(e) => setMailCusto(e.target.checked)} />
                                        <span className="slider round"></span>
                                    </label>

                                    <div className="col-8 col-md-10 agreeText">
                                        <label htmlFor="mailCusto">J'ai un template de facture customisé pour mes patients </label>
                                    </div>
                                </div>
                            } */}



                            <div className="d-flex col-12 offset-xl-0 col-xl-12 mt-3 pl-0">
                                <label className="switch">
                                    <input type="checkbox" id="ccMailPatient" name="ccMailPatient" onChange={(e) => setCcMailPatient(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>

                                <div className="col-8 col-md-10 agreeText">
                                    <label htmlFor="ccMailPatient">Me mettre en copie des mails envoyés à mes patients</label>
                                </div>
                            </div>

                            <div className="d-flex col-12 offset-xl-0 col-xl-12 mt-3 pl-0">
                                <label className="switch">
                                    <input type="checkbox" id="mentionEi" name="mentionEi" onChange={(e) => setMentionEi(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>

                                <div className="col-8 col-md-10 agreeText"
                                    title="Elle apparaîtra juste après votre nom, comme l’exige le décret du 28/04/2022. Qui est concerné ? Tous les entrepreneurs individuels, auto entrepreneurs et micro entrepreneurs. Pour plus d’informations consultez l'article en lien.">
                                    <label htmlFor="mentionEi">Souhaitez-vous ajouter la <a href="https://blog.aliz.io/index.php/2022/06/03/loi-sur-les-ei-quels-impacts-pour-vos-factures-et-votre-comptabilite/" target="_blank" rel="noopener noreferrer">mention (EI) </a> à votre facture ? </label>
                                    <svg className="exclamationPoint" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="35" height="35">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2.75024C17.1089 2.75024 21.2499 6.89224 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89224 6.89188 2.75024 11.9999 2.75024Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.9" d="M11.995 8.20435V12.6233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.9" d="M11.995 15.7961H12.005" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>

                            {/* <div className="d-flex col-12 offset-xl-0 col-xl-12 mt-3 pl-0">
                                <label className="switch">
                                    <input type="checkbox" id="agree" name="agree" onChange={(e) => setAgree(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>

                                <div className="col-8 col-md-10 agreeText">
                                    <label htmlFor="agree" onClick={() => window.open(
                                        "/conditions-générales/cgu",
                                        '_blank' // <- This is what makes it open in a new window.
                                    )}>J'accepte les conditions générales d'utilisation. *</label>
                                </div>
                            </div> */}
                            <div className="d-flex col-12 offset-xl-0 col-xl-12 mt-3 pl-0">
                                <div className="agreeText" onClick={agreeCgu}>
                                    {agree ?
                                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.3344 2.00018H7.66543C4.26785 2.00018 2.00043 4.43278 2.00043 7.91618V16.0842C2.00043 19.5709 4.2618 22.0002 7.66543 22.0002H16.3334C19.738 22.0002 22.0004 19.5709 22.0004 16.0842V7.91618C22.0004 4.42969 19.7382 2.00018 16.3344 2.00018ZM7.66543 3.50018H16.3344C18.8849 3.50018 20.5004 5.23515 20.5004 7.91618V16.0842C20.5004 18.7653 18.8848 20.5002 16.3334 20.5002H7.66543C5.11519 20.5002 3.50043 18.7655 3.50043 16.0842V7.91618C3.50043 5.23856 5.12077 3.50018 7.66543 3.50018ZM16.0903 9.09685C15.7974 8.80396 15.3225 8.80396 15.0296 9.09685L10.813 13.312L8.97015 11.4697L8.88601 11.3971C8.59235 11.1793 8.1757 11.2036 7.90949 11.47C7.61665 11.7629 7.61675 12.2378 7.90971 12.5306L10.2837 14.9036L10.3678 14.9762C10.6614 15.194 11.078 15.1697 11.3443 14.9035L16.0903 10.1575L16.1629 10.0734C16.3807 9.77978 16.3565 9.36312 16.0903 9.09685Z" fill={"#5A8AFF"} />
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#6c757d" viewBox="0 0 16 16">
                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    }
                                    <p className={`${agree ? "accepted" : ""}`}> J'accepte les conditions spécifiques liées au produit.</p>
                                </div>
                            </div>
                            {errors.agree && <span className="text-danger">{errors.agree}</span>}


                            <p className="text-aliz"> * Champs obligatoire</p>

                            <button className="btn-alizFull mb-5" onClick={subscribed}>Suivant</button>

                        </div>
                    } else {
                        return <div className="mt-5">
                            <span className="text-danger-xl col-12 col-xl-9">Vous avez déjà bénéficié d'un essai gratuit sur cette assistant. <br></br> Activer ou modifier votre souscription en <a href="/espace-client/accueil#souscriptions"> cliquant ici .</a></span>
                        </div>
                    }
                })()}
            </div >


            <Modal show={show1}
                onHide={() => setShow1(false)}
                onClick={() => setShow1(false)}
                centered size="lg">
                <img src={Template1} alt="template de facturation" />
            </Modal>
            <Modal show={show2}
                onHide={() => setShow2(false)}
                onClick={() => setShow2(false)}
                centered size="lg">
                <img src={Template2} alt="template de facturation" />
            </Modal>
        </div >
    )
}
