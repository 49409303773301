import React from 'react'

// Packages
import gsap, { Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";


// Images
import Feature1 from '../../../images/QualificationProspect/Qualification - Illu Block 1@2x.png'
import Feature1Webp from '../../../images/QualificationProspect/Qualification - Illu Block 1@2x.webp'
import Feature2 from '../../../images/QualificationProspect/Qualification - Illu Block 2.svg'
import Feature3 from '../../../images/QualificationProspect/Qualification - Illu Block 3@2x.png'
import Feature3Webp from '../../../images/QualificationProspect/Qualification - Illu Block 3@2x.webp'


export default function FeaturesCalendar() {
    gsap.registerPlugin(ScrollTrigger);

    const sections = document.querySelectorAll('section');

    sections.forEach((section, index) => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: 'top-=800',
                toggleActions: 'play none none reverse',
                end: () => `+=${section.clientHeight / 2}`,
                // scrub: true,
                markers: true
            }
        });
        tl.fromTo(section, 2, {
            x: index === 1 ? -100 : 100,
            opacity: 0,
        },
            {
                x: 0,
                opacity: 1,
                ease: Power2
            }
        );
        //     id: index + 1,
        //     toggleClass: { targets: section, className: "is-active" },
    })

    // ----- Title animation -----
    const title = document.querySelectorAll('h3');
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: title,
            start: 'top-=900',
            end: 'top-=750',
            toggleActions: 'play none none reverse',
            // scrub: true,
            markers: true
        }
    });
    tl.fromTo(title, 1, {
        opacity: 0,
    },
        {
            opacity: 1,
            ease: Power2
        }
    );
    return (
        <div className="Features-view">

            <div className="row justify-content-center mb-5">
                <h3>Découvrez notre solution pour vérifier et <br></br><span> collecter toutes les données légales des entreprises</span></h3>
            </div>

            {/* ----- Feature 1 -----*/}
            <section className="row max-md-flex-direction-column-reverse mt-3 features-block" id="feature1" >

                {/* ----- Explanations ----- */}
                <div className="feature-text offset-1 col-10 offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5 flex-direction-column">

                    <h4>Connexion aux bases légales</h4>

                    <p className="describe-feature mt-3 mb-4">
                        Aliz récupère de nombreuses données récentes dans plusieurs bases légales et permet d’assurer une qualité optimale des informations restituées.
                     </p>


                    <div className="mt-3">
                        <a className="text-aliz d-flex text-decoration-none " href={process.env.REACT_APP_LINK_VERIFLEGAL} target="_blank" rel="noopener noreferrer">
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </a>
                    </div>

                </div>


                {/* ----- Illustration ----- */}
                <div className="offset-3 col-6 offset-sm-3 col-sm-6 offset-md-4 col-md-4 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5 ">
                    <picture>
                        <source srcSet={Feature1Webp} type="image/webp" />
                        <img className="feature-picture calendar-picture" src={Feature1} alt="Qualification de prospects et de données légales d'entreprise" />
                    </picture>
                </div>
            </section>
            {/* ----- End feature 1 ----- */}



            {/* ----- Feature 2 -----*/}
            <section className="row max-md-flex-direction-column mt-1 features-block" id="feature2">
                {/* Illustration */}
                <div className="offset-3 col-6 offset-md-4 col-md-4 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5 ">
                    <img className="feature-picture calendar-picture" src={Feature2} alt="Expert comptable" />
                </div>

                {/* ----- Explanations ----- */}
                <div className="feature-text offset-1 col-10 offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-0 col-xl-5 flex-direction-column">

                    <h4>Contrôle de la qualité de vos données</h4>

                    <p className="describe-feature mt-3 mb-4">
                        Nous avons développé un algorithme permettant d’estimer la qualité de vos données.
                        Une notation vous permettra rapidement d'estimer la qualité des données qui composent votre base de données.
                        Comment prospecter efficacement, envoyer des devis à la bonne adresse ou gérer des campagnes marketing
                        si ces données sont incorrectes?
                    </p>

                    <div className="mt-3">
                        <a className="text-aliz d-flex text-decoration-none" href={process.env.REACT_APP_LINK_VERIFLEGAL} target="_blank" rel="noopener noreferrer">
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </a>
                    </div>

                </div>

            </section>
            {/* ----- End feature 2 -----*/}



            {/* ----- Feature 3 ----- */}
            <section className="row max-md-flex-direction-column-reverse features-block">

                {/* ----- Explanations ----- */}
                <div className="feature-text offset-1 col-10 offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5 flex-direction-column">

                    <h4>Intégration à vos ERP, CRM, Cloud</h4>

                    <p className="describe-feature mt-3 mb-4">
                        Aucune installation de logiciel, déposez vos données sur un cloud, ou autorisez Aliz à accéder à votre ERP ou CRM.
                        Votre assistant sera encore plus efficace !
                    </p>

                    <div className="mt-3">
                        <a className="text-aliz d-flex text-decoration-none " href={process.env.REACT_APP_LINK_VERIFLEGAL} target="_blank" rel="noopener noreferrer">
                            <p className="mr-2">En savoir plus</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </a>
                    </div>

                </div>

                {/* ----- Illustration -----*/}
                <div className="offset-3 col-6 offset-sm-3 col-sm-6 offset-md-4 col-md-4 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5">
                    <picture>
                        <source srcSet={Feature3Webp} type="image/webp" />
                        <img className="feature-picture calendar-picture" src={Feature3} alt="Qualification de prospects et de données légales d'entreprise" />
                    </picture>
                </div>

            </section>
            {/* ----- End feature 3 ----- */}


        </div >

    )
}
