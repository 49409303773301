import React, { useState } from 'react'

// ----- Components -----
import Navbar from '../Nav'
import PersonalInformation from './FormPersonal'
import FacturationInformation from './FormFacturation'
import Popup from '../../Popup'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function Profile() {
    const [isUpdateProfileVisible, setIsUpdateProfileVisible] = useState(false)
    const [isUpdateBillingProfileVisible, setisUpdateBillingProfileVisible] = useState(false)

    return (
        <>
            <Helmet title="Espace client - Profil" />

            <div className="userArea-view">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">
                        <PersonalInformation
                            setIsUpdateProfileVisible={setIsUpdateProfileVisible} />
                        <FacturationInformation
                            setisUpdateBillingProfileVisible={setisUpdateBillingProfileVisible} />
                    </div>
                </div>

                {/* Popup success profile */}
                <Popup
                    text="Votre profil a bien été modifié."
                    isVisible={isUpdateProfileVisible}
                />

                {/* Popup success billing */}
                <Popup
                    text="Votre profil de facturation a été modifié avec succès."
                    isVisible={isUpdateBillingProfileVisible}
                />

            </div>
        </>
    )
}
