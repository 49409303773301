import React from 'react'

// ----- Constants -----
import { testimonials } from "../../services/Constant/testimonials";

// ----- Components -----
import Summary from '../Summary'
import Customer from './Customer'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function CustomerOpinion() {
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Nos clients parlent de nous ! - Aliz</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" content="Découvrez les témoignages de nos clients sur leur expérience avec nos assistants. " />
                <link rel="canonical" href="https://aliz.io/avis-clients" />
                <meta property="og:title" content="Nos clients parlent de nous ! - Aliz" />
            </Helmet>

            <div className="customerOpinion-view">

                <div className="customerOpinion-header row justify-content-center">

                    <h1 className="col-12">Nos clients témoignent</h1>

                    <h2 className="col-8 col-lg-6 col-xl-8">
                        Freelance, Professionnels de santé, Professions libérales, Indépendants, TPE, PME:
                        <br></br>
                        Vous nous faites confiance dans votre quotidien. Voici votre histoire!
                    </h2>

                </div>


                <div className="row justify-content-center customerOpinion-components">
                    {testimonials.map((data, key) => {
                        return (
                            <Customer key={key} data={data} />
                        );
                    })}
                </div>

                <Summary />

            </div>
        </>
    )
}
