import React from 'react'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Components -----
import Navbar from './Nav'


export default function SponsorShip() {

    return (
        <>
            <Helmet title="Espace client - Parrainage" />

            <div className="userArea-view">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">
                        <iframe id="form-monday"  src="https://forms.monday.com/forms/embed/bc0d3fada57dfe20127e66b89eef31d5?r=use1"  title="formulaire monday - demande de parrainage Aliz"></iframe>
                    </div>

                </div>

            </div>
        </>
    )
}
