import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { initPayment } from '../../../../services/User/stripe.js'
import { UpdateActiveSubscription, UpdateTrialSubscription } from '../../../../services/User/subscription.js'

// import AdministrativeTable from '../../../Prices/Table/Administrative/Administrative'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


export default function VerifLegal(props) {

    const [updateResponse, setUpdateResponse] = useState([])
    const [updateErrors, setupdateErrors] = useState([])

    let navigate = useNavigate();

    const [updateTrialResponse, setUpdateTrialResponse] = useState([])
    const [updateTrialErrors, setupdateTrialErrors] = useState([])
    // Checkout Stripe 
    const [sessionId, setSessionId] = useState([])
    const stripe = window.Stripe(process.env.REACT_APP_SK_STRIPE);

    const [prices, setPrices] = useState(props.annual ? {
        'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_ANNUAL_PRICE,
        'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_ANNUAL_PRICE,
        'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_ANNUAL_PRICE
    } : {
        'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_MONTHLY_PRICE,
        'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_MONTHLY_PRICE,
        'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_MONTHLY_PRICE
    })
    const [idPrice, setIdPrice] = useState(props.annual ? {
        'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_ANNUAL,
        'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_ANNUAL,
        'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_ANNUAL
    } : {
        'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_MONTHLY,
        'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_MONTHLY,
        'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_MONTHLY
    })

    useEffect(() => {
        if (props.annual) {
            setPrices({
                'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_ANNUAL_PRICE,
                'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_ANNUAL_PRICE,
                'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_ANNUAL_PRICE
            })
            setIdPrice({
                'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_ANNUAL,
                'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_ANNUAL,
                'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_ANNUAL
            })
        } else {
            setPrices({
                'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_MONTHLY_PRICE,
                'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_MONTHLY_PRICE,
                'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_MONTHLY_PRICE
            })
            setIdPrice({
                'starter': process.env.REACT_APP_VERIFLEGAL_STARTER_MONTHLY,
                'business': process.env.REACT_APP_VERIFLEGAL_BUSINESS_MONTHLY,
                'premium': process.env.REACT_APP_VERIFLEGAL_PREMIUM_MONTHLY
            })
        }
    }, [props.annual]);



    const submit = (priceId, price) => {
        let priceTva = 0;
        if (props.annual) {
            priceTva = (parseInt(price) + (20 * parseInt(price) / 100)) * 12
        } else {
            priceTva = parseInt(price) + (20 * parseInt(price) / 100)
        }
        priceTva = Math.round(priceTva * 100) / 100
        if (props.pack === "Trial" || props.pack === "trial") {
            confirmAlert({
                title: "Activation d'abonnement",
                message: 'Êtes-vous sûr de vouloir activer votre abonnement? (' + priceTva + '€ TTC)',
                buttons: [
                    {
                        label: 'Oui',
                        onClick: () => {
                            UpdateTrialSubscription(setUpdateTrialResponse, setupdateTrialErrors, priceId, props.robot)
                        }
                    },
                    {
                        label: 'Non',
                        onClick: () => alert("L'activation n'a pas été pris en compte")
                    }
                ]
            });
        } else {
            // sinon confirm alert :
            confirmAlert({
                title: "Modification d'abonnement",
                message: 'Êtes-vous sûr de vouloir modifier votre abonnement? (' + priceTva + '€ TTC)',
                buttons: [
                    {
                        label: 'Oui',
                        onClick: () => UpdateActiveSubscription(setUpdateResponse, setupdateErrors, priceId, props.robot)
                    },
                    {
                        label: 'Non',
                        onClick: () => alert("La modification n'a pas été pris en compte")
                    }
                ]
            });
        }
    };



    useEffect(() => {
        if (updateResponse === "success") {
            navigate('/espace-client/accueil?updateSubscription=success')
        }
        // eslint-disable-next-line
    }, [updateResponse])


    useEffect(() => {
        if (updateTrialResponse.update === "success") {
            // Activer ma souscription
            initPayment(updateTrialResponse.priceId, setSessionId, props.robot, props.prices.recurring.usage_type)
        }
        // eslint-disable-next-line
    }, [updateTrialResponse])

    // If initPayment success, we redirect to Stripe Checkout
    useEffect(() => {
        if (typeof sessionId === 'string') {
            stripe.redirectToCheckout({
                sessionId: sessionId
            })
        }
    }, [sessionId, stripe]);



    // feature included in subscription
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;
    // feature not included in subscription
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    return (
        <div className="price-pack" id="verifPack-prices">
            <div className="row justify-content-center max-md-flex-direction-column" >

                {updateErrors.length > 0 && <div className="alert alert-danger">{updateErrors} </div>}
                {updateTrialErrors.length > 0 && <div className="alert alert-danger">{updateTrialErrors} </div>}

                {/* Rate pack 1 "Pack starter" */}
                <div className="rate-block rate-light" >

                    {props.prices.id === idPrice.starter && <div className="currentSubscription">Mon abonnement</div>}

                    <p className="pack-title little-title">Starter</p>
                    <hr></hr>
                    <div className="pack-price">
                        <p className="pack-free">GRATUIT</p>
                    </div>
                    <p className="pack-description">
                        Pour accéder à toutes les données d’une recherche de société, faire des recherches de société en masse et fiabiliser vos bases de données.
                    </p>
                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">
                        <div className="features-description">
                            {feature}
                            <p>Données sur l’entreprise et sur le dirigeant</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Analyse de la qualité des données avec le <b>ALIZ TRUST SCORING</b></p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Accès aux recherches multiples</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>25 crédits/mois pour tester la vérification et le nettoyage de base de données</p>
                        </div>
                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Alerte et mise à jour de votre base de données</p>
                        </div>
                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Accès à l’API AlizVerif pour intégration dans vos systèmes d’informations, ERP, CRM...</p>
                        </div>
                    </div>


                    <button className="btn-alizFull"
                        onClick={() => submit(idPrice.starter, prices.starter)}
                        disabled={props.prices.id === idPrice.starter && !props.pack.match(/Trial/) ? true : false}>
                        Activer abonnement
                    </button>
                </div >
                {/* End Rate pack 1 */}




                {/* Rate pack 2 "Offre Business" */}
                <div className="rate-block rate-light">

                    {props.prices.id === idPrice.business && <div className="currentSubscription">Mon abonnement</div>}
                    <p className="pack-title">Business</p>
                    <hr></hr>
                    <div className="pack-price">
                        <p>{prices.business} €</p>
                        <i>/mois <b>HT</b></i>
                    </div>
                    <p className="pack-description">
                        Tous les avantages du Starter + toutes les fonctionnalités du nettoyage de bases de données pour améliorer la qualité de vos bases fournisseurs et clients.
                    </p>
                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">
                        <div className="features-description">
                            {feature}
                            <p>Données sur l’entreprise et sur le dirigeant</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Analyse de la qualité des données avec le <b>ALIZ TRUST SCORING</b></p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Accès aux recherches multiples</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>500 crédits/mois pour vérifier et nettoyer vos bases de données</p>
                        </div>
                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Alerte et mise à jour de votre base de données</p>
                        </div>
                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Accès à l’API AlizVerif pour intégration dans vos systèmes d’informations, ERP, CRM...</p>
                        </div>
                    </div>


                    <button className="btn-alizFull"
                        onClick={() => submit(idPrice.business, prices.business)}
                        disabled={props.prices.id === idPrice.business && !props.pack.match(/Trial/) ? true : false}>
                        Activer abonnement
                    </button>
                </div>
                {/* End Rate pack 2 */}





                {/* Rate pack 3 "Offre Premium" */}
                <div className="rate-block rate-light">

                    {props.prices.id === idPrice.premium && <div className="currentSubscription">Mon abonnement</div>}


                    <p className="pack-title">Enterprise</p>
                    <hr></hr>
                    <div className="pack-price">
                        <p>Sur devis</p>
                    </div>
                    <p className="pack-description">
                        Tous les avantages du Business + intégrer AlizVerif à votre outil, recevoir des alertes en cas de mise à jour des données et nettoyer en masse vos base de données.
                    </p>
                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">
                        <div className="features-description">
                            {feature}
                            <p>Données sur l’entreprise et sur le dirigeant</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Analyse de la qualité des données avec le <b>ALIZ TRUST SCORING</b></p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Accès aux recherches multiples</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Crédits illimités pour nettoyer et améliorer la qualité de vos base de données</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Alerte et mise à jour de votre base de données</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Accès à l’API AlizVerif pour intégration dans vos systèmes d’informations, ERP, CRM...</p>
                        </div>
                    </div>

                    <button className="btn-alizFull"
                        onClick={() => window.open(process.env.REACT_APP_LINK_CALENDAR_VERIF_ENTERPRISE_PACK, '_blank')}
                        disabled={props.prices.id === idPrice.premium && !props.pack.match(/Trial/) ? true : false}>
                        Activer abonnement
                    </button>
                </div>
                {/* End Rate pack 3 */}


            </div >

            <br></br>
            {/* Price Table */}
            {/* < AdministrativeTable /> */}

            <div className="row mb-5">
                <div className="offset-1 col-10 offset-sm-1 col-sm-8 offset-lg-0 col-lg-6 offset-xl-1 col-xl-10 support">
                    <i>(1) Cette fonctionnalité n'est pas disponible lors du test gratuit.<br></br> Vous pouvez contacter le
                        <a href={process.env.REACT_APP_LINK_CALENDAR_CONTACT_SUPPORT} className="text-aliz" target="_blank" rel="noopener noreferrer"><b> support </b></a>
                        pour plus d'informations
                    </i>
                </div>
            </div>
        </div >
    )
}