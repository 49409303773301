import React from 'react';

// ----- Packages -----
import { Helmet } from 'react-helmet-async';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// ----- Components -----
import PaymentSetupForm from './PaymentSetupForm';

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_SK_STRIPE);

export default function CardProvider() {
  return (
    <>
      <Helmet title="Espace client - Ajouter Moyens de paiements" />

      <div className="AppWrapper">
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <PaymentSetupForm />
        </Elements>
      </div>
    </>
  );
};






