import React from 'react'

export default function Desktop() {
    // feature included in subscription
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-active bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // feature not included in subscription
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-inactive bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    // point of question symbol
    const question = <td className="question"></td>;
    return (
        <table className="col-11 col-xl-10 table table-borderless table-hover">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Starter</th>
                    <th scope="col">Business</th>
                    <th scope="col">Premium</th>
                </tr>
            </thead>

            <tbody>
                {/* 0 , FEATURES */}
                <tr className="table-head">
                    <th scope="row" className="py-3"><h4>Fonctionnalités</h4></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {/* 1 */}
                <tr>
                    <th scope="row"><p>Recherche unitaire (accès aux données d'une société par recherche : nom, SIREN, SIRET ou  nom du dirigeant)</p></th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 2 */}
                <tr>
                    <th scope="row"><p>Recherche multiple (Envoie par e-mail d'un fichier contenant le résultat de vos recherches)</p></th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 3 */}
                <tr>
                    <th scope="row">
                        <p>Vérification et notation de vos données (Envoie par e-mail d'une copie vérifié et noté de votre base)</p>
                    </th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 4 */}
                <tr>
                    <th scope="row">
                        <p>Nettoyage de vos bases de données (Envoie par e-mail d'une copie nettoyé de votre base)</p>
                    </th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 5 */}
                <tr>
                    <th scope="row"><p>Accès à toutes les données des banques de données (Infogreffe, Manageo, INSEE) </p></th>
                    {question}
                    {featureInactive}
                    {feature}
                    {feature}
                </tr>
               




                {/* 10, SERVICES */}
                <tr className="table-head">
                    <th scope="row" className="py-3"><h4>Services</h4></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {/* 1, 11 */}
                <tr>
                    <th scope="row">
                        <p>Nombre de fichiers générés (crédits /mois)</p>
                    </th>
                    {question}
                    <td> <p>25</p></td>
                    <td> <p>500</p></td>
                    <td> <p>personnalisé</p></td>
                </tr>
                 {/* 1, 11 */}
                <tr>
                    <th scope="row">
                        <p>Crédits à l'unité supplémentaire</p>
                    </th>
                    {question}
                    <td> <p>bloquée</p></td>
                    <td> <p>0,10cts</p></td>
                    <td> <p>personnalisé</p></td>
                </tr>
                {/* 3, 13 */}
                <tr>
                    <th scope="row">
                        <p>Support Client</p>
                    </th>
                    {question}
                    <td>
                        <p>Standard</p>
                        <i>(5/7j - 9h-17h / Chat &amp; Mail)</i>
                    </td>
                    <td>
                        <p>Prioritaire</p>
                        <i> (6/7j - 9h-20h / Chat, Mail &amp; Tel)</i>
                    </td>
                    <td>
                        <p>Prioritaire</p>
                        <i> (6/7j - 9h-20h / Chat, Mail &amp; Tel)</i>
                    </td>
                </tr>


            </tbody>
        </table>
    )
}
