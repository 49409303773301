import React from 'react'

export default function Footer() {
    return (
        <div className="Sante2_footer-view">
            <div className="row justify-content-center flex-footer">

                <div className="col-12 offset-xl-4 col-xl-4">
                    <div className="col-12 col-xl-12 copyright">© 2020 by Aliz . Imaginez - Nous réalisons</div>
                </div>
                <div className="col-12 col-xl-4">
                    <a className="offset-2 col-8 offset-lg-4 col-lg-4 offset-xl-1 col-xl-8 btn btn-aliz rdv" href="https://calendly.com/tedconsulting/decouverte-aliz-mon-assistant-virtuel">Prendre rendez-vous</a>
                </div>
            </div>
        </div>
    )
}
