import React from 'react';

export default function Price() {
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;
    return (
        <div className="rate-block">

            <p className="pack-title">Business</p>
            <hr></hr>
            <div className="pack-price">
                <p>20 €</p>
                <i>/mois <b>HT</b></i>
            </div>
            <p className="pack-description">
                Chaque mois, la gestion administrative occupe quasiment 20% du temps. La prise de rendez-vous,
                les emails, les factures, la comptabilité… il est temps de vous concentrer sur l’essentiel : votre métier !
            </p>
            <p className="pack-features">Fonctionnalités principales</p>

            <div className="features-description">
                {feature}
                <p>Lecture en temps réel de vos disponibilités</p>
            </div>

            <div className="features-description">
                {feature}
                <p>Connexion à votre agenda Doctolib</p>
            </div>

            <div className="features-description">
                {feature}
                <p>Envoi par mail et stockage automatique des factures</p>
            </div>

        </div>
    )
}
