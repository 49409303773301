import React from 'react'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Components -----
import Navbar from './Nav'


export default function Assistance() {



    return (
        <>
            <Helmet title="Espace client - Demander assistance" />

            <div className="userArea-view">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">
                        <iframe id="form-monday" title="formulaire monday - demande d'assistance Aliz" src="https://forms.monday.com/forms/embed/fb5233ea73d9e92197b5b425ca4d87bc?r=use1"></iframe>
                    </div>

                </div>

            </div>
        </>
    )
}
