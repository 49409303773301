import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import gsap from "gsap"

import LogoWhite from "../../images/logo/Logo Blanc_Sans Texte.png"
import LogoBlue from "../../images/logo/Logo Couleur_Sans texte.png"
import GoogleDriveLogo from "../../images/Header/Gdrive ico@2x.png"
import Arrow from "../../images/Header/Arrow - Down.svg"
import ArrowRight from "../../images/Header/Arrow-Right.svg"
import OneDrive from "../../images/Header/oneDrive Ico@2x.png"
import Outlook from "../../images/Header/outlook.png"
import Gmail from "../../images/Header/gmail.png"
import GoogleAgenda from "../../images/Header/googleAgenda.png"
import BlogAliz from "../../images/Header/blog-aliz.jpg"
import BlogAlizWebp from "../../images/Header/blog-aliz.webp"


import StatsLogo from "../../images/Header/stats.svg"
import PlannerLogo from "../../images/Header/planner.svg"
import SearchClientLogo from "../../images/Header/search-client.svg"
import BookLogo from "../../images/Header/book.svg"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function HeaderDesktop({token}) {
    const locationPathname = useLocation()

    const [toggled, setToggled] = useState(false);
    const toggledRef = useRef(null);

    const [location, setLocation] = useState('')
    const [match, setMatch] = useState(false)

    const [logo, setLogo] = useState(LogoBlue)

    const [disconnectedUser, setDisconnectedUser] = useState(false)


    // Header text color changes depending on the page
    useEffect(() => {
        setLocation(locationPathname.pathname)
        if (location.match(/faq/i) || location === "/") {
            gsap.to('.nav-item:not(.btn-yellow) , .nav-item a:not(.btn-yellow), .navbar-brand span', { css: { color: "white" }, duration: 0 });
            gsap.set('nav .btn-yellow', { css: { backgroundColor: "#FFCF59", color: "black" } });
            gsap.to('.nav-item img', { filter: 'unset', duration: 0 });

            setLogo(LogoWhite);
            setMatch(true)
        } else {
            gsap.to('.nav-item, .nav-item a', { css: { color: "black" }, duration: 0 });
            gsap.set('nav .btn-yellow', { css: { backgroundColor: "#5A8AFF", color: "white" } });
            gsap.to('.nav-item img', { filter: 'invert(100%)', duration: 0 });
            setLogo(LogoBlue);
            setMatch(false)
        }
        // eslint-disable-next-line
    }, [location]);


    // Show or hide dropdown menu and change text colors
    const toggledMenu = (e) => {
        const idToggled = e.currentTarget.id.substring(0, 10);
        const linkToggled = e.currentTarget.id;

        setToggled(!toggled ? true : false)
        if (!toggled) {
            gsap.fromTo('#' + idToggled + 'Dropdown', { display: 'none', opacity: 0 }, { display: 'block', opacity: 1, duration: 1 });
            gsap.to('#' + linkToggled + ' img', { transform: 'rotate(180deg)', duration: 0 });
            if (match) {
                gsap.to('.nav-item:not(.btn-yellow) , .nav-item a:not(.btn-yellow)', { css: { color: "black", duration: 0 } });
                gsap.to('.navbar-brand span', { css: { color: "#5A8AFF" }, duration: 0 });
                gsap.to('nav .btn-yellow', { css: { backgroundColor: "#5A8AFF", color: "white" }, duration: 0.5 });
                gsap.to('.nav-item img', { filter: 'invert(100%)', duration: 0 });
                setLogo(LogoBlue);
            }
        } else {
            gsap.to('#assistantsDropdown, #ressourcesDropdown', { display: 'none', opacity: 0, duration: 0.5 });
            gsap.to('#assistantsToggled img, #ressourcesToggled img', { transform: 'rotate(0deg)', duration: 0 });
            if (match) {
                gsap.to('.nav-item:not(.btn-yellow) , .nav-item a:not(.btn-yellow)', { css: { color: "white" }, duration: 0 });
                gsap.to('.navbar-brand span', { css: { color: "white" }, duration: 0 });
                gsap.to('nav .btn-yellow', { css: { backgroundColor: "#FFCF59", color: "black" }, duration: 0.5 });
                gsap.to('.nav-item img', { filter: 'invert(0%)', duration: 0 });
                setLogo(LogoWhite);
            }
        }
    };

    // Log off user 
    const disconnected = () => {
        if (localStorage.getItem('tokenAliz')) {
            setDisconnectedUser(true)
        }
    }
    useEffect(() => {
        if (disconnectedUser) {
            localStorage.removeItem('tokenAliz');
            window.location.href = "/";
        }
    }, [disconnectedUser]);

    const arrowRight = <LazyLoadImage
        alt={"Icon Arrow right"}
        src={ArrowRight}
        effect="blur"
        className="arrowRight" />;

    const faqWordpress = process.env.REACT_APP_LINK_WORDPRESS_FAQ
    return (
        <header className="headerDesktop-view">
            <nav className="navbar navbar-expand-xl">

                {/* Logo */}
                <Link className="navbar-brand d-flex" to="/">
                    <LazyLoadImage
                        alt={"Aliz Logo"}
                        src={logo}
                        effect="blur" />
                    <span>Aliz</span>
                </Link>

                {/* This block is responsive  */}
                <div className="collapse navbar-collapse" id="navbarNavDropdown">

                    {/* Nav left */}
                    <ul className="navbar-nav mr-auto">

                        {/* Onclick show/hide dropdown menu assistants*/}
                        <li className="nav-item d-flex" id="assistantsToggled" onClick={toggledMenu}>
                            <p>Nos assistants</p>
                            <img alt={"arrow down"} src={Arrow} />
                        </li>


                        {/* ________________________________Menu dropdown assistants_______________________________ */}
                        <div id="assistantsDropdown" ref={toggledRef}>
                            <hr className="m-0"></hr>

                            <div className="row">

                                {/* Assistants section */}
                                <div className="col-xl-5 mt-5">
                                    <p className="dropdown-title">NOS ASSISTANTS</p>

                                    <div className="row">
                                        <div className="col-xl-6 d-flex flex-direction-column">

                                            <Link to="/nos-solutions/AlizPatientele" className="d-flex mt-4 mb-2" >
                                                <LazyLoadImage
                                                    alt={"Logo charts"}
                                                    src={StatsLogo}
                                                    className="logo"
                                                    effect="blur" />
                                                <div className="body-text ml-3 mt-3">
                                                    <p className="dropdown-text fs-16">{process.env.REACT_APP_BOT_NAME_PATIENTELE}</p>
                                                    <p className="light-text pr-3">De la prise de rendez-vous à la facturation, en passant par l’envoi de mails et la compta, tout y est !</p>
                                                </div>
                                            </Link>
                                            <Link to="/nos-solutions/AlizCalendar" className="d-flex my-2" >
                                                <LazyLoadImage
                                                    alt={"Logo Calendar"}
                                                    src={PlannerLogo}
                                                    className="logo"
                                                    effect="blur" />
                                                <div className="body-text ml-3 mt-3">
                                                    <p className="dropdown-text fs-16">{process.env.REACT_APP_BOT_NAME_CALENDAR}</p>
                                                    <p className="light-text pr-3">On gère vos agendas, et en plus on notifie vos invités.</p>
                                                </div>
                                            </Link>
                                            <Link to="/nos-solutions/AlizVerif" className="d-flex my-2" >
                                                <LazyLoadImage
                                                    alt={"Homme avec une loupe "}
                                                    src={SearchClientLogo}
                                                    className="logo"
                                                    effect="blur" />
                                                <div className="body-text ml-3 mt-3">
                                                    <p className="dropdown-text fs-16">{process.env.REACT_APP_BOT_NAME_VERIFLEGAL}</p>
                                                    <p className="light-text pr-3">On collecte et on vérifie pour vous toutes les données juridiques de vos prospects, clients, fournisseurs...</p>
                                                </div>
                                            </Link>

                                        </div>

                                        <div className="col-xl-6 d-flex flex-direction-column">

                                            <Link to="/nos-solutions/AlizTva" className="d-flex my-4" >
                                                <LazyLoadImage
                                                    alt={"Account book"}
                                                    src={BookLogo}
                                                    className="logo"
                                                    effect="blur" />
                                                <div className="body-text ml-3 mt-2 ">
                                                    <p className="dropdown-text fs-16">{process.env.REACT_APP_BOT_NAME_TVA}</p>
                                                    <p className="light-text pr-3">Finissez-en avec la TVA, déléguez et contrôlez chaque mois la déclaration et les paiements sur impots.gouv</p>
                                                </div>
                                            </Link>

                                        </div>
                                    </div>

                                </div>
                                {/* End assistants section */}


                                <hr className="hr-vertical mt-5"></hr>


                                {/* Intégrations section */}
                                <div className="col-xl-2 mt-5">

                                    <Link className="dropdown-title" to="/integrations">INTÉGRATIONS</Link>

                                    <div className="d-flex my-3">
                                        <LazyLoadImage
                                            alt={"Google Drive logo"}
                                            src={GoogleDriveLogo}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3 mt-2" to="/integrations">Google Drive</Link>
                                    </div>

                                    <div className="d-flex my-3">
                                        <LazyLoadImage
                                            alt={"One Drive logo"}
                                            src={OneDrive}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3 mt-2" to="/integrations">One Drive</Link>
                                    </div>

                                    <div className="d-flex my-3">
                                        <LazyLoadImage
                                            alt={"Outlook logo"}
                                            src={Outlook}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3 mt-2" to="/integrations">Outlook</Link>
                                    </div>

                                    <div className="d-flex my-3">
                                        <LazyLoadImage
                                            alt={"Gmail logo"}
                                            src={Gmail}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3 mt-2" to="/integrations">Gmail</Link>
                                    </div>

                                    <div className="d-flex mt-3 mb-5">
                                        <LazyLoadImage
                                            alt={"Google Agenda logo"}
                                            src={GoogleAgenda}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3 mt-2" to="/integrations">Google agenda</Link>
                                    </div>
                                    <Link to="/integrations" className="border-lightgrey col-xl-8">Tous les connecteurs</Link>

                                </div>
                                {/* End Intégrations section */}

                                {/* Testimonials section */}
                                <div className="col-xl-4 pt-5 px-5 blog-block">
                                    <Link className="dropdown-title" to="/avis-clients">TÉMOIGNAGES</Link>

                                    <div className="d-flex my-4" >
                                        {/* <iframe title="Témoignage de Claire - Osthéopathe" width="125" height="70" src="https://www.youtube.com/embed/eVyB_mgpzsw" frameBorder="0" allowFullScreen></iframe> */}
                                        <div className="ml-3">
                                            <p className="dropdown-text fs-14">Sophie - Osthéopathe à Paris depuis 9 ans </p>
                                            <Link to="/avis-clients" className="discover-article d-flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16">
                                                    <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                                </svg>
                                                <p className="fs-12">Découvrir le témoignage</p>
                                            </Link>
                                        </div>
                                    </div>


                                    <div className="d-flex my-4" >
                                        {/* <iframe title="Témoignage de François - Expert comptable" width="125" height="70" src="https://www.youtube.com/embed/_l0Rrbl1hyI" frameBorder="0" allowFullScreen></iframe> */}
                                        <div className="ml-3">
                                            <p className="dropdown-text fs-14">Jean-Christophe - Gérant Cabinet d'expertise-comptable </p>
                                            <Link to="/avis-clients" className="discover-article d-flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16">
                                                    <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                                </svg>
                                                <p className="fs-12">Découvrir le témoignage</p>
                                            </Link>
                                        </div>
                                    </div>


                                    <Link className="fs-14 blog-link" to="/avis-clients">Tous les témoignages</Link>

                                </div>
                                {/* End Testimonials section */}

                            </div>
                        </div>
                        {/* End dropdown assistants */}

                        <li className="nav-item">
                            <Link to="/avis-clients">Avis clients</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/tarifs">Tarifs</Link>
                        </li>





                        {/* Onclick show/hide dropdown menu ressources */}
                        <li className="nav-item d-flex" id="ressourcesToggled" onClick={toggledMenu}>
                            <p> Ressources </p>
                            <img alt={"arrow down"} src={Arrow} />
                        </li>


                        {/* _________________________________Menu dropdown ressources __________________________*/}
                        <div id="ressourcesDropdown" ref={toggledRef}>
                            <hr className=" m-0"></hr>

                            <div className="row">
                                {/* FAQ section */}
                                <div className="col-xl-3 mt-5">
                                    <a className="dropdown-title" href={faqWordpress} target="_blank" rel="noopener noreferrer">FAQ</a>

                                    <div className="d-flex my-4">
                                        {arrowRight}
                                        <a className="dropdown-text fs-16 ml-4" href={faqWordpress} target="_blank" rel="noopener noreferrer">Comment installer Aliz sur mon ordinateur</a>
                                    </div>

                                    <div className="d-flex my-4">
                                        {arrowRight}
                                        <a className="dropdown-text fs-16 ml-4" href={faqWordpress} target="_blank" rel="noopener noreferrer">Puis-je me connecter avec mon adresse email Google?</a>
                                    </div>

                                    <div className="d-flex my-4">
                                        {arrowRight}
                                        <a className="dropdown-text fs-16 ml-4" href={faqWordpress} target="_blank" rel="noopener noreferrer">Peux-ton résilier son contrat à tout moment?</a>
                                    </div>

                                    <div className="d-flex my-4">
                                        {arrowRight}
                                        <a className="dropdown-text fs-16 ml-4" href={faqWordpress} target="_blank" rel="noopener noreferrer">Pourquoi Aliz me fais économiser du temps chaque jours?</a>
                                    </div>

                                    <div className="my-5">
                                        <a className="faq-link fs-16" href={faqWordpress} target="_blank" rel="noopener noreferrer">Consulter la FAQ</a>
                                    </div>
                                </div>
                                {/* End FAQ section */}


                                {/* Job section */}
                                {/* <div className="col-xl-3 mt-5 pr-4">
                                    <Link className="dropdown-title" to="/">LES MÉTIERS</Link>

                                    <div className="border-lightblue my-4">
                                        <Link className="dropdown-text fs-16" to="/">Expert comptable</Link>
                                        <p className="light-text">Curabitur vel vestibulum risus, eu consequat ante…</p>
                                    </div>

                                    <div className="border-lightblue my-4">
                                        <Link className="dropdown-text fs-16" to="/">Ostéopathe</Link>
                                        <p className="light-text">Curabitur vel vestibulum risus, eu consequat ante…</p>
                                    </div>

                                    <div className="mt-5 border-lightgrey col-xl-6">
                                        <Link to="/">Tous les métiers</Link>
                                    </div>
                                </div> */}
                                {/* End FAQ section */}


                                {/* Partners section */}
                                {/* Intégrations section */}
                                <div className="offset-xl-1 col-xl-4 mt-5">

                                    <Link className="dropdown-title" to="/integrations">INTÉGRATIONS</Link>

                                    <div className="d-flex my-3">
                                        <LazyLoadImage
                                            alt={"Google Drive logo"}
                                            src={GoogleDriveLogo}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3 mt-2" to="/integrations">Google Drive</Link>
                                    </div>

                                    <div className="d-flex my-3">
                                        <LazyLoadImage
                                            alt={"One Drive logo"}
                                            src={OneDrive}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3 mt-2" to="/integrations">One Drive</Link>
                                    </div>

                                    <div className="d-flex my-3">
                                        <LazyLoadImage
                                            alt={"Outlook logo"}
                                            src={Outlook}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3 mt-2" to="/integrations">Outlook</Link>
                                    </div>

                                    <div className="d-flex my-3">
                                        <LazyLoadImage
                                            alt={"Gmail logo"}
                                            src={Gmail}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3" to="/integrations">Gmail</Link>
                                    </div>

                                    <div className="d-flex mt-3 mb-5">
                                        <LazyLoadImage
                                            alt={"Google Agenda logo"}
                                            src={GoogleAgenda}
                                            className="logo"
                                            effect="blur" />
                                        <Link className="dropdown-text fs-16 ml-3" to="/integrations">Google agenda</Link>
                                    </div>

                                    <Link to="/integrations" className="border-lightgrey col-xl-5">Tous les connecteurs</Link>
                                </div>
                                {/* End Intégrations section */}
                                {/* End Partners section */}

                                {/* Partners section */}
                                <div className="col-xl-4 pt-5 px-5 blog-block">
                                    <p className="dropdown-title">LE BLOG</p>

                                    <div className="dropdown-title soon col-xl-6">Votre nouveau blog Aliz est arrivé!</div>
                                    <div className="d-flex my-5" >
                                        <picture>
                                            <source srcSet={BlogAlizWebp} type="image/webp" />
                                            <img className="img-article" src={BlogAliz} alt="Professionels de santé" />
                                        </picture>
                                        {/* <iframe title="Témoignage de Claire - Osthéopathe" width="125" height="70" src="https://www.youtube.com/embed/eVyB_mgpzsw" frameBorder="0" allowFullScreen></iframe> */}
                                        <div className="ml-3">
                                            <p className="dropdown-text fs-14">Claire - Osthéopathe à Paris depuis 12 ans </p>
                                            <a href="https://blog.aliz.io/index.php/2021/11/15/3-idees-pour-gagner-du-temps-en-gerant-mieux-sa-patientele/" className="discover-article d-flex" target="_blank" rel="noopener noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16">
                                                    <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                                </svg>
                                                <p className="fs-12">Découvrir l’article</p>
                                            </a>
                                        </div>
                                    </div>


                                    {/* <div className="d-flex my-4" > */}
                                    {/* <iframe title="Témoignage de François - Expert comptable" width="125" height="70" src="https://www.youtube.com/embed/_l0Rrbl1hyI" frameBorder="0" allowFullScreen></iframe> */}
                                    {/* <div className="ml-3">
                                            <p className="dropdown-text fs-14">François - Expert comptable à Bordeaux </p>
                                            <Link to="/" className="discover-article d-flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16">
                                                    <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                                </svg>
                                                <p className="fs-12">Découvrir l’article</p>
                                            </Link>
                                        </div>
                                    </div> */}


                                    <a className="fs-14 blog-link" href={process.env.REACT_APP_LINK_BLOG} target="_blank" rel="noopener noreferrer">Tous les articles du blog</a>

                                </div>
                                {/* End Partners section */}

                            </div>
                        </div>
                        {/* End dropdown ressources */}
                    </ul>
                    {/* End nav left */}


                    {/* Nav right */}
                    {(() => {
                        if (token) {
                            return <ul className="navbar-nav nav2">
                                <li className="nav-item " onClick={disconnected}><button className="btn-yellow">Déconnexion</button></li>
                            </ul>
                        } else {
                            return <ul className="navbar-nav nav2">
                                <li className="nav-item">
                                    <Link to="/login">Connexion</Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="btn-yellow" to="/register">S'inscrire</Link>
                                </li>
                            </ul>
                        }
                    })()}
                    {/* End nav right */}


                </div>

            </nav>
        </header>
    )
}