import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

// const headers = {
//     'Content-Type': 'application/json'
// };
const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}

export async function removePaymentMethod(paymentMethod, setResponse, setIsLoaded) {
    await axios({
        method: 'POST',
        url: url + 'auth/removePaymentMethod',
        headers: headersAuth,
        data: {
            "paymentMethodId" : paymentMethod
        }
    })
        .then(function (response) {
            setResponse(response.data)
            setIsLoaded(false)
        }, (err) => {
            alert(err)
            setIsLoaded(false)
        });
}


export async function createSetupIntent(setResponse, data) {
    await axios({
        url: url + 'auth/createSetupIntent',
        headers: headersAuth,
        data: data
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data.client_secret)
            }
        }, (err) => {
            setResponse(false)
        });
}

export async function setDefaultPaymentUser(paymentMethod, setResponse, setIsLoad) {
    await axios({
        method: 'POST',
        url: url + 'auth/setDefaultPaymentUser',
        headers: headersAuth,
        data: {
            "paymentMethod": paymentMethod
        }
    })
        .then(function (response) {
            setResponse(response)
            setIsLoad(false)
        }, (err) => {
            // console.log(err)
        });
}


export async function getPaymentMethod(setResponse, setIsLoaded) {
    await axios({
        url: url + 'auth/paymentMethod',
        headers: headersAuth,
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data)
                setIsLoaded(true)
            }
        }, (err) => {
            setResponse(false)
        });
}
