import React, { useEffect, useState } from 'react'

// -----
// Services
// -----
import { getBotPatientieleprofile, setBotPatientieleprofile } from '../../../../services/User/patientele.js'
// import { subscriptionType } from '../../../../services/Constant/subscriptionType'



import Template1 from '../../../../images/User/Template-4_Template-Client.png'
import Template1Webp from '../../../../images/User/Template-4_Template-Client.webp'
import Template2 from '../../../../images/User/Template-3_Template.png'
import Template2Webp from '../../../../images/User/Template-3_Template.webp'

import gsap from 'gsap'
import { Modal } from 'react-bootstrap';

export default function FormAdministrative({ setIsProfessionalProfilUpdateVisible, pack }) {
    const [profile, setProfile] = useState([])
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState('')

    const [profession, setProfession] = useState('')
    const [office, setOffice] = useState('')
    const [office2, setOffice2] = useState('')
    const [office3, setOffice3] = useState('')
    const [officeAddress, setOfficeAddress] = useState('')
    const [officeAddress2, setOfficeAddress2] = useState('')
    const [officeAddress3, setOfficeAddress3] = useState('')
    const [siretNumber, setSiretNumber] = useState('')
    const [adeliNumber, setAdeliNumber] = useState('')
    const [template, setTemplate] = useState('')
    const [doctolib, setDoctolib] = useState('')
    const [mentionEi, setMentionEi] = useState('')
    // const [inkPad, setInkPad] = useState('')

    const [isCheckedOne, setIsCheckedOne] = useState(false)
    const [isCheckedTwo, setIsCheckedTwo] = useState(false)

    useEffect(() => {
        getBotPatientieleprofile(setProfile)
    }, [])

    // set Profile with information already exist
    useEffect(() => {
        if (profile) {
            setProfession(profile.profession)
            setOffice(profile.office)
            setOffice2(profile.office2)
            setOffice3(profile.office3)
            setOfficeAddress(profile.officeAddress)
            setOfficeAddress2(profile.officeAddress2)
            setOfficeAddress3(profile.officeAddress3)
            setSiretNumber(profile.siretNumber)
            setAdeliNumber(profile.adeliNumber)
            setTemplate(profile.template)
            setDoctolib(profile.doctolib)
            setMentionEi(profile.mentionEi)

            // setInkPad(profile.inkPad)

            if (profile.template === "1") {
                setIsCheckedOne(true)
            } else if (profile.template === "2") {
                setIsCheckedTwo(true)
            }
        }
    }, [profile])

    const data = {
        'profession': profession,
        'office': office,
        'office2': office2 === "" ? "" : office2,
        'office3': office3 === "" ? "" : office3,
        'officeAddress': officeAddress,
        'officeAddress2': officeAddress2 === "" ? "" : officeAddress2,
        'officeAddress3': officeAddress3 === "" ? "" : officeAddress3,
        'siretNumber': siretNumber,
        'adeliNumber': adeliNumber,
        'template': template,
        'mentionEi': mentionEi,
        // 'inkPad': "chemin impossible"
    }

    const handleProfile = () => {
        setBotPatientieleprofile(data, setErrors, setSuccess)
    }

    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const showOffice2 = () => {
        gsap.set('.addOffice *', { display: 'none' })
        gsap.set('.blockOffice2 *', { display: 'block' })
        gsap.set('.addOffice2 *', { display: 'block' })

    }

    const showOffice3 = () => {
        gsap.set('.addOffice2 *', { display: 'none' })
        gsap.set('.blockOffice3 *', { display: 'block' })
    }

    useEffect(() => {
        if (success === "success") {
            window.scrollTo(0, 0)
            setIsProfessionalProfilUpdateVisible(true)
            setErrors([])
            setSuccess([])
        }
        // eslint-disable-next-line
    }, [success])
    console.log(office2, officeAddress2, errors)

    return (
        <div>
            <div className="col-xl-8 pl-0 mt-5">

                {/* <h4><a href="/tutoriel/google-drive-patientele">Comment accéder à mon Google Drive?</a></h4>
                <hr></hr> */}

                <h3>Modifier mes informations professionnelles</h3>

                <div className="d-flex flex-direction-column">
                    <label htmlFor="profession">Profession</label>
                    <input type="text"
                        name="profession"
                        id="profession"
                        value={profession || ''}
                        minLength="2"
                        maxLength="70"
                        required
                        onChange={(e) => setProfession(e.target.value)}
                    />
                    {errors.profession && <span className="text-danger">{errors.profession}</span>}
                </div>


                <div className="d-flex">
                    <div className="col-5">
                        <label htmlFor="siretNumber">N° SIRET</label>
                        <input type="text"
                            name="siretNumber"
                            id="siretNumber"
                            value={siretNumber || ''}
                            minLength="13"
                            maxLength="14"
                            required
                            onChange={(e) => setSiretNumber(e.target.value)}
                        />
                        {errors.siretNumber && <span className="text-danger">{errors.siretNumber}<br></br></span>}
                    </div>
                    <div className="offset-2 col-5">
                        <label htmlFor="adeliNumber">N° ADELI</label>
                        <input type="text"
                            name="adeliNumber"
                            id="adeliNumber"
                            value={adeliNumber || ''}
                            minLength="8"
                            maxLength="9"
                            required
                            onChange={(e) => setAdeliNumber(e.target.value)}
                        />
                        {errors.adeliNumber && <span className="text-danger">{errors.adeliNumber}</span>}
                    </div>
                </div>

                <div className="d-flex flex-direction-column">
                    <div className="d-flex" title="Pour tout changement de cabinet, merci de contacter le support.">
                        <label htmlFor="office">Nom de votre Cabinet</label>
                        {/* <svg className="exclamationPoint" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2.75024C17.1089 2.75024 21.2499 6.89224 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89224 6.89188 2.75024 11.9999 2.75024Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.9" d="M11.995 8.20435V12.6233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.9" d="M11.995 15.7961H12.005" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> */}
                    </div>
                    <input type="text"
                        name="office"
                        id="office"
                        value={office || ''}
                        minLength="2"
                        maxLength="70"
                        required
                        onChange={(e) => setOffice(e.target.value)}
                        disabled={doctolib !== null ? true : false}
                    />
                    {errors.office && <span className="text-danger">{errors.office}</span>}
                </div>

                <div className="d-flex flex-direction-column">
                    <div className="d-flex" title="Pour tout changement de cabinet, merci de contacter le support.">
                        <label htmlFor="officeAddress">Adresse du cabinet</label>
                        {/* <svg className="exclamationPoint" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2.75024C17.1089 2.75024 21.2499 6.89224 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89224 6.89188 2.75024 11.9999 2.75024Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.9" d="M11.995 8.20435V12.6233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.9" d="M11.995 15.7961H12.005" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> */}
                    </div>
                    <input type="address"
                        name="officeAddress"
                        id="officeAddress"
                        value={officeAddress}
                        minLength="5"
                        maxLength="100"
                        required
                        onChange={(e) => setOfficeAddress(e.target.value)}
                        disabled={doctolib !== null ? true : false}
                    />
                    {errors.officeAddress && <span className="text-danger">{errors.officeAddress}</span>}
                </div>

                {pack && !pack.match(/Starter/i) &&
                    <div className="d-flex addOffice" onClick={showOffice2}>
                        <label htmlFor="">Ajouter un deuxième cabinet </label>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                    </div>
                }
                <div className="blockOffice2">
                    <div className="d-flex flex-direction-column ">
                        <label htmlFor="office2">Nom de votre deuxième Cabinet</label>
                        <input type="text"
                            name="office2"
                            id="office2"
                            placeholder={office2 || "Optionnel"}
                            value={office2}
                            minLength="2"
                            maxLength="70"
                            required
                            onChange={(e) => setOffice2(e.target.value)}
                            disabled={doctolib !== null ? true : false}
                        />
                        {errors.office2 && <span className="text-danger">{errors.office2}</span>}
                    </div>

                    <div className="d-flex flex-direction-column">
                        <label htmlFor="officeAddress2">Adresse de votre deuxième cabinet</label>
                        <input type="address"
                            name="officeAddress2"
                            id="officeAddress2"
                            placeholder={officeAddress2 || "Optionnel"}
                            value={officeAddress2}
                            minLength="5"
                            maxLength="100"
                            required
                            onChange={(e) => setOfficeAddress2(e.target.value)}
                            disabled={doctolib !== null ? true : false}
                        />
                        {errors.officeAddress2 && <span className="text-danger">{errors.officeAddress2}</span>}
                    </div>
                </div>

                {pack && pack.match(/Premium/i) &&
                    <div className="d-flex addOffice2" onClick={showOffice3}>
                        <label htmlFor="">Ajouter un troisième cabinet </label>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                    </div>
                }
                <div className="blockOffice3">
                    <div className="d-flex flex-direction-column">
                        <label htmlFor="office3">Nom de votre troisième Cabinet</label>
                        <input type="text"
                            name="office3"
                            id="office3"
                            placeholder={office3 || "Optionnel"}
                            value={office3}
                            minLength="2"
                            maxLength="70"
                            required
                            onChange={(e) => setOffice3(e.target.value)}
                            disabled={doctolib !== null ? true : false}
                        />
                        {errors.office3 && <span className="text-danger">{errors.office3}</span>}
                    </div>

                    <div className="d-flex flex-direction-column">
                        <label htmlFor="officeAddress3">Adresse de votre troisième cabinet</label>
                        <input type="address"
                            name="officeAddress3"
                            id="officeAddress3"
                            placeholder={officeAddress3 || "Optionnel"}
                            value={officeAddress3}
                            minLength="5"
                            maxLength="100"
                            required
                            onChange={(e) => setOfficeAddress3(e.target.value)}
                            disabled={doctolib !== null ? true : false}
                        />
                        {errors.officeAddress3 && <span className="text-danger">{errors.officeAddress3}</span>}
                    </div>
                </div>


                <div className="d-flex flex-direction-column">
                    <label htmlFor="">Template de facturation</label>
                    <div className="d-flex template">
                        <label htmlFor="1" onClick={() => setShow1(true)} >
                            <picture>
                                <source srcSet={Template1Webp} type="image/webp" />
                                <img src={Template1} alt="template de facturation" />
                            </picture>
                        </label>
                        <input type="radio" id="1" name="template" value="1" defaultChecked={isCheckedOne || null} onChange={(e) => setTemplate(e.target.value)} />

                        <label htmlFor="2" onClick={() => setShow2(true)}>
                            <picture>
                                <source srcSet={Template2Webp} type="image/webp" />
                                <img src={Template2} alt="template de facturation" />
                            </picture>
                        </label>
                        <input type="radio" id="2" name="template" value="2" defaultChecked={isCheckedTwo || null} onChange={(e) => setTemplate(e.target.value)} />
                    </div>
                    {errors.template && <span className="text-danger">{errors.template}</span>}
                </div>

                <div className="d-flex col-12 offset-xl-0 col-xl-12 mt-3">
                    <label className="switch">
                        <input type="checkbox" id="mentionEi" name="mentionEi" defaultChecked={mentionEi || null} onClick={(e) => setMentionEi(e.target.checked)} />
                        <span className="slider round"></span>
                    </label>

                    <div className="col-8 col-md-10 agreeText"
                        title="Elle apparaîtra juste après votre nom, comme l’exige le décret du 28/04/2022. Qui est concerné ? Tous les entrepreneurs individuels, auto entrepreneurs et micro entrepreneurs. Pour plus d’informations consultez l'article en lien.">
                        <label htmlFor="mentionEi">Souhaitez-vous ajouter la <a href="https://blog.aliz.io/index.php/2022/06/03/loi-sur-les-ei-quels-impacts-pour-vos-factures-et-votre-comptabilite/" target="_blank" rel="noopener noreferrer">mention (EI) </a> à votre facture ? </label>
                        <svg className="exclamationPoint" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="35" height="35">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2.75024C17.1089 2.75024 21.2499 6.89224 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89224 6.89188 2.75024 11.9999 2.75024Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.9" d="M11.995 8.20435V12.6233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.9" d="M11.995 15.7961H12.005" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    </div>
                </div>


                {/* <div className="d-flex flex-direction-column">
                    <label htmlFor="inkPad">Tampon du cabinet</label>
                    <input type="file"
                        name="inkPad"
                        id="inkPad"
                        value={inkPad || ''}
                        required
                        onChange={(e) => setInkPad(e.target.value)}
                    />
                    {errors.officeAddress && <span className="text-danger">{errors.officeAddress}</span>}
                </div> */}


                <button className="btn-alizOut mb-5" onClick={handleProfile}>Enregistrer</button>
            </div>

            <Modal show={show1}
                onHide={() => setShow1(false)}
                onClick={() => setShow1(false)}
                centered size="lg">
                <img src={Template1} alt="template de facturation" />
            </Modal>
            <Modal show={show2}
                onHide={() => setShow2(false)}
                onClick={() => setShow2(false)}
                centered size="lg">
                <img src={Template2} alt="template de facturation" />
            </Modal>

        </div>
    )
}
