import React from 'react'

// Packages
import ReactReadMoreReadLess from "react-read-more-read-less";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Customer({ data }) {
    return (
        <div className="customer-block ">

            <div className="profile-img">
                <LazyLoadImage
                    alt={"picture customer"}
                    src={`./images/Testimonials/${data.picture}`}
                    className=""
                    effect="blur"
                />
            </div>

            <div className="informations-block">

                <h3>{data.firstname}</h3>

                <p className="job">{data.job}</p>

                <div className="opinion-description">
                    <ReactReadMoreReadLess
                        charLimit={150}
                        readMoreText={"Lire plus"}
                        readLessText={"Lire moins"}>
                        {"« " + data.description + " »"}
                    </ReactReadMoreReadLess>
                </div>

            </div>
        </div>
    )
}
