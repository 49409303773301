import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'

// ----- Services ----- 
import { loginUser } from '../../services/User/register.js'
import { GoogleAuth } from '../../services/auth.js'
// MicrosoftAuth
// ----- Images ----- 
import Logo from '../../images/logo/Logo Blanc_Sans Texte.png'
import LogoWebp from '../../images/logo/Logo Blanc_Sans Texte.webp'
// import MicrosoftLogo from '../../images/Login/microsoft.png'
// import MicrosoftLogoWebp from '../../images/Login/microsoft.webp'
import Illustration from '../../images/Login/Illustration - Hero.svg'

// ----- Packages -----
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Helmet } from 'react-helmet-async';

export default function Login({ token }) {

    // ----- Get params -----
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [assistantRedirection, setAssistantRedirection] = useState(query.get("assistant") ? query.get("assistant") : '')
    const [priceRedirection, setPriceRedirection] = useState(query.get("price") ? query.get("price") : '')

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState([])
    const [response, setResponse] = useState([])
    const [isLogged, setIsLogged] = useState(false)
    const [successGoogle, setSuccessGoogle] = useState([])
    const [errorsGoogle, setErrorsGoogle] = useState([])
    // const [responseMicrosoft, setResponseMicrosoft] = useState([])
    // const [errorsMicrosoft, setErrorsMicrosoft] = useState([])

    let navigate = useNavigate()

    const input = useRef();


    useEffect(() => {
        setAssistantRedirection(query.get("assistant"))
        setPriceRedirection(query.get("price"))
    }, [query])

    // ----- After Click sign in google & choose account -----
    const responseGoogle = (response) => {
        setSuccessGoogle([])
        setErrorsGoogle([])
        const tokenId = response.credential
        GoogleAuth(tokenId, setSuccessGoogle, setErrorsGoogle)
    }

    // ----- If connection success, we return a token and set in localStorage -----
    useEffect(() => {
        if (successGoogle.data && errorsGoogle.length === 0) {

            localStorage.setItem('tokenAliz', successGoogle.data.token)

            // ----- We check if register or login -----
            if (typeof successGoogle.data.register !== "undefined" && successGoogle.data.register > 0 && successGoogle.data.register === true) {

                // ----- If we find a price and an assistant, this means that we must redirect the user to the subscription page, step 1 -----
                if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                    window.location.href = '/espace-client/souscription-etape1?assistant=' + assistantRedirection + '&price=' + priceRedirection + '&register=success'

                    // ----- If just find an assistant, this means tgat we mut redirect the user to the prices page -----
                } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                    window.location.href = '/tarifs?register=success&assistant=' + assistantRedirection

                    // ----- else, juste redirect homepage -----
                } else {
                    window.location.href = '/?register=success'
                }

            } else {

                if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                    window.location.href = '/espace-client/souscription-etape1?assistant=' + assistantRedirection + '&price=' + priceRedirection + '&login=success'
                } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                    window.location.href = '/tarifs?login=success&assistant=' + assistantRedirection
                } else {
                    window.location.href = '/?login=success'
                }

            }
        }
        // eslint-disable-next-line
    }, [successGoogle, errorsGoogle])



    const handleLogin = (e) => {
        e.preventDefault();
        setErrors([])
        setResponse([])
        loginUser(setResponse, email, password, setErrors, setIsLogged)
    };

    useEffect(() => {
        if (isLogged === "success" && !response.data.errors) {
            localStorage.setItem('tokenAliz', response.data.token)
            if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                window.location.href = '/espace-client/souscription-etape1?login=success&assistant=' + assistantRedirection + '&price=' + priceRedirection
            } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                window.location.href = '/tarifs?login=success&assistant=' + assistantRedirection
            } else {
                window.location.href = '/?login=success'
            }
        }
        // eslint-disable-next-line
    }, [isLogged, response])


    // ----- Show or hide password -----
    const showPassword = () => {
        if (input.current.type === "password") {
            input.current.type = "text";
        } else {
            input.current.type = "password";
        }
    }

    useEffect(() => {

        if (typeof priceRedirection === "undefined" || priceRedirection === null) {

            if (typeof assistantRedirection === "undefined" || assistantRedirection === null) {
                if (token) {
                    window.location.href = "/espace-client/accueil"
                }
            }

        } else if (typeof assistantRedirection === "undefined" || assistantRedirection === null) {

            if (typeof assistantRedirection === "undefined" || assistantRedirection === null) {
                if (token) {
                    window.location.href = "/espace-client/accueil"
                }
            }
        }
        // eslint-disable-next-line
    }, [token, navigate]);


    // const authHandlerMicrosoft = (err, data) => {
    //     if (typeof data !== "undefined") {
    //         MicrosoftAuth(data.accessToken, setResponseMicrosoft, setErrorsMicrosoft)
    //     }
    // };

    // useEffect(() => {
    //     if (typeof responseMicrosoft.token !== 'undefined') {

    //         localStorage.setItem('tokenAliz', responseMicrosoft.token)

    //         if (typeof responseMicrosoft.register !== "undefined" && responseMicrosoft.register > 0 && responseMicrosoft.register === true) {

    //             if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
    //                 window.location.href = '/espace-client/souscription-etape1?register=success&assistant=' + assistantRedirection + '&price=' + priceRedirection
    //             } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
    //                 window.location.href = '/tarifs?register=success&assistant=' + assistantRedirection
    //             } else {
    //                 window.location.href = '/?register=success'
    //             }

    //         } else {

    //             if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
    //                 window.location.href = '/espace-client/souscription-etape1?login=success&assistant=' + assistantRedirection + '&price=' + priceRedirection
    //             } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
    //                 window.location.href = '/tarifs?login=success&assistant=' + assistantRedirection
    //             } else {
    //                 window.location.href = '/?login=success'
    //             }
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [responseMicrosoft])


    return (
        <>
            <Helmet title="Connexion" />

            <div className="login-view">

                <div className="row justify-content-center">

                    <div className="col-sm-12 col-lg-6 illustration-block">

                        {/* ----- Logo ----- */}
                        <Link className="logo d-flex" to="/" title="Cliquez ici pour retourner à l'accueil">
                            <picture>
                                <source srcSet={LogoWebp} type="image/webp" />
                                <img src={Logo} alt="Aliz Logo" />
                            </picture>
                            <span>Aliz</span>
                        </Link>

                        {/* ----- Illustration ----- */}
                        <img className="illustration-login" src={Illustration} alt="Men under wave" />

                    </div>



                    <div className="col-sm-12 col-lg-6 login-block">

                        <h1 className="offset-2 col-8 offset-xl-3 col-xl-6">Connectez-vous avec Aliz:</h1>

                        <h2 className="offset-2 col-8 offset-xl-3 col-xl-6">Déléguez dès à présent vos tâches chronophages. Votre temps n’est pas extensible, celui d’Aliz…oui !</h2>


                        <div className="d-flex flex-direction-column offset-2 col-8 offset-xl-3 col-xl-6 mt-4">

                            <label htmlFor="email">Email</label>

                            <input type="email"
                                name="email"
                                id="email"
                                className="login-input"
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                            />

                        </div>


                        <div className="d-flex flex-direction-column offset-2 col-8 offset-xl-3 col-xl-6 mt-4">

                            <label htmlFor="password">Mot de passe</label>

                            <div className="d-flex">
                                <input type="password"
                                    name="password"
                                    id="password"
                                    className="login-input"
                                    value={password}
                                    ref={input}
                                    minLength="5"
                                    required
                                    onChange={(e) => setPassword(e.target.value)} />

                                <svg onClick={showPassword} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="showPassword bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                            </div>

                            {errors.message === "Invalid credentials." && <span className="text-danger mt-2">Désolé, Aucun utilisateur n'a été trouvé</span>}

                        </div>


                        <div className="offset-2 col-8 offset-xl-3 col-xl-6">
                            <button className="login-btn" onClick={handleLogin}>Se connecter</button>
                        </div>


                        <Link className="forget-password" to="/mot-de-passe-oublie">Mot de passe oublié?</Link>


                        <Link className="redirect" to={assistantRedirection !== null && priceRedirection !== null ? '/register?assistant=' + assistantRedirection + '&price=' + priceRedirection : assistantRedirection !== null ? '/register?assistant=' + assistantRedirection : '/register'} > S'inscrire</Link>


                        <div className="row my-5" id="btn-block">

                            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
                                <GoogleLogin
                                    onSuccess={responseGoogle}
                                    onError={responseGoogle}
                                />
                            </GoogleOAuthProvider>

                            {/* <MsalProvider instance={pca}>
                                {/* <AuthenticatedTemplate>
                                    <span>This will only render for authenticated users</span>
                                </ AuthenticatedTemplate> */}
                                {/* <UnauthenticatedTemplate  clientId={process.env.REACT_APP_MICROSOFT_APP_ID}>
                                    <span onClick={() => InteractionType.Popup}>This will only render for unauthenticated users</span>
                                </ UnauthenticatedTemplate> 
                                <MsalAuthenticationTemplate >
                                    <span>This will only render for authenticated users.</span>
                                </ MsalAuthenticationTemplate>
                            </ MsalProvider>  */}

                           {/* { withMsal(LoginButton)} */}

                            {/* <MicrosoftLogin
                                clientId={process.env.REACT_APP_MICROSOFT_APP_ID}
                                // authCallback={authHandlerMicrosoft}
                                className=""
                                buttonTheme='light_short'
                            >
                                <button className="microsoft-btn">
                                    <picture>
                                        <source srcSet={MicrosoftLogoWebp} type="image/webp" />
                                        <img src={MicrosoftLogo} alt="Microfost logo" />
                                    </picture>
                                    <p>Connectez-vous avec Outlook</p>
                                </button>
                            </MicrosoftLogin> */}
                        </div>


                        <div className="row justify-content-center">
                            {errorsGoogle.length > 0 && <p className="googleErrors text-danger mt-2">{errorsGoogle}</p>}
                            {/* {errorsMicrosoft.length > 0 && <p className="googleErrors text-danger mt-2">{errorsMicrosoft}</p>} */}
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

