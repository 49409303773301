import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Modal } from 'react-bootstrap';

// ----- Images -----
import DoctolibTuto1 from '../../../../../images/Doctolib/doctolib-tuto1.PNG'
import DoctolibTuto1Webp from '../../../../../images/Doctolib/doctolib-tuto1.webp'
import DoctolibTuto2 from '../../../../../images/Doctolib/doctolib-tuto2.PNG'
import DoctolibTuto2Webp from '../../../../../images/Doctolib/doctolib-tuto2.webp'
import Checked from '../../../../../images/Doctolib/checked.png'
import CheckedWebp from '../../../../../images/Doctolib/checked.webp'
import Error from '../../../../../images/Doctolib/cancel.png'
import ErrorWebp from '../../../../../images/Doctolib/cancel.webp'

// ----- Component -----
import LoaderSuspense from '../../../../LoaderSuspense'

// ----- Services -----
import { connectDoctolib } from '../../../../../services/User/subscription'


export default function Yes() {
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const [response, setResponse] = useState("o")
    // test pour affichage popup
    const [Loaded, setLoaded] = useState("ok");

    let navigate = useNavigate()

    const ConnectionDoctolib = () => {
        setLoaded(false)
        connectDoctolib(setResponse, setLoaded)
    }
    useEffect(() => {
        if ((Loaded === true || Loaded === false)) {

            confirmAlert({
                customUI: ({ onClose }) => {
                    if (!Loaded) {
                        return (
                            <div className="row justify-content-center popup-custom-doctolib">
                                <div className="custom-ui">
                                    <p>Une minute ! nous vérifions que tout soit bien connecté</p>
                                </div>
                                <LoaderSuspense />
                            </div>
                        );
                    } else if (Loaded && typeof response.response !== 'undefined') {
                        return (
                            <div className="row justify-content-center popup-custom-doctolib">
                                <div className="custom-ui">
                                    <p> Félicitations ! <br></br>
                                        Vos comptes Doctolib et Aliz sont désormais connectés</p>


                                    {response.response && response.officeDoctolibMatchAliz &&
                                        <div className="connected-office">
                                            {/* {response.response.numberOffice} */}
                                            <b>Vous avez {response.numberConnected} cabinet(s) connecté(s) à Doctolib dont {response.officeConnectedAndMatch} cabinet(s) dans votre espace professionnel !</b>

                                            {response.officeDoctolibMatchAliz && response.officeDoctolibMatchAliz.map((cabinet, key) =>
                                                <div className="office-block" key={key}>
                                                    <p>{cabinet?.adresse}</p>
                                                    <p>{cabinet?.name}</p>
                                                    <div className="d-flex">
                                                        <p>connecté</p>
                                                        {(() => {
                                                            if (cabinet.connected === true) {
                                                                return <picture>
                                                                    <source srcSet={CheckedWebp} type="image/webp" />
                                                                    <img className="checkedCircle" src={Checked} alt="checked circle" />
                                                                </picture>
                                                            } else {
                                                                return <picture>
                                                                    <source srcSet={ErrorWebp} type="image/webp" />
                                                                    <img className="checkedCircle" src={Error} alt="error icon" />
                                                                </picture>
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                            )

                                            }
                                        </div>}
                                    <p className="support"> Pour toute question, n'hésitez pas à nous contacter en
                                        <a href={process.env.REACT_APP_LINK_CALENDAR_CONTACT_SUPPORT}> cliquant ici</a>
                                    </p>

                                    <div className=""></div>
                                </div>


                                <div className="row justify-content-center w-100 btn-block">
                                    <button className="btn-alizFull col-12 offset-xl-1 col-xl-10" onClick={() => {
                                        onClose()
                                        return navigate('/espace-client/accueil?subscription=success')
                                    }}>
                                        Démarrer mon essai gratuit
                                    </button>
                                    {response.haveNotConnectedOffice === true &&
                                        <button className="btn-greyFull col-12 offset-xl-1 col-xl-10 mt-3"
                                            onClick={() => {
                                                onClose();
                                                setResponse(false)
                                                return ConnectionDoctolib();
                                            }} >
                                            Réssayer
                                        </button>
                                    }
                                </div>
                            </div>
                        );
                    } else if (Loaded && typeof response.response === 'undefined') {
                        return (
                            <div className="row justify-content-center popup-custom-doctolib">
                                <div className="custom-ui" >
                                    <p>
                                        Oups … Il semble que quelque chose ne fonctionne pas <span role="img" aria-label="disappointed">&#128577;</span>
                                        <br></br>
                                        Ne vous inquiétez pas, nous allons vous contacter pour vous accompagner.
                                        <br></br>
                                    </p>
                                    <picture>
                                        <source srcSet={ErrorWebp} type="image/webp" />
                                        <img id="errorCircle" src={Error} alt="checked circle" />
                                    </picture>
                                    <p className="support"> Pour toute question, n'hésitez pas à nous contacter en
                                        <a href={process.env.REACT_APP_LINK_CALENDAR_CONTACT_SUPPORT}> cliquant ici</a>
                                    </p>
                                </div>

                                <div className="row justify-content-center w-100 btn-block">
                                    <button className="btn-greyFull col-12 offset-xl-1 col-xl-10"
                                        onClick={() => {
                                            onClose();
                                            setResponse(false)
                                            return ConnectionDoctolib();
                                        }} >
                                        Réssayer
                                    </button>
                                    <button className="btn-alizFull col-12 offset-xl-1 col-xl-10" onClick={() => {
                                        onClose();
                                        return navigate('/espace-client/accueil?subscription=success')
                                    }}>
                                        Démarrer mon essai gratuit
                                    </button>
                                </div>

                            </div>
                        );
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, [Loaded, response])





    return (
        <>
            <Helmet title="Connecter mon compte Doctolib" />

            <div className="subscription-view container-fluid doctolib-connection">

                <div className="row">

                    <div className="offset-1 col-10 col-md-10 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8 ">
                        <div className="subscribForm-view shadow-frame">
                            <div className="d-flex flex-direction-column">
                                <h1>Assistant de Gestion Patientèle</h1>

                                <div className="row ask-information gmail-explains max-sm-flex-direction-column">
                                    <p className="col-12 col-md-6">
                                        Pas de panique! ça ne prend pas plus de 5 minutes.
                                        <br></br>
                                        Suivez les instructions ci-dessous,
                                        <br></br>
                                        OU cliquez ici pour voir le <a href="https://alizhelp.zendesk.com/hc/fr/articles/4408236302225-Comment-connecter-Doctolib-%C3%A0-mon-assistant-virtuel-de-gestion-patient%C3%A8le-">tutoriel complet</a>.
                                    </p>
                                    <div className="col-12 col-md-6">
                                        <p><u>Adresse email à ajouter à Doctolib:</u></p>
                                        <b id="mail-doctolib">doctolib@aliz.io</b>
                                    </div>
                                </div>

                                <div className="row my-4">
                                    <div className="col-6 imgTuto" onClick={() => setShow1(true)}>
                                        <picture>
                                            <source srcSet={DoctolibTuto1Webp} type="image/webp" />
                                            <img src={DoctolibTuto1} alt="tutoriel: connecter son compte doctolib à Aliz" />
                                        </picture>
                                    </div>

                                    <div className="col-6 imgTuto" onClick={() => setShow2(true)}>
                                        <picture>
                                            <source srcSet={DoctolibTuto2Webp} type="image/webp" />
                                            <img src={DoctolibTuto2} alt="tutoriel: connecter son compte doctolib à Aliz" />
                                        </picture>
                                    </div>
                                </div>

                                <div id="btn-block-2" className="d-flex flex-direction-column-reverse col-12 offset-xl-1 col-xl-10">
                                    <button className="btn-greyFull" id="btn-grey" onClick={() => navigate('/espace-client/accueil?subscription=success')}>Je le ferai plus tard</button>
                                    <button className="btn-alizFull" id="btn-blue" onClick={ConnectionDoctolib}>Vérifier</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Modal show={show1}
                    onHide={() => setShow1(false)}
                    onClick={() => setShow1(false)}
                    centered size="xl">
                    <picture>
                        <source srcSet={DoctolibTuto1Webp} type="image/webp" />
                        <img src={DoctolibTuto1} alt="tutoriel: connecter son compte doctolib à Aliz" />
                    </picture>
                </Modal>
                <Modal show={show2}
                    onHide={() => setShow2(false)}
                    onClick={() => setShow2(false)}
                    centered size="xl">
                    <picture>
                        <source srcSet={DoctolibTuto2Webp} type="image/webp" />
                        <img src={DoctolibTuto2} alt="tutoriel: connecter son compte doctolib à Aliz" />
                    </picture>
                </Modal>
            </div>
        </>
    )
}
