import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Components -----
import GoogleDrive from './GoogleDrive'

export default function HomeStep2({ responseToken }) {
    // Get params 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const subscription = {
        'robot': query.get("assistant"),
        'priceId': query.get("price"),
        'user': responseToken
    }

    let navigate = useNavigate()


    // when assistant or priceId change, we redirect to prices page
    useEffect(() => {
        if (!subscription.robot || !subscription.priceId) {
            navigate("/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_PATIENTELE + "&pack-annuel=true")
        }
    }, [subscription, navigate]);

    useEffect(() => {
        if (query.has('error')) {
            if (query.get("error") === "not_granted") {
                alert("Vous n'avez pas coché toute les cases pour donner les accès à Aliz, veuillez réessayer s'il vous plaît")
                query.delete('error')
                navigate.replace({
                    search: query.toString(),
                })
            }
        }
        // eslint-disable-next-line
    }, [query])

    return (
        <>
            <Helmet title="Souscription - Étape 2" />

            <div className="subscription-view container-fluid">

                <div className="row">

                    <div className="col-12 offset-sm-1 col-sm-10 col-md-10 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8">
                        <GoogleDrive subscription={subscription} />
                    </div>
                </div>

            </div>
        </>
    )
}
