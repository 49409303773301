import React, { useEffect, useState } from 'react'

// ---- Services -----
import { getBotVerifLegalprofile, setBotVerifLegalProfile, regenerateToken } from '../../../../services/User/verifLegal'
import { copyToClipBoard } from '../../../../services/helper'

import GooglePlaces from '../../Subscription/Step1/GooglePlaces';


export default function FormVerifLegal({ setIsProfessionalProfilUpdateVisible }) {
    const [profile, setProfile] = useState([])
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState('')
    const [tokenResponse, setTokenResponse] = useState("")
    const [copy, setCopy] = useState(false)

    const [profession, setProfession] = useState("")
    const [enterprise, setEnterprise] = useState("")
    const [cityEnterprise, setCityEnterprise] = useState("")
    const [token, setToken] = useState("")


    useEffect(() => {
        getBotVerifLegalprofile(setProfile)
    }, [])

    useEffect(() => {
        if (profile) {
            setProfession(profile.profession)
            setEnterprise(profile.enterprise)
            setCityEnterprise(profile.city)
            setToken(profile.token)
        }
    }, [profile])

    const data = {
        'profession': profession,
        'enterprise': enterprise,
        'city': cityEnterprise,
        'token': token,
    }

    const handleProfile = () => {
        setBotVerifLegalProfile(data, setErrors, setSuccess)
    }


    useEffect(() => {
        if (success === "success") {
            setIsProfessionalProfilUpdateVisible(true)
        }
        // eslint-disable-next-line
    }, [success])

    useEffect(() => {
        if (tokenResponse.success) {
            setToken(tokenResponse.success)
            setTimeout(() => {
                setTokenResponse([])
            }, 1000);
        }
    }, [tokenResponse])

    useEffect(() => {
        if (copy) {
            setTimeout(() => {
                setCopy(false)
            }, 1000);
        }
    }, [copy])

    return (
        <div>
            <div className="col-xl-8 pl-0 mt-5">

                <h3 className="mt-5">Vous pouvez vous rendre sur AlizVerif</h3>

                <div className="row justify-content-center">
                    <button className="btn-alizOut" onClick={() => window.open(
                        process.env.REACT_APP_LINK_VERIFLEGAL + "?token=" + localStorage.getItem('tokenAliz'),
                        '_blank' // <- This is what makes it open in a new window.
                    )}>Gérer mes données légales</button>
                </div>

                <h3>Votre token d'API :</h3>

                <input type="text" name="token" value={token} />

                <div class="">
                    <button className="btn-yellow" onClick={() => regenerateToken(setTokenResponse)}>Regénerer</button>
                    <button className="btn-alizFull" onClick={() => copyToClipBoard(setCopy, token)}>Copier</button>
                </div>

                {tokenResponse.success && <div className="alert alert-success mt-3">Regénéré!</div>}
                {copy && <div className="alert alert-success mt-3">Copié!</div>}

                <h3>Modifier mes informations professionnelles</h3>

                <div className="d-flex">
                    <div className="col-5">
                        <label htmlFor="enterprise">Entreprise *</label>
                        <input type="text"
                            name="enterprise"
                            id="enterprise"
                            value={enterprise || ''}
                            minLength="2"
                            required
                            onChange={(e) => setEnterprise(e.target.value)}
                        />
                        {errors.enterprise && <span className="text-danger">{errors.enterprise}</span>}
                    </div>
                    <div className="offset-2 col-5">
                        <label htmlFor="profession">Profession</label>
                        <input type="text"
                            name="profession"
                            id="profession"
                            value={profession || ''}
                            minLength="2"
                            required
                            onChange={(e) => setProfession(e.target.value)}
                        />
                        {errors.profession && <span className="text-danger">{errors.profession}</span>}
                    </div>
                </div>

                <div className="d-flex">
                    <div className="col-5">
                        <div className="d-flex flex-direction-column">
                            <label htmlFor="cityEnterprise">Ville</label>
                            <GooglePlaces officeAddress={cityEnterprise} setOfficeAddress={setCityEnterprise} />

                            {errors.cityEnterprise && <span className="text-danger">{errors.cityEnterprise}</span>}
                        </div>
                    </div>
                </div>

                <button className="btn-alizOut mb-5" onClick={handleProfile}>Enregistrer</button>
            </div>
        </div>
    )
}
