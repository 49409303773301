export const testimonials = [
    {
        firstname: "Jean-Christophe",
        job: "Gérant Cabinet d'expertise-comptable",
        rate: 5,
        description: "On a commencé avec 1 assistant Aliz et puis j'ai équipé 8 de mes collaborateurs. Le gain de temps est considérable. Pour la déclaration de TVA au lieu de 5 jours c'est plus qu'1 jour de travail. Efficace !",
        picture: "person1.jpeg"
    },
    {
        firstname: "Méline",
        job: "Ostéopathe",
        rate: 5,
        description: "J'ai pu tester l'assitant virtuel Aliz, simple d'utilisation, les patients reçoivent automatiquement leurs factures où ils peuvent rajouter leurs informations personnelles. Je gagne du temps derrière avec l'archivage et en plus on utilise moins de papier pour la planète ! Tout bénèf!",
        picture: "melineTestimonial.jpg"
    },
    {
        firstname: "Romain",
        job: "Freelance",
        rate: 5,
        description: "Un assistant virtuel qui coute moins de 30€ et qui me fait gagner 3 jours par mois ?!! J'ai dit oui direct et je recommande à 200%",
        picture: "person2.jpeg"
    },
    {
        firstname: "Ambre",
        job: "Psychologue",
        rate: 5,
        description: "Une équipe réactive et bienveillante allié à un service de qualité ! En tant que libéral, gagner du temps sur les tâches administratives est un vrai confort ! Je recommande vivement !",
        picture: "ambre.jpeg"
    },
    {
        firstname: "Elise",
        job: "Photographe",
        rate: 5,
        description: "J'ai peu de clients par mois et j'avais peur de prendre un assistant pour finalement pas grand chose. Et finalement je me suis rendu compte que ça me déchargeait vraiment de tâches que je déteste faire! Moi qui suis allergique à tout ce qui est administratif :D",
        picture: "person3.jpeg"
    },
    {
        firstname: "Sophie",
        job: "Ostéopathe D.O.",
        rate: 5,
        description: "Une application très pratique, facile d'utilisation et visuelle. Elle me sert à la fois de comptabilité mensuelle et de facturier. Avec l'envoi par mail géré par le robot, les patients peuvent les transmettre directement à leur mutuelle sans avoir à les imprimer. C'est un gain de temps non négligeable et un geste écologique pour chacun",
        picture: "sophie_boicherot.jpg"
    },
];


export const testimonialsPatientele = [
    {
        firstname: "Anne-Laure",
        job: "Psychologue",
        rate: 5,
        description: "Très satisfaite du service de facturation. Un gain de temps considérable. Quelques petites difficultés techniques de mon côté mais qui sont rapidement prise en considération par l'équipe (qui est très disponible et à l'écoute !). Je recommande sans hésiter !",
        picture: "anne-laure.png"
    },
    {
        firstname: "Méline",
        job: "Ostéopathe",
        rate: 5,
        description: "J'ai pu tester l'assitant virtuel Aliz, simple d'utilisation, les patients reçoivent automatiquement leurs factures où ils peuvent rajouter leurs informations personnelles. Je gagne du temps derrière avec l'archivage et en plus on utilise moins de papier pour la planète ! Tout bénèf!",
        picture: "melineTestimonial.jpg"
    },
  
    {
        firstname: "Ambre",
        job: "Psychologue",
        rate: 5,
        description: "Une équipe réactive et bienveillante allié à un service de qualité ! En tant que libéral, gagner du temps sur les tâches administratives est un vrai confort ! Je recommande vivement !",
        picture: "ambre.jpeg"
    },

    {
        firstname: "Sophie",
        job: "Ostéopathe D.O.",
        rate: 5,
        description: "Une application très pratique, facile d'utilisation et visuelle. Elle me sert à la fois de comptabilité mensuelle et de facturier. Avec l'envoi par mail géré par le robot, les patients peuvent les transmettre directement à leur mutuelle sans avoir à les imprimer. C'est un gain de temps non négligeable et un geste écologique pour chacun",
        picture: "sophie_boicherot.jpg"
    },
];
