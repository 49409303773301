export const integrations = [
    {
        title: "Google Drive",
        href: '/tarifs',
        description: "Le Drive de Google permet à votre assistant Aliz d'être plus efficace en partageant avec vous en temps réel vos outils de travail",
        logo: "Gdrive ico@2x.png"
    },
    {
        title: "Microsoft One",
        href: '/tarifs',
        description: "Le Drive de Microsoft est utilsé par nos assistants virtuels pour faciliter la collaboration et le partage",
        logo: "oneDrive.png"
    },
    {
        title: "Google agenda",
        href: '/tarifs',
        description: "Aliz peut accéder à votre agenda Google pour vous décharger de la prise de rendez-vous",
        logo: "googleAgenda.jpg"
    },
    {
        title: "Calendrier Outlook",
        href: '/tarifs',
        description: "Aliz peut accéder à votre agenda Microsoft outlook. Déléguez lui facilement la gestion de vos rendez-vous",
        logo: "outlook.png"
    },
    {
        title: "impots.gouv",
        href: '/tarifs',
        description: "Pour la déclaration de TVA, Aliz se connecte automatiquement à impots.gouv et automatise des tâches pour les comptables",
        logo: "impots.png"
    },
    {
        title: "Bodacc",
        href: '/tarifs',
        description: "Aliz récupère les informations officielles du Bodacc pour le contrôle de données de vos clients, prospects...",
        logo: "bodacc.png"
    },
    {
        title: "INSEE",
        href: '/tarifs',
        description: "Aliz récupère les informations légales et juridiques pour le contrôle de données de vos clients, prospects...",
        logo: "Insee.svg"
    },
    {
        title: "Google Sheet",
        href: '/tarifs',
        description: "Aliz se connecte à vos google sheets pour lancer des actions telles que l'envoi d'email, la génération de factures, la déclaration urssaf, la déclaration TVA...",
        logo: "google-sheets.png"
    },
    {
        title: "Gmail",
        href: '/tarifs',
        description: "Votre assistant peut envoyer des mails depuis une adresse gmail",
        logo: "gmail.png"
    },
    {
        title: "Outlook",
        href: '/tarifs',
        description: "Votre assistant peut envoyer des mails depuis une adresse Outlook",
        logo: "outlook.png"
    },
    {
        title: "Doctolib",
        href: '/tarifs',
        description: "Partagez votre agenda doctolib à votre assistant Aliz, il pourra alors vous faire gagner du temps sur votre facturation et votre comptabilité !",
        logo: "doctolib.png"
    },
    {
        title: "Urssaf (auto-entrepreneur)",
        href: '/tarifs',
        description: "Prochainement!",
        logo: "AE.svg"
    },
];