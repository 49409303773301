import React from 'react'

export default function FormCalendar() {

    return (
        <div>
            <h3 className="mt-5">Vous pouvez vous rendre sur AlizCalendar</h3>
            <div className="row justify-content-center">
                <button className="btn-alizOut" onClick={() => window.open(
                    process.env.REACT_APP_LINK_CALENDAR + "user/aliz/connect?token=" + localStorage.getItem('tokenAliz'),
                    '_blank' // <- This is what makes it open in a new window.
                )}>Gérer mes prises de rendez-vous</button>
            </div>
        </div >
    )
}
