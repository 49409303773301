import React, { useState, useEffect, Suspense, lazy } from 'react'
import { useLocation } from "react-router-dom";

// ----- Services -----
import { getSubscriptions } from '../../../services/User/subscription'
import { getStatistics } from '../../../services/User/clientArea.js'

// ----- Packages -----
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Helmet } from 'react-helmet-async';

// ----- Component -----
import LoaderSuspense from '../../LoaderSuspense'

import Navbar from '../Nav'
import Popup from '../../Popup'

import Pack from './Pack'
import Charts from './Charts'
import ChartsDetails from './ChartsDetails'

import Survey from './Survey'


const LittleFAQ = lazy(() => import("./LitteFAQ"));


export default function Home({ responseToken }) {
    const [isLoadedStats, setIsLoadedStats] = useState(false)
    const [isLoadedSubscription, setIsLoadedSubscription] = useState(false)

    // Get params 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [subscriptions, setSubscriptions] = useState([])

    const [statistics, setStatistics] = useState([])
    const [chartSelected, setChartSelected] = useState(1)
    const [byMonthStatistics, setByMonthStatistics] = useState(1)

    // isVisible
    const [isSuccessSubscribVisible, setIsSuccessSubscribVisible] = useState(false)
    const [isFailedSubscribVisible, setIsFailedSubscribVisible] = useState(false)

    const [isUpdateSubscriptionSuccessIsVisible, setIsUpdateSubscriptionSuccessIsVisible] = useState(false)
    const [isResponseSurveyVisible, setIsResponseSurveyVisible] = useState(false)

    // All Popup
    useEffect(() => {
        if (query.get("subscription") === "success") {
            setIsSuccessSubscribVisible(true)
        }

        if (query.get("updateSubscription") === "success") {
            setIsUpdateSubscriptionSuccessIsVisible(true)
        }

        if (query.get("checkout") === "success") {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <Survey
                            onClose={onClose}
                            assistantPayed={query.get("assistant")}
                            setIsResponseSurveyVisible={setIsResponseSurveyVisible}
                        />
                    );
                }
            });
        }
        if (query.get("checkout") === "failed") {
            setIsFailedSubscribVisible(true)
        }
    }, [query]);


    useEffect(() => {
        getSubscriptions(setSubscriptions, setIsLoadedSubscription)
    }, []);

    useEffect(() => {
        getStatistics(setStatistics, setIsLoadedStats, byMonthStatistics)
    }, [byMonthStatistics])

    return (
        <>
            <Helmet title="Espace client - Accueil" />


            <div className="homeClient-view container-fluid">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-7">
                        <h1> Bonjour {responseToken.firstname && responseToken.firstname} !</h1>
                        <h2 className="catch-phrase">Merci de votre confiance et d'avoir embauché un assistant Aliz ! Vous allez être surpris du temps qu'il va vous faire gagner ce mois-ci <span role="img" aria-label="Emoji clin d'oeil"> &#128521;</span></h2>
                        <p className="dashboard-text">Tableau de bord</p>

                        {statistics.nmbTask > 0 &&
                            <div>
                                <p className="catch-phrase">Statistiques AlizPatientèle:</p>

                                <div className="row dashboard-info">
                                    <div className="square-info">
                                        <p>{statistics.timeWin}</p>
                                        <p>Gagnées</p>
                                    </div>
                                    <div className="square-info">
                                        <p>{statistics.nmbTask}</p>
                                        <p>Tâches exécutées</p>
                                    </div>
                                    <div className="square-info">
                                        <p>{statistics.winThisMonth}</p>
                                        {(() => {
                                            if (typeof byMonthStatistics !== "undefined" && byMonthStatistics === true) {
                                                return <p>Gagnées ce mois-ci</p>
                                            } else {
                                                return <p>Gagnées cette semaine</p>
                                            }
                                        })()}


                                    </div>
                                    <div className="square-info">
                                        <p>{statistics.thisMonth}</p>
                                        {(() => {
                                            if (typeof byMonthStatistics !== "undefined" && byMonthStatistics === true) {
                                                return <p>Tâches exécutées ce mois-ci</p>
                                            } else {
                                                return <p>Tâches exécutées cette semaine</p>
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="chart-choice">
                            <div className={`${chartSelected === 1 ? 'active' : ""}`} onClick={() => setChartSelected(1)}>Général</div>
                            <div className={`${chartSelected === 2 ? 'active' : ""}`} onClick={() => setChartSelected(2)}>Détails</div>
                        </div>

                        {/* Statistics */}
                        {(() => {
                            if (typeof chartSelected !== "undefined" && chartSelected === 1) {
                                return <Charts statistics={statistics} isLoaded={isLoadedStats} />
                            } else if (typeof chartSelected !== "undefined" && chartSelected === 2) {
                                return <ChartsDetails statistics={statistics} isLoaded={isLoadedStats} />
                            }
                        })()}

                        <div className="interval-choice">
                            <div className={`${byMonthStatistics === 1 ? 'active' : ""}`} onClick={() => setByMonthStatistics(1)}>Par mois</div>
                            <div className={`${byMonthStatistics === 2 ? 'active' : ""}`} onClick={() => setByMonthStatistics(2)}>Par semaine</div>
                        </div>


                        <h2 id="souscriptions">Mes souscriptions</h2>

                        {(() => {
                            if (subscriptions.length === 0 && isLoadedSubscription === true) {
                                return <div className="graphic-block">
                                    <h4 className="statsSoon">Vous n'avez souscrit à aucun assistant.</h4>
                                </div>
                            } else if (subscriptions.length > 0 && isLoadedSubscription === true) {
                                return subscriptions && subscriptions.map((subscription, key) =>
                                    <Pack subscription={subscription} key={key} />
                                )
                            } else {
                                return <LoaderSuspense />;
                            }
                        })()}



                        <Suspense fallback={<LoaderSuspense />}>
                            <LittleFAQ />
                        </Suspense>

                    </div>
                </div>


                {/* Popup success subscription */}
                <Popup
                    text="Votre souscription a bien été prise en compte."
                    isVisible={isSuccessSubscribVisible}
                />

                {/* Popup success payment checkout */}
                <Popup
                    text="Merci d'avoir répondu à notre questionnaire, votre avis est précieux! "
                    isVisible={isResponseSurveyVisible}
                />


                {/* Popup success update subscription */}
                <Popup
                    text="La modification de votre souscription a bien été prise en compte! Votre facture va vous être envoyée par mail."
                    isVisible={isUpdateSubscriptionSuccessIsVisible}
                />


                {/* Popup failed payment checkout */}
                <Popup
                    className="failed-popup"
                    text="Oops! une erreur s'est produite lors de votre paiment, veuillez contacter le support."
                    isVisible={isFailedSubscribVisible}
                />
            </div>
        </>
    )
}
