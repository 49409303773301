import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Illustration from '../../../images/CalendarManagement/Agenda - Illu Block Hero.svg'

// ----- Component ----- 
import FeaturesCalendar from "./FeaturesCalendar"
import CalendarPack from "../RatePack"
import LittleFaq from "../../Assistants/FAQ"
import Summary from '../../Summary'

// ----- Services ----- 
import { isCalendarSubscribed } from '../../../services/User/calendar.js'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function CalendarManagement({ token, location }) {

    const [isSubscribedCalendar, setIsSubscribedCalendar] = useState(false)

    useEffect(() => {
        isCalendarSubscribed(setIsSubscribedCalendar)
    }, [])


    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>AlizCalendar, l’assistant de prise de rendez vous en ligne - Aliz</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" content="Plus besoin de téléphone ou d'e-mails pour fixer des rendez-vous. AlizCalendar est un outil de prise de rendez-vous en ligne gratuit et facile d’utilisation." />
                <link rel="canonical" href="https://aliz.io/nos-solutions/AlizCalendar" />
                <meta property="og:title" content="Aliz Calendar, l’assistant de prise de rendez vous en ligne - Aliz" />
            </Helmet>

            <div className="homeAssistant-view">
                {/* ----- Assistant presentation -----*/}
                <div className="row justify-content-center home-block">

                    {/* ----- Catch phrase & Cta -----*/}
                    <div className="col-11 col-sm-10 col-md-11 col-lg-6 flex-direction-column catch-phrase">
                        <h1>Assistant gestion et prise de rendez-vous - {process.env.REACT_APP_BOT_NAME_CALENDAR}</h1>
                        <h2>
                            Prendre des rendez-vous avec vos clients, vos collègues, vos patients demande du temps.
                            Sans compter le choix d'une date de disponibilité commune, les relances par mail et les notifications.
                            Déléguez dès à présent cette charge quotidienne.
                        </h2>



                        <div className={`${isSubscribedCalendar ? 'flex-direction-column' : ""} row btn-block`}>

                            {isSubscribedCalendar ?
                                <button className="hasSubscriptionBtn calendarBtn col-auto" onClick={() => window.open(
                                    process.env.REACT_APP_LINK_CALENDAR + "user/aliz/connect?token=" + localStorage.getItem('tokenAliz'),
                                    '_blank' // <- This is what makes it open in a new window.
                                )}>Accéder à AlizCalendar</button> :
                                <a className="col-auto btn-alizFull" href={process.env.REACT_APP_LINK_CALENDAR_SUBSCRIPTION}>Essayer gratuitement</a>
                            }

                            <Link className={`${!isSubscribedCalendar ? 'offset-1' : "mt-3"} col-auto btn-alizOut`} to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_CALENDAR}>Voir les prix</Link>
                        </div>

                    </div>
                    {/* ----- End catch phrase ----- */}
                    <div className="col-10 col-sm-8 col-md-9 offset-lg-0 col-lg-6">
                        <img src={Illustration} alt="calendar" className="illustration" />
                    </div>
                </div>

                <FeaturesCalendar />

                <CalendarPack token={token} location={location} />

                <LittleFaq />

                <Summary />

            </div>
        </>
    )
}
