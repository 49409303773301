import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// ----- Services -----
import { getPaymentMethod, setDefaultPaymentUser } from '../../../services/User/paymentMethod'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// ----- Components -----
import Navbar from '../Nav'
import Card from './Card'
import SEPA from './SEPA'
import LoaderSuspense from '../../LoaderSuspense'

export default function PaymentMethod(props) {
    const [paymentMethods, setPaymentMethods] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [countCards, setCountCards] = useState(0)
    const [cardDefaultPayementId, setCardDefaultPayementId] = useState("")
    const [responseDefaultPayment, setResponseDefaultPayment] = useState([])
    const [defaultCardChanged, setDefaultCardChanged] = useState(false)
    const [isLoadUpdateDefaultPayment, setisLoadUpdateDefaultPayment] = useState("")

    let navigate = useNavigate()

    useEffect(() => {
        getPaymentMethod(setPaymentMethods, setIsLoaded)
    }, [])

    useEffect(() => {
        if (typeof paymentMethods.CardPaymentMethod !== "undefined") {
            setCountCards(paymentMethods.CardPaymentMethod.length)
            // get Payment Default Id
            paymentMethods.CardPaymentMethod.forEach(card => {
                if (card.defaultPayment === true) {
                    setCardDefaultPayementId(card.id)
                }
            });
        }
    }, [paymentMethods])

    useEffect(() => {
        if (defaultCardChanged === true) {
            setisLoadUpdateDefaultPayment(true)
            return confirmAlert({
                title: "CB par défaut",
                message: 'Êtes-vous sûr de vouloir modifier votre moyen de paiement par défaut?',
                buttons: [
                    {
                        label: 'Oui',
                        onClick: () => setDefaultPaymentUser(cardDefaultPayementId, setResponseDefaultPayment)

                    },
                    {
                        label: 'Non',
                        onClick: () => {
                            setDefaultCardChanged(false)
                            if (paymentMethods.CardPaymentMethod) {
                                paymentMethods.CardPaymentMethod.forEach(card => {
                                    if (card.defaultPayment === true) {
                                        setCardDefaultPayementId(card.id)
                                    }
                                });
                            }
                            alert("La modification n'a pas été pris en compte")
                            window.location.reload()
                        }
                    }
                ]
            });
        }
        // eslint-disable-next-line
    }, [cardDefaultPayementId, defaultCardChanged])

    useEffect(() => {
        if (responseDefaultPayment.status === 200) {
            window.location.href = '/espace-client/mes_moyens_de_paiement?paymentMethod=updateDefaultCard'
        }
    }, [responseDefaultPayment])
    return (
        <>
            <Helmet title="Espace client - Moyens de paiments" />

            <div className="userArea-view">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">
                        <h1> Mes moyens de paiement</h1>
                        <h2 className="catch-phrase mb-5">
                            Voici les moyens de paiement qui serviront à régler vos souscriptions.
                            <br></br>
                            Pour toute demande, vous pouvez contacter le
                            <a href={process.env.REACT_APP_LINK_CALENDAR_CONTACT_SUPPORT} className="text-aliz" target="_blank" rel="noopener noreferrer">
                                <b> support</b>
                            </a>
                        </h2>

                        <div className="allCards">
                            {isLoadUpdateDefaultPayment === true && <LoaderSuspense />}

                            {(() => {
                                if (paymentMethods && !isLoaded) {
                                    return <LoaderSuspense />
                                } else if (paymentMethods && isLoaded) {
                                    return paymentMethods.CardPaymentMethod.map((paymentMethod, key) =>
                                        <Card
                                            paymentMethod={paymentMethod}
                                            key={key}
                                            countCards={countCards}
                                            setDefaultCardChanged={setDefaultCardChanged}
                                            setCardDefaultPayementId={setCardDefaultPayementId}
                                            cardDefaultPayementId={cardDefaultPayementId}
                                        />
                                    )
                                }
                            })()}
                            {(() => {
                                if (paymentMethods && !isLoaded) {
                                    return <LoaderSuspense />
                                } else if (paymentMethods && isLoaded) {
                                    return paymentMethods.SEPAPaymentMethod.map((paymentMethod, key) =>
                                        <SEPA paymentMethod={paymentMethod} key={key} />
                                    )
                                }
                            })()}
                        </div>

                        <div className="d-flex max-xs-flex-direction-column mb-5">
                            <button className="btn-alizFull" onClick={() => navigate("/espace-client/ajouter_cb")}>Ajouter une carte</button>
                            {/* <button id="addCardBtn" className="btn-alizOut" onClick={() => navigate("/espace-client/ajouter_sepa")}>Ajouter un RIB</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
