import React, { useState, useEffect } from 'react'

import { insertSurvey } from '../../../services/User/subscription'

export default function Survey({ onClose, assistantPayed, setIsResponseSurveyVisible }) {
    const [comment, setComment] = useState("")
    const [choice, setChoice] = useState("")
    const [errors, setErrors] = useState([])
    const [responseSurvey, setResponseSurvey] = useState([])

    const data = {
        "choice": choice,
        "comment": comment,
        "robot": assistantPayed
    }
    const insert = () => {
        insertSurvey(data, setResponseSurvey, setErrors)
    }

    useEffect(() => {
        if (responseSurvey === "success") {
            setIsResponseSurveyVisible(true)
            onClose()
        } 
        // eslint-disable-next-line
    }, [responseSurvey])

    return (
        <div className="row justify-content-center popup-custom-survey">
            <h2>
                Félicitations! Votre paiment a bien été pris en compte.
                Une facture va vous être envoyée par mail.
            </h2>

            <div className="custom-ui">
                <h3>Comment avez-vous entendu parler de nous?</h3>

                <div className="input-block">
                    <input
                        onChange={(e) => setChoice(e.target.value)}
                        value="instagram"
                        id="instagram"
                        name="choice"
                        checked={choice === 'instagram'}
                        type="radio"
                    />
                    <label htmlFor="instagram">Instagram</label>
                </div>
                <div className="input-block">
                    <input
                        onChange={(e) => setChoice(e.target.value)}
                        value="facebook"
                        id="facebook"
                        name="choice"
                        type="radio"
                        checked={choice === 'facebook'}

                    />
                    <label htmlFor="facebook">Facebook</label>
                </div>
                <div className="input-block">
                    <input
                        onChange={(e) => setChoice(e.target.value)}
                        value="people"
                        id="people"
                        name="choice"
                        type="radio"
                        checked={choice === 'people'}
                    />
                    <label htmlFor="people">Bouche-à-oreille</label>
                </div>
                <div className="input-block">
                    <input
                        onChange={(e) => setChoice(e.target.value)}
                        value="google search"
                        id="google search"
                        name="choice"
                        type="radio"
                        checked={choice === 'google search'}
                    />
                    <label htmlFor="google search">Recherche Google</label>
                </div>

                <div className="input-block">
                    <input
                        onChange={(e) => setChoice(e.target.value)}
                        value="other"
                        id="other"
                        name="choice"
                        type="radio"
                        checked={choice === 'other'}
                    />
                    <label htmlFor="other">Autre</label>
                </div>
                <div className="input-block mt-5 justify-content-center">
                    <textarea
                        id="outlined-textarea"
                        label="Autre..."
                        placeholder="Votre commentaire ici.."
                        multiline
                        rows={3}
                        onChange={(e) => setComment(e.target.value)}
                    />

                </div>

                <div className="row justify-content-center mt-3">
                    {errors.comment && <span className="text-danger">{errors.comment}<br></br></span>}
                    {errors.choice && <span className="text-danger">{errors.choice}<br></br></span>}
                    {errors.robot && <span className="text-danger">{errors.robot}<br></br></span>}
                </div>

                <div className="row justify-content-center">
                    <button className="col-10 btn-alizFull"
                        onClick={insert}
                    >
                        Accepter
                    </button>
                </div>
            </div>


        </div >
    )
}
