import React, { useState } from 'react'

// ----- Component -----
import AdministrativeOffer from '../Prices/Offers/Administrative'
import TvaOffer from '../Prices/Offers/Tva'
import VerifLegalOffer from '../Prices/Offers/VerifLegal'
import CalendarOffer from '../Prices/Offers/Calendar'


export default function RatePack({ token, location }) {
    const [sub, setSub] = useState(true)

    return (
        <div className="ratePack-view">

            <div className="row justify-content-center">
                <h3 className="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-6">Engagez votre assistant Aliz !</h3>
            </div>

            <div className="row justify-content-center">
                <p className="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-4 presentation">Découvrez nos offres et profitez des à présent d'un <b>essai GRATUIT</b> et <b>sans CB</b> de <b>15 jours</b> sur tous nos assistants</p>
            </div>


            {/* ----- Monthly or Annual subscription ----- */}
            <div className="row justify-content-center my-4">

                <div className="col-auto d-flex">
                    <p className="monthly-pack">PLAN MENSUEL</p>

                    <label className="switch">
                        <input type="checkbox" id="pack" name="pack" checked={sub} onChange={(e) => setSub(e.target.checked)} />
                        <span className="slider round"></span>
                    </label>

                    <p className="annual-pack">PLAN ANNUEL</p>

                    <p className="discount">-30%</p>
                </div>
            </div>

            {(() => {
                if (location.includes(process.env.REACT_APP_BOT_NAME_PATIENTELE)) {
                    return <AdministrativeOffer annual={sub} token={token} />;
                } else if (location.includes(process.env.REACT_APP_BOT_NAME_TVA)) {
                    return <TvaOffer annual={sub} token={token} />
                } else if (location.includes(process.env.REACT_APP_BOT_NAME_CALENDAR)) {
                    return <CalendarOffer annual={sub} token={token} />
                } else if (location.includes(process.env.REACT_APP_BOT_NAME_VERIFLEGAL)) {
                    return <VerifLegalOffer annual={sub} token={token} />
                }
            })()}
        </div>
    )
}
