import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;
const headers = {
    'Content-Type': 'application/json'
};
const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}


// __________________ Check Token ___________________
export async function checkToken(setResponse) {
    await axios({
        url: url + 'auth/user',
        headers: headersAuth,
    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setResponse(response.data)
            } else {
                setResponse(false)
            }
        }, (err) => {
            setResponse(false)
        });
}


export async function GoogleAuth(tokenId, setSuccess, setErrors) {
    await axios({
        method: "post",
        url: url + 'googleAuth',
        headers: headers,
        data: {
            'tokenId': tokenId
        }
    })
        .then(function (response) {
            if (typeof response.data.errors !== "undefined" && response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setSuccess(response);
            }
        }, (err) => {
            setErrors(err)
        });
}


export async function authGoogleDrive(setResponseDrive, location, bot, userId) {
    await axios({
        method: "post",
        url: url + 'auth/googledrive',
        headers: headersAuth,
        data: {
            'location' : location,
            'bot': bot,
            'userId': userId,
        }
    })
        .then((response) => {
            if (response) {
                setResponseDrive(response);
            }
        }, (err) => {
            setResponseDrive(err)
        });
}


export async function setGmailUser(setResponse, setErrors, gmail) {
    await axios({
        method: "post",
        url: url + 'auth/setgmail',
        headers: headersAuth,
        data: {
            'gmail' : gmail
        }
    })
        .then((response) => {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setResponse(response.data);
            }
        }, (err) => {
            setResponse(err)
        });
}


export async function MicrosoftAuth(tokenId, setSuccess, setErrors) {
    await axios({
        method: "post",
        url: url + 'microsoftAuth',
        headers: headers,
        data: {
            'tokenId': tokenId
        }
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setSuccess(response.data);
            }
        }, (err) => {
            setErrors(err)
        });
}

