import React from 'react'

export default function SEPA({paymentMethod}) {
    return (
        <div className="card-block row">

        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 flex-direction-column">
            <p className="card-expire"> {paymentMethod.type.charAt(0).toUpperCase() + paymentMethod.type.slice(1)}</p>
            <p className="card-number">&#x2022; &#x2022; &#x2022; &#x2022; &#160; &#x2022; &#x2022; &#x2022; &#x2022; &#160; &#x2022; &#x2022; &#x2022; &#x2022;  &#160;{paymentMethod.last4}</p>
        </div>
        {/* <div className="card-isDefault col-6 col-sm-4 col-xl-2" >
            <p>Défaut</p>
        </div> */}
    </div>
    )
}
