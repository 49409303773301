import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}


// __________________ New Subscription _______________________

// __________________ Add new subscription patientele ___________________
export async function newSubscriptionPatientele(data, setErrors, setResponse) {
    const formData = new FormData()

    for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
    };
    await axios({
        method: 'POST',
        url: url + 'auth/new_subscription_patientele',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ` + localStorage.getItem('tokenAliz'),
        },
        data: formData
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setResponse("success");
            }
        }, (err) => {
            setResponse(err)
        });
}

// __________________ Add new subscription Tva ___________________
export async function newSubscriptionTva(data, setErrors, setResponse) {
    await axios({
        method: 'POST',
        url: url + 'auth/new_subscription_tva',
        headers: headersAuth,
        data: data
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setResponse("success");
            }
        }, (err) => {
            setResponse(err)
        });
}

// __________________ Add new subscription Calendar ___________________
export async function newSubscriptionCalendar(data, setErrors, setResponse) {
    await axios({
        method: 'POST',
        url: url + 'auth/new_subscription_calendar',
        headers: headersAuth,
        data: data
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setResponse("success");
            }
        }, (err) => {
            setResponse(err)
        });
}

// __________________ Add new subscription Verif Legal ___________________
export async function newSubscriptionVerifLegal(data, setErrors, setResponse) {
    await axios({
        method: 'POST',
        url: url + 'auth/new_subscription_veriflegal',
        headers: headersAuth,
        data: data
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setResponse("success");
            }
        }, (err) => {
            setResponse(err)
        });
}

// ______________________________________________

// __________________ Get all subscription by user ___________________
export async function getSubscriptions(setResponse, setIsLoadedSubscription) {
    await axios({
        url: url + 'auth/subscriptions',
        headers: headersAuth,
    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setResponse(response.data.packs)
                setIsLoadedSubscription(true)
            }
        }, (err) => {
            setResponse(false)
        });
}



export async function getOneSubscription(setResponse, robot) {
    await axios({
        method: "post",
        url: url + 'auth/onesubscription',
        headers: headersAuth,
        data: {
            'robot': robot
        }
    })
        .then((response) => {
            if (response.data.error) {
                setResponse(response.data)
            } else if (response.data.packs) {
                setResponse(response.data.packs)
            }
        }, (err) => {
            setResponse(false)
        });
}


export async function UpdateActiveSubscription(setResponse, setErrors, priceId, robot) {
    await axios({
        method: "post",
        url: url + 'auth/updateActiveSubscription',
        headers: headersAuth,
        data: {
            'priceId': priceId,
            'robot': robot
        }
    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data.update)
            }
        }, (err) => {
            setErrors(err)
        });
}

export async function UpdateTrialSubscription(setResponse, setErrors, priceId, robot) {
    await axios({
        method: "post",
        url: url + 'auth/updateTrialSubscription',
        headers: headersAuth,
        data: {
            'priceId': priceId,
            'robot': robot
        }
    })
        .then((response) => {
            if (response.data.error) {
                setErrors(response.data.error)
            } else if (response.data) {
                setResponse(response.data)
            }
        }, (err) => {
            setErrors(err)
        });
}


// __________________ Add new subscription Tva ___________________
export async function setSubscriptionStatus(data, setResponse) {
    await axios({
        method: 'POST',
        url: url + 'auth/setSubscriptionStatus',
        headers: headersAuth,
        data: data
    })
        .then(function (response) {
            setResponse(response)
        }, (err) => {
            console.log(err)
        });
}

// __________________ Add new subscription Tva ___________________
export async function connectDoctolib(setResponse, setLoaded) {
    await axios({
        method: 'POST',
        url: url + 'auth/connectDoctolib',
        headers: headersAuth,
    })
        .then(function (response) {
            setResponse(response.data)
            setLoaded(true)
        }, (err) => {
            setResponse(err)
        });
}

// __________________ Add new survey  ___________________
export async function insertSurvey(data, setResponse, setErrors) {
    await axios({
        method: 'POST',
        url: url + 'auth/new_survey',
        headers: headersAuth,
        data: data
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setResponse("success");
            }
        }, (err) => {
            setErrors(err)
        });
}

// __________________ Ask survey  ___________________
export async function askReview(setResponse) {
    await axios({
        method: 'GET',
        url: url + 'auth/askReview',
        headers: headersAuth,
    })
        .then(function (response) {
            if(response.data){
                setResponse(response.data);
            }else{
                setResponse(response);
            }
        }, (err) => {
            setResponse(err)
        });
}