import React, { useState, useEffect } from 'react'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Services -----
import { getInvoices } from '../../../services/User/stripe'

// ----- Components -----
import Navbar from '../Nav'
import LoaderSuspense from '../../LoaderSuspense'

export default function Billing() {
    const [invoices, setInvoices] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        getInvoices(setInvoices, setIsLoaded)
    }, [])

    return (
        <>
            <Helmet title="Espace client - Mes factures" />

            <div className="billing-view">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-7">
                        <h1> Mes factures</h1>
                        <h2 className="catch-phrase">Voici les factures annuelles et mensuelles qui correspondent à vos souscriptions.</h2>


                        <div className="row summary">
                            <p className="col-md-12 col-xl-4">Souscription</p>

                            <p className="col-md-8 col-lg-12 col-xl-3">Date de renouvellement</p>
                            <p className="col-md-4 col-lg-6 col-xl-2">Montant</p>
                            <p className="col-md-12 col-lg-4 col-xl-2"></p>
                        </div>


                        {(() => {
                            if (invoices.length === 0 && isLoaded) {
                                return <p className="no-data-fetching">Vous n'avez aucune facture </p>
                            } else if (invoices.length > 0 && isLoaded) {
                                return invoices.map((invoice, key) =>
                                    <div className="invoice-block row" invoice={invoice} key={key}>

                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 flex-direction-column">
                                            <p className="invoice-offer">Offre {invoice.sub.nickname}</p>
                                            <p className="invoice-name">Assistant {invoice.sub.metadata}</p>
                                        </div>

                                        <div className="invoice-end col-md-8 col-lg-12 col-xl-3" title={"Payé le " + invoice.sub.start}>
                                            <p>{invoice.sub.end}</p>
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2.75024C17.1089 2.75024 21.2499 6.89224 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89224 6.89188 2.75024 11.9999 2.75024Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.8" d="M11.995 8.20435V12.6233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.8" d="M11.995 15.7961H12.005" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>

                                        <p className="invoice-paid col-md-4 col-lg-6 col-xl-2"> {invoice.amount_paid} €</p>

                                        <a className="invoice-download col-8 col-sm-6 col-md-5 col-lg-5 col-xl-2" href={invoice.invoice_pdf} target="_blank" rel="noopener noreferrer">Télécharger </a>
                                    </div>
                                )
                            } else {
                                return <LoaderSuspense />
                            }
                        })()}

                    </div>
                </div>
            </div>
        </>
    )
}
