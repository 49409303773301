import React from 'react'
import { Link } from 'react-router-dom'

// ----- Component -----
import FeaturesTva from "./FeaturesTva"
import TvaPack from "../RatePack"
import LittleFaq from "../../Assistants/FAQ"
import Summary from '../../Summary'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

export default function TvaDeclaration({ token, location }) {
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>AlizTva, l’assistant de déclaration de TVA - Aliz</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" content="Chez Aliz, nous aidons les comptables à rester libres ! La préparation des données, les calculs, la saisie dans impots.gouv sont des tâches sans valeur ajoutée. Déléguez les à Aliz. " />
                <link rel="canonical" href="https://aliz.io/nos-solutions/AlizTva" />
                <meta property="og:title" content="L’assistant de Gestion administrative de la patientèle - Aliz" />
            </Helmet>
            
            <div className="homeAssistant-view">

                {/* ----- Assistant presentation ----- */}
                <div className="row justify-content-center home-block">

                    {/* ----- Catch phrase & Cta ----- */}
                    <div className="col-11 col-sm-10 col-md-11 col-lg-6 flex-direction-column catch-phrase">

                        <h1>Assistant déclaration de TVA - {process.env.REACT_APP_BOT_NAME_TVA}.</h1>

                        <h2>
                            La Déclaration TVA est un processus récurrent et qui prend du temps à tout comptables qui s'y frottent.
                            La préparation des données, les calculs, la saisie dans impots.gouv demandent de la rigueur.
                            Pourtant chez Aliz, de nombreux comptables et experts-comptables ont fait le choix de déléguer cette tâche
                            à notre assistant virtuel, spécialement formé pour cette mission !
                        </h2>

                        <div className="row btn-block">
                            <a className="col-auto btn-alizFull" href={process.env.REACT_APP_LINK_CALENDAR_FOOTER_DEMO} target="_blank" rel="noopener noreferrer">Demander une démo</a>
                            <Link className="offset-1 col-auto btn-alizOut" to={"/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_TVA}>Voir les prix</Link>
                        </div>
                    </div>
                    {/* ----- End catch phrase -----*/}

                    <div className="col-11 col-sm-10 offset-lg-1 col-lg-5 offset-xl-0 col-xl-6">
                        <iframe src="https://www.youtube.com/embed/ExBQNjtOgRQ" title="Aliz - Votre assistant de déclaration TVA" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>

                </div>

                <FeaturesTva />

                <TvaPack token={token} location={location} />

                <LittleFaq />

                <Summary />

            </div>
        </>
    )
}
