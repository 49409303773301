import React from 'react'


export default function HowItWork() {
    return (
        <div className="howitwork-view">

            {/* ----- How it Work ----- */}
            <div className="row justify-content-center" id="video">
                <div className="col-9 col-sm-9 col-xl-6 flex-direction-column">

                    <h3>Comment ça marche ?</h3>

                    <p className="description">
                        Aliz est un assistant virtuel.
                        Cet assistant est capable de vous aider sans que vous ne passiez du temps à lui apprendre ses tâches,
                        vous allez pouvoir lui déléguer vos charges administratives.
                    </p>

                </div>
            </div>



            <iframe src={process.env.REACT_APP_ALIZ_ADVERTISING_VIDEO} allowFullScreen title="Présentation d'Aliz et de ses fonctionnalitées" ></iframe>



            {/* ----- Steps how it works ----- */}
            <div className="mt-2 pt-5 row justify-content-center">

                {/* ----- Step 1  ----- */}
                <div className="col-11 col-sm-7 col-md-5 col-lg-4 col-xl-3 px-5 flex-direction-column">

                    <div className="bullet-colored my-4">01</div>

                    <h4>Sélectionnez votre assistant virtuel et prenons contact</h4>

                    <p className="step-description my-4">
                        Choisissez votre assistant, les options et fonctionnalités. Nous allons vous accompagner.
                    </p>

                </div>


                {/* ----- Step 2  ----- */}
                <div className="col-11 col-sm-7 col-md-5 col-lg-4 col-xl-3 px-5 flex-direction-column">

                    <div className="bullet-colored my-4">02</div>

                    <h4>En moins de 20 min, vous êtes prêts à déléguer vos tâches</h4>

                    <p className="step-description my-4">
                        Afin que l’assistant puisse<br></br> parfaitement prendre le relais sur vos tâches chronophages,
                        notre équipe vous aide pour la prise en main si vous le souhaitez.
                    </p>

                </div>


                {/* ----- Step 3  ----- */}
                <div className="col-11 col-sm-7 col-md-5 col-lg-4 col-xl-3 px-5 flex-direction-column">

                    <div className="bullet-colored my-4">03</div>

                    <h4>Vous avez assez travaillé, il est temps pour votre assistant de prendre le relais !</h4>

                    <p className="step-description my-4 ">
                        Vos paramètres mis en place avec notre équipe,
                        votre assistant Aliz est autonome et va vous faire gagner des minutes, des heures.... des jours !
                    </p>

                </div>

            </div>
            {/* ----- End Steps ----- */}
        </div >
    )
}
