import React from 'react'

export default function Tva() {
    return (
        <div className="confirmCgu row justify-content-center">
            <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-1 col-lg-10 col-xl-10">

                <h1 >Conditions spécifiques liées aux produits - Assistant virtuel de Déclaration TVA</h1>
                <br></br>

                <h3>Définitions</h3>

                <p className="conditionsTerms-text">
                    Les « Informations de contact » sont le nom, l'adresse e-mail, le numéro de téléphone, le(s) nom(s) d'utilisateur(s)
                    et les informations similaires envoyées par les visiteurs sur les pages de destination du Client, dans le Service d'abonnement,
                    ou chargées par le Client dans le Service d'abonnement.
                    <br></br>
                    Le produit « DECLARATION TVA » est basé sur un modèle de plans de facturation et engagement annuels ou mensuels
                    et des packs avec différentes options :

                </p>
                <ul className="conditionsTerms-text">
                    <li> Le Pack Starter ;</li>
                    <li> Le Pack Business ;</li>
                    <li> Le Pack Premium ;</li>
                </ul>

                <h3>Frais d'abonnement assistant virtuel Déclaration TVA</h3>

                <p className="conditionsTerms-text">
                    Les Frais d'abonnement aux produit DECLARATION TVA demeurent fixes pendant la Période d'abonnement du Client,
                    à moins que ce dernier :

                    Des informations complémentaires sur les autres ajustements possibles de ces frais sont disponibles dans la suite
                    de la section « Ajustements des frais » ci-dessous.
                    <br></br>
                    Les Frais sont perçus au 1er jour du démarrage de l’abonnement quelque soit le type de pack et le type d’engagement.
                </p>

                <ul className="conditionsTerms-text">
                    <li>
                        (I) ne dépasse le nombre de crédits maximum, la Limite de declarations TVA ou toute autre limite applicable
                        (voir la section « Limites » ci-dessous),
                    </li>
                    <li> (II) ne mette à niveau les produits ou les forfaits de base, ou</li>
                    <li> (III) ne souscrive à des produits ou fonctionnalités supplémentaires, ou </li>
                    <li> (IV) sauf mention contraire dans le Bon de commande.</li>
                </ul>

                <h3>Engagement assistant virtuel Déclaration TVA</h3>

                <p className="conditionsTerms-text">
                    L’engagement pour le produit DECLARATION TVA se fait au moment de la souscription, au choix:
                </p>

                <ul className="conditionsTerms-text">
                    <li>Engagement Annuel</li>
                    <li> Engagement Mensuel</li>
                </ul>

                <p className="conditionsTerms-text">
                    Si le client s’engage annuellement, cela veut dire que pour 365 jours il bénéficie du service sans augmentation tarifaire.
                    <br></br>
                    Il n’est pas possible de changer d’engagement au cours de la période.
                    <br></br>
                    Toute demande de changement d’engagement (passer d’un Annuel à Mensuel) peut être discutée et validée pendant
                    la durée de souscription. Les changements seront appliqués à la date de renouvellement.
                    <br></br>
                    Si le client s’engage mensuellement, il bénéficie donc du service sur un mois glissant.
                    <br></br>
                    S’il décide de s’engager annuellement, c’est alors possible, le nouvel engagement sera alors effectif à la date
                    conclue entre le prestataire et le client.
                    <br></br>
                    Si le changement intervient pendant un mois en cours, les frais d’abonnement de l’engagement seront rétrocédés
                    au prorata selon la date de démarrage de l’abonnement annuel.
                    <br></br>
                    Les Frais sont perçus au 1er jour du démarrage de l’abonnement quelque soit le type de pack et le type d’engagement.
                </p>


                <h3>Ajustements des frais</h3>

                <p className="conditionsTerms-text">
                    Les Frais d'abonnement du Client augmenteront immédiatement au cours d'une Période de facturation si le Client
                    dépasse son nombre de Crédits (nombre de declaration TVA réalisées sur impots.gouv pour le produit DECLARATION TVA)
                    maximum durant une Période de facturation.
                    <br></br>
                    Dans ce cas, les Frais d'abonnement augmenteront en fonction de la tranche tarifaire qui correspond au nombre de factures
                    générées et envoyées par mail aux patients du client, et le montant de la facture du client Aliz augmentera du montant au prorata
                    correspondant pour le reste de la Période d'abonnement. Les tranches tarifaires des Produits Contacts marketing sont définies
                    dans la rubrique TARIFS du site web aliz.io.
                    <br></br>
                    Le Client recevra une notification électronique, s'il approche la limite de sa tranche de Crédits
                    (nombre de declaration TVA réalisées sur impots.gouv).
                </p>


                <h3>Limite d'envois d'e-mails avec facture générée pour le patient</h3>

                <p className="conditionsTerms-text">
                    Pour le produit DECLARATION TVA, lorsque le client est en période d’essai, cette limite est établie au maximum du pack le plus élevé,
                    le pack PREMIUM.
                    <br></br>
                    Dans le cadre d’un engagement mensuel :
                </p>

                <ul className="conditionsTerms-text">
                    <li>
                        Si le Client dépasse sa Limite de crédits (nombre de déclarations TVA réalisées sur impots.gouv),
                        il ne sera pas bloqué, l’assistant virtuel sera capable de traiter de nouvelles déclarations TVA avant le début
                        du prochain mois (à date de renouvellement).
                    </li>
                </ul>

                <p className="conditionsTerms-text">Dans le cadre d’un engagement annuel :</p>

                <ul className="conditionsTerms-text">
                    <li>
                        Les crédits sont utilisables sur toute l’année. Il n’y a pas de surconsommation possible d’un mois à
                        l’autre si le client n’utilise pas le service pendant plusieurs mois.
                        <br></br>
                        Si le Client dépasse sa Limite de crédits sur l’année, (nombre de déclarations TVA réalisées sur impots.gouv),
                        il ne sera pas bloqué, l’assistant virtuel sera capable d'envoyer de nouveaux e-mails.
                        <br></br>
                        Il sera en revanche facturé de la surconsommation à la fin de la souscription annuelle et ce peut importe
                        si le contrat est renouvelé.
                    </li>
                </ul>


                <h3>Modifications</h3>

                <p className="conditionsTerms-text">
                    Aliz se réserve le droit d'apporter des modifications au produit DECLARATION TVA ou aux Outils gratuits
                    susceptibles de réduire considérablement les fonctionnalités fournies au Client durant la Période d'abonnement.
                </p>

            </div>
        </div >
    )
}
