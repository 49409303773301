import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}



export async function getBotVerifLegalprofile(setProfile) {
    await axios({
        url: url + 'auth/botveriflegal',
        headers: headersAuth,

    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setProfile(response.data.tbotveriflegal)
            }
        }, (err) => {
            setProfile(false)
        });
}
export async function setBotVerifLegalProfile(data, setErrors, setSuccess) {
    await axios({
        method: "post",
        url: url + 'auth/setbotveriflegal',
        headers: headersAuth,
        data: data
    })
        .then(function (response) {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setSuccess("success");
            }
        }, (err) => {
            alert(err)
        });
}


export async function isBotVerifLegalSubscribed(setResponse) {
    await axios({
        url: url + 'auth/isVerifLegalSubscribed',
        headers: headersAuth,

    })
        .then((response) => {
            // Send response for check token 
            if (response.data) {
                setResponse(response.data.response)
            }
        }, (err) => {
            setResponse(false)
        });
}


export async function regenerateToken(setResponse) {
    await axios({
        url: url + 'auth/regenerateToken',
        headers: headersAuth,

    })
        .then((response) => {
            if (response.data) {
                setResponse(response.data);
            }
        }, (err) => {
            setResponse(err)
        });
}