import React from 'react'

export default function Desktop() {
    // feature included in subscription
    const feature = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-active bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg></td>;

    // feature not included in subscription
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="features-inactive bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    // point of question symbol
    const question = <td className="question"></td>;
    return (
        <table className="col-11 col-xl-10 table table-borderless table-hover">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Starter</th>
                    <th scope="col">Business</th>
                    <th scope="col">Premium</th>
                </tr>
            </thead>

            <tbody>
                {/* 0 , FEATURES */}
                <tr className="table-head">
                    <th scope="row" className="py-3"><h4>Fonctionnalités</h4></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {/* 1 */}
                <tr>
                    <th scope="row"><p>Intégration Google Meet et Outlook (lien de visioconférence)</p></th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 2 */}
                <tr>
                    <th scope="row"><p>Lien de partage unique pour vos évènements</p></th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 3 */}
                <tr>
                    <th scope="row">
                        <p>Suivi de vos rendez-vous avec notifications Google et Outlook</p>
                    </th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 4 */}
                <tr>
                    <th scope="row">
                        <p>E-mail de rappel pour vous et vos invités</p>
                    </th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 5 */}
                <tr>
                    <th scope="row"><p>Création de 3 types d'événements en illimité</p></th>
                    {question}
                    {featureInactive}
                    {feature}
                    {feature}
                </tr>
               
                {/* 7 */}
                <tr>
                    <th scope="row"><p>Ajout d’un formulaire personnalisable dans vos évènements pour vos invités</p></th>
                    {question}
                    {featureInactive}
                    {feature}
                    {feature}
                </tr>
                {/* 8 */}
                <tr>
                    <th scope="row"><p>Rappel par SMS du rendez-vous pour vos invités</p></th>
                    {question}
                    {featureInactive}
                    {featureInactive}
                    {feature}
                </tr>
                {/* 9 */}
                <tr>
                    <th scope="row"><p>Possibilité de masquer les éléments mentionnant « Aliz »</p></th>
                    {question}
                    {featureInactive}
                    {featureInactive}
                    {feature}
                </tr>




                {/* 10, SERVICES */}
                <tr className="table-head">
                    <th scope="row" className="py-3"><h4>Services</h4></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {/* 1 , 11 */}
                <tr>
                    <th scope="row">
                        <p>Nombre maximal d’agendas connectés à AlizCalendar</p>
                    </th>
                    {question}
                    <td> <p>1</p></td>
                    <td> <p>5</p></td>
                    <td> <p>10</p></td>
                </tr>
                {/* 2, 12 */}
                <tr>
                    <th scope="row">
                        <p>Nombre de rendez-vous par mois</p>
                    </th>
                    {question}
                    <td> <p>illimité</p></td>
                    <td> <p>illimité</p></td>
                    <td> <p>illimité</p></td>
                </tr>
                {/* 3, 13 */}
                <tr>
                    <th scope="row">
                        <p>Support Client</p>
                    </th>
                    {question}
                    <td>
                        <p>Standard</p>
                        <i>(5/7j - 9h-17h / Chat &amp; Mail)</i>
                    </td>
                    <td>
                        <p>Prioritaire</p>
                        <i> (6/7j - 9h-20h / Chat, Mail &amp; Tel)</i>
                    </td>
                    <td>
                        <p>Prioritaire</p>
                        <i> (6/7j - 9h-20h / Chat, Mail &amp; Tel)</i>
                    </td>
                </tr>



                {/* 14, INTEGRATION WEB */}
                <tr className="table-head">
                    <th scope="row" className="py-3"><h4>Intégration web</h4></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {/* 1 , 15 */}
                <tr>
                    <th scope="row">
                        <p>Intégration sur votre site web de votre agenda AlizCalendar</p>
                    </th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 1 , 16 */}
                <tr>
                    <th scope="row">
                        <p>Intégration d’un widget AlizCalendar sur votre site web </p>
                    </th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
                {/* 1 , 17 */}
                <tr>
                    <th scope="row">
                        <p>Intégration d’un lien/bouton de redirection vers votre agenda AlizCalendar </p>
                    </th>
                    {question}
                    {feature}
                    {feature}
                    {feature}
                </tr>
            </tbody>
        </table>
    )
}
