import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'

// ----- Services -----
import { loginUser, registerUser, getGeoInfo } from '../../services/User/register.js'
import { GoogleAuth } from '../../services/auth.js'
// MicrosoftAuth
// ----- Packages ----- 
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
// import { MicrosoftLogin } from "react-microsoft-login";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Helmet } from 'react-helmet-async';

// ----- Images ----- 
// import MicrosoftLogo from '../../images/Login/microsoft.png'
// import MicrosoftLogoWebp from '../../images/Login/microsoft.webp'
import Logo from '../../images/logo/Logo Blanc_Sans Texte.png'
import LogoWebp from '../../images/logo/Logo Blanc_Sans Texte.webp'
import Illustration from '../../images/Login/Illustration - Hero.svg'

// ----- Component ----- 
import Cgu from './Cgu'


export default function Register({ token }) {

    // ----- Get params -----
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [assistantRedirection, setAssistantRedirection] = useState(query.get("assistant") ? query.get("assistant") : '')
    const [priceRedirection, setPriceRedirection] = useState(query.get("price") ? query.get("price") : '')

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [city, setCity] = useState("")
    const [phone, setPhone] = useState("")

    const [agree, setAgree] = useState(false)

    const [success, setSuccess] = useState("")
    const [errors, setErrors] = useState([])

    const [responseLogin, setResponseLogin] = useState([])
    const [isLogged, setIsLogged] = useState(false)
    const [successGoogle, setSuccessGoogle] = useState([])
    const [errorsGoogle, setErrorsGoogle] = useState([])

    // const [responseMicrosoft, setResponseMicrosoft] = useState([])
    // const [errorsMicrosoft, setErrorsMicrosoft] = useState([])


    let navigate = useNavigate()

    const input = useRef();



    useEffect(() => {
        setAssistantRedirection(query.get("assistant"))
        setPriceRedirection(query.get("price"))
    }, [query])

    // ----- After Click sign in google & choose account -----
    const responseGoogle = (response) => {
        const tokenId = response.credential
        console.log(response)
        GoogleAuth(tokenId, setSuccessGoogle, setErrorsGoogle)
    }

    // ----- if connection success, we return a token and set in localStorage -----
    useEffect(() => {
        if (successGoogle.data && errorsGoogle.length === 0) {

            localStorage.setItem('tokenAliz', successGoogle.data.token)

            if (typeof successGoogle.data.register !== "undefined" && successGoogle.data.register > 0 && successGoogle.data.register === true) {

                // ----- if we find a price and an assistant, this means that we must redirect the user to the subscription page, step 1 -----
                if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                    window.location.href = '/espace-client/souscription-etape1?assistant=' + assistantRedirection + '&price=' + priceRedirection + '&login=success'

                    // ----- If just find an assistant, this means tgat we mut redirect the user to the prices page -----
                } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                    window.location.href = '/tarifs?register=success&assistant=' + assistantRedirection

                    // ----- else, juste redirect homepage -----
                } else {
                    window.location.href = '/?register=success'
                }

            } else {

                if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                    window.location.href = '/espace-client/souscription-etape1?assistant=' + assistantRedirection + '&price=' + priceRedirection + '&login=success'
                } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                    window.location.href = '/tarifs?login=success&assistant=' + assistantRedirection
                } else {
                    window.location.href = '/?login=success'
                }
            }
        }
        // eslint-disable-next-line
    }, [successGoogle, errorsGoogle])


    // ----- Agree Button section 
    const agreeBtnActived = useRef();


    const agreeCgu = () => {
        confirmAlert({

            customUI: ({ onClose }) => {
                return (
                    <div className="row justify-content-center popup-custom">

                        <div className="d-flex justify-content-center">
                            <a href="#cgu" className="btn-alizOut">Voir CGU</a>
                            <a href="#cgv" className="btn-alizOut">Voir CGV</a>
                        </div>

                        <div className="custom-ui" >
                            <Cgu />
                        </div>

                        <div className="d-flex justify-content-center">

                            <button className="btn-red"
                                onClick={() => {
                                    setAgree(false)
                                    onClose();
                                }}
                            >
                                Refuser
                            </button>

                            <button className="btn-alizFull"
                                ref={agreeBtnActived}
                                onClick={() => {
                                    setAgree(true)
                                    onClose();
                                }}
                            >
                                Accepter
                            </button>

                        </div>
                    </div >
                );
            }
        });
    }


    const data = {
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "password": password,
        "phone": phone,
        "city": typeof city !== "undefined" ? city : '',
        "agree": agree
    }

    const handleRegister = () => {
        setErrors([]);
        registerUser({ data }, setErrors, setSuccess)
    };

    // ----- GEOLOCALISATION USER -----
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            getGeoInfo(setCity, position.coords.latitude, position.coords.longitude)
        });
    }, [city])


    useEffect(() => {
        if (success === "success") {
            loginUser(setResponseLogin, email, password, setErrors, setIsLogged)
        }
        // eslint-disable-next-line
    }, [success])


    useEffect(() => {
        if (isLogged && !responseLogin.data.errors) {
            localStorage.setItem('tokenAliz', responseLogin.data.token)
            if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                window.location.href = '/espace-client/souscription-etape1?register=success&assistant=' + assistantRedirection + '&price=' + priceRedirection
            } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
                window.location.href = '/tarifs?register=success&assistant=' + assistantRedirection
            } else {
                window.location.href = '/?register=success'
            }
        }
        // eslint-disable-next-line
    }, [isLogged, navigate, responseLogin])


    // ----- Check if user not logged -----
    useEffect(() => {
        if (token) {
            window.location.href = "/espace-client/accueil"
        }
    }, [token, navigate]);


    // ----- Show or hide password -----
    const showPassword = () => {
        if (input.current.type === "password") {
            input.current.type = "text";
        } else {
            input.current.type = "password";
        }
    }


    // const authHandlerMicrosoft = (err, data) => {
    //     MicrosoftAuth(data.accessToken, setResponseMicrosoft, setErrorsMicrosoft)
    // };

    // useEffect(() => {
    //     if (typeof responseMicrosoft.token !== 'undefined') {

    //         localStorage.setItem('tokenAliz', responseMicrosoft.token)

    //         if (typeof responseMicrosoft.register !== "undefined" && responseMicrosoft.register > 0 && responseMicrosoft.register === true) {

    //             if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
    //                 window.location.href = '/espace-client/souscription-etape1?register=success&assistant=' + assistantRedirection + '&price=' + priceRedirection
    //             } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
    //                 window.location.href = '/tarifs?register=success&assistant=' + assistantRedirection
    //             } else {
    //                 window.location.href = '/?register=success'
    //             }

    //         } else {

    //             if (typeof priceRedirection !== "undefined" && priceRedirection !== null && typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
    //                 window.location.href = '/espace-client/souscription-etape1?login=success&assistant=' + assistantRedirection + '&price=' + priceRedirection
    //             } else if (typeof assistantRedirection !== "undefined" && assistantRedirection !== null) {
    //                 window.location.href = '/tarifs?login=success&assistant=' + assistantRedirection
    //             } else {
    //                 window.location.href = '/?login=success'
    //             }
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [responseMicrosoft])




    return (
        <>
            <Helmet title="Inscription" />

            <div className="login-view">

                <div className="row justify-content-center">

                    <div className="col-sm-12 col-lg-6 illustration-block">

                        {/* ----- Logo -----*/}
                        <Link className="logo d-flex" to="/" title="Cliquez ici pour retourner à l'accueil">
                            <picture>
                                <source srcSet={LogoWebp} type="image/webp" />
                                <img src={Logo} alt="Aliz Logo" />
                            </picture>
                            <span>Aliz</span>
                        </Link>

                        {/* ----- Illustration -----*/}
                        <img className="illustration-login" src={Illustration} alt="Men under wave" />

                    </div>



                    <div className="col-sm-12 col-lg-6 login-block">

                        {/* <div className="border-connexion"> */}

                        <h1 className="offset-2 col-8 offset-xl-3 col-xl-6">Créez un compte Aliz:</h1>

                        <h2 className="offset-2 col-8 offset-xl-3 col-xl-6">Déléguez dès à présent vos tâches chronophages. Votre temps n’est pas extensible, celui d’Aliz…oui !</h2>


                        <div className="d-flex offset-2 col-8 offset-xl-3 col-xl-6 mt-4 px-0">

                            <div className="col-5">
                                <label htmlFor="lastname">Nom</label>
                                <input type="text"
                                    name="lastname"
                                    id="lastname"
                                    value={lastname}
                                    minLength="2"
                                    maxLength="50"
                                    required
                                    onChange={(e) => setLastname(e.target.value)} />
                                {errors.lastname && <span className="text-danger">{errors.lastname}<br></br></span>}
                            </div>

                            <div className="offset-2 col-5">

                                <label htmlFor="firstname">Prénom</label>

                                <input type="text"
                                    name="firstname"
                                    id="firstname"
                                    value={firstname}
                                    minLength="2"
                                    maxLength="50"
                                    required
                                    onChange={(e) => setFirstname(e.target.value)} />

                                {errors.firstname && <span className="text-danger">{errors.firstname}</span>}

                            </div>

                        </div>



                        <div className="d-flex flex-direction-column offset-2 col-8 offset-xl-3 col-xl-6">

                            <label htmlFor="email">Email</label>

                            <input type="email"
                                name="email"
                                id="email"
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)} />
                            {errors.email && <span className="text-danger">{errors.email}<br></br></span>}

                        </div>

                        <div className="d-flex flex-direction-column offset-2 col-8 offset-xl-3 col-xl-6">

                            <label htmlFor="email">Téléphone (facultatif)</label>

                            <input type="tel"
                                name="phone"
                                id="phone"
                                value={phone}
                                required
                                onChange={(e) => setPhone(e.target.value)} />

                        </div>
                        <div className="d-flex flex-direction-column offset-2 col-8 offset-xl-3 col-xl-6">

                            <label htmlFor="password">Mot de passe</label>

                            <div className="d-flex">
                                <input type="password"
                                    name="password"
                                    id="password"
                                    value={password}
                                    ref={input}
                                    minLength="5"
                                    maxLength="50"
                                    required
                                    onChange={(e) => setPassword(e.target.value)} />

                                <svg onClick={showPassword} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="showPassword bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                            </div>

                            {errors.password && <span className="text-danger">{errors.password}<br></br></span>}
                        </div>



                        <div className="d-flex offset-2 col-8 offset-xl-3 col-xl-6 mt-4">
                            <div className="agreeText" onClick={agreeCgu}>
                                {agree ?
                                    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.3344 2.00018H7.66543C4.26785 2.00018 2.00043 4.43278 2.00043 7.91618V16.0842C2.00043 19.5709 4.2618 22.0002 7.66543 22.0002H16.3334C19.738 22.0002 22.0004 19.5709 22.0004 16.0842V7.91618C22.0004 4.42969 19.7382 2.00018 16.3344 2.00018ZM7.66543 3.50018H16.3344C18.8849 3.50018 20.5004 5.23515 20.5004 7.91618V16.0842C20.5004 18.7653 18.8848 20.5002 16.3334 20.5002H7.66543C5.11519 20.5002 3.50043 18.7655 3.50043 16.0842V7.91618C3.50043 5.23856 5.12077 3.50018 7.66543 3.50018ZM16.0903 9.09685C15.7974 8.80396 15.3225 8.80396 15.0296 9.09685L10.813 13.312L8.97015 11.4697L8.88601 11.3971C8.59235 11.1793 8.1757 11.2036 7.90949 11.47C7.61665 11.7629 7.61675 12.2378 7.90971 12.5306L10.2837 14.9036L10.3678 14.9762C10.6614 15.194 11.078 15.1697 11.3443 14.9035L16.0903 10.1575L16.1629 10.0734C16.3807 9.77978 16.3565 9.36312 16.0903 9.09685Z" fill={"#5A8AFF"} />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#6c757d" viewBox="0 0 16 16">
                                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                }
                                <p className={`${agree ? "accepted" : ""}`}> J'accepte les conditions générales d'utilisation.</p>
                            </div>
                        </div>


                        <div className="offset-2 col-8 offset-xl-3 col-xl-6">
                            {errors.agree && <span className="text-danger">{errors.agree}<br></br></span>}
                        </div>


                        <div className="offset-2 col-8 offset-xl-3 col-xl-6">
                            <button className="login-btn" onClick={handleRegister}>Créer un compte</button>
                        </div>

                        <Link className="redirect" to="/login">Se connecter</Link>


                        <div className="row my-5" id="btn-block">

                            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
                                <GoogleLogin
                                    onSuccess={responseGoogle}
                                    onError={responseGoogle}
                                />
                            </GoogleOAuthProvider>



                            {/* <MicrosoftLogin
                                clientId={process.env.REACT_APP_MICROSOFT_APP_ID}
                                onClick={authHandlerMicrosoft}
                                className=""
                                buttonTheme='light_short'
                            >
                                <button className="microsoft-btn">
                                    <picture>
                                        <source srcSet={MicrosoftLogoWebp} type="image/webp" />
                                        <img src={MicrosoftLogo} alt="Microfost logo" />
                                    </picture>
                                    <p>Inscrivez-vous avec Outlook</p>
                                </button>
                            </MicrosoftLogin> */}
                        </div>


                        <div className="row justify-content-center">
                            {errorsGoogle.length > 0 && <p className="googleErrors text-danger mt-2">{errorsGoogle}</p>}
                            {/* {errorsMicrosoft.length > 0 && <p className="googleErrors text-danger mt-2">{errorsMicrosoft}</p>} */}
                        </div>

                        {/* </div> */}

                    </div>
                </div>

            </div>
        </>
    )
}
