import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}

// _______________________________ Stripe _________________________________________



// __________________ init a payment by PriceId ___________________
export async function initPayment(stripePriceId, setResponse, robotId, recurring) {
    await axios({
        method: 'POST',
        url: url + 'auth/initPayment',
        headers: headersAuth,
        data: {
            'stripePriceId': stripePriceId,
            'robotId': robotId,
            'recurring': recurring,
        }
    })
        .then(function (response) {
            setResponse(response.data.sessionId)
        })
}

export async function getInvoices(setInvoices, setIsLoaded) {
    await axios({
        url: url + 'auth/invoices',
        headers: headersAuth,
    })
        .then((response) => {
            if (response.data) {
                setInvoices(response.data.invoices)
                setIsLoaded(true)
            }
        }, (err) => {
            setInvoices(false)
        });
}


// __________________ Get informations by priceId ________________
export async function getPrice(setResponse, id) {
    await axios({
        url: url + 'auth/price/' + id,
        headers: headersAuth,
    })
        .then((response) => {
            // Send response for check token 
            if (response) {
                setResponse(response.data.price)
            }

        }, (err) => {
            setResponse(err)
        });
}