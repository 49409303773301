import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

const headers = {
    'Content-Type': 'application/json'
};
const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}

// __________________ Get Robot ___________________
export async function getRobot(setResponse, id) {
    await axios({
        url: url + 'robots/' + id,
        headers: headers,

    })
        .then((response) => {
            // Send response for check token 
            if (response) {
                setResponse(response.data)
            }

        }, (err) => {
            setResponse(false)
        });
}


export async function issetBot(setResponse, robot) {
    await axios({
        method: "post",
        url: url + 'auth/issetbot',
        headers: headersAuth,
        data: {
            "robot": robot
        }
    })
        .then((response) => {
            // Send response for check if subscription exist
            if (response.data) {
                setResponse(response.data)
            }
        }, (err) => {
            setResponse(false)
        });
}
