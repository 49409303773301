import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Services -----
import { getOneSubscription } from '../../../../../services/User/subscription'
import { getBotPatientieleprofile } from '../../../../../services/User/patientele'

// ----- Images -----
import Doctolib from '../../../../../images/Integration/doctolib.png'
import DoctolibWebp from '../../../../../images/Integration/doctolib.webp'

export default function HomeStep3() {

    let navigate = useNavigate()

    const [mySubscription, setMySubscription] = useState([])
    const [myProfile, setMyProfile] = useState([])

    useEffect(() => {
        getOneSubscription(setMySubscription, 1)
        getBotPatientieleprofile(setMyProfile)
    }, [])

    // Check if step 1 and 2 is done
    useEffect(() => {
        if (typeof mySubscription.error !== 'undefined') {
            navigate("/tarifs?assistant=" + process.env.REACT_APP_BOT_NAME_PATIENTELE + "&pack-annuel=true")
        }
        // eslint-disable-next-line
    }, [mySubscription]);

    // Check if doctolib account is not already connected with us
    useEffect(() => {
        if (myProfile.doctolib === "connected") {
            navigate('/espace-client/accueil?subscription=success');
        }
        // eslint-disable-next-line
    }, [myProfile.doctolib])



    return (
        <>
            <Helmet title="Souscription - Étape 3/3" />

            <div className="subscription-view container-fluid doctolib-connection">

                <div className="row">

                    <div className="offset-1 col-10 col-md-10 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6 shadow-frame">
                        <div className="subscribForm-view">
                            <div className="d-flex flex-direction-column">
                                <h1>Assistant de Gestion Patientèle</h1>

                                <p className="ask-information gmail-explains">
                                    Voulez-vous que vos factures soient générées et envoyées à la vitesse de la lumière?
                                    <br></br>
                                    Connectez dés à présent votre agenda Doctolib!
                                </p>

                                <picture>
                                    <source srcSet={DoctolibWebp} type="image/webp" />
                                    <img src={Doctolib} alt='Logo Doctolib' id='doctolib-logo' />
                                </picture>

                                <div id="btn-block-1" className="d-flex max-xs-flex-direction-column-reverse col-12 offset-xl-1 col-xl-10">
                                    <button className="btn-greyFull" id="btn-grey" onClick={() => navigate('/espace-client/souscription-etape3/guide_utilisation')}>Je n'ai pas Doctolib</button>
                                    <button className="btn-alizFull" id="btn-blue" onClick={() => navigate('/espace-client/souscription-etape3/connecter_doctolib')}>Suivant</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
