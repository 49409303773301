import React from 'react'

// ----- Packages -----
import { Helmet } from 'react-helmet-async';

// ----- Components -----
import Navbar from './NavTerms'

export default function Cgu() {
    return (
        <>
            <Helmet title="CGU" />

            <div className="conditionsTerms container-fluid">

                <div className="row">

                    <div className="nav-clientArea col-10 col-md-6 col-lg-4 col-xl-3">
                        <Navbar />
                    </div>

                    <div className="offset-1 col-10 offset-md-1 col-md-10 offset-lg-0 col-lg-7 col-xl-6">

                        <h1>Conditions générales d’utilisation des sites « ALIZ »</h1>

                        <h2 className="catch-phrase">Mise à jour : le 09/07/2021</h2>


                        <h2 className="catch-phrase">1. Objet</h2>

                        <p className="conditionsTerms-text">
                            La Société TED (ci-après « <b>TED</b> » ou « <b>l’Éditeur</b> ») édite et gère les sites internet
                            <a href="https://aliz.io">aliz.io</a> et <a href="https://calendar.aliz.io">calendar.aliz.io</a>
                            (ci-après « <b>les Sites</b> »).
                            <br></br>
                            Les présentes Conditions Générales d’Utilisation (ci-après « <b>CGU</b> ») définissent et encadrent les modalités
                            d’accès et de navigation sur les Sites et déterminent les droits et les obligations respectifs de toute
                            personne utilisant les Sites (l’« <b>Utilisateur</b> ») et de l’Éditeur dans le cadre de l’utilisation des Sites.
                            <br></br>
                            Chaque Utilisateur doit prendre connaissance des Conditions Générales d’Utilisation,
                            de la <a href="/conditions-generales/politique-de-confidentialite">Politique de confidentialité</a>
                            et de la <a href="/conditions-generales/politique-utilisation-des-cookies">Politique de cookies</a> .
                            <br></br>
                            <b>En naviguant et en créant un compte sur les Sites, l’Utilisateur s’engage à respecter les présentes CGU, qui lui sont directement applicables. </b>
                            <br></br>
                            <b>Modifications des CGU.</b> TED se réserve le droit d'apporter des modifications à tout moment aux présentes CGU.
                            Les CGU applicables sont accessibles sur les Sites, l’Utilisateur est invité à en prendre connaissance régulièrement.
                        </p>





                        <h2 className="catch-phrase">2. Conditions d’accès </h2>

                        <p className="conditionsTerms-text">
                            L’Utilisateur peut choisir de naviguer sur les Sites sans créer de compte utilisateur ou de créer un compte utilisateur afin
                            d’avoir accès aux fonctionnalités des Sites.
                        </p>

                        <h3>4.1 Inscription sur les Sites</h3>

                        <p className="conditionsTerms-text">Lors de l’inscription sur le Site, l’Utilisateur a plusieurs choix :</p>
                        <ul className="conditionsTerms-text">
                            <li>I. Il peut choisir de créer un compte directement sur le Site en indiquant ses nom, prénom, adresse mail, un mot de passe et un numéro de téléphone ;</li>
                            <li>II. Il peut choisir de créer son compte utilisateur à partir de son compte Google ;</li>
                            <li>III. Il peut choisir de créer son compte utilisateur à partir de son compte Outlook ;</li>
                        </ul>

                        <p className="conditionsTerms-text">
                            Lorsque le compte est créé via Google ou Outlook, l’Utilisateur n’a pas à saisir un mot de passe.
                            <br></br>
                            L’Utilisateur doit également accepter les présentes CGU.
                            <br></br>
                            L’Utilisateur recevra un mail de confirmation de création de son compte utilisateur.
                        </p>


                        <h3>4.2 Conditions liées à l’Utilisateur  </h3>

                        <p className="conditionsTerms-text">
                            L’Utilisateur doit être âgé de minimum 18 ans à la date d’inscription aux Sites.
                            Si un mineur souhaite s’inscrire, son ou ses tuteurs légaux devront superviser l’utilisation des Sites par le mineur.
                        </p>







                        <h2 className="catch-phrase">3.Contenu des Sites</h2>


                        <p className="conditionsTerms-text">
                            Les Sites permettent aux Utilisateurs de trouver des informations sur les services d’assistants virtuels,
                            proposés par ALIZ (les « <b>Services ALIZ</b> »).
                            Les Utilisateurs ont la possibilité de souscrire aux Services ALIZ dans les conditions stipulées par les Conditions Générales
                            de Vente (ci-après « <b>CGV</b> »).
                            <br></br>
                            Ces Services ALIZ sont :
                        </p>
                        <ul className="conditionsTerms-text">
                            <li>
                                <b>L’assistant virtuel « Gestion patientèle »</b> est un assistant permettant de gérer la patientèle des professionnels de santé
                                par l’envoi de mail de factures, basé sur un modèle de plans de facturation.
                                Il est disponible en plusieurs packs (Pack Starter, pack Business et pack Premium) ;
                            </li>

                            <li>
                                <b>L’assistant virtuel « Déclaration TVA »</b> est un assistant permettant de transmettre des déclarations
                                TVA sur un modèle de plans de facturation.
                                Il est disponible en plusieurs packs (Pack Starter, pack Business et pack Premium) ;
                            </li>
                            <li>
                                <b>L’assistant virtuel « Gestion de rendez-vous »</b> gère les rendez-vous patients des professionnels de santé.
                                Il est disponible en plusieurs packs (Pack Starter, pack Business et pack Premium) ;
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            La description complète de ces Services ALIZ est disponible dans les
                            <a href="/conditions-generales/conditions-utilisation-des-services">Conditions d’Utilisation des Services d’ALIZ</a> (ci-après « <b>CUSA</b> »).
                        </p>






                        <h2 className="catch-phrase">4. Droits et les obligations de l’Utilisateur</h2>

                        <p className="conditionsTerms-text">
                            <b>Accessibilité :</b> Le Client pourra utiliser le Service 24h/24, 7j/7, y compris les dimanches et jours fériés,
                            hors périodes de maintenance décidée par TED.
                            Pour autant, TED ne garantit pas la disponibilité permanente et continue des Sites.
                            <br></br>
                            <b>Inscription :</b> L’Utilisateur s’engage à fournir des informations à jour, exactes et complètes à son inscription.
                            L’Utilisateur s’engage à rectifier les données inexactes le cas échéant.
                            <br></br>
                            <b>Utilisation non autorisée du compte :</b> L’Utilisateur s’engage à porter immédiatement à la connaissance de TED
                            toute utilisation non autorisée de ses identifiants, mots de passe et comptes en
                            contactant le support dédié à l’adresse contact@aliz.io.
                            <br></br>
                            L’Utilisateur ne doit pas utiliser les Services ALIZ s'il lui est légalement interdit de recevoir ou
                            d'utiliser ledit Service ALIZ en vertu des lois du pays dans lequel il réside,
                            ou à partir duquel il accède audit Service ALIZ ou utilise ledit Service ALIZ.
                            <br></br>
                            S’il s’avère que l’Utilisateur a un lien quelconque avec l’usage frauduleux d’un compte utilisateur,
                            il sera procédé à la suppression de son compte utilisateur.
                            <br></br>
                            L’Utilisateur sera responsable des pertes, dommages et frais encourus par TED ou par un tiers en cas d’utilisation de son compte par un tiers.
                            <br></br>
                            <b>Utilisation prohibée :</b> L’Utilisateur ne doit pas utiliser son compte utilisateur d'une manière illégale ou
                            interdite par les présentes CGU. Il est interdit à l’Utilisateur de :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>
                                D’usurper l'identité d'une autre personne ou entité d'une manière qui induit ou vise à induire en erreur,
                                à semer la confusion ou à tromper autrui ;
                            </li>
                            <li>
                                D’accéder ou de se maintenir frauduleusement dans les Sites, d’entraver ou d’altérer le fonctionnement de celui-ci notamment
                                en y introduisant des virus ou tout autre programme susceptible de causer un dommage et/ou de porter atteinte aux données des Sites ;
                            </li>
                            <li>
                                D’utiliser les Sites dans un but illicite, illégal, malveillant ou discriminatoire ;
                            </li>
                            <li>
                                De collecter des informations relatives aux autres Utilisateurs, ou de nuire aux autres Utilisateurs, directement ou indirectement.
                            </li>
                        </ul>

                        <p className="conditionsTerms-text">
                            <b>Responsabilité.</b> L’Utilisateur est seul responsable de la conservation de la confidentialité
                            de ses codes d’accès à son compte Utilisateur, sa boite de messagerie électronique, son calendrier,
                            à son Drive et à toutes autres applications partagées pour l’utilisation des Services ALIZ.
                            TED décline toute responsabilité en cas d’utilisation des Sites par une personne autre que l’Utilisateur ou une personne autorisée par lui,
                            disposant des identifiants et mots de passe.
                            Aussi, toute action réalisée à partir du compte de l’Utilisateur sera réputée effectuée par ce dernier et susceptible d’engager sa responsabilité.
                            <br></br>
                            <b>Suppression.</b> L’Utilisateur pourra procéder à la suppression de son compte soit en contactant le service contact@aliz.io.
                        </p>





                        <h2 className="catch-phrase">5. Traitement des données personnelles et gestion des cookies</h2>

                        <p className="conditionsTerms-text">
                            Dans le cadre de l’utilisation des Sites et de la création du compte utilisateur, TED est susceptible de recevoir,
                            d’accéder ou de traiter des données personnelles.
                            À ce titre, TED a la qualité de responsable de traitement au sens de la Règlementation en matière de protection des données personnelles.
                            Les informations relatives au traitement des données personnelles sont disponibles dans la
                            <a href="/conditions-generales/politique-de-confidentialite">Politique de confidentialité</a>,
                            rubrique « Vous êtes utilisateur des sites internet ».
                            La gestion des cookies est décrite dans la
                            <a href="/conditions-generales/politique-utilisation-des-cookies">Politique Cookies</a>.
                        </p>






                        <h2 className="catch-phrase">6. Propriété intellectuelle </h2>

                        <p className="conditionsTerms-text">
                            Le Site et les services ALIZ ainsi que tout leur contenu
                            (logiciel, clips, infographies, outil, logo, marque, texte éditorial et informatif, documents contractuels, etc.)
                            susceptible d’être protégé par le droit de la propriété intellectuelle demeure sa propriété exclusive de TED.
                            <br></br>
                            Les Sites et les Services ALIZ, chacun des éléments qui les compose, et notamment les textes communiqués, présentations,
                            brochures, illustrations, logos, bases de données, sont la propriété pleine, entière et exclusive de TED.
                            Ces éléments sont protégés par des droits de propriété intellectuelle.
                            TED accorde à l’Utilisateur un simple droit d’usage des Sites, personnel, non cessible, non exclusif et pour ses seuls besoins.
                            L’Utilisateur n’est pas autorisé à reproduire, représenter, modifier, traduire et/ou adapter, partiellement ou totalement,
                            à titre onéreux ou gratuit, les Sites et chacun des éléments qui les compose, et reproduire et/ou représenter de telles traductions,
                            adaptations et modifications, partiellement ou totalement, à titre onéreux ou gratuit, sans l’accord préalable écrit de TED.
                        </p>





                        <h2 className="catch-phrase">7. Liens hypertextes </h2>

                        <p className="conditionsTerms-text">
                            TED s’assure que les Sites ne comportent pas de lien hypertexte permettant d’accéder directement ou indirectement à
                            des sites ou à des pages de sites dont le contenu serait illicite et prendre sans délai toutes dispositions pour
                            les supprimer le cas échéant.
                            TED ne saurait cependant engager sa responsabilité au-delà de cette obligation.
                        </p>






                        <h2 className="catch-phrase">8. Responsabilité de TED</h2>

                        <p className="conditionsTerms-text">
                            <b>Principe de responsabilité.</b> TED n’est pas responsable en cas de pertes, dommages, responsabilités,
                            dépenses ayant pour source une négligence ou un manquement de l’Utilisateur aux CGU, ou d’un tiers.
                            <br></br>
                            TED n’est pas responsable en cas d’interruption de l’accès aux Sites et des conséquences qui en découleront pour l’Utilisateur.
                            <br></br>
                            <b>Limitation de responsabilité.</b> La responsabilité de TED ne pourra pas non plus être engagée en cas de :
                        </p>

                        <ul className="conditionsTerms-text">
                            <li>
                                I. défaut de fourniture par l’Utilisateur d’une adresse de messagerie valable ;
                            </li>
                            <li>
                                II. défaut de fourniture par l’Utilisateur des accès suffisants à son ou ses calendriers ;
                            </li>
                            <li>
                                III. défaut de respect par l’Utilisateur de la configuration minimale requise pour l’utilisation du Service ;
                            </li>
                            <li>
                                IV. utilisation non conforme ou illicite du Service par l’Utilisateur ;
                            </li>
                            <li>
                                V. indisponibilité et/ou déconnexion des calendriers et/ou défaut de synchronisation des données de l’Utilisateur
                                avec ses Calendriers entrainant notamment une possibilité de chevauchement de rendez-vous.
                            </li>
                        </ul>






                        <h2 className="catch-phrase">9. Droit applicable et juridiction compétente</h2>

                        <p className="conditionsTerms-text">
                            Les présentes CGU ainsi que leur objet et leur formation, sont régies par la loi française.
                            <br></br>
                            En cas de plaintes ou de différend, l’Utilisateur peut contacter contact@aliz.io. L’Utilisateur et ALIZ s’efforceront de trouver une
                            solution amiable avant toute saisine du tribunal compétent.
                            <br></br>
                            En l’absence d’accord ou en cas d’échec d’une solution amiable à la réclamation, plainte ou différend dans un délai raisonnable,
                            LE LITIGE SERA SOUMIS AUX TRIBUNAUX COMPÉTENTS DE PARIS.
                        </p>



                    </div>
                </div>

            </div>
        </>
    )
}
