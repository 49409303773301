import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

// Images
import Logo from '../../images/logo/Logo Blanc_Sans Texte.png'
import Illustration from '../../images/Login/Illustration - Hero.svg'


//  ----- Services ----- 
import { ForgotPasswordLink } from '../../services/User/register.js'

// ----- Component ----- 
import Popup from '../Popup'

// ----- Packages -----
import { Helmet } from 'react-helmet-async'; 


export default function ForgotPassword({ token }) {

    const [email, setEmail] = useState("")
    const [errors, setErrors] = useState([])
    const [response, setResponse] = useState("")

    // Popup
    const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false)

    let navigate = useNavigate()


    const handleReset = (e) => {
        e.preventDefault();
        setErrors([])
        setResponse("")
        ForgotPasswordLink(email, setResponse, setErrors)
    };

    useEffect(() => {
        if (typeof response !== "undefined" && response.length > 1) {
            setIsForgotPasswordVisible(true)
        }
    }, [response])

    useEffect(() => {
        if (token) {
            navigate(-1)
        }
    }, [token, navigate]);

    return (
        <>
            <Helmet title="Mot de passe oublie" />

            <div className="login-view">
                <div className="row justify-content-center">

                    <div className="col-sm-12 col-lg-6 illustration-block">
                        {/* ----- Logo ----- */}
                        <Link className="logo d-flex" to="/">
                            <img src={Logo} alt="Aliz Logo" />
                            <span>Aliz</span>
                        </Link>
                        {/* ----- Illustration ----- */}
                        <img className="illustration-login" src={Illustration} alt="Men under wave" />
                    </div>


                    <div className="col-sm-12 col-lg-6 login-block">

                        <h1 className="offset-2 col-8 offset-xl-3 col-xl-6">Mot de passe oublié:</h1>

                        <h2 className="offset-2 col-8 offset-xl-3 col-xl-6">Insérez l'email de votre compte ci-dessous. Vous recevrez un mail pour réinitialiser votre mot de passe.</h2>


                        <div className="d-flex flex-direction-column offset-2 col-8 offset-xl-3 col-xl-6 mt-4">

                            <label htmlFor="email">Email</label>

                            <input type="email"
                                name="email"
                                id="email"
                                className="login-input"
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)} />

                            {errors.email && <p className="text-danger mt-2">{errors.email}</p>}
                            {errors.user && <p className="text-danger mt-2">{errors.user}</p>}

                        </div>


                        <div className="offset-2 col-8 offset-xl-3 col-xl-6">
                            <button className="login-btn" onClick={handleReset}>Envoyer</button>
                        </div>


                        <Link className="redirect" to="/login">Se connecter</Link>


                        <div className="row justify-content-center">
                        </div>
                    </div>

                </div>


                <Popup
                    text="Un email viens de vous être envoyé avec un lien de réinitialisation! Attention, le lien n'est valide que 24h."
                    isVisible={isForgotPasswordVisible}
                />

            </div>
        </>
    )
}
