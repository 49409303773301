import React from 'react'
import ReactReadMoreReadLess from "react-read-more-read-less";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Testimonial({data}) {
    const requestImageFile = require.context('../../../../images/Testimonials/optimized/', true);

    const rates = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
    </svg>;

    return (
        <div className="testimonial-block">
            <div className="d-flex">
                <LazyLoadImage
                    alt={"profile users"}
                    src={requestImageFile(`./${data.picture}`)} // use normal <img> attributes as props
                    className="testimonial-picture"
                    effect="blur" />

                <div className="d-flex flex-direction-column ml-3">
                    <p className="testimonial-name">{data.firstname}</p>
                    <p className="testimonial-job">{data.job}</p>
                </div>
            </div>
            <div className="d-flex flex-direction-column">

                <div className="d-flex">
                    {rates}
                    {rates}
                    {rates}
                    {rates}
                    {rates}
                </div>

                <div className="testimonial-description">
                    <ReactReadMoreReadLess
                        charLimit={150}
                        readMoreText={"Lire plus"}
                        readLessText={"Lire moins"}>
                        {"« " + data.description + " »"}
                    </ReactReadMoreReadLess>
                </div>

                {/* <a className="testimonial-link" href="/">Lire l’avis sur Google</a> */}
            </div>

        </div>
    )
}
